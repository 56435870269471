import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<ActivityTask>(
    QUERY_KEYS.ACTIVITY_TASKS.EDIT(id as string),
    () => ApiService.ActivityTasks.get(id as string),
  )

  const onSave = useCallback(
    async (activityTask: ActivityTask) => {
      try {
        if (!id) {
          openErrorToast({
            message: `Erro ao editar ${activityTask.name}!`,
          })

          return
        }

        await ApiService.ActivityTasks.update({
          id: id,
          activityTask: {
            name: activityTask.name as string,
            default: !!Number(activityTask.default),
            active: !!Number(activityTask.active),
          },
        })

        openSuccessToast({
          message: `A tarefa ${activityTask.name} foi atualizado com sucesso!`,
        })

        navigate(ROUTES.ACTIVITY_TASKS.LIST)
      } catch (saveError) {
        openErrorToast({
          message: `Erro ao editar ${activityTask.name}!`,
        })
      }
    },
    [id, navigate, openErrorToast, openSuccessToast],
  )

  return <Add activityTask={data} onSave={onSave} isEditMode />
}
