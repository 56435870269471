import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (
  params?: GetAllParams,
): Promise<InstitutionApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.post<InstitutionApiResponse[]>(
      ENDPOINTS.INSTITUTIONS.GET_ALL,
      {
        ...params,
      },
    )

    return getAllResponse.data.sort((currentInstitution, nextInstitution) =>
      currentInstitution.fantasy_name.localeCompare(
        nextInstitution.fantasy_name,
      ),
    )
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (
  params?: GetAllParams,
): Promise<GelAllNamesAndIdsOptions[]> => {
  try {
    const getAllResponse = await getAll(params)
    return (getAllResponse || []).map((institution) => ({
      label: `${institution.fantasy_name} - ${institution.document}`,
      value: institution.id.toString(),
      type: institution.type,
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<InstitutionApiResponse> => {
  try {
    const getResponse = await apiInstance.get<InstitutionApiResponse>(
      ENDPOINTS.INSTITUTIONS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  institution: InstitutionsApiRequest,
): Promise<InstitutionApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<InstitutionApiResponse>(
      ENDPOINTS.INSTITUTIONS.CREATE,
      institution,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  institution,
}: {
  institution: InstitutionsApiRequest
  id: string
}): Promise<InstitutionApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<InstitutionApiResponse>(
      ENDPOINTS.INSTITUTIONS.UPDATE(id),
      institution,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.INSTITUTIONS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
