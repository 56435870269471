import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const listGrouped = async ({
  roleId,
}: {
  roleId: string
}): Promise<RolePermissionsApiResponse> => {
  try {
    const getAllResponse = await apiInstance.get<RolePermissionsApiResponse>(
      ENDPOINTS.ROLES.PERMISSIONS.LIST_GROUPED(roleId, '0'),
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async ({
  permissionId,
  roleId,
}: {
  permissionId: string
  roleId: string
}): Promise<RolePermissionsApiResponse> => {
  try {
    const getAllResponse = await apiInstance.delete<RolePermissionsApiResponse>(
      ENDPOINTS.ROLES.PERMISSIONS.DELETE(permissionId, roleId),
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async ({
  permissionId,
  roleId,
}: {
  permissionId: string
  roleId: string
}): Promise<RolePermissionsApiResponse> => {
  try {
    const getAllResponse = await apiInstance.post<RolePermissionsApiResponse>(
      ENDPOINTS.ROLES.PERMISSIONS.CREATE,
      {
        role_id: roleId,
        permission_id: permissionId,
      },
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
