import { useEffect, useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Autocomplete, Breadcrumbs, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AddressService } from 'services/address'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (supplier: SupplierApiRequest) => Promise<void>

type AddProps = {
  supplier?: SupplierApiResponse
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({
  supplier,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    unregister,
  } = useForm<SupplierApiRequest>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    SupplierApiRequest | undefined
  >()

  const { data: commercialUnits, isLoading: isLoadingComercialUnits } =
    useQuery<SelectProps['options']>(
      QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
      () =>
        ApiService.CommercialUnits.getAllNamesAndIds({
          fields: ['id', 'fantasy_name', 'document'],
          relations: [],
        }),
    )

  const [documentType, setDocumentType] =
    useState<SupplierApiRequest['type']>('')

  const { data: categories, isLoading: isLoadingCategories } = useQuery<
    SelectProps['options']
  >(QUERY_KEYS.CATEGORIES.NAMES_AND_IDS, () =>
    ApiService.Categories.getAllNamesAndIds('supplier'),
  )

  useEffect(() => {
    if (isEditMode && !!supplier && !formUpdated) {
      reset({
        commercial_unit_id: supplier.commercial_unit_id,
        name: supplier.name,
        document: supplier.document,
        type: supplier.type,
        state_registration: supplier.state_registration,
        city_registration: supplier.city_registration,
        fantasy_name: supplier.fantasy_name,
        street: supplier.street,
        number: supplier.number,
        complement: supplier.complement,
        district: supplier.district,
        city: supplier.city,
        uf: supplier.uf,
        zip_code: supplier.zip_code,
        email: supplier.email,
        website: supplier.website,
        phone: supplier.phone,
        cell_phone: supplier.cell_phone,
        contact_name: supplier.contact_name,
      })

      setValue('type', supplier.type)

      setValue('active', supplier.active.toString() as unknown as number)
      setValue(
        'categories',
        supplier.categories?.map((category) => {
          const findDataCategory = categories?.find(
            (item) => Number(item.value) === category,
          )
          if (findDataCategory) {
            return {
              ...findDataCategory,
            }
          }
          return findDataCategory
        }),
      )
      setTimeout(() => {
        setFormUpdated(true)
      }, 500)
    }
  }, [isEditMode, supplier, formUpdated, reset, setValue, categories])

  const addNewSupplier = useMutation({
    mutationFn: async (supplier: SupplierApiRequest) => {
      if (!supplier.complement) {
        // FIXME: make this information optional
        supplier.complement = ' '
      }

      await ApiService.Suppliers.create(supplier)
    },
    onSuccess: (_data, supplier: SupplierApiRequest) => {
      queryClient.setQueryData<SupplierApiRequest[]>(
        QUERY_KEYS.SUPPLIERS.LIST,
        (oldSuppliers) => {
          if (!oldSuppliers?.length) return

          return oldSuppliers.map((currentSupplier) =>
            currentSupplier.document === supplier.document
              ? supplier
              : currentSupplier,
          )
        },
      )

      openSuccessToast({
        message: `O fornecedor ${supplier.fantasy_name} foi adicionado com sucesso!`,
      })

      navigate(ROUTES.SUPPLIERS.LIST)
    },
    onError: (error: ApiError, supplier: SupplierApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${supplier.fantasy_name}!`,
      })
    },
  })

  const inputProps = useCallback(
    (fieldName: keyof SupplierApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  useEffect(() => {
    if (!isEditMode) {
      setValue('document', '')
    }
    if (documentType === 'pf') {
      unregister('fantasy_name')
      unregister('state_registration')
      unregister('city_registration')
      unregister('website')
    }
  }, [documentType, isEditMode, setValue, unregister])

  if (
    (isEditMode && !supplier) ||
    isLoadingCategories ||
    isLoadingComercialUnits
  ) {
    return <LoadingFullPage />
  }
  if (isEditMode && !formUpdated) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.SUPPLIERS.LIST}
            >
              Fornecedores
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} fornecedor
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.SUPPLIERS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((supplier: SupplierApiRequest) => {
                const copyCategories: { label: string; value: number }[] = [
                  ...JSON.parse(JSON.stringify(supplier.categories)),
                ]
                supplier.categories = copyCategories.map((item) =>
                  Number(item.value),
                )
                if (isEditMode) {
                  onSave?.(supplier)
                  return
                }

                addNewSupplier.mutate(supplier)
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={6}>
                      <Controller
                        name="commercial_unit_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Unidade comercial"
                              id="commercial_unit_id"
                              onChange={onChange}
                              value={value}
                              options={commercialUnits || []}
                              autoFill={isEditMode}
                              error={!!errors.commercial_unit_id?.message}
                              helperText={errors.commercial_unit_id?.message}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field: { onChange, value }, formState }) => {
                          setDocumentType(value)

                          return (
                            <Select
                              label="Tipo"
                              id="type"
                              onChange={onChange}
                              value={value}
                              {...inputProps('type')}
                              autoFill={isEditMode}
                              error={!!formState.errors.type?.message}
                              helperText={formState.errors.type?.message}
                              disabled={isEditMode}
                              options={[
                                {
                                  label: 'Pessoa Física',
                                  value: 'pf',
                                },
                                {
                                  label: 'Pessoa Jurídica',
                                  value: 'pj',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Controller
                        name="categories"
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => {
                          return (
                            <Autocomplete
                              id="supplier-category"
                              options={categories || []}
                              noOptionsText="Nada encontrado"
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              value={value as any}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              disabledItemsFocusable
                              multiple
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Categorias"
                                  placeholder="Selecione"
                                  margin="normal"
                                  error={!!errors.categories?.message}
                                  helperText={errors.categories?.message}
                                />
                              )}
                              onChange={(_, data) => onChange(data)}
                              {...props}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label={documentType === 'pf' ? 'Nome' : 'Razão Social'}
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name', { required: true })}
                      />
                    </Grid>

                    {documentType === 'pj' && (
                      <Grid item xs={6}>
                        <TextField
                          error={!!errors.fantasy_name?.message}
                          fullWidth
                          helperText={errors.fantasy_name?.message}
                          label="Nome fantasia"
                          margin="normal"
                          type="text"
                          variant="outlined"
                          {...inputProps('fantasy_name')}
                          {...register('fantasy_name', { required: true })}
                        />
                      </Grid>
                    )}

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="document"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.document?.message}
                              helperText={errors.document?.message}
                              fullWidth
                              label={documentType === 'pj' ? 'CNPJ' : 'CPF'}
                              id="document"
                              maskType={documentType === 'pj' ? 'cnpj' : 'cpf'}
                              onChange={onChange}
                              value={value}
                              {...inputProps('document')}
                            />
                          )
                        }}
                      />
                    </Grid>

                    {documentType === 'pj' && (
                      <>
                        <Grid item xs={3}>
                          <Controller
                            name="state_registration"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.state_registration?.message}
                                  fullWidth
                                  helperText={
                                    errors.state_registration?.message
                                  }
                                  label="Inscrição Estadual"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('state_registration')}
                                  onChange={(event) => {
                                    event.target.value =
                                      event.target.value.replace(
                                        /[a-z,A-Z]/g,
                                        '',
                                      )
                                    onChange(event)
                                  }}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controller
                            name="city_registration"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.city_registration?.message}
                                  fullWidth
                                  helperText={errors.city_registration?.message}
                                  label="Inscrição Municipal"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('city_registration')}
                                  onChange={(event) => {
                                    event.target.value =
                                      event.target.value.replace(
                                        /[a-z,A-Z]/g,
                                        '',
                                      )
                                    onChange(event)
                                  }}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.contact_name?.message}
                        fullWidth
                        helperText={errors.contact_name?.message}
                        label="Nome de contato"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('contact_name')}
                        {...register('contact_name')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email', { required: true })}
                      />
                    </Grid>

                    {documentType !== 'pf' && (
                      <Grid item xs={3}>
                        <TextField
                          error={!!errors.website?.message}
                          fullWidth
                          helperText={errors.website?.message}
                          label="Website"
                          margin="normal"
                          type="text"
                          variant="outlined"
                          {...inputProps('website')}
                          {...register('website', { required: true })}
                        />
                      </Grid>
                    )}

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('cell_phone')}
                              error={!!errors.cell_phone?.message}
                              helperText={errors.cell_phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('phone')}
                              error={!!errors.phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    {isEditMode && (
                      <Grid item xs={1}>
                        <Controller
                          name="active"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                label="Ativo"
                                id="active"
                                onChange={onChange}
                                value={value}
                                {...inputProps('active')}
                                autoFill={isEditMode}
                                options={[
                                  {
                                    label: 'Sim',
                                    value: '1',
                                  },
                                  {
                                    label: 'Não',
                                    value: '0',
                                  },
                                ]}
                                error={!!errors.active?.message}
                                helperText={errors.active?.message}
                              />
                            )
                          }}
                        />
                      </Grid>
                    )}

                    {documentType === 'pf' && <Grid item xs={3}></Grid>}

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="zip_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('zip_code')}
                              error={!!errors.zip_code?.message}
                              helperText={errors.zip_code?.message}
                              fullWidth
                              label="CEP"
                              id="zip_code"
                              maskType="zipCode"
                              onChange={onChange}
                              value={value}
                              onBlur={async () => {
                                const address =
                                  await AddressService.getAddressByCEP(value)

                                if (!address) return

                                setValue('street', address.street, {
                                  shouldValidate: true,
                                })
                                setValue('district', address.neighborhood, {
                                  shouldValidate: true,
                                })
                                setValue('uf', address.state, {
                                  shouldValidate: true,
                                })
                                setValue('city', address.city, {
                                  shouldValidate: true,
                                })
                              }}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.street?.message}
                        fullWidth
                        helperText={errors.street?.message}
                        label="Endereço"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('street')}
                        {...register('street', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.number?.message}
                        fullWidth
                        helperText={errors.number?.message}
                        label="Número"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('number')}
                        {...register('number', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.complement?.message}
                        fullWidth
                        helperText={errors.complement?.message}
                        label="Complemento"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('complement')}
                        {...register('complement', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.district?.message}
                        fullWidth
                        helperText={errors.district?.message}
                        label="Bairro"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('district')}
                        {...register('district', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.city?.message}
                        fullWidth
                        helperText={errors.city?.message}
                        label="Cidade"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('city')}
                        {...register('city', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.uf?.message}
                        fullWidth
                        helperText={errors.uf?.message}
                        label="Estado"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('uf')}
                        {...register('uf', { required: true })}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                        marginRight: '24px',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        supplier={deleteConfirmation as SupplierApiResponse}
      />
    </>
  )
}
