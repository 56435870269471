import { ApiService } from 'services/api'

import { CreateOrderServiceRequest } from '../@types/form'

type HandleCreateOrderServiceProps = {
  onLoading: (loadingState: boolean) => void
  onClose?: () => void
  openErrorToast: (props: { message: string }) => void
  openSuccessToast: (props: { message: string }) => void
  data: CreateOrderServiceRequest
  activity?: { id: number }
}

export async function handleCreateOrderService({
  onLoading,
  openErrorToast,
  onClose,
  openSuccessToast,
  data,
  activity,
}: HandleCreateOrderServiceProps) {
  onLoading(true)
  try {
    await ApiService.OrderServices.create({
      ...data,
      activity_id: activity?.id,
    })
    openSuccessToast({
      message: 'Ordem de serviço atualizada com sucesso',
    })
    onClose?.()
  } catch (error: unknown) {
    openErrorToast({
      message:
        (error as ApiError).message || `Erro ao adicionar ordem de serviço!`,
    })
  } finally {
    onLoading(false)
  }
}
