import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import { SELECT_BRAZILIAN_STATES } from 'constants/brazilian-states'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AddressService } from 'services/address'
import { ApiService } from 'services/api'
import { CompanyService } from 'services/company'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (clients: ClientsApiRequest) => Promise<void>

type AddProps = {
  clients?: Clients
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({ clients, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<ClientsApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Clients | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!clients && !formUpdated) {
      reset({
        fantasy_name: clients.fantasy_name,
        document: clients.document,
        birth_date: clients.birth_date,
        cell_phone: clients.cell_phone,
        email: clients.email,
        phone: clients.phone,
        street: clients.street,
        number: clients.number,
        complement: clients.complement,
        district: clients.district,
        city: clients.city,
        uf: clients.uf,
        zip_code: clients.zip_code,
        country: clients.country,
        gender: clients.gender,
        document_type: clients.document_type,
        number_document: clients.number_document,
        rg_state_issue: clients.rg_state_issue,
      })

      setFormUpdated(true)
    }
  }, [isEditMode, clients, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof ClientsApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewClient = useMutation({
    mutationFn: async (clients: ClientsApiRequest) => {
      await ApiService.Clients.create(clients)
    },
    onSuccess: (_data, clients: ClientsApiRequest) => {
      openSuccessToast({
        message: `O cliente ${clients.fantasy_name} foi adicionado com sucesso!`,
      })

      navigate(ROUTES.CLIENTS.LIST)
    },
    onError: (error: ApiError, clients: ClientsApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${clients.fantasy_name}!`,
      })
    },
  })

  const handleGetZipCode = useCallback(
    async (value: string) => {
      const address = await AddressService.getAddressByCEP(value)

      if (!address) return

      setValue('street', address.street, {
        shouldValidate: true,
      })
      setValue('district', address.neighborhood, { shouldValidate: true })
      setValue('uf', address.state, { shouldValidate: true })
      setValue('city', address.city, { shouldValidate: true })
    },
    [setValue],
  )

  if (isEditMode && !!clients && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.CLIENTS.LIST}>
              Clientes
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} cliente
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(clients as Clients)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.CLIENTS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((clients: ClientsApiRequest) => {
                if (isEditMode) {
                  onSave?.(clients)
                  return
                }

                addNewClient.mutate(clients)
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={8}>
                      <TextField
                        error={!!errors.fantasy_name?.message}
                        fullWidth
                        helperText={errors.fantasy_name?.message}
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('fantasy_name')}
                        {...register('fantasy_name')}
                      />
                    </Grid>

                    <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="document"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.document?.message}
                              helperText={errors.document?.message}
                              fullWidth
                              label="CPF"
                              id="document"
                              maskType="cpfCnpj"
                              onChange={onChange}
                              onBlur={async () => {
                                if (
                                  value.replace(/[^0-9 ]/g, '').length === 14
                                ) {
                                  const company =
                                    await CompanyService.getCompanyByCNPJ(value)
                                  if (!company) return

                                  setValue(
                                    'fantasy_name',
                                    company.company_social_name,
                                    {
                                      shouldValidate: true,
                                    },
                                  )
                                  setValue('zip_code', company.cep, {
                                    shouldValidate: true,
                                  })
                                  setValue('email', company.email, {
                                    shouldValidate: true,
                                  })
                                  await handleGetZipCode(company.cep)
                                }
                              }}
                              value={value}
                              {...inputProps('document')}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        error={!!errors.email?.message}
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email')}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="birth_date"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.birth_date?.message}
                              fullWidth
                              label="Data de nascimento"
                              id="birth_date"
                              maskType="date"
                              {...inputProps('birth_date')}
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Controller
                        name="gender"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Sexo"
                              id="gender"
                              onChange={onChange}
                              value={value}
                              options={[
                                {
                                  label: 'Não desejo informar',
                                  value: 'not_inform',
                                },
                                {
                                  label: 'Masculino',
                                  value: 'masculine',
                                },
                                {
                                  label: 'Feminino',
                                  value: 'feminine',
                                },
                              ]}
                              autoFill={isEditMode}
                              error={!!errors.gender?.message}
                              helperText={errors.gender?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Controller
                        name="document_type"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Tipo do documento"
                              id="document_type"
                              onChange={onChange}
                              value={value}
                              options={[
                                { value: 'rg', label: 'RG' },
                                { value: 'passport', label: 'Passaporte' },
                                { value: 'term', label: 'Termo' },
                                { value: 'cnh', label: 'CNH' },
                              ]}
                              autoFill={isEditMode}
                              error={!!errors.document_type?.message}
                              helperText={errors.document_type?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <TextField
                        label="Nº do documento"
                        fullWidth
                        margin="normal"
                        error={!!errors.number_document?.message}
                        helperText={errors.number_document?.message}
                        {...inputProps('number_document')}
                        {...register('number_document')}
                      />
                    </Grid>

                    {watch('document_type') === 'rg' && (
                      <Grid item xs={5}>
                        <Controller
                          name="rg_state_issue"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                label="Estado de emissão do RG"
                                options={SELECT_BRAZILIAN_STATES}
                                id="rg_state_issue"
                                onChange={onChange}
                                value={value}
                                autoFill={isEditMode}
                                error={!!errors.rg_state_issue?.message}
                                helperText={errors.rg_state_issue?.message}
                              />
                            )
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              {...inputProps('phone')}
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('cell_phone')}
                              error={!!errors.cell_phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="zip_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('zip_code')}
                              error={!!errors.zip_code?.message}
                              helperText={errors.zip_code?.message}
                              fullWidth
                              label="CEP"
                              id="cep"
                              maskType="zipCode"
                              onChange={onChange}
                              value={value}
                              onBlur={() => handleGetZipCode(value)}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={9}>
                      <TextField
                        error={!!errors.street?.message}
                        fullWidth
                        helperText={errors.street?.message}
                        label="Endereço"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('street')}
                        {...register('street')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.number?.message}
                        fullWidth
                        helperText={errors.number?.message}
                        label="Número"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('number')}
                        {...register('number')}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        error={!!errors.complement?.message}
                        fullWidth
                        helperText={errors.complement?.message}
                        label="Complemento"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('complement')}
                        {...register('complement')}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        error={!!errors.district?.message}
                        fullWidth
                        helperText={errors.district?.message}
                        label="Bairro"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('district')}
                        {...register('district')}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        error={!!errors.city?.message}
                        fullWidth
                        helperText={errors.city?.message}
                        label="Cidade"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('city')}
                        {...register('city')}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Controller
                        name="uf"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Estado"
                              id="uf"
                              onChange={onChange}
                              value={value}
                              options={SELECT_BRAZILIAN_STATES}
                              autoFill={isEditMode}
                              error={!!errors.uf?.message}
                              helperText={errors.uf?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        error={!!errors.country?.message}
                        fullWidth
                        helperText={errors.country?.message}
                        label="País"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('country')}
                        {...register('country')}
                      />
                    </Grid>
                    {(!isEditMode ||
                      !clients?.parent_id ||
                      clients.parent_id === clients.id) && (
                      <Grid item xs={4}>
                        <TextField
                          error={!!errors.password?.message}
                          fullWidth
                          helperText={errors.password?.message}
                          label="Nova senha"
                          margin="normal"
                          type="text"
                          variant="outlined"
                          {...inputProps('password')}
                          {...register('password')}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        clients={deleteConfirmation as Clients}
      />
    </>
  )
}
