import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { GridToolbarContainer, type GridColDef } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (label: LabelsApiRequest) => Promise<void>

type AddProps = {
  label?: Label
  onSave?: OnSave
  isEditMode?: boolean
}

const getRandomId = () => {
  return Math.floor(Math.random() * 9999999)
}

function EditToolbar({
  setRows,
}: {
  setRows: React.Dispatch<React.SetStateAction<LabelsTable>>
}) {
  const handleClick = () => {
    const id = getRandomId()
    setRows((oldRows) => ({
      ...oldRows,
      fields: [...oldRows.fields, { id, field: '', oldField: '', isNew: true }],
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Adicionar
      </Button>
    </GridToolbarContainer>
  )
}

export default function Add({ label, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<LabelsApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [selectedEntity, setSelectedEntity] = useState<LabelsTable>()

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Label | undefined
  >()

  const { data: entitiesColumns } = useQuery<LabelsEntitiesColumns[]>(
    QUERY_KEYS.LABELS.ENTITIES_COLUMNS,
    async () => {
      const response = await ApiService.Labels.getAllColumnsEntities()
      return response
    },
  )

  useEffect(() => {
    if (isEditMode && !!label && !formUpdated) {
      reset({
        entity: label.entity,
        entity_translated: label.entity_translated,
      })
      const findEntity = entitiesColumns?.find(
        ({ entity }) => entity === label.entity,
      )
      if (!findEntity) {
        return
      }
      const labelsFields = Object.entries(label?.fields)
      let updatedEntity = labelsFields?.map(([key, value]) => ({
        id: getRandomId(),
        field: value,
        oldField: key,
      }))
      updatedEntity = [
        ...updatedEntity,
        ...findEntity.fields
          .filter((field) => {
            const findField = updatedEntity.find(
              (item) => item.oldField === field.field,
            )
            return !findField
          })
          .map((field) => ({
            id: getRandomId(),
            field: field.field,
            oldField: field.field,
          })),
      ]
      setSelectedEntity({ ...findEntity, fields: updatedEntity })

      setFormUpdated(true)
    }
  }, [isEditMode, label, formUpdated, reset, setValue, entitiesColumns])

  const inputProps = useCallback(
    (fieldName: keyof LabelsApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewLabel = useMutation({
    mutationFn: async ({ label }: { label: LabelsApiRequest }) => {
      await ApiService.Labels.create(label)
    },
    onSuccess: (_data, { label }: { label: LabelsApiRequest }) => {
      openSuccessToast({
        message: `O label para ${label.entity_translated} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.LABELS.LIST)
    },
    onError: (error: ApiError, { label }: { label: LabelsApiRequest }) => {
      openErrorToast({
        message:
          error.message || `Erro ao adicionar ${label.entity_translated}!`,
      })
    },
  })

  const selectedEntityColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'oldField',
        headerName: 'Nome Original',
        flex: 1,
        editable: true,
      },
      {
        field: 'field',
        headerName: 'Nome',
        flex: 1,
        editable: true,
      },
    ],
    [],
  )

  const processRowUpdate = (newRow: EntityField) => {
    const newFields =
      selectedEntity?.fields.map((row) =>
        row.id === newRow.id
          ? { ...newRow, oldField: newRow.oldField || row.oldField }
          : row,
      ) || []

    setSelectedEntity((oldSelectedEntity) => {
      if (oldSelectedEntity) {
        return {
          entity: oldSelectedEntity?.entity,
          entity_translated: oldSelectedEntity?.entity_translated,
          fields: newFields,
        }
      }
      return oldSelectedEntity
    })
    return newRow
  }

  if (isEditMode && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.LABELS.LIST}>
              Labels
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} label
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(label as Label)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.LABELS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((label: LabelsApiRequest) => {
                const newFields: Record<string, string> = {}
                selectedEntity?.fields.forEach((field) => {
                  if (field.oldField) {
                    newFields[field.oldField] = field.field
                    return
                  }
                  newFields[field.field] = field.field
                })
                const newLabel = {
                  ...label,
                  fields: newFields,
                }
                if (isEditMode) {
                  onSave?.(newLabel)
                  return
                }

                addNewLabel.mutate({ label: newLabel })
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="entity"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Entidade"
                              id="entity"
                              onChange={(event) => {
                                const findEntity = entitiesColumns?.find(
                                  ({ entity }) => entity === event.target.value,
                                )
                                if (!findEntity) return
                                setSelectedEntity({
                                  ...findEntity,
                                  fields: findEntity.fields.map((item) => ({
                                    id: getRandomId(),
                                    field: item.field || '',
                                    oldField: item.field || '',
                                  })),
                                })
                                setValue('entity_translated', '')
                                onChange(event)
                              }}
                              value={value}
                              options={(entitiesColumns || []).map(
                                ({ entity }) => ({
                                  label: entity,
                                  value: entity,
                                }),
                              )}
                              autoFill={isEditMode}
                              error={!!errors.entity?.message}
                              helperText={errors.entity?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.entity_translated?.message}
                        fullWidth
                        helperText={errors.entity_translated?.message}
                        label="Nome para entidade"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('entity_translated')}
                        {...register('entity_translated', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={10}>
                      <Typography sx={{ m: 1 }} variant="h4">
                        Campos
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Table
                        rows={selectedEntity?.fields || []}
                        columns={selectedEntityColumns}
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={processRowUpdate}
                        components={{
                          Toolbar: EditToolbar,
                        }}
                        componentsProps={{
                          toolbar: { setRows: setSelectedEntity },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        label={deleteConfirmation as Label}
      />
    </>
  )
}
