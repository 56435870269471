export const removeEmptyFieldsInObjectFieldsInObject = (
  obj: GenericObject,
): GenericObject => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => {
        if (typeof value === 'object') {
          return true
        }
        return value != null && value !== ''
      })
      .map(([key, value]: [string, unknown | string]) => [
        key,
        value && typeof value === 'object'
          ? removeEmptyFieldsInObjectFieldsInObject(value as GenericObject)
          : typeof value === 'string'
          ? value.toLowerCase()
          : value,
      ]),
  )
}
