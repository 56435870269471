import { useCallback, useMemo, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import WhatsApp from '@mui/icons-material/WhatsApp'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import LoadingFullPage from 'components/LoadingFullPage'
import { SelectProps } from 'components/Select'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

export default function Affiliates() {
  const navigate = useNavigate()

  const { data } = useQuery<Affiliate[]>(QUERY_KEYS.AFFILIATES.LIST, () =>
    ApiService.Affiliates.getAll(),
  )

  useQuery<SelectProps['options']>(
    QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
    () => ApiService.CommercialUnits.getAllNamesAndIds(),
  )

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const [messageModal, setMessageModal] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [currentName, setCurrentName] = useState()
  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)

  const onConfirmMessage = useCallback(async () => {
    setIsSending(true)
    await ApiService.Affiliates.sendMessage({
      id: Number(currentRow),
      message,
    })
      .then((response) => {
        openSuccessToast({ message: 'A mensagem foi enviada' })
        setIsSending(false)
        setMessageModal(false)
      })
      .catch((error) => {
        openErrorToast({
          message: (error as ApiError).message || 'Erro ao enviar mensagem.',
        })
        setIsSending(false)
        setMessageModal(false)
      })
  }, [message, currentRow, openErrorToast, openSuccessToast])

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'document', headerName: 'Documento', flex: 1 },
      { field: 'code', headerName: 'Código de Afiliação', flex: 1 },
      {
        field: 'city',
        headerName: 'Cidade',
        valueGetter: (params: GridValueGetterParams<SupplierApiRequest>) =>
          `${params.row.city || ''}/${params.row.uf || ''}`,
        flex: 1,
      },
      { field: 'cell_phone', headerName: 'Celular', flex: 1 },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<SupplierApiResponse>) => {
          return (
            <>
              <WhatsApp
                fontSize="small"
                onClick={() => {
                  setCurrentRow(params.row.id)
                  setCurrentName(params.row.name)
                  setMessage('')
                  setMessageModal(true)
                }}
              />
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.AFFILIATES.VIEW(params.row.id))
                }}
              />
              <EditIcon
                onClick={() => {
                  navigate(ROUTES.AFFILIATES.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  if (isSending) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Afiliados
              </Typography>

              <Box sx={{ m: 1 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.AFFILIATES.CREATE)
                  }}
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Table rows={data} columns={columns} />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ConfirmationModal
        title={`Enviar Mensagem - ${currentName}`}
        opened={messageModal}
        onClose={() => setMessageModal(false)}
        onConfim={onConfirmMessage}
        onCancel={() => setMessageModal(false)}
      >
        <label htmlFor="message-wpp"></label>
        <textarea
          name="message-wpp"
          style={{ width: '100%' }}
          rows={5}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </ConfirmationModal>
    </>
  )
}
