import { useCallback, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import EditIcon from '@mui/icons-material/Edit'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridFilterItem,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import DataTable from 'components/DataTable'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPageWithOverlay'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import {
  METHODS_PAYMENTS_ORDER_SERVICE,
  METHODS_PAYMENTS_ORDER_SERVICE_OPTIONS,
} from 'constants/methods-payments'
import {
  STATUS_ORDER_SERVICE,
  STATUS_ORDER_SERVICE_OPTIONS,
} from 'constants/order-service-status'
import {
  PAYMENTS_STATUS_ORDER_SERVICE,
  PAYMENTS_STATUS_ORDER_SERVICE_OPTIONS,
} from 'constants/payments-status'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useQueryParams } from 'hooks/useQueryParams'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { cpfCnpjMask, currencyMask } from 'utils/mask'
import * as yup from 'yup'

import { CustomToolbar } from './components/Export'

interface FormFilters {
  which_date?: string
  document?: string
  category_id?: string
  supplier_id?: string
  status?: string
  start_date?: string
  end_date?: string
}

type DownloadPaymentManualRequest = {
  settlement_date: string
  status?: string
}

const filterFormSchema = yup.object().shape({
  start_date: yup.string().optional().nullable(),
  end_date: yup
    .string()
    .optional()
    .nullable()
    .test(
      'test-end-date-after-start-date',
      'A Data de fim precisa ser maior que a de inicio',
      (value, context) => {
        if (value && context.parent.start_date) {
          return moment(value, INPUT_MASK.DATE_LOCALE).isSameOrAfter(
            moment(context.parent.start_date, INPUT_MASK.DATE_LOCALE),
          )
        }
        return true
      },
    ),
  which_date: yup
    .string()
    .when('start_date', {
      is: (value: string) => {
        return !!value
      },
      then: (schema) => schema.required('Escolha por qual data filtrar'),
      otherwise: (schema) => schema.optional().nullable(),
    })
    .when('end_date', {
      is: (value: string) => {
        return !!value
      },
      then: (schema) => schema.required('Escolha por qual data filtrar'),
      otherwise: (schema) => schema.optional().nullable(),
    }),
})

export default function OrderServices() {
  const reactHookFormSearch = useForm<FormFilters>({
    resolver: yupResolver(filterFormSchema),
  })
  const reactHookFormDownloadPaymentManual =
    useForm<DownloadPaymentManualRequest>({
      resolver: yupResolver(
        yup.object().shape({
          settlement_date: yup.string().required('Este campo é obrigatório'),
        }),
      ),
      mode: 'onChange',
    })
  const navigate = useNavigate()
  const query = useQueryParams()
  const { openSuccessToast, openErrorToast } = useGlobalState()
  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [sortOptions, setSortOptions] = useState<GridSortModel>([])
  const [searchParams, setSearchParams] = useState<GenericObject[]>([])
  const orderServicesIds = useMemo(() => {
    const queryIds = query.get('ids')
    return queryIds?.split(',') || []
  }, [query])

  const [isLoadingDownloadPaymentManual, setIsLoadingDownloadPaymentManual] =
    useState<boolean>(false)
  const canUpdateOrders = useMemo(() => {
    const queryUpdate = query.get('update_status')
    if (!orderServicesIds.length) {
      return false
    }
    if (queryUpdate === 'true') {
      return true
    }
    return false
  }, [orderServicesIds.length, query])
  const openModalDownloadManual = useMemo(() => {
    const queryData = query.get('open_modal')
    if (queryData === 'true') {
      return true
    }
    return false
  }, [query])
  const handleFilter = useCallback((data: FormFilters) => {
    setSearchParams([
      {
        ...data,
        document: data?.document?.length
          ? cpfCnpjMask(data?.document as string)
          : undefined,
      },
    ])
  }, [])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'number_order_service',
        headerName: 'Nº Ordem',
        flex: 1,
        valueGetter: (params) => {
          return params.row?.number_order_service || 'Aguardando emissão'
        },
      },
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        valueGetter: (params) => {
          return params.row?.name || 'Sem nome'
        },
      },
      {
        field: 'activity.name',
        headerName: 'Atividade',
        relation: {
          name: 'orderService.activity',
          alias: 'activity',
        },
        valueGetter: (params) => {
          return params.row?.activity?.name || 'Sem atividade'
        },
        flex: 1,
      },
      {
        field: 'category.name',
        headerName: 'Categoria',
        flex: 1,
        relation: {
          name: 'orderService.category',
          alias: 'category',
        },
        valueGetter: (params) => {
          return params.row.category.name
        },
      },
      {
        field: 'supplier.name',
        headerName: 'Nome fornecedor',
        flex: 1,
        sortable: false,
        relation: {
          name: 'orderService.supplier',
          alias: 'supplier',
        },
        valueGetter: (params) => {
          return params.row.supplier.name
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: [...STATUS_ORDER_SERVICE_OPTIONS],
        valueGetter: (params) => {
          return STATUS_ORDER_SERVICE[params.row.status]
        },
      },
      {
        field: 'payment_status',
        headerName: 'Status de pagamento',
        flex: 1,
        sortable: false,
        type: 'singleSelect',
        valueOptions: [...PAYMENTS_STATUS_ORDER_SERVICE_OPTIONS],
        valueGetter: (params) => {
          return PAYMENTS_STATUS_ORDER_SERVICE[params.row.payment_status]
        },
      },
      {
        field: 'method_payment',
        headerName: 'Método de pagamento',
        flex: 1,
        sortable: false,
        type: 'singleSelect',
        valueOptions: [...METHODS_PAYMENTS_ORDER_SERVICE_OPTIONS],
        valueGetter: (params) => {
          return METHODS_PAYMENTS_ORDER_SERVICE[params.row.method_payment]
        },
      },
      {
        field: 'total_value',
        headerName: 'Total',
        flex: 1,
        sortable: false,
        valueFormatter: (params) => {
          return currencyMask(params.value)
        },
      },
      {
        field: 'issue_date',
        headerName: 'Data de emissão',
        flex: 1,
        sortable: false,
      },
      {
        field: 'due_date',
        headerName: 'Data de vencimento',
        flex: 1,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        exclude: true,
        disableExport: true,
        renderCell: (params: GridRenderCellParams<Clients>) => {
          return (
            <>
              <EditIcon
                onClick={() => {
                  navigate(ROUTES.ORDER_SERVICES.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  const { data: categories, isLoading: isLoadingCategories } = useQuery<
    SelectProps['options']
  >(QUERY_KEYS.CATEGORIES.NAMES_AND_IDS, () =>
    ApiService.Categories.getAllNamesAndIds(),
  )

  const { data: suppliers, isLoading: isLoadingSuppliers } = useQuery<
    SelectProps['options']
  >(QUERY_KEYS.SUPPLIERS.NAME_AND_IDS, () =>
    ApiService.Suppliers.getAllNamesAndIds(),
  )

  const handleCloseDownloadManualPayment = useCallback(() => {
    navigate('/commercial/orderServices?update_status=true')
  }, [navigate])

  const handleSubmitDownloadPayment = useCallback(
    async (data: DownloadPaymentManualRequest) => {
      setIsLoadingDownloadPaymentManual(true)
      try {
        await ApiService.OrderServices.downloadPaymentManual({
          ids: orderServicesIds.map((id) => Number(id)),
          settlement_date: data.settlement_date,
        })
        openSuccessToast({
          message: 'Ordens atualizadas com sucesso',
        })
        reactHookFormDownloadPaymentManual.reset({
          settlement_date: '',
        })
        // setOrderServicesIds([])
        setIsLoadingDownloadPaymentManual(false)
        navigate(ROUTES.ORDER_SERVICES.LIST)
      } catch (error) {
        openErrorToast({
          message: 'Ocorreu um erro ao atualizar as ordens',
        })
        setIsLoadingDownloadPaymentManual(false)
        navigate(ROUTES.ORDER_SERVICES.LIST)
      }
    },
    [
      navigate,
      openErrorToast,
      openSuccessToast,
      orderServicesIds,
      reactHookFormDownloadPaymentManual,
    ],
  )

  if (
    isLoadingCategories ||
    isLoadingSuppliers ||
    isLoadingDownloadPaymentManual
  ) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Ordens de serviço
              </Typography>

              <Box sx={{ display: 'flex' }}>
                {!!canUpdateOrders && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        navigate(
                          `${ROUTES.ORDER_SERVICES.LIST}?update_status=true&open_modal=true&ids=${orderServicesIds}`,
                        )
                      }}
                    >
                      Baixa Manual
                    </Button>
                  </Box>
                )}
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ORDER_SERVICES.CREATE)
                    }}
                  >
                    Adicionar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
              marginTop: '20px',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Controller
                  name="status"
                  control={reactHookFormSearch.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Status"
                        id="status"
                        onChange={onChange}
                        value={value}
                        options={STATUS_ORDER_SERVICE_OPTIONS}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="category_id"
                  control={reactHookFormSearch.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Categorias"
                        id="category_id"
                        onChange={onChange}
                        value={value}
                        options={categories || []}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="supplier_id"
                  control={reactHookFormSearch.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Fornecedor"
                        id="supplier_id"
                        onChange={onChange}
                        value={value}
                        options={suppliers || []}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="which_date"
                  control={reactHookFormSearch.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Filtrar data por"
                        id="which_date"
                        onChange={onChange}
                        value={value}
                        options={[
                          {
                            label: 'Data de emissão',
                            value: 'issue_date',
                          },
                          {
                            label: 'Data de vencimento',
                            value: 'due_date',
                          },
                        ]}
                        helperText={
                          reactHookFormSearch.formState.errors.which_date
                            ?.message
                        }
                        error={
                          !!reactHookFormSearch.formState.errors.which_date
                        }
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                <Controller
                  name="start_date"
                  control={reactHookFormSearch.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        fullWidth
                        label="Data de inicio"
                        id="start_date"
                        onChange={onChange}
                        value={value}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                <Controller
                  name="end_date"
                  control={reactHookFormSearch.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        fullWidth
                        label="Data de fim"
                        id="end_date"
                        onChange={onChange}
                        value={value}
                        helperText={
                          reactHookFormSearch.formState.errors.end_date?.message
                        }
                        error={!!reactHookFormSearch.formState.errors.end_date}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                xs
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'end',
                  gap: '1rem',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={reactHookFormSearch.handleSubmit(handleFilter)}
                >
                  Pesquisar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    reactHookFormSearch.reset()
                    setSearchParams([])
                  }}
                >
                  Todos
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <DataTable
                entity="OrderService"
                source="orderService"
                columns={columns}
                filterMode="server"
                onFilterModelChange={(newValue) => {
                  setFilterOptions(newValue.items)
                }}
                onSortModelChange={(newValue) => {
                  setSortOptions(newValue)
                }}
                likeParameters={filterOptions}
                sortParameters={sortOptions}
                disableSelectionOnClick
                searchParameters={searchParams}
                customToolbar={() => <CustomToolbar />}
                enableCheckBox
                onSelectRow={(data) => {
                  if (data.length) {
                    navigate(
                      `/commercial/orderServices?update_status=true&ids=${data.join(
                        ',',
                      )}`,
                    )
                  }
                  if (!data.length) {
                    navigate('/commercial/orderServices')
                  }
                  // setOrderServicesIds(data as number[])
                }}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ConfirmationModal
        title={'Baixa manual de pagamento'}
        opened={openModalDownloadManual}
        onClose={handleCloseDownloadManualPayment}
        onConfim={async () => {
          const data = reactHookFormDownloadPaymentManual.getValues()
          handleSubmitDownloadPayment(data)
        }}
        onCancel={handleCloseDownloadManualPayment}
      >
        <Grid container xs={12} spacing={0.3} style={{ width: '500px' }}>
          <Grid item xs={12}>
            <Controller
              name="settlement_date"
              control={reactHookFormDownloadPaymentManual.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    error={
                      !!reactHookFormDownloadPaymentManual.formState?.errors
                        ?.settlement_date?.message
                    }
                    helperText={
                      reactHookFormDownloadPaymentManual.formState?.errors
                        ?.settlement_date?.message
                    }
                    label="Data de liquidação"
                    id="settlement_date"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </ConfirmationModal>
    </>
  )
}
