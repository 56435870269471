import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import RequestPageIcon from '@mui/icons-material/RequestPage'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useQuery } from '@tanstack/react-query'
import {
  AddItemBudgetRequest,
  CreateOrderServiceRequest,
} from 'components/CreateOrderService/@types/form'
import { ButtonPrintOrderService } from 'components/CreateOrderService/components/ButtonPrintOrderService'
import { ButtonSendForSupplierOrderService } from 'components/CreateOrderService/components/ButtonSendForSupplierOrderService'
import { ButtonSubmitOrderService } from 'components/CreateOrderService/components/ButtonSubmitOrderService'
import { OrderServiceForm } from 'components/CreateOrderService/components/Form'
import {
  addItemBudgetSchema,
  sendOrderServiceToSupplierSchema,
} from 'components/CreateOrderService/components/form-validation'
import { handleUpdateOrderService } from 'components/CreateOrderService/utils/handle-update-order-service'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { UseFormReturn, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'
import { currencyMask, monetaryToNumber } from 'utils/mask'

import { SendOrderServiceToSupplier } from './ChecklistItem'

type FinishedButtonProps = {
  isEditMode?: boolean
  disabled?: boolean
  activity: Activity
  itemBudget: ActivityChecklistItemsForm
  activityChecklistItems: ActivityChecklistItemsForm[]
  onLoading: (value: boolean) => void
}

type OnSaveProps = { orderService: OrderServicesApiRequest; id: string }

type CreateOrderServiceModalProps = {
  opened: boolean
  isEditMode?: boolean
  activity: Activity
  itemBudget: ActivityChecklistItemsForm
  activityChecklistItems: ActivityChecklistItemsForm[]
  reactHookFormOrderService: UseFormReturn<CreateOrderServiceRequest>
  onClose: () => void
  onLoading: (isLoading: boolean) => void
  onSave: (data: OnSaveProps) => Promise<void>
}

const CreateOrderServiceModal = ({
  itemBudget,
  activity,
  activityChecklistItems,
  onClose,
  opened,
  onSave,
  reactHookFormOrderService,
  onLoading,
  isEditMode = false,
}: CreateOrderServiceModalProps) => {
  const { id } = useParams<{ id: string }>()
  const { openErrorToast, openSuccessToast } = useGlobalState()

  const reactHookFormSendOrderServiceToSupplier =
    useForm<SendOrderServiceToSupplier>({
      resolver: yupResolver(sendOrderServiceToSupplierSchema),
    })

  const reactHookFormAddItemBudget = useForm<AddItemBudgetRequest>({
    resolver: yupResolver(addItemBudgetSchema),
    defaultValues: {
      subtotal: '0,00',
    },
  })

  const { data: orderService } = useQuery<OrderServiceWithItemsApiResponse>(
    QUERY_KEYS.ORDER_SERVICES.GET_WITH_ITEMS_BY_ACTIVITY_CHECKLIST_ID(
      (itemBudget.id as number).toString(),
    ),
    () =>
      ApiService.OrderServices.getByActivityChecklistItemIdWithItems(
        (itemBudget.id as number).toString(),
      ),
    {
      retry: false,
    },
  )

  // const { data: supplier } = useQuery<Pick<SupplierApiResponse, 'category_id'>>(
  //   QUERY_KEYS.SUPPLIERS.GET_CATEGORY_BY_SUPPLIER(
  //     (itemBudget.supplier_id as number).toString(),
  //   ),
  //   () =>
  //     ApiService.Suppliers.getCategoryBySupplier({
  //       supplier_id: (itemBudget.supplier_id as number).toString(),
  //     }),
  //   {
  //     retry: false,
  //   },
  // )

  const handleCreateOrderService = useCallback(
    async (data: CreateOrderServiceRequest) => {
      onLoading(true)
      try {
        await ApiService.OrderServices.create({
          ...data,
          activity_id: activity.id,
        })
        if (id) {
          CacheService.queryClient.invalidateQueries(
            QUERY_KEYS.ACTIVITY_CHECKLIST.EDIT(id),
          )
          CacheService.queryClient.invalidateQueries(
            QUERY_KEYS.ACTIVITIES.VIEW(id),
          )
        }
        openSuccessToast({
          message: 'Ordem de serviço adicionada com sucesso',
        })
        onClose()
      } catch (error: unknown) {
        openErrorToast({
          message:
            (error as ApiError).message ||
            `Erro ao adicionar ordem de serviço!`,
        })
      } finally {
        onLoading(false)
      }
    },
    [activity.id, id, onClose, onLoading, openErrorToast, openSuccessToast],
  )

  const items = reactHookFormOrderService.watch('order_service_items')
  useEffect(() => {
    if (!items?.length) {
      return
    }
    const totalValue = items?.reduce((acc, item) => {
      return Number(acc) + monetaryToNumber(item.subtotal)
    }, 0)
    reactHookFormOrderService.setValue('total_value', currencyMask(totalValue))
  }, [items, reactHookFormOrderService])

  useEffect(() => {
    if (itemBudget?.budget_id) {
      reactHookFormOrderService.setValue(
        'budget_id',
        itemBudget?.budget_id.toString(),
      )
    }
    if (itemBudget?.supplier_id) {
      reactHookFormOrderService.setValue(
        'supplier_id',
        itemBudget?.supplier_id.toString(),
      )
    }
    if (itemBudget?.category_id) {
      reactHookFormOrderService.setValue(
        'category_id',
        itemBudget?.category_id.toString(),
      )
    }
  }, [
    itemBudget?.budget_id,
    itemBudget?.category_id,
    itemBudget?.supplier_id,
    reactHookFormOrderService,
  ])

  return (
    <Dialog
      title="Ordem de serviço"
      open={opened}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        Ordem de serviço {orderService?.name ? `- ${orderService?.name}` : ''}
      </DialogTitle>
      <DialogContent sx={{ paddingRight: 0 }}>
        <OrderServiceForm
          type="checklist"
          orderService={orderService}
          isEditMode={isEditMode}
          itemsBudget={activityChecklistItems}
          reactHookFormAddItemBudget={reactHookFormAddItemBudget}
          reactHookFormOrderService={reactHookFormOrderService}
        />
      </DialogContent>

      <DialogActions sx={{ gap: '2rem' }}>
        <Button onClick={onClose} color="primary" variant="contained">
          Fechar
        </Button>

        <ButtonPrintOrderService
          onLoading={onLoading}
          openErrorToast={openErrorToast}
          orderService={orderService}
        />

        <ButtonSendForSupplierOrderService
          onLoading={onLoading}
          openErrorToast={openErrorToast}
          openSuccessToast={openSuccessToast}
          reactHookFormSendOrderServiceToSupplier={
            reactHookFormSendOrderServiceToSupplier
          }
          orderService={orderService}
          itemBudget={itemBudget}
        />

        <ButtonSubmitOrderService
          orderService={orderService}
          activity={activity}
          handleCreateOrderService={handleCreateOrderService}
          items={items}
          onSave={onSave}
          openErrorToast={openErrorToast}
          reactHookFormOrderService={reactHookFormOrderService}
        />
      </DialogActions>
    </Dialog>
  )
}

export const CreateOrderService = ({
  itemBudget,
  activity,
  activityChecklistItems,
  onLoading,
  disabled,
  isEditMode = false,
}: FinishedButtonProps) => {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const [createOrderServiceModal, setCreateOrderServiceModal] = useState(false)
  const reactHookFormOrderService = useForm<CreateOrderServiceRequest>({
    defaultValues: {
      order_service_items: [
        {
          name: itemBudget.name,
          quantity: itemBudget.quantity,
          unit_value: itemBudget.unit_value,
          subtotal:
            itemBudget.subtotal === '' || !itemBudget.subtotal
              ? currencyMask(
                  Number(itemBudget.quantity) *
                    monetaryToNumber(
                      (itemBudget.unit_value || '0,00')?.toString(),
                    ),
                )
              : itemBudget.subtotal,
          id: itemBudget.id,
        },
      ],
    },
  })
  const selectedCategoryId = reactHookFormOrderService.watch('category_id')
  const handleUpdateModal = useCallback(
    async ({ id, orderService }: OnSaveProps) => {
      await handleUpdateOrderService({
        id,
        onLoading,
        openErrorToast,
        openSuccessToast,
        orderService,
        onClose: () => setCreateOrderServiceModal(false),
      })
    },
    [onLoading, openErrorToast, openSuccessToast],
  )

  const filteredItemsOptions = useMemo(() => {
    return activityChecklistItems.filter((item) => {
      return (
        !item.order_service &&
        item.itemBudget?.has_service_order &&
        Number(selectedCategoryId) === item.itemBudget?.category_id
      )
    })
  }, [activityChecklistItems, selectedCategoryId])

  if (!itemBudget.itemBudget?.has_service_order) {
    return null
  }

  return (
    <>
      <IconButton color="primary" disabled={disabled}>
        <RequestPageIcon
          fontSize="small"
          onClick={async () => {
            setCreateOrderServiceModal(true)
          }}
        />
      </IconButton>

      {createOrderServiceModal && (
        <CreateOrderServiceModal
          onLoading={onLoading}
          onClose={() => {
            onLoading?.(false)
            setCreateOrderServiceModal(false)
          }}
          isEditMode={isEditMode}
          onSave={handleUpdateModal}
          activityChecklistItems={filteredItemsOptions}
          itemBudget={itemBudget}
          activity={activity}
          opened={createOrderServiceModal}
          reactHookFormOrderService={reactHookFormOrderService}
        />
      )}
    </>
  )
}
