import { useState, useCallback } from 'react'

import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import {
  Chart as ChartJS,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import { QUERY_KEYS } from 'constants/keys'
import {
  RESERVATION_SITUATION_OPTIONS,
  FINANCIAL_MOVEMENT_PAYMENT_METHODS,
} from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { Pie } from 'react-chartjs-2'
import { ApiService } from 'services/api'
import { CHART_COLORS } from 'utils/chartColors'
import formatCurrencyUnit from 'utils/formatCurrencyUnit'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

type GenerateResultsParams = {
  querySituation?: { value: string }[] | null
  queryStartDate?: string | null
  queryEndDate?: string | null
}

export default function SalesCharts() {
  const [total, setTotal] = useState<number>(0)

  const [salesChartData, setSalesChartData] = useState<
    ChartData<'pie', number[], unknown> | undefined
  >()

  const [formOfPaymentChartData, setFormOfPaymentChartData] = useState<
    ChartData<'pie', number[], unknown> | undefined
  >()

  const [responsibleForActivityChartData, setResponsibleForActivityChartData] =
    useState<ChartData<'pie', number[], unknown> | undefined>()

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedReservationSituation, setSelectedReservationSituation] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedCollaborators, setSelectedCollaborators] =
    useState<SelectWithCheckboxOptions>([])

  const [activityCode, setActivityCode] = useState<string>('')

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { openErrorToast } = useGlobalState()

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const { data: collaborators } = useQuery<SelectWithCheckboxOptions>(
    QUERY_KEYS.COLLABORATORS.LIST,
    () => ApiService.Collaborators.getAllNamesAndIdsOnlyEmployee(),
  )

  const generateResponsibleForActivityReport = useCallback(() => {
    ApiService.Reports.generateSales({
      institutions: selectedInstitutions.map((si) => Number(si.value)),
      status: selectedReservationSituation.map((rs) => String(rs.value)),
      collaborators: selectedCollaborators.map((c) => Number(c.value)),
      activityCode,
      start: startDate
        ? String(startDate).split('/').reverse().join('-')
        : undefined,
      end: endDate ? String(endDate).split('/').reverse().join('-') : undefined,
      fields: ['responsible.name', 'payment_value'],
      relations: ['responsible'],
    })
      .then((response) => {
        if (!response.length) {
          openErrorToast({ message: 'Nenhum resultado encontrado.' })
          return
        }

        const totalSales = response.reduce((accumulator, currentValue) => {
          accumulator += parseFloat(currentValue.payment_value)
          return accumulator
        }, 0)

        const totalSalesByResponsible = response.reduce(
          (accumulator: Record<string, number>, currentValue) => {
            if (
              accumulator[
                (currentValue.responsible_name || '').replaceAll('"', '').trim()
              ]
            ) {
              accumulator[
                (currentValue.responsible_name || '').replaceAll('"', '').trim()
              ] += parseFloat(currentValue.payment_value)

              return accumulator
            }

            accumulator[
              (currentValue.responsible_name || '').replaceAll('"', '').trim()
            ] = parseFloat(currentValue.payment_value)

            return accumulator
          },
          {},
        )

        const totalSalesByResponsibleWithPercentage = Object.entries(
          totalSalesByResponsible,
        ).reduce((accumulator: Record<string, string>, [key, value]) => {
          accumulator[key] = ((value / totalSales) * 100).toFixed(2)
          return accumulator
        }, {})

        const sortedBySales = Object.entries(totalSalesByResponsible).sort(
          (a, b) => b[1] - a[1],
        )

        const responsibleNames = sortedBySales.map((s) => s[0])
        const responsibleValues = sortedBySales.map((s) => s[1])

        setResponsibleForActivityChartData({
          labels: responsibleNames.map(
            (responsible) =>
              `${responsible} - ${
                totalSalesByResponsibleWithPercentage[responsible]
              }% - ${formatCurrencyUnit(totalSalesByResponsible[responsible])}`,
          ),
          datasets: [
            {
              data: responsibleValues,
              backgroundColor: CHART_COLORS,
              borderWidth: 1,
            },
          ],
        })

        setIsLoading(false)
      })
      .catch((_error) => {
        openErrorToast({ message: 'Ocorreu um erro ao gerar gráfico!' })
        setIsLoading(false)
      })
  }, [
    activityCode,
    endDate,
    openErrorToast,
    selectedCollaborators,
    selectedInstitutions,
    selectedReservationSituation,
    startDate,
  ])

  const generateReport = useCallback(
    ({
      querySituation,
      queryStartDate,
      queryEndDate,
    }: GenerateResultsParams) => {
      setIsLoading(true)
      ApiService.Reports.generateSales({
        institutions: selectedInstitutions.map((si) => Number(si.value)),
        status: (querySituation || selectedReservationSituation).map((rs) =>
          String(rs.value),
        ),
        collaborators: selectedCollaborators.map((c) => Number(c.value)),
        activityCode,
        start: queryStartDate
          ? queryStartDate
          : startDate
          ? String(startDate).split('/').reverse().join('-')
          : undefined,
        end: queryEndDate
          ? queryEndDate
          : endDate
          ? String(endDate).split('/').reverse().join('-')
          : undefined,
      })
        .then((response) => {
          const totalSales = response.reduce((accumulator, currentValue) => {
            accumulator += parseFloat(currentValue.payment_value)
            return accumulator
          }, 0)

          const totalSalesByInstitution = response.reduce(
            (accumulator: Record<string, number>, currentValue) => {
              if (
                accumulator[
                  (currentValue.institution_fantasy_name || '')
                    .replaceAll('"', '')
                    .trim()
                ]
              ) {
                accumulator[
                  (currentValue.institution_fantasy_name || '')
                    .replaceAll('"', '')
                    .trim()
                ] += parseFloat(currentValue.payment_value)

                return accumulator
              }

              accumulator[
                (currentValue.institution_fantasy_name || '')
                  .replaceAll('"', '')
                  .trim()
              ] = parseFloat(currentValue.payment_value)

              return accumulator
            },
            {},
          )

          const sortedBySales = Object.entries(totalSalesByInstitution).sort(
            (a, b) => b[1] - a[1],
          )

          const institutionsNames = sortedBySales.map((s) => s[0])
          const institutionsValues = sortedBySales.map((s) => s[1])

          const totalSalesByInstitutionWithPercentage = Object.entries(
            totalSalesByInstitution,
          ).reduce((accumulator: Record<string, string>, [key, value]) => {
            accumulator[key] = ((value / totalSales) * 100).toFixed(2)
            return accumulator
          }, {})

          const totalSalesByFormOfPayment = response.reduce(
            (accumulator: Record<string, number>, currentValue) => {
              const key =
                FINANCIAL_MOVEMENT_PAYMENT_METHODS[
                  currentValue.financialMovements_payment_method
                ] || 'Desconhecido'

              if (accumulator[key]) {
                accumulator[key] += parseFloat(currentValue.payment_value)
                return accumulator
              }

              accumulator[key] = parseFloat(currentValue.payment_value)
              return accumulator
            },
            {},
          )

          const sortedByFormOfPayment = Object.entries(
            totalSalesByFormOfPayment,
          ).sort((a, b) => b[1] - a[1])

          const formOfPaymentNames = sortedByFormOfPayment.map((s) => s[0])
          const formOfPaymentValues = sortedByFormOfPayment.map((s) => s[1])

          const totalSalesByFormOfPaymentWithPercentage = Object.entries(
            totalSalesByFormOfPayment,
          ).reduce((accumulator: Record<string, string>, [key, value]) => {
            accumulator[key] = ((value / totalSales) * 100).toFixed(2)
            return accumulator
          }, {})

          setSalesChartData({
            labels: institutionsNames.map(
              (institution) =>
                `${institution} - ${
                  totalSalesByInstitutionWithPercentage[institution]
                }% - ${formatCurrencyUnit(
                  totalSalesByInstitution[institution],
                )}`,
            ),
            datasets: [
              {
                data: institutionsValues,
                backgroundColor: CHART_COLORS,
                borderWidth: 1,
              },
            ],
          })

          setFormOfPaymentChartData({
            labels: formOfPaymentNames.map(
              (institution) =>
                `${institution} - ${
                  totalSalesByFormOfPaymentWithPercentage[institution]
                }% - ${formatCurrencyUnit(
                  totalSalesByFormOfPayment[institution],
                )}`,
            ),
            datasets: [
              {
                data: formOfPaymentValues,
                backgroundColor: CHART_COLORS,
                borderWidth: 1,
              },
            ],
          })

          setTotal(totalSales)

          if (!response.length) {
            openErrorToast({ message: 'Nenhum resultado encontrado.' })
          }

          generateResponsibleForActivityReport()
        })
        .catch((error) => {
          openErrorToast({ message: 'Ocorreu um erro ao gerar gráfico!' })
          setIsLoading(false)
        })
    },
    [
      selectedInstitutions,
      selectedReservationSituation,
      selectedCollaborators,
      activityCode,
      startDate,
      endDate,
      generateResponsibleForActivityReport,
      openErrorToast,
    ],
  )

  if (!institutions?.length || isLoading) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Gráfico de vendas
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <SelectWithCheckbox
              options={RESERVATION_SITUATION_OPTIONS}
              value={selectedReservationSituation}
              onSelect={setSelectedReservationSituation}
              selectAllLabel="Todas as situações"
              placeholder="Status da reserva"
            />

            <SelectWithCheckbox
              options={collaborators || []}
              value={selectedCollaborators}
              onSelect={setSelectedCollaborators}
              selectAllLabel="Todas os colaboradores"
              placeholder="Responsável pela atividade"
            />

            <TextField
              label="Código da atividade"
              type="text"
              variant="outlined"
              value={activityCode}
              onChange={(event) => {
                setActivityCode(event.target.value)
              }}
            />

            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={() => generateReport({})}
              disabled={
                !selectedInstitutions.length &&
                !selectedCollaborators.length &&
                !selectedReservationSituation.length &&
                !startDate &&
                !endDate
              }
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        {salesChartData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Pie
                data={salesChartData}
                width={600}
                height={600}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => formatCurrencyUnit(context.parsed),
                      },
                    },
                    title: {
                      display: true,
                      font: {
                        size: 16,
                      },
                      text: `Total por instituição - ${formatCurrencyUnit(
                        total,
                      )}`,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        )}

        {formOfPaymentChartData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Pie
                data={formOfPaymentChartData}
                width={600}
                height={600}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => formatCurrencyUnit(context.parsed),
                      },
                    },
                    title: {
                      display: true,
                      font: {
                        size: 16,
                      },
                      text: `Total por forma de pagamento - ${formatCurrencyUnit(
                        total,
                      )}`,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        )}

        {responsibleForActivityChartData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Pie
                data={responsibleForActivityChartData}
                width={600}
                height={600}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => formatCurrencyUnit(context.parsed),
                      },
                    },
                    title: {
                      display: true,
                      font: {
                        size: 16,
                      },
                      text: `Total responsável da atividade - ${formatCurrencyUnit(
                        total,
                      )}`,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </Box>
  )
}
