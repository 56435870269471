import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const institutionsRoutes: RouteObject[] = [
  {
    path: ROUTES.INSTITUTIONS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Institutions />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.INSTITUTIONS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddInstitutions />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.INSTITUTIONS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditInstitutions />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.INSTITUTIONS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewInstitutions />
      </PrivateRoute>
    ),
  },
]

export default institutionsRoutes
