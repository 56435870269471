import Providers from 'providers'
import { ToastContainer } from 'react-toastify'
import Router from 'router'
import 'font-awesome/css/font-awesome.css'
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
  return (
    <Providers>
      <Router />

      <ToastContainer />
    </Providers>
  )
}
