import { useMemo, useState, useCallback } from 'react'

import SaveAltIcon from '@mui/icons-material/SaveAlt'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import {
  GridToolbar,
  type GridColDef,
  GridToolbarExportProps,
  useGridApiContext,
  GridToolbarContainer,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ACTIVITY_SITATUS_MAP_COLOR } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { ApiService } from 'services/api'
const ACTIVITY_SITATUS_OPTIONS: Array<{
  value: ActivityStatus
  label: string
}> = [
  { value: 'opened', label: 'Em aberto' },
  { value: 'cancelled', label: 'Cancelada' },
  { value: 'confirmed', label: 'Confirmada' },
  { value: 'finished', label: 'Concluída' },
]

const ACTIVITY_SITATUS_MAP = {
  opened: 'Em aberto',
  cancelled: 'Cancelada',
  confirmed: 'Confirmada',
  finished: 'Concluída',
}

export default function ActivitiesControlReport() {
  const [generateResult, setGenerateResult] = useState<
    ActivitiesControlReportResponse[]
  >([])

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedActivitySituation, setSelectedActivitySituation] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedCollaborators, setSelectedCollaborators] =
    useState<SelectWithCheckboxOptions>([])

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)

  const tableColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: '#',
        headerName: '#',
        filterable: false,
        width: 70,
        renderCell: (cell) => cell.api.getRowIndex(cell.row.id) + 1,
      },
      {
        field: 'code',
        headerName: 'Código',
        renderCell: (params) => (
          <span
            style={{
              color:
                ACTIVITY_SITATUS_MAP_COLOR[params.row.status as ActivityStatus],
            }}
          >
            {params.value}
          </span>
        ),
      },
      { field: 'start_travel_date', headerName: 'Data' },
      { field: 'month', headerName: 'Mês' },
      {
        field: 'year',
        headerName: 'Ano',
      },
      {
        field: 'name',
        headerName: 'Local',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'institution_fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'commercialUnit_name',
        headerName: 'Unidade',
        flex: 1,
        minWidth: 300,
      },
      { field: 'course_name', headerName: 'Curso' },
      {
        field: 'accompanying_teacher',
        headerName: 'Professor',
        valueFormatter: (value) => {
          const parsed = JSON.parse(value.value || '[]')
          return parsed?.[0]?.name
        },
      },
      { field: 'enrollment_limit', headerName: 'Inscrição até' },
      { field: 'departure_at', headerName: 'Saída às' },
      { field: 'return_at', headerName: 'Chegada às' },
      { field: 'price', headerName: 'Valor' },
      { field: 'budget_number_of_people', headerName: 'Min' },
      {
        field: 'students_confirmed',
        headerName: 'Alunos confirmados',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'shipping_company',
        headerName: 'Transportadora',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'type_shipping_company',
        headerName: 'Tipo de transporte',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'value_shipping_company',
        headerName: 'Valor transporte',
        minWidth: 130,
      },
      {
        field: 'status',
        headerName: 'Status',
        valueFormatter: (value) =>
          ACTIVITY_SITATUS_MAP[value.value as ActivityStatus],
      },
      {
        field: 'responsible_name',
        headerName: 'Responsável',
        flex: 1,
        minWidth: 300,
      },
    ],
    [],
  )

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const { data: collaborators } = useQuery<SelectWithCheckboxOptions>(
    QUERY_KEYS.COLLABORATORS.ONLY_EMPLOYEE,
    () => ApiService.Collaborators.getAllNamesAndIdsOnlyEmployee(),
  )

  const generateReport = useCallback(() => {
    ApiService.Reports.generateActivitiesControl({
      institutions: selectedInstitutions.map((si) => Number(si.value)),
      status: selectedActivitySituation.map((rs) => String(rs.value)),
      collaborators: selectedCollaborators.map((c) => Number(c.value)),
      start: startDate
        ? String(startDate).split('/').reverse().join('-')
        : undefined,
      end: endDate ? String(endDate).split('/').reverse().join('-') : undefined,
    }).then((response) => {
      setGenerateResult(response)
    })
  }, [
    selectedInstitutions,
    selectedCollaborators,
    selectedActivitySituation,
    startDate,
    endDate,
  ])

  function CustomToolbar(props: GridToolbarExportProps) {
    const { user, openErrorToast } = useGlobalState()
    const apiRef = useGridApiContext()

    const generateExportCsv = useCallback(
      () =>
        apiRef.current.exportDataAsCsv({
          ...props.csvOptions,
          utf8WithBom: true,
          delimiter: ';',
        }),
      [apiRef, props.csvOptions],
    )

    const handleExport = useCallback(async () => {
      try {
        await ApiService.AuditLogs.create({
          entity: 'Report',
          type: 'export',
          changes: JSON.stringify([
            {
              valueAfter: {
                institutions: selectedInstitutions.map((si) =>
                  Number(si.value),
                ),
                status: selectedActivitySituation.map((rs) => String(rs.value)),
                collaborators: selectedCollaborators.map((c) =>
                  Number(c.value),
                ),
                start: startDate
                  ? String(startDate).split('/').reverse().join('-')
                  : undefined,
                end: endDate
                  ? String(endDate).split('/').reverse().join('-')
                  : undefined,
              },
            },
          ]),
          client_id: null,
          entity_id: 0,
          user_id: user?.id,
        })
        generateExportCsv()
      } catch (error: unknown) {
        openErrorToast({
          message: `Ocorreu um erro ao criar um registro na auditoria: ${
            (error as ApiError).message
          }`,
        })
      }
    }, [generateExportCsv, openErrorToast, user?.id])

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <SaveAltIcon />,
    }
    return (
      <GridToolbarContainer>
        <GridToolbar
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
        <Button {...buttonBaseProps} onClick={() => handleExport()}>
          Exportar
        </Button>
      </GridToolbarContainer>
    )
  }

  if (!institutions?.length) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Relatório de controle de atividades
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <SelectWithCheckbox
              options={ACTIVITY_SITATUS_OPTIONS}
              value={selectedActivitySituation}
              onSelect={setSelectedActivitySituation}
              selectAllLabel="Todas as situações"
              placeholder="Status da atividade"
            />

            <SelectWithCheckbox
              options={collaborators || []}
              value={selectedCollaborators}
              onSelect={setSelectedCollaborators}
              selectAllLabel="Todas os colaboradores"
              placeholder="Responsável pela atividade"
            />

            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={generateReport}
              disabled={
                !selectedInstitutions.length &&
                !selectedCollaborators.length &&
                !selectedActivitySituation.length &&
                !startDate &&
                !endDate
              }
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Table
              rows={generateResult}
              columns={tableColumns}
              customToolbar={() => <CustomToolbar />}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
