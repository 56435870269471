import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const login = async (user: LoginApiRequest) => {
  try {
    const loginResponse = await apiInstance.post<LoginApiResponse>(
      ENDPOINTS.LOGIN,
      user,
    )

    return loginResponse.data
  } catch (loginError) {
    const axiosError = loginError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
