import { ApiService } from 'services/api'

type CancelTransactionParams = {
  id: string
  message: string
  isReversePayment?: boolean
}

type usePagseguroResponse = {
  cancelTransaction: (
    params: CancelTransactionParams,
  ) => Promise<PagseguroCancelTransactionResponse>
  cancelActivity: (
    params: CancelTransactionParams,
  ) => Promise<PagseguroCancelTransactionResponse>
}

export default function usePagseguro(): usePagseguroResponse {
  const cancelTransaction = async ({
    id,
    message,
    isReversePayment = true,
  }: CancelTransactionParams): Promise<PagseguroCancelTransactionResponse> => {
    const cancelResponse = await ApiService.Pagseguro.cancelTransaction({
      id,
      message,
      isReversePayment,
    })
    return cancelResponse
  }

  const cancelActivity = async ({
    id,
    message,
    isReversePayment = true,
  }: CancelTransactionParams): Promise<PagseguroCancelTransactionResponse> => {
    const cancelResponse = await ApiService.Pagseguro.cancelActivity({
      id,
      message,
      isReversePayment,
    })
    return cancelResponse
  }

  return {
    cancelTransaction,
    cancelActivity,
  }
}
