import { useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import * as yup from 'yup'

export default function Import() {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<ReservationsPerImportApiRequest>({
    resolver: yupResolver(
      yup.object().shape({
        activity_id: yup.string().required('Campo obrigatório'),
        file: yup.mixed().required('Campo obrigatório'),
      }),
    ),
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { data: activities, isLoading: isLoadingActivities } = useQuery<
    SelectProps['options']
  >(QUERY_KEYS.ACTIVITIES.NAMES_AND_IDS, async () =>
    ApiService.Activities.getAllNamesAndIds(),
  )

  const inputProps = useCallback(
    (fieldName: keyof ReservationsPerImportApiRequest) => {
      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [watch],
  )

  const onSubmit = useCallback(
    async (data: ReservationsPerImportApiRequest) => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('activity_id', data.activity_id)
      formData.append('file', data.file[0])
      try {
        await ApiService.Reservations.createPerImport(formData)
        openSuccessToast({
          message: 'Reservas importadas com sucesso',
        })
        setIsLoading(false)
      } catch (error) {
        openErrorToast({
          message: (error as ApiError).message || 'Houve um erro na importação',
        })
        setIsLoading(false)
      }
    },
    [openErrorToast, openSuccessToast],
  )

  if (isLoading || isLoadingActivities) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.RESERVATIONS.LIST}
            >
              Reservas
            </Link>

            <Typography color="text.primary">Importar</Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                Importar Reservas
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.RESERVATIONS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="activity_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Atividade"
                              id="activity_id"
                              onChange={onChange}
                              value={value}
                              options={activities || []}
                              error={!!errors.activity_id?.message}
                              helperText={errors.activity_id?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.file?.message}
                        fullWidth
                        helperText={errors.file?.message}
                        label=""
                        margin="normal"
                        type="file"
                        variant="outlined"
                        {...inputProps('file')}
                        {...register('file', {
                          required: true,
                        })}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      Importar
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  )
}
