import { useMemo, useState, useCallback } from 'react'

import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import {
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExportProps,
  useGridApiContext,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { CLIENTS_GENDER } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { ApiService } from 'services/api'

const RESERVATION_SITUATION_OPTIONS: Array<{
  value: ReservationSituation
  label: string
}> = [
  { value: 'waiting_payment', label: 'Aguardando pagamento' },
  { value: 'payment_confirmed', label: 'Pago' },
  { value: 'realized', label: 'Realizada' },
  { value: 'cancelled', label: 'Cancelada' },
  { value: 'finished', label: 'Concluída' },
  { value: 'payment_reversal', label: 'Pagamento estornado' },
]

const RESERVATION_SITUATION_MAP = {
  waiting_payment: 'Aguardando pagamento',
  payment_confirmed: 'Pago',
  realized: 'Realizada',
  cancelled: 'Cancelada',
  finished: 'Concluída',
  payment_reversal: 'Pagamento estornado',
}

export default function FacultyTripReport() {
  const [generateResult, setGenerateResult] = useState<
    FacultyTripReportResponse[]
  >([])

  const [selectedReservationSituation, setSelectedReservationSituation] =
    useState<SelectWithCheckboxOptions>([])

  const [code, setCode] = useState('')

  const { openErrorToast } = useGlobalState()

  const tableColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: '#',
        headerName: '#',
        filterable: false,
        width: 70,
        renderCell: (cell) => cell.api.getRowIndex(cell.row.id) + 1,
      },
      {
        field: 'activity_name',
        headerName: 'Atividade',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'code',
        headerName: 'Código',
        width: 200,
        renderCell: (cell) => (
          <Typography style={{ padding: '14px 0px' }}>{cell.value}</Typography>
        ),
      },
      {
        field: 'situation',
        headerName: 'Status',
        valueFormatter: (value) =>
          RESERVATION_SITUATION_MAP[value.value as ReservationSituation],
        width: 200,
      },
      {
        field: 'client_fantasy_name',
        headerName: 'Cliente',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'client_document_type',
        headerName: 'Tipo doc',
      },
      {
        field: 'client_rg_state_issue',
        headerName: 'UF doc',
      },
      { field: 'client_number_document', headerName: 'Documento', width: 150 },
      {
        field: 'parentClient_document',
        headerName: 'CPF',
        width: 150,
      },
      { field: 'client_birth_date', headerName: 'Nascimento' },
      { field: 'client_age', headerName: 'Idade' },
      {
        field: 'parentClient_email',
        headerName: 'E-mail',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'parentClient_cell_phone',
        headerName: 'Telefone',
        width: 150,
      },
      { field: 'client_period', headerName: 'Turma' },
      {
        field: 'client_gender',
        headerName: 'Sexo',
        valueGetter: (params) => {
          return CLIENTS_GENDER[
            params.row.client_gender as keyof typeof CLIENTS_GENDER
          ]
        },
      },
      {
        field: 'observations',
        headerName: 'Obs Aluno',
        flex: 1,
        minWidth: 300,
        renderCell: (cell) => (
          <Typography style={{ whiteSpace: 'break-spaces' }}>
            {cell.value}
          </Typography>
        ),
      },
    ],
    [],
  )

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const generateReport = useCallback(() => {
    ApiService.Reports.generateFacultyTrip({
      status: selectedReservationSituation.map((rs) => String(rs.value)),
      code,
    }).then((response) => {
      setGenerateResult(response)
      if (!response.length) {
        openErrorToast({ message: 'Nenhuma reserva para esta atividade!' })
      }
    })
  }, [selectedReservationSituation, code, openErrorToast])

  function CustomToolbar(props: GridToolbarExportProps) {
    const { user, openErrorToast } = useGlobalState()
    const apiRef = useGridApiContext()

    const generateExportCsv = useCallback(
      () =>
        apiRef.current.exportDataAsCsv({
          ...props.csvOptions,
          utf8WithBom: true,
          delimiter: ';',
        }),
      [apiRef, props.csvOptions],
    )

    const handleExport = useCallback(async () => {
      try {
        await ApiService.AuditLogs.create({
          entity: 'Report',
          type: 'export',
          changes: JSON.stringify([
            {
              valueAfter: {
                status: selectedReservationSituation.map((rs) =>
                  String(rs.value),
                ),
                code,
              },
            },
          ]),
          client_id: null,
          entity_id: 0,
          user_id: user?.id,
        })
        generateExportCsv()
      } catch (error: unknown) {
        openErrorToast({
          message: `Ocorreu um erro ao criar um registro na auditoria: ${
            (error as ApiError).message
          }`,
        })
      }
    }, [generateExportCsv, openErrorToast, user?.id])

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <SaveAltIcon />,
    }
    return (
      <GridToolbarContainer>
        <GridToolbar
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
        <Button {...buttonBaseProps} onClick={() => handleExport()}>
          Exportar
        </Button>
      </GridToolbarContainer>
    )
  }

  if (!institutions?.length) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Relatório de viagem - Faculdade
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <TextField
              label="Código da atividade"
              type="text"
              variant="outlined"
              value={code}
              onChange={(event) => {
                setCode(event.target.value)
              }}
            />

            <SelectWithCheckbox
              options={RESERVATION_SITUATION_OPTIONS}
              value={selectedReservationSituation}
              onSelect={setSelectedReservationSituation}
              selectAllLabel="Todas as situações"
              placeholder="Status da reserva"
            />

            <Button
              color="primary"
              variant="contained"
              onClick={generateReport}
              disabled={!selectedReservationSituation.length || !code}
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Table
              rows={generateResult}
              columns={tableColumns}
              getRowHeight={() => 'auto'}
              customToolbar={() => <CustomToolbar />}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
