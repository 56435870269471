import * as yup from 'yup'

const MESSAGE_REQUIRED_FIELD = 'Campo obrigatório'

const schema = yup.object().shape({
  budget_id: yup.string().required(MESSAGE_REQUIRED_FIELD),
  payment_gateway_id: yup.string().required(MESSAGE_REQUIRED_FIELD),
  commercial_unit_id: yup.string().required(MESSAGE_REQUIRED_FIELD),
  code: yup.string().required(MESSAGE_REQUIRED_FIELD),
  type: yup.string().required(MESSAGE_REQUIRED_FIELD),
  name: yup.string().required(MESSAGE_REQUIRED_FIELD),
  course_name: yup.string().required(MESSAGE_REQUIRED_FIELD),
  start_travel_date: yup.string().required(MESSAGE_REQUIRED_FIELD),
  end_travel_date: yup.string().required(MESSAGE_REQUIRED_FIELD),
  price: yup.string().required(MESSAGE_REQUIRED_FIELD),
  viability: yup.string().required(MESSAGE_REQUIRED_FIELD),
  interest_free_installment: yup.string().required(MESSAGE_REQUIRED_FIELD),
  initial_stock: yup.string().required(MESSAGE_REQUIRED_FIELD),
  responsible_id: yup.string().required(MESSAGE_REQUIRED_FIELD),
  institution_id: yup.string().required(MESSAGE_REQUIRED_FIELD),
  campus_institution_id: yup.string().required(MESSAGE_REQUIRED_FIELD),
  departure_at: yup.string().required(MESSAGE_REQUIRED_FIELD),
  return_at: yup.string().required(MESSAGE_REQUIRED_FIELD),
  enrollment_limit: yup.string().required(MESSAGE_REQUIRED_FIELD),
  arrival_forecast_last_unit: yup.string().required(MESSAGE_REQUIRED_FIELD),
  shipping_company: yup.string().required(MESSAGE_REQUIRED_FIELD),
  driver_name: yup.string().required(MESSAGE_REQUIRED_FIELD),
  restaurant: yup.string().required(MESSAGE_REQUIRED_FIELD),
  type_shipping_company: yup.string().required(MESSAGE_REQUIRED_FIELD),
  value_shipping_company: yup.string().required(MESSAGE_REQUIRED_FIELD),
  restaurant_package: yup.string().required(MESSAGE_REQUIRED_FIELD),
  description: yup.string().required(MESSAGE_REQUIRED_FIELD),
})

export { schema }
