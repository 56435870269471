import { useMemo, useState, useCallback } from 'react'

import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import {
  useGridApiContext,
  type GridColDef,
  type GridToolbarExportProps,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { ApiService } from 'services/api'
import { currencyMask } from 'utils/mask'

const RESERVATION_SITUATION_OPTIONS: Array<{
  value: ReservationSituation
  label: string
}> = [
  { value: 'waiting_payment', label: 'Aguardando pagamento' },
  { value: 'payment_confirmed', label: 'Pago' },
  { value: 'realized', label: 'Realizada' },
  { value: 'cancelled', label: 'Cancelada' },
  { value: 'payment_reversal', label: 'Pagamento estornado' },
]

export default function SalesAffiliatesReport() {
  const [generateResult, setGenerateResult] = useState<SalesReportResponse[]>(
    [],
  )

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedReservationSituation, setSelectedReservationSituation] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedAffiliates, setSelectedAffiliates] =
    useState<SelectWithCheckboxOptions>([])

  const [activityCode, setActivityCode] = useState<string>('')

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)

  const { openErrorToast } = useGlobalState()

  const tableColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: '#',
        headerName: '#',
        filterable: false,
        width: 70,
        renderCell: (cell) => cell.api.getRowIndex(cell.row.id) + 1,
      },
      {
        field: 'reservation_code',
        headerName: 'Reserva',
        flex: 1,
      },
      {
        field: 'date',
        headerName: 'Data',
        flex: 1,
      },
      {
        field: 'affiliate_name',
        headerName: 'Afiliado',
        width: 200,
      },
      {
        field: 'institution_fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'activity_name',
        headerName: 'Atividade',
        flex: 1,
        minWidth: 300,
      },
      { field: 'client_name', headerName: 'Cliente', flex: 1, minWidth: 300 },
      {
        field: 'reservation_payment_value',
        headerName: 'Valor',
        valueGetter: (param) => {
          return currencyMask(param.row.reservation_payment_value, {
            style: 'currency',
            currency: 'BRL',
          })
        },
        flex: 1,
      },
      {
        field: 'affiliate_percent',
        headerName: '% Afiliado',
        valueGetter: (param) => {
          return currencyMask(Number(param.row.affiliate_percent))
        },
        flex: 1,
      },
      {
        field: 'affiliate_value',
        headerName: 'Valor afiliado',
        valueGetter: (param) => {
          return currencyMask(param.row.affiliate_value, {
            style: 'currency',
            currency: 'BRL',
          })
        },
        flex: 1,
      },
    ],
    [],
  )

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const { data: affiliates } = useQuery<SelectWithCheckboxOptions>(
    QUERY_KEYS.AFFILIATES.LIST,
    () => ApiService.Affiliates.getAllNamesAndIds(),
  )

  const generateReport = useCallback(() => {
    ApiService.Reports.generateSalesAffiliates({
      institutions: selectedInstitutions.map((si) => Number(si.value)),
      status: selectedReservationSituation.map((rs) => String(rs.value)),
      affiliates: selectedAffiliates.map((c) => Number(c.value)),
      activityCode,
      start: startDate
        ? String(startDate).split('/').reverse().join('-')
        : undefined,
      end: endDate ? String(endDate).split('/').reverse().join('-') : undefined,
    }).then((response) => {
      setGenerateResult(response)
      if (!response.length) {
        openErrorToast({ message: 'Nenhum resultado encontrado.' })
      }
    })
  }, [
    selectedInstitutions,
    selectedReservationSituation,
    selectedAffiliates,
    activityCode,
    startDate,
    endDate,
    openErrorToast,
  ])

  function CustomToolbar(props: GridToolbarExportProps) {
    const { user, openErrorToast } = useGlobalState()
    const apiRef = useGridApiContext()

    const generateExportCsv = useCallback(
      () =>
        apiRef.current.exportDataAsCsv({
          ...props.csvOptions,
          utf8WithBom: true,
          delimiter: ';',
        }),
      [apiRef, props.csvOptions],
    )

    const handleExport = useCallback(async () => {
      try {
        await ApiService.AuditLogs.create({
          entity: 'Report',
          type: 'export',
          changes: JSON.stringify([
            {
              valueAfter: {
                institutions: selectedInstitutions.map((si) =>
                  Number(si.value),
                ),
                status: selectedReservationSituation.map((rs) =>
                  String(rs.value),
                ),
                affiliates: selectedAffiliates.map((c) => Number(c.value)),
                activityCode,
                start: startDate
                  ? String(startDate).split('/').reverse().join('-')
                  : undefined,
                end: endDate
                  ? String(endDate).split('/').reverse().join('-')
                  : undefined,
              },
            },
          ]),
          client_id: null,
          entity_id: 0,
          user_id: user?.id,
        })
        generateExportCsv()
      } catch (error: unknown) {
        openErrorToast({
          message: `Ocorreu um erro ao criar um registro na auditoria: ${
            (error as ApiError).message
          }`,
        })
      }
    }, [generateExportCsv, openErrorToast, user?.id])

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <SaveAltIcon />,
    }
    return (
      <GridToolbarContainer>
        <GridToolbar
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
        <Button {...buttonBaseProps} onClick={() => handleExport()}>
          Exportar
        </Button>
      </GridToolbarContainer>
    )
  }

  if (!institutions?.length || !affiliates?.length) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Relatório de vendas - Afiliados
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <SelectWithCheckbox
              options={RESERVATION_SITUATION_OPTIONS}
              value={selectedReservationSituation}
              onSelect={setSelectedReservationSituation}
              selectAllLabel="Todas as situações"
              placeholder="Status da reserva"
            />

            <SelectWithCheckbox
              options={affiliates}
              value={selectedAffiliates}
              onSelect={setSelectedAffiliates}
              selectAllLabel="Todas os afiliados"
              placeholder="Afiliados"
            />

            <TextField
              label="Código da atividade"
              type="text"
              variant="outlined"
              value={activityCode}
              onChange={(event) => {
                setActivityCode(event.target.value)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={generateReport}
              disabled={
                !selectedInstitutions.length &&
                !selectedAffiliates.length &&
                !selectedReservationSituation.length &&
                !startDate &&
                !endDate
              }
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Table
              rows={generateResult}
              columns={tableColumns}
              customToolbar={() => <CustomToolbar />}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
