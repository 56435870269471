import { useCallback, useEffect, useRef } from 'react'

import { InputBase } from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import InputCurrency from 'components/InputCurrency'
import { monetaryToNumber } from 'utils/mask'

import { monetaryMask } from '../add'

export const CustomEditField = ({
  id,
  value,
  field,
  row,
  handleCal,
  budget,
  setBudget,
  error,
  onChange,
  hasFocus,
  cellMode,
  onBlur,
  ...rest
}: GridRenderEditCellParams & {
  handleCal: (data: BudgetItemsForm[]) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => void
  setBudget: React.Dispatch<React.SetStateAction<BudgetItemsForm[]>>
  budget: BudgetItemsForm[]
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const apiRef = useGridApiContext()

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      const isFieldQuantity = field === 'quantity'
      apiRef.current.setEditCellValue({ id, field, value: newValue })
      const updated = budget.map((item) =>
        Number(item.id) === Number(id)
          ? {
              ...row,
              [field]: newValue,
              subtotal: monetaryMask(
                isFieldQuantity
                  ? Number(newValue) * monetaryToNumber(row.unit_value)
                  : Number(row.quantity) * monetaryToNumber(newValue),
              ),
            }
          : item,
      )
      setBudget(updated)
      handleCal(updated)
    },
    [apiRef, budget, field, handleCal, id, row, setBudget],
  )
  const InputBaseComponent = field === 'quantity' ? InputBase : InputCurrency
  useEffect(() => {
    if (inputRef.current && row?.isNew && field === 'unit_value') {
      inputRef.current.focus()
    }
  }, [field, row?.isNew])

  return (
    <InputBaseComponent
      sx={{
        flexGrow: 1,
        padding: '5px 20px 5px 5px',
      }}
      fullWidth
      label=""
      id=""
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
        handleValueChange(event)
      }}
      inputRef={inputRef}
      outlined={false}
      error={error}
      onBlur={onBlur}
    />
  )
}
