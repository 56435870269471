import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<ItemsBudget[]> => {
  try {
    const getAllResponse = await apiInstance.get<ItemsBudgetApiResponse[]>(
      ENDPOINTS.ITEMS_BUDGET.GET_ALL,
    )

    return getAllResponse.data.sort((currentBudgetItem, nextBudgetItem) =>
      currentBudgetItem.name.localeCompare(nextBudgetItem.name),
    )
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const listPage = async (): Promise<ItemsBudget[]> => {
  try {
    const getAllResponse = await apiInstance.get<ItemsBudgetApiResponse[]>(
      ENDPOINTS.ITEMS_BUDGET.LIST_PAGE,
    )

    return getAllResponse.data.sort((currentBudgetItem, nextBudgetItem) =>
      currentBudgetItem.name.localeCompare(nextBudgetItem.name),
    )
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllIsDefault = async (): Promise<ItemsBudget[]> => {
  try {
    const getAllResponse = await getAll()
    const filterResponseByDefault = getAllResponse.filter(
      (itemBudget) => itemBudget.default,
    )

    return filterResponseByDefault
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<ItemsBudget> => {
  try {
    const getResponse = await apiInstance.get<ItemsBudgetApiResponse>(
      ENDPOINTS.ITEMS_BUDGET.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  itemsBudget: ItemsBudgetApiRequest,
): Promise<ItemsBudget> => {
  try {
    const creationResponse = await apiInstance.post<ItemsBudgetApiResponse>(
      ENDPOINTS.ITEMS_BUDGET.CREATE,
      itemsBudget,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  itemsBudget,
}: {
  itemsBudget: ItemsBudgetApiRequest
  id: string
}): Promise<ItemsBudget> => {
  try {
    const updateResponse = await apiInstance.put<ItemsBudgetApiResponse>(
      ENDPOINTS.ITEMS_BUDGET.UPDATE(id),
      itemsBudget,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.ITEMS_BUDGET.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
