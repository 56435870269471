import { useCallback, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ConfirmationModal from 'components/ConfirmationModal'
import { INPUT_MASK } from 'constants/masks'
import { useGlobalState } from 'contexts/global-state'
import moment from 'moment'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'

type FinishedButtonProps = {
  activity: Activity
  type: 'icon' | 'button'
  onLoading?: (value: boolean) => void
}

type FinishedModalProps = {
  opened: boolean
  activity_name: string
  onClose: () => void
  onConfim: () => void
  onCancel: () => void
}

const FinishedModal = ({
  onCancel,
  onClose,
  onConfim,
  opened,
  activity_name,
}: FinishedModalProps) => {
  return (
    <ConfirmationModal
      title="Finalizar atividade"
      opened={opened}
      onClose={onClose}
      onConfim={onConfim}
      onCancel={onCancel}
    >
      <p>
        Deseja confirmar a alteração do status <strong>CONFIRMADA</strong> para{' '}
        <strong>FINALIZADA</strong> para a atividade{' '}
        <strong>{activity_name}</strong>
      </p>
    </ConfirmationModal>
  )
}

export const FinishedButton = ({
  activity,
  type,
  onLoading,
}: FinishedButtonProps) => {
  const { openErrorToast, openSuccessToast } = useGlobalState()

  const [cancelModal, setCancelModal] = useState(false)

  const handleFinishedActivity = useCallback(async () => {
    onLoading?.(true)
    const { id } = activity
    try {
      await ApiService.Activities.update({
        activity: {
          status: 'finished',
          notUpdateRelations: true,
        } as ActivityApiRequest,
        id: id.toString(),
      })
      CacheService.queryClient.invalidateQueries()
      setCancelModal(false)
      openSuccessToast({
        message: 'Status alterado com sucesso',
      })
      onLoading?.(false)
    } catch (error: unknown) {
      openErrorToast({
        message: (error as ApiError)?.message || (error as string),
      })
    }
  }, [activity, onLoading, openErrorToast, openSuccessToast])

  if (
    moment(activity.end_travel_date, INPUT_MASK.DATE_LOCALE).isAfter(
      moment(),
    ) ||
    activity.status !== 'confirmed'
  ) {
    return null
  }
  return (
    <>
      {type === 'icon' ? (
        <CheckIcon
          fontSize="small"
          onClick={() => {
            setCancelModal(true)
          }}
        />
      ) : (
        <Box sx={{ m: 1 }}>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setCancelModal(true)
            }}
          >
            Finalizar atividade
          </Button>
        </Box>
      )}

      <FinishedModal
        activity_name={activity.name}
        onCancel={() => setCancelModal(false)}
        onClose={() => setCancelModal(false)}
        onConfim={handleFinishedActivity}
        opened={cancelModal}
      />
    </>
  )
}
