import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<BudgetApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<BudgetApiResponse[]>(
      ENDPOINTS.BUDGETS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIdsCurrentYear = async ({
  budget_id,
}: BudgetsGetNameAndIdsCurrentYearApiRequest): Promise<BudgetApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.post<BudgetApiResponse[]>(
      ENDPOINTS.BUDGETS.GET_NAME_AND_IDS_CURRENT_YEAR,
      {
        budget_id,
      },
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((paymentGateway) => ({
      label: paymentGateway.name,
      value: paymentGateway.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<BudgetApiResponse> => {
  try {
    const getResponse = await apiInstance.get<BudgetApiResponse>(
      ENDPOINTS.BUDGETS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const duplicate = async (id: string): Promise<BudgetApiResponse> => {
  try {
    const duplicateResponse = await apiInstance.get<BudgetApiResponse>(
      ENDPOINTS.BUDGETS.DUPLICATE(id),
    )

    return duplicateResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  budget: BudgetsApiRequest,
): Promise<BudgetApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<BudgetApiResponse>(
      ENDPOINTS.BUDGETS.CREATE,
      budget,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  budget,
}: {
  budget: BudgetsApiRequest
  id: string
}): Promise<BudgetApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<BudgetApiResponse>(
      ENDPOINTS.BUDGETS.UPDATE(id),
      budget,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.BUDGETS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
