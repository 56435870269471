import { useCallback } from 'react'

import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Button, ButtonProps } from '@mui/material'
import {
  GridToolbarContainer,
  GridToolbarExportProps,
  useGridApiContext,
} from '@mui/x-data-grid'
import { useGlobalState } from 'contexts/global-state'

export function CustomToolbar(props: GridToolbarExportProps) {
  const { openErrorToast } = useGlobalState()
  const apiRef = useGridApiContext()

  const generateExportCsv = useCallback(
    () =>
      apiRef.current.exportDataAsCsv({
        ...props.csvOptions,
        utf8WithBom: true,
        delimiter: ';',
      }),
    [apiRef, props.csvOptions],
  )

  const handleExport = useCallback(async () => {
    try {
      generateExportCsv()
    } catch (error: unknown) {
      openErrorToast({
        message: `Ocorreu um erro ao criar um registro na auditoria: ${
          (error as ApiError).message
        }`,
      })
    }
  }, [generateExportCsv, openErrorToast])

  const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <SaveAltIcon />,
  }
  return (
    <GridToolbarContainer>
      {/* <GridToolbar
        csvOptions={{ disableToolbarButton: true }}
        printOptions={{ disableToolbarButton: true }}
      /> */}
      <Button {...buttonBaseProps} onClick={() => handleExport()}>
        Exportar
      </Button>
    </GridToolbarContainer>
  )
}
