import { QUERY_KEYS } from 'constants/keys'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'

import { ERRORS_ORDER_SERVICE } from '../constants/errors'

type HandleUpdateOrderServiceProps = {
  onLoading?: (loadingState: boolean) => void
  onClose?: () => void
  openErrorToast: (props: { message: string }) => void
  openSuccessToast: (props: { message: string }) => void
  orderService: OrderServicesApiRequest
  id: number | string
}

export async function handleUpdateOrderService({
  id,
  orderService,
  onLoading,
  openErrorToast,
  openSuccessToast,
  onClose,
}: HandleUpdateOrderServiceProps) {
  onLoading?.(true)
  try {
    await ApiService.OrderServices.update({
      id: id.toString(),
      orderService,
    })
    CacheService.queryClient.invalidateQueries(
      QUERY_KEYS.ORDER_SERVICES.EDIT(id.toString()),
    )
    onClose?.()
    openSuccessToast({
      message: 'Ordem de serviço atualizada com sucesso',
    })
  } catch (error: unknown) {
    const errorData = error as ApiError
    let message = 'Erro ao adicionar ordem de serviço!'
    if (errorData?.message) {
      message = ERRORS_ORDER_SERVICE[errorData.message]?.(errorData.data)
    }
    openErrorToast({
      message,
    })
  } finally {
    onLoading?.(false)
  }
}
