import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'
export * as Permissions from './permissions'

export const getAll = async (): Promise<RoleApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<RoleApiResponse[]>(
      ENDPOINTS.ROLES.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()

    return (getAllResponse || []).map((role) => ({
      label: role.name,
      value: role.id.toString(),
    }))
  } catch (_getAllError) {
    return Promise.reject([])
  }
}

export const get = async (id: string): Promise<RoleApiResponse> => {
  try {
    const getResponse = await apiInstance.get<RoleApiResponse>(
      ENDPOINTS.ROLES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  role: RolesApiRequest,
): Promise<RoleApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<RoleApiResponse>(
      ENDPOINTS.ROLES.CREATE,
      role,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  role,
}: {
  role: RolesApiRequest
  id: string
}): Promise<RoleApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<RoleApiResponse>(
      ENDPOINTS.ROLES.UPDATE(id),
      role,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(ENDPOINTS.ROLES.DELETE(id))

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
