import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  video?: Video
}

export default function Delete({ opened, closeModal, video }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeVideo = useMutation({
    mutationFn: async (video: Video) => {
      await ApiService.Videos.remove(video.id.toString())

      openSuccessToast({
        message: `O video ${video?.title} foi removida com sucesso!`,
      })

      navigate(ROUTES.VIDEOS.LIST)
    },
    onSuccess: (_data, video: Video) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.VIDEOS.LIST,
        (oldVideos) => {
          if (!oldVideos?.length) return

          return oldVideos.filter((oldVideo) => oldVideo.id !== video.id)
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(video.id.toString()),
      )

      openSuccessToast({
        message: `O video ${video.title} foi removida com sucesso!`,
      })

      navigate(ROUTES.VIDEOS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeVideo.mutate(video as Video)}
      confirmColor="error"
    >
      Deseja realmente remover o video <strong>{`${video?.title}?`}</strong>
    </ConfirmationModal>
  )
}
