import { useCallback, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import IconButton from '@mui/material/IconButton'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { ENDPOINTS } from 'constants/endpoints'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import currency from 'currency.js'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'
import { currencyMask } from 'utils/mask'

const BRL = (value: number) =>
  currency(value, {
    decimal: '.',
    separator: '',
    symbol: '',
  })

type FinishedButtonProps = {
  activity: Activity
  affiliate: Affiliate
  onLoading?: (value: boolean) => void
}

type ReleasedPaymentModalProps = {
  opened: boolean
  activity_name: string
  affiliate_value: number
  affiliate_name: string
  available_amount: number
  pending_amount: number
  onClose: () => void
  onConfim: () => void
  onCancel: () => void
}

const ReleasedPaymentModal = ({
  onCancel,
  onClose,
  onConfim,
  opened,
  activity_name,
  affiliate_value,
  affiliate_name,
  available_amount,
  pending_amount,
}: ReleasedPaymentModalProps) => {
  return (
    <ConfirmationModal
      title="Liberar pagamento"
      opened={opened}
      onClose={onClose}
      onConfim={onConfim}
      onCancel={onCancel}
    >
      <>
        <p>
          Deseja realmente fazer a liberação do pagamento da atividade{' '}
          <strong>{activity_name}</strong> para o afiliado{' '}
          <strong>{affiliate_name}</strong> no valor de .
          <strong>R$ {currencyMask(affiliate_value)}</strong>
        </p>
        <p>
          Saldo disponível: <strong>R$ {currencyMask(available_amount)}</strong>
        </p>
        <p>
          Saldo aguardando liberação:{' '}
          <strong>R$ {currencyMask(pending_amount)}</strong>
        </p>
        {affiliate_value > available_amount && (
          <p>*Saldo insuficiente para transferência</p>
        )}
      </>
    </ConfirmationModal>
  )
}

export const ReleasedPayment = ({
  activity,
  affiliate,
  onLoading,
}: FinishedButtonProps) => {
  const { openErrorToast, openSuccessToast, user } = useGlobalState()

  const [cancelModal, setCancelModal] = useState(false)
  const [availableAmount, setAvailableAmount] = useState<number>()
  const [pendingAmount, setPendingAmount] = useState<number>()
  const [hasAvailableAmount, setHasAvailableAmount] = useState(false)

  const { data: checkPermission, isLoading: checkPermissionLoading } = useQuery<
    CheckPermissionApiResponse | undefined
  >({
    enabled: !!user,
    queryKey: QUERY_KEYS.CHECK_PERMISSION.GET(
      ENDPOINTS.FAKES_ROUTES.RELEASED_PAYMENT,
    ),
    queryFn: async () => {
      if (!user) return
      return await ApiService.CheckPermission.get({
        method: 'GET',
        originalUrl: ENDPOINTS.FAKES_ROUTES.RELEASED_PAYMENT,
        role_id: user?.role_id,
        user_id: user?.id,
      })
    },
  })

  const handleReleasedPayment = useCallback(async () => {
    onLoading?.(true)
    try {
      if (affiliate.affiliate_commission_value && hasAvailableAmount) {
        await ApiService.Recipients.createWithrawals({
          recipient_id: String(affiliate.recipient_id),
          amount: BRL(affiliate.affiliate_commission_value).intValue || 100,
          affiliate_id: affiliate.id,
          activity_id: activity.id,
        })
        CacheService.queryClient.invalidateQueries()
        setCancelModal(false)
        openSuccessToast({
          message: 'Pagamento liberado com sucesso',
        })
        onLoading?.(false)
      }
    } catch (error: unknown) {
      onLoading?.(false)
      setCancelModal(false)
      openErrorToast({
        message: (error as ApiError)?.message || (error as string),
      })
    }
  }, [
    activity.id,
    affiliate.affiliate_commission_value,
    affiliate.id,
    affiliate.recipient_id,
    hasAvailableAmount,
    onLoading,
    openErrorToast,
    openSuccessToast,
  ])

  if (
    activity.status !== 'confirmed' ||
    checkPermissionLoading ||
    !checkPermission?.approved
  ) {
    return null
  }
  return (
    <>
      <IconButton color="primary">
        <CheckIcon
          fontSize="small"
          onClick={async () => {
            onLoading?.(true)
            try {
              const balanceResponse = await ApiService.Recipients.getBalance({
                recipient_id: String(affiliate.recipient_id),
              })
              const availableAmount = BRL(
                balanceResponse.available_amount,
              ).divide(100).value
              let pendingAmount = BRL(
                affiliate.affiliate_commission_value || 0,
              ).subtract(availableAmount).value

              if (pendingAmount < 0) {
                pendingAmount = 0
              }
              setPendingAmount(pendingAmount)
              setAvailableAmount(availableAmount)

              if (affiliate.affiliate_commission_value) {
                setHasAvailableAmount(
                  affiliate.affiliate_commission_value <= availableAmount,
                )
              }
            } catch (error) {
              openErrorToast({
                message: 'Erro ao consutar saldo na Pagar.me',
              })
            }
            onLoading?.(false)
            setCancelModal(true)
          }}
        />
      </IconButton>

      <ReleasedPaymentModal
        activity_name={activity.name}
        affiliate_name={affiliate.name}
        affiliate_value={affiliate.affiliate_commission_value || 0}
        available_amount={availableAmount || 0}
        pending_amount={pendingAmount || 0}
        onCancel={() => {
          onLoading?.(false)
          setCancelModal(false)
        }}
        onClose={() => {
          onLoading?.(false)
          setCancelModal(false)
        }}
        onConfim={handleReleasedPayment}
        opened={cancelModal}
      />
    </>
  )
}
