import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  reservation?: Reservation
}

export default function Delete({
  opened,
  closeModal,
  reservation,
}: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeReservation = useMutation({
    mutationFn: async (reservation: Reservation) => {
      await ApiService.Reservations.remove(reservation.id.toString())

      openSuccessToast({
        message: `A reserva ${reservation?.code} foi removida com sucesso!`,
      })

      navigate(ROUTES.RESERVATIONS.LIST)
    },
    onSuccess: (_data, reservation: Reservation) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.RESERVATIONS.LIST,
        (oldReservations) => {
          if (!oldReservations?.length) return

          return oldReservations.filter(
            (oldReservation) => oldReservation.id !== reservation.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(reservation.id.toString()),
      )

      openSuccessToast({
        message: `A reserva ${reservation.code} foi removida com sucesso!`,
      })

      navigate(ROUTES.RESERVATIONS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeReservation.mutate(reservation as Reservation)}
      confirmColor="error"
    >
      Deseja realmente remover a reserva{' '}
      <strong>{`${reservation?.code}?`}</strong>
    </ConfirmationModal>
  )
}
