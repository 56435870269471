import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<PaymentGatewayApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<PaymentGatewayApiResponse[]>(
      ENDPOINTS.PAYMENT_GATEWAYS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((paymentGateway) => ({
      label: paymentGateway.account_name,
      value: paymentGateway.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<PaymentGatewayApiResponse> => {
  try {
    const getResponse = await apiInstance.get<PaymentGatewayApiResponse>(
      ENDPOINTS.PAYMENT_GATEWAYS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  paymentGateway: PaymentGatewayApiRequest,
): Promise<PaymentGatewayApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<PaymentGatewayApiResponse>(
      ENDPOINTS.PAYMENT_GATEWAYS.CREATE,
      paymentGateway,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  paymentGateway,
}: {
  paymentGateway: PaymentGatewayApiRequest
  id: string
}): Promise<PaymentGatewayApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<PaymentGatewayApiResponse>(
      ENDPOINTS.PAYMENT_GATEWAYS.UPDATE(id),
      paymentGateway,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.PAYMENT_GATEWAYS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
