import { useState, useCallback } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import {
  Chart as ChartJS,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  SubTitle,
} from 'chart.js'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { Pie } from 'react-chartjs-2'
import { ApiService } from 'services/api'
import { CHART_COLORS } from 'utils/chartColors'
import formatCurrencyUnit from 'utils/formatCurrencyUnit'
import { MONTHS } from 'utils/months'

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle)

export default function AffiliateAverageTicket() {
  const [totalSales, setTotalSales] = useState<number>(0)
  const [totalSalesCount, setTotalSalesCount] = useState<number>(0)

  const [averageTicketChartData, setAverageChartData] = useState<
    ChartData<'pie', number[], unknown> | undefined
  >()

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { openErrorToast } = useGlobalState()

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const generateReport = useCallback(() => {
    setIsLoading(true)
    ApiService.Reports.generateSalesAffiliates({
      institutions: selectedInstitutions.map((si) => Number(si.value)),
      start: startDate
        ? String(startDate).split('/').reverse().join('-')
        : undefined,
      end: endDate ? String(endDate).split('/').reverse().join('-') : undefined,
    })
      .then((response) => {
        const totalSales = response.reduce((accumulator, currentValue) => {
          accumulator += parseFloat(currentValue.reservation_payment_value)
          return accumulator
        }, 0)

        const countByMonth = response.reduce(
          (accumulator: Record<string, number>, currentValue) => {
            const key =
              MONTHS[
                new Date(
                  currentValue.date.split('/').reverse().join('-'),
                ).getMonth()
              ]

            if (accumulator[key]) {
              accumulator[key] += 1
              return accumulator
            }

            accumulator[key] = 1
            return accumulator
          },
          {},
        )

        const totalSalesByMonth = response.reduce(
          (accumulator: Record<string, number>, currentValue) => {
            const key =
              MONTHS[
                new Date(
                  currentValue.date.split('/').reverse().join('-'),
                ).getMonth()
              ]

            if (accumulator[key]) {
              accumulator[key] += parseFloat(
                currentValue.reservation_payment_value,
              )
              return accumulator
            }

            accumulator[key] = parseFloat(
              currentValue.reservation_payment_value,
            )
            return accumulator
          },
          {},
        )

        const averageTicketByMonth = Object.keys(totalSalesByMonth).reduce(
          (accumulator: Record<string, number>, currentValue) => {
            accumulator[currentValue] =
              totalSalesByMonth[currentValue] / countByMonth[currentValue]
            return accumulator
          },
          {},
        )

        setAverageChartData({
          labels: Object.keys(averageTicketByMonth).map(
            (month) =>
              `${month} | ${
                countByMonth[month]
              } tickets | Total: ${formatCurrencyUnit(
                totalSalesByMonth[month],
              )}`,
          ),
          datasets: [
            {
              data: Object.values(averageTicketByMonth),
              backgroundColor: CHART_COLORS,
              borderWidth: 1,
            },
          ],
        })

        setTotalSales(totalSales)
        setTotalSalesCount(response.length)

        if (!response.length) {
          openErrorToast({ message: 'Nenhum resultado encontrado.' })
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [selectedInstitutions, startDate, endDate, openErrorToast])

  if (!institutions?.length || isLoading) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Gráfico de vendas por afiliados | Ticket Médio
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={generateReport}
              disabled={!selectedInstitutions.length && !startDate && !endDate}
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        {averageTicketChartData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Pie
                data={averageTicketChartData}
                width={600}
                height={600}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => formatCurrencyUnit(context.parsed),
                      },
                    },
                    title: {
                      display: true,
                      font: {
                        size: 16,
                      },
                      text: 'Vendas por afiliados / Ticket Médio',
                    },
                    subtitle: {
                      display: true,
                      text: `Total Período: ${formatCurrencyUnit(
                        totalSales,
                      )} | Tickets vendidos: ${totalSalesCount} | Valor médio por Ticket: ${formatCurrencyUnit(
                        totalSales / totalSalesCount,
                      )}`,
                      font: {
                        size: 16,
                      },
                      padding: 12,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </Box>
  )
}
