import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@tanstack/react-query'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (teacher: TeacherApiRequest) => Promise<void>

type AddProps = {
  teacher?: Teacher
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({ teacher, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<TeacherApiRequest>({
    resolver: yupResolver(schema),
  })

  const [addTeachersLoading, setAddTeachersLoading] = useState<boolean>(false)
  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [selectedInstitutions, setSelectedInstitutions] = useState<
    Institution[]
  >([])

  useEffect(() => {
    if (isEditMode && !!teacher && !formUpdated) {
      reset({
        name: teacher.name,
        email: teacher.email,
        password: teacher.password,
        phone: teacher.phone,
        cell_phone: teacher.cell_phone,
        active: teacher.active,
      })

      setFormUpdated(true)
    }
  }, [isEditMode, teacher, formUpdated, reset])

  useEffect(() => {
    if (isEditMode && teacher) {
      setSelectedInstitutions(teacher.institutions)
    }
  }, [isEditMode, teacher])

  const inputProps = useMemo(
    () => (isEditMode ? { InputLabelProps: { shrink: true } } : {}),
    [isEditMode],
  )

  const { data: institutions } = useQuery<Institution[]>(
    QUERY_KEYS.INSTITUTIONS.LIST,
    () => ApiService.Institutions.getAll(),
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Teacher | undefined
  >()

  const institutionsColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'fantasy_name', headerName: 'Nome fantasia', flex: 1 },
      { field: 'contact_name', headerName: 'Nome de contato', flex: 1 },
      {
        field: 'city',
        headerName: 'Cidade',
        valueGetter: (params: GridValueGetterParams<Teacher>) =>
          `${params.row.city || ''}/${params.row.uf || ''}`,
        flex: 1,
      },
      { field: 'cell_phone', headerName: 'Celular', flex: 1 },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
    ],
    [],
  )

  const addNewTeacher = useMutation({
    mutationFn: async (teacher: TeacherApiRequest) => {
      setAddTeachersLoading(true)
      await ApiService.Teachers.create(teacher)
    },
    onSuccess: (_data, teacher: TeacherApiRequest) => {
      setAddTeachersLoading(false)
      openSuccessToast({
        message: `O(a) ${teacher.name} professor(a) foi adicionado(a) com sucesso!`,
      })

      navigate(ROUTES.TEACHERS.LIST)
    },
    onError: (error: ApiError, teacher: TeacherApiRequest) => {
      setAddTeachersLoading(false)
      openErrorToast({
        message:
          error.message ||
          `Erro ao adicionar o (a) ${teacher.name} professor(a)!`,
      })
    },
  })

  if (isEditMode && !!teacher && formUpdated === false) {
    return <LoadingFullPage />
  }

  if (addTeachersLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.TEACHERS.LIST}>
              Professores
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} professor
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(teacher as Teacher)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.TEACHERS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((teacher: TeacherApiRequest) => {
                teacher.institutions = selectedInstitutions
                if (isEditMode) {
                  onSave?.(teacher)
                  return
                }

                addNewTeacher.mutate(teacher)
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps}
                        {...register('name', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.cell_phone?.message}
                              helperText={errors.cell_phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps}
                        {...register('email', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography sx={{ m: 1 }} variant="h4">
                        Instituições
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Table
                        rows={institutions}
                        columns={institutionsColumns}
                        enableCheckBox
                        selectedItems={selectedInstitutions.map(
                          (institution) => +institution.id,
                        )}
                        onSelectRow={(selectedRows) => {
                          setValue(
                            'institutions',
                            selectedRows as Institution[],
                          )
                          setSelectedInstitutions(selectedRows as Institution[])
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    style={{
                      display: 'block',
                      marginTop: '1em',
                      width: 'calc(100% - 24px)',
                    }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        teacher={deleteConfirmation as Teacher}
      />
    </>
  )
}
