import { useCallback, useMemo, useState } from 'react'

import { Delete as DeleteIcon } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

enum TABS {
  INSTITUTIONS,
}

export default function View() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { openSuccessToast, openErrorToast } = useGlobalState()
  const { id } = useParams<{ id: string }>()

  const { data, isLoading, refetch } = useQuery<Teacher>(
    QUERY_KEYS.TEACHERS.VIEW(id as string),
    () => ApiService.Teachers.get(id as string),
  )

  const [currentTab, setCurrentTab] = useState<TABS>(TABS.INSTITUTIONS)
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Teacher | undefined
  >()

  const handleRemoveTeacherInstitution = useCallback(
    async ({
      institution_id,
      teacher_id,
    }: TeacherInstitutionRemoveApiRequest) => {
      setLoading(true)
      try {
        await ApiService.TeachersInstitutions.remove({
          institution_id,
          teacher_id,
        })
        refetch()
        openSuccessToast({
          message: `Professor removido da instituição com sucesso`,
        })
        setLoading(false)
      } catch (catchError: unknown) {
        const error = catchError as ApiError
        openErrorToast({
          message:
            error.message ||
            `Ocorreu um erro ao remover esse registro: ${error.message}`,
        })
        setLoading(false)
      }
    },
    [openErrorToast, openSuccessToast, refetch],
  )

  const institutionsColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'fantasy_name', headerName: 'Nome fantasia', flex: 1 },
      { field: 'contact_name', headerName: 'Nome de contato', flex: 1 },
      {
        field: 'city',
        headerName: 'Cidade',
        valueGetter: (params: GridValueGetterParams<Institution>) =>
          `${params.row.city || ''}/${params.row.uf || ''}`,
        flex: 1,
      },
      { field: 'cell_phone', headerName: 'Celular', flex: 1 },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Institution>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.INSTITUTIONS.VIEW(params.row.id))
                }}
              />
              <DeleteIcon
                onClick={() => {
                  if (!params.row.id || !id) {
                    openErrorToast({
                      message: `Dados inválidos não conseguimos identificar o professor ou instituição`,
                    })
                    return
                  }
                  handleRemoveTeacherInstitution({
                    institution_id: Number(params.row.id),
                    teacher_id: Number(id),
                  })
                }}
              />
            </>
          )
        },
      },
    ],
    [handleRemoveTeacherInstitution, id, navigate, openErrorToast],
  )

  const handleSendPassword = useCallback(
    async ({ email, id }: TeacherSendPasswordRequest) => {
      try {
        await ApiService.Teachers.sendPassword({
          id,
          email,
        })
        openSuccessToast({
          message: `Senha enviada com sucesso para o email: ${email}`,
        })
      } catch (error) {
        openErrorToast({
          message: `Erro ao enviar a senha para o email: ${email}`,
        })
      }
    },
    [openErrorToast, openSuccessToast],
  )

  if (isLoading || loading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {data?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={async () => {
                      await handleSendPassword({
                        email: data?.email,
                        id: data?.id,
                      })
                    }}
                  >
                    Enviar Senha
                  </Button>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.TEACHERS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(data as Teacher)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel label="Nome" value={data?.name} />

              <TextWithLabel label="Email" value={data?.email} />

              <TextWithLabel label="Telefone" value={data?.phone} />

              <TextWithLabel label="Celular" value={data?.cell_phone} />
            </CardContent>
          </Card>
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Instituições" />
                </Tabs>

                <TabPanel value={TABS.INSTITUTIONS} index={currentTab}>
                  <Table
                    rows={data?.institutions || []}
                    columns={institutionsColumns}
                  />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        teacher={deleteConfirmation as Teacher}
      />
    </>
  )
}
