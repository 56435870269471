import { useEffect, type PropsWithChildren, useCallback, useState } from 'react'

import AppBar from 'components/AppBar'
import LoadingFullPage from 'components/LoadingFullPage'
import {
  HAS_AUTHORIZATION_ERROR,
  TOAST_NOT_AUTHORIZED_ROUTE,
  USER_SESSION_STORAGE_KEY,
} from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { PATH_METHODS } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ApiService } from 'services/api'

const handleGetPathUrl = (url: string) => {
  if (url.includes('/create')) {
    return 'create'
  }
  if (url.includes('/update')) {
    return 'update'
  }
  if (url.includes('/delete')) {
    return 'delete'
  }
  return 'view'
}

export const PrivateRoute = ({ children }: PropsWithChildren<unknown>) => {
  const [loading, setLoading] = useState(true)
  const { user, openErrorToast } = useGlobalState()
  const history = useLocation()
  const navigate = useNavigate()
  const logged = sessionStorage.getItem(USER_SESSION_STORAGE_KEY)

  useEffect(() => {
    const hasAuthorizationError = localStorage.getItem(HAS_AUTHORIZATION_ERROR)
    if (hasAuthorizationError === 'true') {
      toast.error('Você não tem permissão para acessar essa página', {
        position: toast.POSITION.TOP_CENTER,
        toastId: TOAST_NOT_AUTHORIZED_ROUTE,
      })
      localStorage.removeItem(HAS_AUTHORIZATION_ERROR)
    }
  }, [history])

  const handleCheckPermission = useCallback(async () => {
    setLoading(true)
    const excludeRoutes = [ROUTES.ROOT, ROUTES.LOGIN]
    if (excludeRoutes.includes(history.pathname)) {
      setLoading(false)
      return
    }
    if (!user) return
    try {
      const responsePermission = await ApiService.CheckPermission.get({
        method: PATH_METHODS[handleGetPathUrl(history.pathname)],
        originalUrl: history.pathname.slice(1, history.pathname.length),
        role_id: user?.role_id,
        user_id: user?.id,
      })
      if (!responsePermission.approved) {
        localStorage.setItem(HAS_AUTHORIZATION_ERROR, 'true')
        navigate(-1)
      }
    } catch (error) {
      const errorMessage = (error as { response: ApiError })?.response?.message
      openErrorToast({
        message: errorMessage || 'Ocorreu um erro ao verificar as permissões',
      })
    }
    setLoading(false)
  }, [history.pathname, user, navigate, openErrorToast])

  useEffect(() => {
    handleCheckPermission()
  }, [handleCheckPermission])

  if (!logged) {
    return <Navigate to={ROUTES.LOGIN} replace />
  }
  if (loading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <AppBar />
      {children}
    </>
  )
}
