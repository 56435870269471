import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<OrderServiceApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<OrderServiceApiResponse[]>(
      ENDPOINTS.ORDER_SERVICES.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<OrderServiceApiResponse> => {
  try {
    const getResponse = await apiInstance.get<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getByItemBudget = async ({
  budget_id,
  item_budget_id,
  supplier_id,
}: GetOrderServiceByItemBudgetApiRequest): Promise<OrderServiceApiResponse> => {
  try {
    const getResponse = await apiInstance.post<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.GET_BY_ITEM_BUDGET,
      {
        budget_id,
        item_budget_id,
        supplier_id,
      },
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getByItemBudgetWithStatusIssuedOrSend = async ({
  budget_id,
  item_budget_id,
  supplier_id,
}: GetOrderServiceByItemBudgetApiRequest): Promise<OrderServiceApiResponse> => {
  try {
    const getResponse = await apiInstance.post<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.GET_BY_ITEM_BUDGET_WITH_STATUS_ISSUED_OR_SEND,
      {
        budget_id,
        item_budget_id,
        supplier_id,
      },
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getWithItemsById = async (
  id: string,
): Promise<OrderServiceWithItemsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<OrderServiceWithItemsApiResponse>(
      ENDPOINTS.ORDER_SERVICES.GET_WITH_ITEMS_BY_ID(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const sendOrderServiceToSupplier = async (
  id: string,
  queryParams: { sendTo: string },
): Promise<void> => {
  try {
    await apiInstance.get<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.SEND_ORDER_SERVICE_TO_SUPPLIER(id),
      {
        params: {
          ...queryParams,
        },
      },
    )
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const downloadPrint = async (
  id: string,
  options: { fileName: string },
) => {
  try {
    const getResponse = await apiInstance.get(
      ENDPOINTS.ORDER_SERVICES.GET_PDF(id),
      {
        responseType: 'arraybuffer',
      },
    )

    const a = document.createElement('a')
    const blob = new Blob([getResponse.data], {
      type: 'application/pdf',
    })

    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = `${options.fileName}.pdf`
    a.click()

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getByActivityChecklistItemIdWithItems = async (
  activity_checklist_item_id: string,
): Promise<OrderServiceWithItemsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<OrderServiceWithItemsApiResponse>(
      ENDPOINTS.ORDER_SERVICES.GET_WITH_ITEMS_BY_ACTIVITY_CHECKLIST_ID(
        activity_checklist_item_id,
      ),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getByActivityChecklistItemId = async (
  activity_checklist_item_id: string,
): Promise<OrderServiceApiResponse> => {
  try {
    const getResponse = await apiInstance.post<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.GET_ONLY_ORDER_SERVICE(
        activity_checklist_item_id,
      ),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const downloadPaymentManual = async ({
  ids,
  settlement_date,
}: {
  ids: number[]
  settlement_date: string
}): Promise<OrderServiceApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.DOWNLOAD_PAYMENT_MANUAL,
      {
        ids,
        settlement_date,
      },
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const cancel = async ({
  order_service_id,
}: {
  order_service_id: number
}): Promise<OrderServiceApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.CANCEL(order_service_id),
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  orderService: OrderServicesApiRequest,
): Promise<OrderServiceApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.CREATE,
      orderService,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  orderService,
}: {
  orderService: OrderServicesApiRequest
  id: string
}): Promise<OrderServiceApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<OrderServiceApiResponse>(
      ENDPOINTS.ORDER_SERVICES.UPDATE(id),
      orderService,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.ORDER_SERVICES.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
