import { createContext, useCallback, useContext, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs } from '@mui/material'
import { Button } from '@mui/material'
import { Container } from '@mui/material'
import { Link } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import LoadingFullPage from 'components/LoadingFullPage'
import { ROUTES } from 'constants/routes'
import moment from 'moment'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { roundUpperNumber } from 'utils/mask'

import ChecklistItem from './components/ChecklistItem'
import TaskItem from './components/TaskItem'

export function monetaryMask(value: string | number): string {
  let newValue = value

  if (typeof newValue === 'string') {
    newValue = newValue?.replace('.', '').replace(',', '').replace(/\D/g, '')
  }

  if (Number.isNaN(newValue)) return '0,00'

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    roundUpperNumber(Number(newValue)),
  )

  return result
}

type ActivityTaskFormProps = {
  name: string
  date_realized: string
  description: string
  status: ActivityTaskStatus
}

type OnSave = (activityChecklist: ActivityChecklistsApiRequest) => Promise<void>

type AddProps = {
  activity: ActivityApiResponse
  activityChecklist?: ActivityChecklist
  onSave?: OnSave
  isEditMode?: boolean
}
type ActivityChecklistContextProps = {
  activity: ActivityApiResponse
  activityChecklistItems: ActivityChecklistItemsForm[]
  setActivityChecklistsItems: React.Dispatch<
    React.SetStateAction<ActivityChecklistItemsForm[]>
  >
  activityTaskItems: ActivityTaskItemsForm[]
  setActivityTaskItems: React.Dispatch<
    React.SetStateAction<ActivityTaskItemsForm[]>
  >
}

export const ActivityChecklistContext =
  createContext<ActivityChecklistContextProps>(
    {} as ActivityChecklistContextProps,
  )

export const useActivityChecklistContext = () => {
  return useContext(ActivityChecklistContext)
}

export default function Add({
  activity,
  onSave,
  isEditMode = false,
}: AddProps) {
  const [activityChecklistItems, setActivityChecklistsItems] = useState<
    ActivityChecklistItemsForm[]
  >([])
  const [activityTaskItems, setActivityTaskItems] = useState<
    ActivityTaskItemsForm[]
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const reactHookForm = useForm<ActivityChecklistForm>()

  const onSubmit = useCallback(
    async (data: {
      activityChecklistsItems: ActivityChecklistItemsForm[]
      activityTasksItems: ActivityTaskFormProps[]
    }) => {
      setIsLoading(true)
      const activityChecklist = activityChecklistItems.map((item) => ({
        ...item,
        name: item.itemBudget?.name || item?.name || '',
      }))
      if (isEditMode) {
        await onSave?.({
          checklistItems: activityChecklist.map((item) => {
            const findFormData = data.activityChecklistsItems.find(
              (_: unknown, index: number) => index === item.id,
            )
            return {
              ...item,
              item_budget_id: item.itemBudget?.id,
              activity_id: activity?.id,
              details: {
                quantity: findFormData?.quantity,
                subtotal: item?.subtotal,
                name: item?.itemBudget?.name || item?.name || '',
                unit_value: findFormData?.unit_value,
                date_payment: findFormData?.date_payment,
                schedule: findFormData?.schedule,
                status: findFormData?.status,
              },
            }
          }),
          taskItems: activityTaskItems.map((item) => {
            const findFormData = data.activityTasksItems.find(
              (_: unknown, index: number) => index === item.id,
            )
            return {
              ...item,
              activity_id: activity?.id,
              activity_task_id: item?.id,
              details: {
                name: item.name,
                date_realized: findFormData?.date_realized,
                description: findFormData?.description,
                status: findFormData?.status,
              },
            }
          }),
        })
        return
      }
      setIsLoading(false)
    },
    [
      activity?.id,
      activityChecklistItems,
      activityTaskItems,
      isEditMode,
      onSave,
    ],
  )

  if ((isEditMode && !activity) || isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <ActivityChecklistContext.Provider
        value={{
          activity,
          activityChecklistItems,
          activityTaskItems,
          setActivityChecklistsItems,
          setActivityTaskItems,
        }}
      >
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth={false}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                underline="hover"
                color="inherit"
                href={ROUTES.ACTIVITIES.LIST + `?status_checklist=pending`}
              >
                Checklist
              </Link>

              <Typography color="text.primary">
                {isEditMode ? 'Editar' : 'Adicionar'}
              </Typography>
            </Breadcrumbs>

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />
                <Typography sx={{ m: 1 }} variant="h4">
                  {isEditMode ? 'Editar' : 'Adicionar'} - {activity.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      const start = moment().format('YYYY-MM-DD')
                      const period = `${start}to`
                      navigate(
                        ROUTES.ACTIVITIES.LIST +
                          `?status_checklist=pending&start_travel_date_period=${period}`,
                      )
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>

            <FormProvider {...reactHookForm}>
              <form onSubmit={reactHookForm.handleSubmit(onSubmit)}>
                <ChecklistItem
                  activity={activity}
                  onSave={onSave}
                  isEditMode={isEditMode}
                />
                <TaskItem
                  activity={activity}
                  onSave={onSave}
                  isEditMode={isEditMode}
                />
                <Box>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{
                      marginLeft: 'auto',
                      display: 'block',
                      marginTop: '1em',
                    }}
                  >
                    {isEditMode ? 'Salvar' : 'Adicionar'}
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Container>
        </Box>
      </ActivityChecklistContext.Provider>
    </>
  )
}
