import { useMemo, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditOutlined from '@mui/icons-material/EditOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { Tab, Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import CloseModal from 'components/CloseModal'
import LoadingFullPage from 'components/LoadingFullPage'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import {
  CLIENTS_GENDER,
  FINANCIAL_MOVEMENT_PAYMENT_METHODS,
} from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { RESERVATION_SITUATION_TYPES } from 'pages/reservations/types'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { currencyMask } from 'utils/mask'

import Delete from './delete'
import FormStudent from './formStudent'

enum TABS {
  RESERVATION,
  CHILD,
}

const SHIFT_MAP = {
  morning: 'Manhã',
  afternoon: 'Tarde',
  night: 'Noite',
}

export default function View() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const [currentTab, setCurrentTab] = useState<TABS>(TABS.RESERVATION)
  const { data, isLoading, refetch } = useQuery<Clients>(
    QUERY_KEYS.CLIENTS.VIEW(id as string),
    () => ApiService.Clients.get(id as string),
  )

  const [child, setChild] = useState<Clients>()

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Clients | undefined
  >()

  const [viewModal, setViewModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const { openSuccessToast, openErrorToast } = useGlobalState()
  const queryClient = useQueryClient()

  const updateClient = useMutation({
    mutationFn: async ({
      id,
      client,
    }: {
      id: string
      client: ClientsApiRequest
    }) => {
      await ApiService.Clients.update({
        id,
        client,
      })
    },
    onSuccess: (_data, { id, client }) => {
      queryClient.setQueryData<ClientsApiRequest>(
        QUERY_KEYS.CLIENTS.EDIT(id),
        (oldClient) => {
          if (!oldClient) return
          return client
        },
      )

      openSuccessToast({
        message: `O cliente ${client.fantasy_name} foi atualizado com sucesso!`,
      })

      setEditModal(false)
      refetch()
    },
    onError: (_error, { client }) => {
      openErrorToast({
        message: `Erro ao editar ${client.fantasy_name}!`,
      })
    },
  })

  const reservationsColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'commercial_unit_id',
        headerName: 'Unidade Comercial',
        flex: 1,
        valueGetter: (
          params: GridValueGetterParams<Reservation['commercialUnit']>,
        ) => params.row.commercialUnit?.fantasy_name,
      },
      {
        field: 'institution_id',
        headerName: 'Instituição',
        flex: 1,
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => params.row.institution?.fantasy_name,
      },
      {
        field: 'activity_id',
        headerName: 'Atividade',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Reservation['activity']>) =>
          params.row.activity.name,
      },
      {
        field: 'payment_form',
        headerName: 'Forma de Pagamento',
        flex: 1,
        valueGetter: (
          params: GridValueGetterParams<Reservation['paymentGateway']>,
        ) =>
          FINANCIAL_MOVEMENT_PAYMENT_METHODS[
            params.row.financialMovements?.[0]
              .payment_method as FinancialMovementPaymentForm
          ],
      },
      {
        field: 'payment_value',
        headerName: 'Valor Total',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Reservation>) =>
          currencyMask(params.row.payment_value),
      },
      {
        field: 'situation',
        headerName: 'Situação',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Reservation>) =>
          RESERVATION_SITUATION_TYPES[
            params.row.situation as ReservationSituation
          ],
      },
      { field: 'created_at', headerName: 'Cadastrado em', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Reservation>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.RESERVATIONS.VIEW(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )
  const childColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'fantasy_name',
        headerName: 'Nome',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Clients>) =>
          params.row?.fantasy_name,
      },
      {
        field: 'document',
        headerName: 'Documento',
        flex: 1,
      },
      {
        field: 'gender',
        headerName: 'Sexo',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Clients>) =>
          CLIENTS_GENDER[params.row.gender as keyof typeof CLIENTS_GENDER],
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Clients>) => {
          return (
            <>
              <EditOutlined
                onClick={() => {
                  setChild(params.row)
                  setEditModal(true)
                }}
              />
              <SearchIcon
                onClick={() => {
                  setChild(params.row)
                  setViewModal(true)
                }}
              />
            </>
          )
        },
      },
    ],
    [],
  )

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {data?.fantasy_name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.CLIENTS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(data as Clients)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.CLIENTS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel label="Nome" value={data?.fantasy_name} />
              <TextWithLabel
                label="Sexo"
                value={
                  CLIENTS_GENDER[data?.gender as keyof typeof CLIENTS_GENDER]
                }
              />

              <TextWithLabel label="CPF" value={data?.document} />

              <TextWithLabel label="Email" value={data?.email} />

              <TextWithLabel label="Telefone" value={data?.phone} />

              <TextWithLabel label="Celular" value={data?.cell_phone} />

              <TextWithLabel
                label="Tipo documento"
                value={data?.document_type}
              />
              <TextWithLabel
                label="Número do documento"
                value={data?.number_document}
              />
              {data?.document_type === 'rg' && (
                <TextWithLabel
                  label="UF do documento"
                  value={data?.rg_state_issue}
                />
              )}

              <TextWithLabel
                label="Endereço"
                value={`${data?.street}, ${data?.number}`}
              />

              <TextWithLabel label="Complemento" value={data?.complement} />

              <TextWithLabel label="Bairro" value={data?.district} />

              <TextWithLabel label="Estado" value={data?.uf} />

              <TextWithLabel
                label="Cidade"
                value={`${data?.city}/${data?.uf}`}
              />

              <TextWithLabel label="País" value={data?.country} />

              <TextWithLabel label="CEP" value={data?.zip_code} />
              <TextWithLabel label="Criado em" value={data?.created_at} />
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Reservas" />
                  <Tab label="Filhos" />
                </Tabs>

                <TabPanel value={TABS.RESERVATION} index={currentTab}>
                  <Table
                    rows={data?.reservations}
                    columns={reservationsColumns}
                  />
                </TabPanel>
                <TabPanel value={TABS.CHILD} index={currentTab}>
                  <Table rows={data?.childParents} columns={childColumns} />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        clients={deleteConfirmation as Clients}
      />

      <CloseModal
        title="Editar Filho"
        opened={editModal}
        onClose={() => setEditModal(false)}
      >
        <FormStudent
          clients={child}
          isEditMode
          onSave={async (client: ClientsApiRequest) => {
            if (!child?.id) {
              return
            }
            updateClient.mutate({ id: String(child.id), client })
          }}
        />
      </CloseModal>
      <CloseModal
        title={child?.fantasy_name || 'Filho'}
        opened={viewModal}
        onClose={() => setViewModal(false)}
      >
        <>
          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel label="Nome" value={child?.fantasy_name} />
              <TextWithLabel
                label="Nascimento"
                value={child?.birth_date.substring(0, 10)}
              />

              <TextWithLabel
                label="Sexo"
                value={
                  CLIENTS_GENDER[child?.gender as keyof typeof CLIENTS_GENDER]
                }
              />

              <TextWithLabel label="CPF" value={child?.document} />

              <TextWithLabel
                label="Tipo documento"
                value={child?.document_type}
              />
              <TextWithLabel
                label="Número do documento"
                value={child?.number_document}
              />
              {child?.document_type === 'rg' && (
                <TextWithLabel
                  label="UF do documento"
                  value={child?.rg_state_issue}
                />
              )}
              {child?.shift && (
                <TextWithLabel
                  label="Turno"
                  value={SHIFT_MAP[child.shift as 'morning']}
                />
              )}
              <TextWithLabel label="Turma" value={child?.period} />
            </CardContent>
          </Card>
        </>
      </CloseModal>
    </>
  )
}
