import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<PaymentModule>(
    QUERY_KEYS.PAYMENT_MODULES.EDIT(id as string),
    () => ApiService.PaymentModules.get(id as string),
  )

  const updatePaymentModule = useMutation({
    mutationFn: async ({
      id,
      paymentModule,
    }: {
      id: string
      paymentModule: PaymentModuleApiRequest
    }) => {
      await ApiService.PaymentModules.update({
        id,
        paymentModule,
      })
    },
    onSuccess: (_data, { id, paymentModule }) => {
      queryClient.setQueryData<PaymentModuleApiRequest>(
        QUERY_KEYS.PAYMENT_MODULES.EDIT(id),
        (oldPaymentModule) => {
          if (!oldPaymentModule) return
          return paymentModule
        },
      )

      openSuccessToast({
        message: `A forma de pagamento ${paymentModule.name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.PAYMENT_MODULES.LIST)
    },
    onError: (_error, { paymentModule }) => {
      openErrorToast({
        message: `Erro ao editar ${paymentModule.name}!`,
      })
    },
  })

  return (
    <Add
      paymentModule={data}
      isEditMode
      onSave={async (paymentModule: PaymentModuleApiRequest) =>
        updatePaymentModule.mutate({ id: id as string, paymentModule })
      }
    />
  )
}
