import type { PropsWithChildren } from 'react'

import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

type ConfirmationModalProps = {
  opened: boolean
  onlyConfirmed?: boolean
  loading?: boolean
  title: string
  confirmColor?: ButtonProps['color']
  onClose?: () => void
  onCancel: () => void
  onConfim: () => void
  text?: {
    confirm?: string
    cancel?: string
  }
}

export default function ConfirmationModal({
  opened,
  title,
  onClose,
  onCancel,
  onConfim,
  children,
  onlyConfirmed,
  text,
  confirmColor = 'primary',
  loading,
}: PropsWithChildren<ConfirmationModalProps>) {
  return (
    <Modal
      open={opened}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '8px',
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          flexWrap={'wrap'}
        >
          {title}
        </Typography>

        <Box sx={{ mt: 2 }}>{children}</Box>

        <Box
          sx={{
            mt: 2,
            flex: 1,
            display: 'flex',
            justifyContent: onlyConfirmed ? 'end' : 'space-evenly',
          }}
        >
          {!onlyConfirmed && (
            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={onCancel}
              sx={{ flexGrow: 1, padding: '10px', maxWidth: '120px' }}
            >
              {text?.cancel || 'Cancelar'}
            </Button>
          )}

          <Button
            color={confirmColor}
            variant="contained"
            disabled={loading}
            onClick={onConfim}
            sx={{ flexGrow: 1, padding: '10px', maxWidth: '120px' }}
          >
            {text?.confirm || 'Confirmar'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
