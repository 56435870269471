import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import AutoRenewIcon from '@mui/icons-material/Autorenew'

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

type LoadingProps = {
  height?: string
  width?: string
}

const Loading = styled(AutoRenewIcon)<LoadingProps>`
  height: ${({ height = '50px' }) => height};
  width: ${({ width = '50px' }) => width};
  animation: ${rotate} 2s linear infinite;
`

export default Loading
