import * as yup from 'yup'

function schema(isEditMode: boolean) {
  return yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
    role_id: yup.string().required('Campo obrigatório'),
    password: isEditMode
      ? yup.string().nullable()
      : yup.string().required('Campo obrigatório'),
    username: yup.string().required('Campo obrigatório'),
  })
}

export { schema }
