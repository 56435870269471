import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  email: yup.string().email().required('Campo obrigatório'),
  bank_code: yup.string().required('Campo obrigatório'),
  agency: yup.string().required('Campo obrigatório'),
  account: yup.string().required('Campo obrigatório'),
  account_digit: yup.string().required('Campo obrigatório'),
})

export { schema }
