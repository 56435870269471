import { useState, useMemo, useCallback } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import WhatsApp from '@mui/icons-material/WhatsApp'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import LoadingFullPage from 'components/LoadingFullPage'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'
import {
  COLLABORATOR_HIERARCHICAL_LEVEL,
  COLLABORATORS_TYPE_MAP,
} from './types'

export default function Collaborators() {
  const navigate = useNavigate()

  const { data, isLoading: isLoadingCollaborators } = useQuery<Collaborator[]>(
    QUERY_KEYS.COLLABORATORS.LIST,
    async () => ApiService.Collaborators.getAll(),
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Collaborator | undefined
  >()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const [messageModal, setMessageModal] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [currentName, setCurrentName] = useState()
  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)

  const onConfirmMessage = useCallback(async () => {
    setIsSending(true)
    await ApiService.Collaborators.sendMessage({
      id: Number(currentRow),
      message,
    })
      .then((response) => {
        openSuccessToast({ message: 'A mensagem foi enviada' })
        setIsSending(false)
        setMessageModal(false)
      })
      .catch((error) => {
        const message = error?.message || 'Erro ao enviar mensagem.'
        openErrorToast({ message })
        setIsSending(false)
        setMessageModal(false)
      })
  }, [message, currentRow, openErrorToast, openSuccessToast])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'type',
        headerName: 'Tipo',
        flex: 1,
        valueFormatter: (value) =>
          COLLABORATORS_TYPE_MAP[value.value as CollaboratorApiRequest['type']],
      },
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'email', headerName: 'Email', flex: 1 },
      {
        field: 'hierarchical_Level',
        headerName: 'Cargo',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Collaborator>) => {
          return COLLABORATOR_HIERARCHICAL_LEVEL.find(
            (hierarchicalLevel) =>
              hierarchicalLevel.english?.toLowerCase() ===
              params.row.hierarchical_Level?.toLowerCase(),
          )?.portuguese
        },
      },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
      { field: 'cell_phone', headerName: 'Celular', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Collaborator>) => {
          return (
            <>
              <WhatsApp
                fontSize="small"
                onClick={() => {
                  setCurrentRow(params.row.id)
                  setCurrentName(params.row.name)
                  setMessage('')
                  setMessageModal(true)
                }}
              />
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.COLLABORATORS.VIEW(params.row.id))
                }}
              />

              <EditIcon
                onClick={() => {
                  navigate(ROUTES.COLLABORATORS.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  if (isSending || isLoadingCollaborators) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Colaboradores
              </Typography>

              <Box sx={{ m: 1 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.COLLABORATORS.CREATE)
                  }}
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Table rows={data} columns={columns} />
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        collaborator={deleteConfirmation as Collaborator}
      />
      <ConfirmationModal
        title={`Enviar Mensagem - ${currentName}`}
        opened={messageModal}
        onClose={() => setMessageModal(false)}
        onConfim={onConfirmMessage}
        onCancel={() => setMessageModal(false)}
      >
        <label htmlFor="message-wpp"></label>
        <textarea
          name="message-wpp"
          style={{ width: '100%' }}
          rows={5}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </ConfirmationModal>
    </>
  )
}
