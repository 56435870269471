import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  permission?: Permission
}

export default function Delete({
  opened,
  closeModal,
  permission,
}: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removePermission = useMutation({
    mutationFn: async (permission: Permission) => {
      await ApiService.Permissions.remove(permission.id.toString())

      openSuccessToast({
        message: `A permissão ${permission?.name} foi removido com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS.LIST)
    },
    onSuccess: (_data, permission: Permission) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.PERMISSIONS.LIST,
        (oldPermissions) => {
          if (!oldPermissions?.length) return

          return oldPermissions.filter(
            (oldPermission) => oldPermission.id !== permission.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.PERMISSIONS.EDIT(permission.id.toString()),
      )

      openSuccessToast({
        message: `A permissão ${permission.name} foi removido com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removePermission.mutate(permission as Permission)}
      confirmColor="error"
    >
      Deseja realmente remover a permissão{' '}
      <strong>{`${permission?.name}?`}</strong>
    </ConfirmationModal>
  )
}
