import { useState, useMemo } from 'react'

import {
  DataGrid,
  ptBR,
  DataGridProps,
  GridSelectionModel,
  GridFilterModel,
  GridToolbar,
  GridRowHeightParams,
  GridRowHeightReturnValue,
} from '@mui/x-data-grid'
import type { GridColumns, GridValidRowModel } from '@mui/x-data-grid'

interface TableProps extends Omit<DataGridProps, 'rows'> {
  rows?: unknown[]
  columns: GridColumns<GridValidRowModel>
  enableCheckBox?: boolean
  selectedRowKey?: string
  onSelectRow?: (selectedRows: unknown[]) => void
  selectedItems?: number[]
  filterModel?: GridFilterModel
  toolbar?: boolean
  customToolbar?: React.JSXElementConstructor<unknown>
  getRowHeight?:
    | ((params: GridRowHeightParams) => GridRowHeightReturnValue)
    | undefined
}

export default function Table({
  rows = [],
  columns,
  enableCheckBox = false,
  selectedRowKey = 'id',
  onSelectRow,
  selectedItems,
  filterModel,
  onFilterModelChange,
  toolbar,
  getRowHeight,
  getRowId,
  customToolbar,
  ...rest
}: TableProps) {
  const [pageSize, setPageSize] = useState<number>(30)

  const checkboxProps = useMemo<Partial<DataGridProps>>(() => {
    if (enableCheckBox) {
      return {
        checkboxSelection: true,
        disableSelectionOnClick: true,
        selectionModel: selectedItems,
        onSelectionModelChange: (selectionModel: GridSelectionModel) => {
          const selectedRows = rows.filter((row) =>
            selectionModel.includes(
              (row as Record<string, string>)[selectedRowKey],
            ),
          )

          onSelectRow?.(selectedRows)
        },
      }
    }

    return {}
  }, [enableCheckBox, onSelectRow, rows, selectedItems, selectedRowKey])

  return (
    <DataGrid
      rows={rows as GridValidRowModel[]}
      columns={columns}
      pageSize={pageSize}
      autoHeight
      rowsPerPageOptions={
        [5, 10, 15, 30, 50, 100, { value: -1, label: 'Todos' }] as number[]
      }
      onPageSizeChange={(newPageSize) => {
        setPageSize(newPageSize)
      }}
      onFilterModelChange={onFilterModelChange}
      filterModel={filterModel}
      localeText={{
        ...ptBR.components.MuiDataGrid.defaultProps.localeText,
      }}
      {...checkboxProps}
      components={{
        ...(toolbar
          ? {
              Toolbar: GridToolbar,
            }
          : undefined),
        ...(customToolbar
          ? {
              Toolbar: customToolbar,
            }
          : undefined),
        NoRowsOverlay: () => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '100%',
                marginTop: '2rem',
              }}
            >
              <p>Nenhum registro encontrado</p>
            </div>
          )
        },
        NoResultsOverlay: () => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '100%',
                marginTop: '2rem',
              }}
            >
              <p>Nenhum registro encontrado</p>
            </div>
          )
        },
      }}
      componentsProps={{
        toolbar: {
          csvOptions: {
            utf8WithBom: true,
            delimiter: ';',
          },
        },
      }}
      getRowHeight={getRowHeight}
      getRowId={getRowId}
      {...rest}
    />
  )
}
