import moment, { Moment } from 'moment'

interface formatDateProps {
  date: string | Date | Moment
  inputFormat: string | string[]
  outputFormat: string
}

export const formatDate = ({
  date,
  inputFormat,
  outputFormat,
}: formatDateProps) => moment(date, inputFormat, true).format(outputFormat)
