import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

type OnSave = (paymentGateway: PaymentGatewayApiRequest) => Promise<void>

type AddProps = {
  paymentGateway?: PaymentGateway
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({
  paymentGateway,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<PaymentGatewayApiRequest>()

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [selectedPaymentModule, setSelectedPaymentModule] = useState<
    Credentials | undefined
  >()

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    PaymentGateway | undefined
  >()

  const { data: paymentModules } = useQuery<PaymentModuleOptions[]>(
    QUERY_KEYS.PAYMENT_MODULES.NAMES_AND_IDS,
    () => ApiService.PaymentModules.getAllNamesAndIds(),
  )

  const { data: commercialUnits } = useQuery<SelectProps['options']>(
    QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
    () => ApiService.CommercialUnits.getAllNamesAndIds(),
  )

  const inputProps = useCallback(
    (fieldName: keyof PaymentGatewayApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewPaymentGateway = useMutation({
    mutationFn: async (paymentGateway: PaymentGatewayApiRequest) => {
      await ApiService.PaymentGateways.create(paymentGateway)
    },
    onSuccess: (_data, paymentGateway: PaymentGatewayApiRequest) => {
      openSuccessToast({
        message: `A forma de pagamento ${paymentGateway.account_name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.PAYMENT_GATEWAYS.LIST)
    },
    onError: (error: ApiError, paymentGateway: PaymentGatewayApiRequest) => {
      openErrorToast({
        message:
          error.message || `Erro ao adicionar ${paymentGateway.account_name}!`,
      })
    },
  })

  useEffect(() => {
    if (isEditMode && !!paymentGateway && !formUpdated) {
      reset({
        account_name: paymentGateway.account_name,
        number_interest_free_installments:
          paymentGateway.number_interest_free_installments,
        credentials: paymentGateway.credentials,
        payment_module_id: paymentGateway.payment_module_id,
      })
      setValue(
        'commercial_unit_id',
        paymentGateway.commercial_unit_id.toString(),
      )
      setValue('sandbox', paymentGateway.sandbox ? 1 : 0)
      setValue('is_split', paymentGateway.is_split ? 1 : 0)
      setValue(
        'needs_billing_address',
        paymentGateway.needs_billing_address ? 1 : 0,
      )
      setFormUpdated(true)
    }
  }, [isEditMode, paymentGateway, formUpdated, reset, setValue])

  useLayoutEffect(() => {
    if (isEditMode) {
      setSelectedPaymentModule(paymentGateway?.credentials)
    }
  }, [isEditMode, paymentGateway])

  if (isEditMode && !!paymentGateway && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.PAYMENT_GATEWAYS.LIST}
            >
              Gateways de Pagamento
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} Gateways de Pagamento
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(paymentGateway as PaymentGateway)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.PAYMENT_GATEWAYS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit(
                (paymentGateway: PaymentGatewayApiRequest) => {
                  if (isEditMode) {
                    onSave?.(paymentGateway)
                    return
                  }

                  addNewPaymentGateway.mutate(paymentGateway)
                },
              )}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="commercial_unit_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Unidade comercial"
                              id="commercial_unit_id"
                              error={!!errors.commercial_unit_id?.message}
                              helperText={errors.commercial_unit_id?.message}
                              onChange={onChange}
                              value={value}
                              options={commercialUnits || []}
                              autoFill={isEditMode}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="payment_module_id"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Modulo"
                              id="payment_module_id"
                              onChange={(event) => {
                                const findPaymentModule = paymentModules?.find(
                                  (paymentModule) =>
                                    paymentModule.value ===
                                    Number(event?.target.value),
                                )
                                let credential = {}

                                findPaymentModule?.fields?.forEach(
                                  (field: PaymentModuleOptionField) => {
                                    credential = {
                                      ...credential,
                                      [field.label]: '',
                                    }
                                  },
                                )

                                setSelectedPaymentModule(credential)
                                onChange(event)
                              }}
                              value={value}
                              options={paymentModules || []}
                              autoFill={isEditMode}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.account_name?.message}
                        fullWidth
                        helperText={errors.account_name?.message}
                        label="Nome da conta"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('account_name')}
                        {...register('account_name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="sandbox"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Sandbox"
                              id="sandbox"
                              {...inputProps}
                              autoFill={isEditMode}
                              value={value?.toString()}
                              onChange={onChange}
                              options={[
                                {
                                  label: 'Ativo',
                                  value: '1',
                                },
                                {
                                  label: 'Inativo',
                                  value: '0',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="is_split"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="É split"
                              id="is_split"
                              {...inputProps}
                              autoFill={isEditMode}
                              value={value?.toString()}
                              onChange={onChange}
                              options={[
                                {
                                  label: 'Sim',
                                  value: 1,
                                },
                                {
                                  label: 'Não',
                                  value: 0,
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="needs_billing_address"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Precisa de endereço"
                              id="needs_billing_address"
                              {...inputProps}
                              autoFill={isEditMode}
                              value={value?.toString()}
                              onChange={onChange}
                              options={[
                                {
                                  label: 'Sim',
                                  value: 1,
                                },
                                {
                                  label: 'Não',
                                  value: 0,
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>
                    {selectedPaymentModule &&
                      Object.keys(selectedPaymentModule).map((key: string) => {
                        const name = key as keyof Credentials
                        const fieldName =
                          `credentials.${name}` as keyof PaymentGatewayApiRequest
                        return (
                          <Grid item xs={3} key={name}>
                            <TextField
                              fullWidth
                              error={!!errors?.[fieldName]?.message}
                              helperText={!!errors?.[fieldName]?.message}
                              defaultValue={selectedPaymentModule[key]}
                              label={key}
                              margin="normal"
                              type="text"
                              variant="outlined"
                              {...inputProps(fieldName)}
                              {...register(fieldName, {
                                required: true,
                              })}
                            />
                          </Grid>
                        )
                      })}
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        paymentGateway={deleteConfirmation as PaymentGateway}
      />
    </>
  )
}
