export function generateHexColorsArray(): string[] {
  const colors: string[] = Array.from({ length: 100 }, () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    return '#' + randomColor.padStart(6, '0')
  })

  return colors
}

export const CHART_COLORS: string[] = generateHexColorsArray()
