import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<GlobalParametersApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<GlobalParametersApiResponse[]>(
      ENDPOINTS.GLOBAL_PARAMETERS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<GlobalParametersApiResponse> => {
  try {
    const getResponse = await apiInstance.get<GlobalParametersApiResponse>(
      ENDPOINTS.GLOBAL_PARAMETERS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  globalParameter: GlobalParametersApiRequest,
): Promise<GlobalParametersApiResponse> => {
  try {
    const creationResponse =
      await apiInstance.post<GlobalParametersApiResponse>(
        ENDPOINTS.GLOBAL_PARAMETERS.CREATE,
        globalParameter,
      )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  globalParameter,
}: {
  globalParameter: GlobalParametersApiRequest
  id: string
}): Promise<GlobalParametersApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<GlobalParametersApiResponse>(
      ENDPOINTS.GLOBAL_PARAMETERS.UPDATE(id),
      globalParameter,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.GLOBAL_PARAMETERS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
