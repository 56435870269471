export const ENDPOINTS = {
  LOGIN: '/sessions',
  CHECK_PERMISSION: '/checkPermission',

  FAKES_ROUTES: {
    RELEASED_PAYMENT: '/frontRouteReleasedPayment',
  },

  AUDITLOGS: {
    GET_ALL: '/auditlogs',
    GET: (id: string) => `/institutions/view/${id}`,
    CREATE: '/auditlogs/create',
    UPDATE: (id: string) => `/institutions/update/${id}`,
    DELETE: (id: string) => `/institutions/delete/${id}`,
  },
  ORDER_SERVICES: {
    GET_ALL: '/commercial/orderServices',
    GET: (id: string) => `/commercial/orderServices/view/${id}`,
    GET_BY_ITEM_BUDGET: '/commercial/orderServices/getByItemBudget',
    GET_BY_ITEM_BUDGET_WITH_STATUS_ISSUED_OR_SEND:
      '/commercial/orderServices/getByItemBudgetWithStatusIssuedOrSend',
    SEND_ORDER_SERVICE_TO_SUPPLIER: (id: string) =>
      `/commercial/orderServices/mailWithReservations/${id}`,
    GET_PDF: (id: string) => `/commercial/orderServices/getPdf/${id}`,
    GET_WITH_ITEMS_BY_ACTIVITY_CHECKLIST_ID: (
      activity_checklist_item_id: string,
    ) =>
      `/commercial/orderServices/view/orderServiceItem/${activity_checklist_item_id}`,
    GET_WITH_ITEMS_BY_ID: (id: string) =>
      `/commercial/orderServices/view/withItemsById/${id}`,
    GET_ONLY_ORDER_SERVICE: (activity_checklist_item_id: string) =>
      `/commercial/orderServices/view/orderServiceItem/${activity_checklist_item_id}`,
    CREATE: '/commercial/orderServices/create',
    DOWNLOAD_PAYMENT_MANUAL: `/commercial/orderServices/downloadPaymentManual`,
    CANCEL: (id: string | number) => `/commercial/orderServices/cancel/${id}`,
    UPDATE: (id: string) => `/commercial/orderServices/update/${id}`,
    DELETE: (id: string) => `/commercial/orderServices/delete/${id}`,
  },

  LABELS: {
    GET_ALL: '/labels',
    GET_ALL_COLUMNS_ENTITIES: '/entityColumns',
    GET: (id: string) => `/labels/view/${id}`,
    CREATE: '/labels/create',
    UPDATE: (id: string) => `/labels/update/${id}`,
    DELETE: (id: string) => `/labels/delete/${id}`,
  },

  INSTITUTIONS: {
    GET_ALL: '/commercial/institutions',
    GET: (id: string) => `/commercial/institutions/view/${id}`,
    CREATE: '/commercial/institutions/create',
    UPDATE: (id: string) => `/commercial/institutions/update/${id}`,
    DELETE: (id: string) => `/commercial/institutions/delete/${id}`,
  },

  DATATABLE: {
    GET_ALL: '/dataTable',
    GET: (id: string) => `/commercial/institutions/view/${id}`,
    CREATE: '/commercial/institutions/create',
    UPDATE: (id: string) => `/commercial/institutions/update/${id}`,
    DELETE: (id: string) => `/commercial/institutions/delete/${id}`,
  },

  VIDEOS: {
    GET_ALL: '/commercial/videos',
    GET: (id: string) => `/commercial/videos/view/${id}`,
    CREATE: '/commercial/videos/create',
    UPDATE: (id: string) => `/commercial/videos/update/${id}`,
    DELETE: (id: string) => `/commercial/videos/delete/${id}`,
  },

  RESERVATIONS: {
    GET_ALL: '/commercial/reservations',
    GET_ALL_BY_ACTIVITY: (id: string | number) =>
      `/commercial/reservations/getByActivity/${id}`,
    RECONCILIATION_PAGSEGURO: '/commercial/reservations',
    GET_ALL_WITH_PARAMS: '/commercial/reservations/listByParams',
    GET: (id: string) => `/commercial/reservations/view/${id}`,
    CREATE: '/commercial/reservations/create',
    DOWNLOAD_PAYMENT_MANUAL: (id: string) =>
      `/commercial/reservations/downloadPaymentManual/${id}`,
    CREATE_PER_IMPORT: '/commercial/reservations/import',
    UPDATE: (id: string) => `/commercial/reservations/update/${id}`,
    UPDATE_PAYMENT_STATUS: `/commercial/reservations/updatePaymentStatus`,
    UPDATE_PAYMENT_VALUE: `/commercial/reservations/updatePaymentValue`,
    UPDATE_PERIOD_BY_ACTIVITY: (id: string | number) =>
      `/commercial/reservations/updatePeriod/${id}`,
    DELETE: (id: string) => `/commercial/reservations/delete/${id}`,
    ADD_OBSERVATION: `/commercial/reservationsObservations/create`,
    DELETE_OBSERVATION: (id: string) =>
      `/commercial/reservationsObservations/delete/${id}`,
    UPDATE_OBSERVATION: (id: string) =>
      `/commercial/reservationsObservations/update/${id}`,
    GET_CERT_PDF: (id: string) => `/commercial/reservations/getCertPdf/${id}`,
    SEND_MESSAGE: '/commercial/reservations/sendMessage',
  },

  AFFILIATES: {
    GET_ALL: '/commercial/affiliates',
    GET_NAME_AND_IDS_OPTIONS: '/commercial/affiliates/getNameAndIdsOptions',
    GET: (id: string) => `/commercial/affiliates/view/${id}`,
    CREATE: '/commercial/affiliates/create',
    UPDATE: (id: string) => `/commercial/affiliates/update/${id}`,
    DELETE: (id: string) => `/commercial/affiliates/delete/${id}`,
    SEND_MESSAGE: '/commercial/affiliates/sendMessage',
    EXPORT_AFFILIATE_RESERVATIONS:
      '/commercial/affiliates/exportAffiliateReservations',
  },

  RECIPIENTS: {
    CREATE: '/financial/pagarme/recipients/create',
    BALANCE: '/financial/pagarme/recipients/balance',
    CREATE_WITHDRAWALS: '/financial/pagarme/recipients/createWithdrawals',
  },

  COMMERCIAL_UNITS: {
    GET_ALL: '/commercial/commercialUnits',
    GET: (id: string) => `/commercial/commercialUnits/view/${id}`,
    CREATE: '/commercial/commercialUnits/create',
    UPDATE: (id: string) => `/commercial/commercialUnits/update/${id}`,
    DELETE: (id: string) => `/commercial/commercialUnits/delete/${id}`,
  },

  TEACHERS: {
    GET_ALL: '/commercial/teachers',
    GET: (id: string) => `/commercial/teachers/view/${id}`,
    CREATE: '/commercial/teachers/create',
    SEND_PASSWORD: '/commercial/teachers/sendPassword',
    SEND_MESSAGE: '/commercial/teachers/sendMessage',
    UPDATE: (id: string) => `/commercial/teachers/update/${id}`,
    DELETE: (id: string) => `/commercial/teachers/delete/${id}`,
  },

  TEACHERS_INSTITUTIONS: {
    GET_ALL: '/commercial/teachers',
    GET_BY_INSTITUTION: (institution_id: number) =>
      `/commercial/teacherInstitutions/view/institution/${institution_id}`,
    CREATE: '/commercial/teachers/create',
    SEND_PASSWORD: '/commercial/teachers/sendPassword',
    UPDATE: (id: string) => `/commercial/teachers/update/${id}`,
    DELETE: `/commercial/teacherInstitutions/delete`,
  },

  SUPPLIERS: {
    GET_ALL: '/commercial/suppliers',
    GET: (id: string) => `/commercial/suppliers/view/${id}`,
    GET_VIEW_PAGE: (id: string) =>
      `/commercial/suppliers/viewWithRelations/${id}`,
    GET_OPTIONS_BY_CATEGORY: (category_id: string) =>
      `/commercial/suppliers/getByCategory/${category_id}`,
    GET_CATEGORY_BY_SUPPLIER: (supplier_id: string) =>
      `/commercial/suppliers/getCategoryBySupplier/${supplier_id}`,
    CREATE: '/commercial/suppliers/create',
    UPDATE: (id: string) => `/commercial/suppliers/update/${id}`,
    DELETE: (id: string) => `/commercial/suppliers/delete/${id}`,
  },

  COLLABORATORS: {
    GET_ALL: '/commercial/teams',
    NAME_AND_IDS_ONLY_EMPLOYEE_OPTIONS:
      '/commercial/teams/getAllNamesAndIdsOnlyEmployee',
    NAME_AND_IDS_OPTIONS: '/commercial/teams/getAllNamesAndIdsOptions',
    GET: (id: string) => `/commercial/teams/view/${id}`,
    CREATE: '/commercial/teams/create',
    UPDATE: (id: string) => `/commercial/teams/update/${id}`,
    DELETE: (id: string) => `/commercial/teams/delete/${id}`,
    SEND_MESSAGE: '/commercial/teams/sendMessage',
  },

  CATEGORIES: {
    GET_ALL: '/commercial/categories',
    GET_ALL_WITH_SUB_CATEGORIES: '/commercial/categories/list',
    GET: (id: string) => `/commercial/categories/view/${id}`,
    CREATE: '/commercial/categories/create',
    UPDATE: (id: string) => `/commercial/categories/update/${id}`,
    DELETE: (id: string) => `/commercial/categories/delete/${id}`,
  },

  ROLES: {
    GET_ALL: '/users/roles',
    GET: (id: string) => `/users/roles/view/${id}`,
    CREATE: '/users/roles/create',
    UPDATE: (id: string) => `/users/roles/update/${id}`,
    DELETE: (id: string) => `/users/roles/delete/${id}`,
    PERMISSIONS: {
      LIST_GROUPED: (roleId: string, userId: string) =>
        `users/permissions/listAggrouped/${roleId}/${userId}`,
      DELETE: (permissionId: string, userId: string) =>
        `users/permissionRole/delete/${userId}/${permissionId}`,
      CREATE: 'users/permissionRole/create',
    },
  },

  PERMISSIONS: {
    GET_ALL: '/users/permissions',
    GET: (id: string) => `/users/permissions/view/${id}`,
    CREATE: '/users/permissions/create',
    UPDATE: (id: string) => `/users/permissions/update/${id}`,
    DELETE: (id: string) => `/users/permissions/delete/${id}`,
  },

  PERMISSIONS_ASSOCIATED: {
    GET_ALL: '/users/permissionsAssociated',
    GET: (id: string) => `/users/permissionsAssociated/view/${id}`,
    CREATE: '/users/permissionsAssociated/create',
    UPDATE: (id: string) => `/users/permissionsAssociated/update/${id}`,
    DELETE: (id: string) => `/users/permissionsAssociated/delete/${id}`,
  },

  USERS: {
    GET_ALL: '/users',
    GET: (id: string) => `/users/view/${id}`,
    CREATE: '/users/create',
    UPDATE: (id: string) => `/users/update/${id}`,
    DELETE: (id: string) => `/users/delete/${id}`,
    PERMISSIONS: {
      LIST_GROUPED: (roleId: string, userId: string) =>
        `users/permissions/listAggrouped/${roleId}/${userId}`,
      DELETE: (permissionId: string, userId: string) =>
        `users/permissionUser/delete/${userId}/${permissionId}`,
      CREATE: 'users/permissionUser/create',
    },
    CHANGE_PASSWORD: `/users/changePassword`,
  },

  INDICATORS: {
    GET: `/commercial/indicators/`,
  },

  MENUS: {
    GET_ALL: '/menus',
    GET: (id: string) => `/menus/view/${id}`,
    CREATE: '/menus/create',
    UPDATE: (id: string) => `/menus/update/${id}`,
    DELETE: (id: string) => `/menus/delete/${id}`,
  },

  IPS_ALLOWED: {
    GET_ALL: '/ipAllowed',
    GET: (id: string) => `/ipAllowed/view/${id}`,
    CREATE: '/ipAllowed/create',
    UPDATE: (id: string) => `/ipAllowed/update/${id}`,
    DELETE: (id: string) => `/ipAllowed/delete/${id}`,
  },

  GLOBAL_PARAMETERS: {
    GET_ALL: '/commercial/globalParameters',
    GET: (id: string) => `/commercial/globalParameters/view/${id}`,
    CREATE: '/commercial/globalParameters/create',
    UPDATE: (id: string) => `/commercial/globalParameters/update/${id}`,
    DELETE: (id: string) => `/commercial/globalParameters/delete/${id}`,
  },
  PAYMENT_GATEWAYS: {
    GET_ALL: '/financial/paymentGateways',
    GET: (id: string) => `/financial/paymentGateways/view/${id}`,
    CREATE: '/financial/paymentGateways/create',
    UPDATE: (id: string) => `/financial/paymentGateways/update/${id}`,
    DELETE: (id: string) => `/financial/paymentGateways/delete/${id}`,
  },
  PAYMENT_MODULES: {
    GET_ALL: '/financial/paymentModules',
    GET: (id: string) => `/financial/paymentModules/view/${id}`,
    CREATE: '/financial/paymentModules/create',
    UPDATE: (id: string) => `/financial/paymentModules/update/${id}`,
    DELETE: (id: string) => `/financial/paymentModules/delete/${id}`,
  },
  ITEMS_BUDGET: {
    GET_ALL: '/commercial/itemsBudget',
    LIST_PAGE: '/commercial/itemsBudget/listPage',
    GET: (id: string) => `/commercial/itemsBudget/view/${id}`,
    CREATE: '/commercial/itemsBudget/create',
    UPDATE: (id: string) => `/commercial/itemsBudget/update/${id}`,
    DELETE: (id: string) => `/commercial/itemsBudget/delete/${id}`,
  },

  CLIENTS: {
    GET_ALL: '/commercial/clients',
    GET: (id: string) => `/commercial/clients/view/${id}`,
    CREATE: '/commercial/clients/create',
    UPDATE: (id: string) => `/commercial/clients/update/${id}`,
    DELETE: (id: string) => `/commercial/clients/delete/${id}`,
  },

  CAMPUS: {
    GET_ALL: '/commercial/campusInstitution',
    NAME_AND_IDS_BY_INSTITUTION: (institution_id: string | number) =>
      `/commercial/campusInstitution/getNameAndIdsByInstitutionId/${institution_id}`,
    GET: (id: string) => `/commercial/campusInstitution/view/${id}`,
    CREATE: '/commercial/campusInstitution/create',
    UPDATE: (id: string) => `/commercial/campusInstitution/update/${id}`,
    DELETE: (id: string) => `/commercial/campusInstitution/delete/${id}`,
  },

  CLASSES_INSTITUTIONS: {
    GET_ALL: '/commercial/classesInstitutions',
    GET: (id: string) => `/commercial/classesInstitutions/view/${id}`,
    GET_BY_INSTITUTION: (id: string | number) =>
      `/commercial/classesInstitutions/getByInstitution/${id}`,
    CREATE: '/commercial/classesInstitutions/create',
    UPDATE: (id: string) => `/commercial/classesInstitutions/update/${id}`,
    DELETE: (id: string) => `/commercial/classesInstitutions/delete/${id}`,
  },

  CLASSES_ACTIVITIES: {
    GET_ALL: '/commercial/classesActivities',
    GET: (id: string) => `/commercial/classesActivities/view/${id}`,
    GET_BY_ACTIVITY: `/commercial/classesActivities/getByActivity`,
    CREATE: '/commercial/classesActivities/create',
    UPDATE: (id: string) => `/commercial/classesActivities/update/${id}`,
    DELETE: (id: string) => `/commercial/classesActivities/delete/${id}`,
  },
  ACTIVITY_CHECKLIST: {
    GET_ALL: '/commercial/activityChecklists',
    GET: (id: string) => `/commercial/activityChecklists/view/${id}`,
    CREATE: '/commercial/activityChecklists/create',
    UPDATE: (id: string) => `/commercial/activityChecklists/update/${id}`,
    DELETE: (id: string) => `/commercial/activityChecklists/delete/${id}`,
  },
  ACTIVITY_TASK: {
    GET_ALL: '/commercial/activityTasks',
    GET: (id: string) => `/commercial/activityTasks/view/${id}`,
    CREATE: '/commercial/activityTasks/create',
    UPDATE: (id: string) => `/commercial/activityTasks/update/${id}`,
    DELETE: (id: string) => `/commercial/activityTasks/delete/${id}`,
  },
  BUDGETS: {
    GET_ALL: '/commercial/budgets',
    GET_NAME_AND_IDS_CURRENT_YEAR:
      '/commercial/budgets/getNameAndIdCurrentYear',
    GET: (id: string) => `/commercial/budgets/view/${id}`,
    DUPLICATE: (id: string) => `/commercial/budgets/duplicate/${id}`,
    CREATE: '/commercial/budgets/create',
    UPDATE: (id: string) => `/commercial/budgets/update/${id}`,
    DELETE: (id: string) => `/commercial/budgets/delete/${id}`,
  },
  BUDGETS_ITEMS: {
    GET_ITEM_BY_BUDGET_ID: `/commercial/budgetItems/getItemByBudgetId`,
  },
  PAGES: {
    GET_ALL: '/commercial/pages',
    GET: (id: string) => `/commercial/pages/view/${id}`,
    CREATE: '/commercial/pages/create',
    UPDATE: (id: string) => `/commercial/pages/update/${id}`,
    UPDATE_BY_LINK: (link: string) => `/commercial/pages/update/${link}`,
    DELETE: (id: string) => `/commercial/pages/delete/${id}`,
    DELETE_BY_LINK: (link: string) => `/commercial/pages/delete/${link}`,
  },
  ACTIVITIES: {
    GET_ALL: '/commercial/activities',
    GET_ALL_CALENDAR: '/commercial/activities/calendar',
    GET: (id: string) => `/commercial/activities/view/${id}`,
    GET_OPTIONS: `/commercial/activities/options`,
    GET_CUSTOM_FIELDS: (id: string) =>
      `/commercial/activities/view/fields/${id}`,
    CREATE: '/commercial/activities/create',
    UPDATE: (id: string) => `/commercial/activities/update/${id}`,
    DELETE: (id: string) => `/commercial/activities/delete/${id}`,
    SEND_GUIDE_MAIL: (id: string) =>
      `/commercial/activities/sendGuideMail/${id}`,
    SEND_MESSAGE: '/commercial/activities/sendMessage',
    SEND_CERT_MAIL: (id: string) => `/commercial/activities/sendCertMail/${id}`,
  },
  RESPONSIBLE_GUIDES: {
    GET_ALL: 'commercial/responsibleGuidesActivities',
    GET: (id: string) => `commercial/responsibleGuidesActivities/view/${id}`,
    CREATE: 'commercial/responsibleGuidesActivities/create',
    UPDATE: (id: string) =>
      `commercial/responsibleGuidesActivities/update/${id}`,
    DELETE: (id: string | number) =>
      `commercial/responsibleGuidesActivities/delete/${id}`,
  },
  PAGSEGURO_RESERVATION: {
    GET_SESSION: '/financial/pagseguro/reservation/session',
    CREATE_BANK_SLIP: '/financial/pagseguro/reservation/createBankSlip',
    CREATE_CREDIT_CARD: '/financial/pagseguro/reservation/createCreditCard',
    CREATE_CREDIT_PIX: '/financial/pagseguro/reservation/createPix',
    CANCEL_TRANSACTION: '/financial/pagseguro/reservation/cancelTransaction',
    CANCEL_ACTIVITY: '/financial/pagseguro/reservation/cancelActivity',
    RECONCILIATION: '/financial/pagseguroApi/reconciliation',
  },
  REPORTS: {
    ACTIVITIES_CONTROL: '/commercial/reports/activitiesControl',
    ACTIVITIES_CHECKLIST: '/commercial/reports/activityChecklist',
    ACTIVITIES_CONTROL_BY_PARAMS:
      '/commercial/reports/activitiesControlByParams',
    SCHOOL_TRIP: '/commercial/reports/schoolTrip',
    FACULTY_TRIP: '/commercial/reports/facultyTrip',
    SALES: '/commercial/reports/sales',
    SALES_AFFILIATES: '/commercial/reports/salesAffiliates',
    RESERVATION_AWAITING_PAYMENT:
      '/commercial/reports/reservationAwaitingPayment',
    RESERVATION_PAID: '/commercial/reports/reservationPaid',
    BUDGET_AWAITING_APPROVAL: '/commercial/reports/budgetAwaitingApproval',
    ACTIVITY_CONFIRMED: '/commercial/reports/activityConfirmed',
    ACTIVITY_OPENED: '/commercial/reports/activityOpened',
    ACTIVITY_CANCELLED: '/commercial/reports/activityCancelled',
  },
}
export const REPORTS_ROUTES_ARRAY = Object.entries(ENDPOINTS.REPORTS).map(
  ([_, value]) => value,
)
