import {
  Dialog,
  DialogTitle,
  Box,
  Container,
  Grid,
  Button,
} from '@mui/material'
import Select from 'components/Select'
import { CONTACTS_SUPPLIER_TYPES_OPTIONS } from 'constants/contact-supplier'
import { SendOrderServiceToSupplier } from 'pages/activity-checklist/components/ChecklistItem'
import { Controller, UseFormReturn } from 'react-hook-form'

type DialogContactSupplierProps = {
  openModalSelectContact: {
    isActive: boolean
    id?: number
  }
  reactHookFormSendOrderServiceToSupplier: UseFormReturn<SendOrderServiceToSupplier>
  onClose: () => void
  onSubmit: (data: SendOrderServiceToSupplier) => Promise<void>
}

export const DialogContactSupplier = ({
  openModalSelectContact,
  reactHookFormSendOrderServiceToSupplier,
  onClose,
  onSubmit,
}: DialogContactSupplierProps) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openModalSelectContact.isActive}
      onClose={onClose}
    >
      <DialogTitle>Como deseja enviar?</DialogTitle>
      <Box sx={{ py: 1, px: 1 }}>
        <Container maxWidth={false}>
          <Box>
            <form>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    name="sendTo"
                    control={reactHookFormSendOrderServiceToSupplier.control}
                    shouldUnregister
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          label="Contato"
                          id="sendTo"
                          onChange={onChange}
                          value={value}
                          options={CONTACTS_SUPPLIER_TYPES_OPTIONS}
                          error={
                            !!reactHookFormSendOrderServiceToSupplier.formState
                              .errors.sendTo?.message
                          }
                          helperText={
                            reactHookFormSendOrderServiceToSupplier.formState
                              .errors.sendTo?.message
                          }
                        />
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Box>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  style={{
                    marginLeft: 'auto',
                    display: 'block',
                    marginTop: '1em',
                  }}
                  onClick={reactHookFormSendOrderServiceToSupplier?.handleSubmit(
                    async (data) => {
                      await onSubmit(data)
                      onClose()
                    },
                  )}
                >
                  Enviar
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </Dialog>
  )
}
