import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const PermissionsRoutes: RouteObject[] = [
  {
    path: ROUTES.PERMISSIONS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Permissions />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PERMISSIONS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddPermissions />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PERMISSIONS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditPermissions />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PERMISSIONS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewPermissions />
      </PrivateRoute>
    ),
  },
]

export default PermissionsRoutes
