import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function TextWithLabel({
  label,
  value = '',
  isHtmlContent = false,
}: {
  label: string
  value?: string | number
  isHtmlContent?: boolean
}) {
  return (
    <Box
      sx={{
        height: 'auto',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      <Typography variant="body2" component="label">
        {label}
      </Typography>

      {isHtmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: (value || '').toString() }} />
      ) : (
        <Typography
          variant="body2"
          component="span"
          sx={{ fontWeight: 'bold' }}
        >
          {value}
        </Typography>
      )}
    </Box>
  )
}
