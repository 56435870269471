export const COLLABORATORS_SECTOR: {
  english: CollaboratorSector
  portuguese: string
}[] = [
  { english: 'administration', portuguese: 'Administrador' },
  { english: 'commercial', portuguese: 'Comercial' },
  { english: 'production', portuguese: 'Produção' },
]

export const COLLABORATOR_HIERARCHICAL_LEVEL: {
  english: CollaboratorHierarchyLevel
  portuguese: string
}[] = [
  { english: 'analyst', portuguese: 'Analista' },
  { english: 'assistant', portuguese: 'Assistente' },
  { english: 'helper', portuguese: 'Auxiliar' },
  { english: 'consultant', portuguese: 'Consultor' },
  { english: 'intern', portuguese: 'Estagiário' },
  { english: 'coordinator', portuguese: 'Coordenador' },
  { english: 'director', portuguese: 'Diretor' },
  { english: 'foreman', portuguese: 'Encarregado' },
  { english: 'manager', portuguese: 'Gerente' },
  { english: 'supervisor', portuguese: 'Supervisor' },
]

export const COLLABORATORS_TYPE: {
  label: string
  value: string
}[] = [
  { label: 'Funcionário', value: 'employee' },
  { label: 'Monitor', value: 'monitor' },
  { label: 'Guia', value: 'guide' },
]

export const COLLABORATORS_TYPE_MAP = {
  employee: 'Funcionário',
  monitor: 'Monitor',
  guide: 'Guia',
}
