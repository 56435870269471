import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  collaborator?: CollaboratorApiResponse
}

export default function Delete({
  opened,
  closeModal,
  collaborator,
}: DeleteProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { openErrorToast, openSuccessToast } = useGlobalState()

  const removeCollaborator = useMutation({
    mutationFn: async (collaborator: CollaboratorApiResponse) => {
      await ApiService.Collaborators.remove(collaborator.id.toString())
    },
    onSuccess: (_data, collaborator: CollaboratorApiResponse) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.COLLABORATORS.LIST,
        (oldCollaborators) => {
          if (!oldCollaborators?.length) return

          return oldCollaborators.filter(
            (oldCollaborator) => oldCollaborator.id !== collaborator.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COLLABORATORS.EDIT(collaborator.id.toString()),
      )

      openSuccessToast({
        message: `O colaborador(a) ${collaborator?.name} foi removido(a) com sucesso!`,
      })

      navigate(ROUTES.COLLABORATORS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeCollaborator.mutate(collaborator as Collaborator)}
      confirmColor="error"
    >
      Deseja realmente remover o colaborador(a){' '}
      <strong>{`${collaborator?.name}?`}</strong>
    </ConfirmationModal>
  )
}
