import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<ClassesActivitiesApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<
      ClassesActivitiesApiResponse[]
    >(ENDPOINTS.CLASSES_ACTIVITIES.GET_ALL)

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((classesActivities) => ({
      label: classesActivities.name,
      value: classesActivities.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllByActivity = async (
  activityId: number,
): Promise<ClassesActivities[]> => {
  try {
    const getAllResponse = await getAll()

    return getAllResponse.filter(
      (classesActivities) => classesActivities.activity_id === +activityId,
    )
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (
  id: string,
): Promise<ClassesActivitiesApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ClassesActivitiesApiResponse>(
      ENDPOINTS.CLASSES_ACTIVITIES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getByActivity = async (
  activity_id: string | number,
): Promise<ClassesActivitiesApiResponse[]> => {
  try {
    const getResponse = await apiInstance.post<ClassesActivitiesApiResponse[]>(
      ENDPOINTS.CLASSES_ACTIVITIES.GET_BY_ACTIVITY,
      {
        activity_id,
      },
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  classeActivity: ClassesActivitiesApiRequest,
): Promise<ClassesActivitiesApiResponse> => {
  try {
    const creationResponse =
      await apiInstance.post<ClassesActivitiesApiResponse>(
        ENDPOINTS.CLASSES_ACTIVITIES.CREATE,
        classeActivity,
      )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  classesActivities,
}: {
  classesActivities: ClassesActivitiesApiRequest
  id: string
}): Promise<ClassesActivitiesApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<ClassesActivitiesApiResponse>(
      ENDPOINTS.CLASSES_ACTIVITIES.UPDATE(id),
      classesActivities,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string | number) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.CLASSES_ACTIVITIES.DELETE(id.toString()),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
