import axios, { AxiosError } from 'axios'
import { ENDPOINTS, REPORTS_ROUTES_ARRAY } from 'constants/endpoints'
import { PATHS_ROUTE } from 'constants/keys'
import { ROUTES } from 'constants/routes'

import { CacheService } from '../cache'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

axiosInstance.interceptors.request.use((config) => {
  if (config.url && !config.url.includes('checkPermission')) {
    const url = window.location.pathname
    const path = PATHS_ROUTE.find((pathItem) =>
      url.split('/').find((item) => pathItem.name === item),
    )
    config.headers.frontorigin = JSON.stringify({
      url,
      method: path?.method || 'GET',
    })
  }
  return config
})

axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
  const errorResponse = error.response?.data as ApiError
  if (
    error.response?.status === 403 &&
    errorResponse.message === 'Not authorized!'
  ) {
    if (
      window.location.pathname.includes(ROUTES.ROOT) &&
      [...REPORTS_ROUTES_ARRAY, ENDPOINTS.INDICATORS.GET].includes(
        error?.config?.url || '',
      )
    ) {
      return Promise.reject(error)
    }
  }
  if (
    error.response?.status === 401 &&
    errorResponse.message === 'JWT token missing.'
  ) {
    CacheService.user.clear()
    window.location.href = ROUTES.LOGIN
  }

  return Promise.reject(error)
})

export default axiosInstance
