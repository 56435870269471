import { useEffect, useMemo, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models'
import { useQuery } from '@tanstack/react-query'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { useActivityContext } from 'pages/activities/add'
import { useFormContext } from 'react-hook-form'
import { ApiService } from 'services/api'

export const AffiliatesForm = () => {
  const { isEditMode, activity } = useActivityContext()
  const { setValue, getValues } = useFormContext<ActivityApiRequest>()
  const [isLoaded, setIsLoaded] = useState(false)

  const [affiliatePercent, setAffiliatePercent] = useState('0')

  const [selectedAffiliate, setSelectedAffiliate] =
    useState<AffiliateApiResponse>()
  const [AffiliateList, setAffiliateList] = useState<
    AffiliatesActivityRequest[]
  >([])

  const {
    data: affiliates,
    isLoading: isLoadingAffiliates,
    isFetched,
  } = useQuery<AffiliateApiResponse[]>(
    [QUERY_KEYS.AFFILIATES.NAME_AND_IDS_OPTIONS],
    () => ApiService.Affiliates.getAllNamesAndIdsOptions(),
  )

  const AffiliateColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'percent', headerName: 'Percentual de ganho', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<AffiliateApiResponse>) => {
          return (
            <DeleteIcon
              onClick={() => {
                const updatedAffiliateList = AffiliateList.filter(
                  (affiliate) => affiliate.id !== params.row.id,
                )
                setAffiliateList(updatedAffiliateList)
              }}
            />
          )
        },
      },
    ],
    [AffiliateList],
  )

  useEffect(() => {
    if (isEditMode) {
      const affiliatesActivities = activity?.affiliatesActivities?.map(
        (item) => ({
          ...item,
          name: item.affiliate.name,
          id: item.affiliate.id,
        }),
      )

      setAffiliateList(
        affiliatesActivities as unknown as AffiliatesActivityRequest[],
      )
    }
  }, [isEditMode, activity])

  useEffect(() => {
    setValue(
      'affiliates',
      AffiliateList.map((affiliate) => ({
        id: affiliate.id,
        percent: affiliate.percent,
      })),
    )
  }, [AffiliateList, setValue])

  useEffect(() => {
    if (
      isEditMode &&
      isFetched &&
      affiliates &&
      !isLoaded &&
      getValues('affiliatesActivities')
    ) {
      const affiliatesActivity = getValues('affiliatesActivities')
        ?.map((affiliate) => {
          const hasAffiliate = affiliates?.find(
            (a) => a.id === affiliate.affiliate_id,
          )
          return hasAffiliate
            ? {
                id: hasAffiliate.id,
                name: hasAffiliate.name,
                percent: affiliate.percent,
              }
            : undefined
        })
        .filter((affiliate) => !!affiliate) as AffiliatesActivityRequest[]
      setValue('affiliates', affiliatesActivity)
      setAffiliateList(affiliatesActivity)
      setIsLoaded(true)
    }
  }, [getValues, isEditMode, isFetched, isLoaded, setValue, affiliates])

  return (
    <Grid
      item
      container
      xs={12}
      columnSpacing={2}
      rowSpacing={2}
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h6" component="h2">
          Afiliados
        </Typography>
      </Grid>

      <Grid item xs={6} style={{ margin: '16px 0 8px' }}>
        <Autocomplete
          disablePortal
          id="affiliates"
          disabled={isLoadingAffiliates}
          options={
            affiliates?.length
              ? affiliates?.map((affiliate) => ({
                  label: affiliate.name,
                  id: affiliate.id,
                }))
              : []
          }
          onChange={(_, newAffiliate) => {
            const AffiliateToAdd = affiliates?.find(
              (affiliate) => newAffiliate && affiliate.id === newAffiliate.id,
            )
            setSelectedAffiliate(AffiliateToAdd)
          }}
          getOptionLabel={(option) => option.label || ''}
          noOptionsText="Nada foi encontrado"
          renderInput={(params) => <TextField {...params} label="Afiliados" />}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Percentual de Ganho"
          margin="normal"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          variant="outlined"
          onChange={(event) => {
            const percent = +event.target.value
            if (percent >= 0 && percent <= 100)
              setAffiliatePercent(event.target.value)
          }}
          value={affiliatePercent}
        />
      </Grid>

      <Grid container item xs={2} justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const updatedAffiliateList = selectedAffiliate
              ? [...AffiliateList, selectedAffiliate]
              : AffiliateList
            setAffiliateList(
              updatedAffiliateList.map((affiliate) => ({
                id: affiliate.id,
                name: affiliate.name,
                percent:
                  selectedAffiliate?.id === affiliate.id
                    ? affiliatePercent
                    : affiliate.percent,
              })),
            )
            setSelectedAffiliate(undefined)
          }}
        >
          Adicionar
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Table rows={AffiliateList} columns={AffiliateColumns} />
      </Grid>
    </Grid>
  )
}
