import { useCallback, useMemo, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Tab, Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import { ROUTES } from 'constants/routes'
import moment from 'moment'
import { ACTIVITY_STATUS, ACTIVITY_STATUS_OPTION } from 'pages/activities/types'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { currencyMask } from 'utils/mask'

import Delete from './delete'
import { COLLABORATOR_HIERARCHICAL_LEVEL, COLLABORATORS_SECTOR } from './types'

enum TABS {
  ACTIVITIES,
}

export default function View() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const [currentTab, setCurrentTab] = useState<TABS>(TABS.ACTIVITIES)
  const { data, isLoading } = useQuery<Collaborator>(
    QUERY_KEYS.COLLABORATORS.VIEW(id as string),
    () => ApiService.Collaborators.get(id as string),
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Collaborator | undefined
  >()

  const handleGetFieldValue = useCallback(
    (value: string, data: { english: string; portuguese: string }[]) => {
      const findValue = data?.find(
        (item) => item.english.toLowerCase() === value.toLowerCase(),
      )

      return findValue?.portuguese
    },
    [],
  )

  const activitiesColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'code', headerName: 'Código', flex: 1 },
      {
        field: 'institution.fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        relation: {
          name: 'activity.institution',
          alias: 'institution',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => params.row?.institution?.fantasy_name,
      },
      {
        field: 'commercialUnit.fantasy_name',
        headerName: 'Unidade Comercial',
        flex: 1,
        relation: {
          name: 'activity.commercialUnit',
          alias: 'commercialUnit',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['commercialUnit']>,
        ) => params.row?.commercialUnit?.fantasy_name,
      },
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'start_travel_date',
        headerName: 'Data início',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Activity>) =>
          params.row.start_travel_date.substring(0, 10),
      },
      {
        field: 'end_travel_date',
        headerName: 'Data fim',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Activity>) =>
          params.row.end_travel_date.substring(0, 10),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        type: 'singleSelect',
        valueOptions: ACTIVITY_STATUS_OPTION,
        valueFormatter: ({ value }) =>
          `${ACTIVITY_STATUS[value as ActivityStatus]}`,
      },
    ],
    [],
  )

  if (!data) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {data?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.COLLABORATORS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(data as Collaborator)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.COLLABORATORS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              {isLoading ? (
                'Carregando Dados'
              ) : (
                <>
                  <TextWithLabel label="Nome" value={data?.name} />

                  <TextWithLabel label="CPF" value={data?.cpf} />

                  <TextWithLabel label="RG" value={data?.rg} />

                  <TextWithLabel
                    label="Data de nascimento"
                    value={data?.birth_date}
                  />

                  <TextWithLabel
                    label="Data de admissão"
                    value={data?.admission_date}
                  />

                  <TextWithLabel
                    label="Data de demissão"
                    value={data?.dismiss_date}
                  />

                  <TextWithLabel
                    label="Salario mensal"
                    value={
                      data?.monthly_salary
                        ? currencyMask(data?.monthly_salary || '0,00')
                        : '0,00'
                    }
                  />

                  <TextWithLabel
                    label="Setor"
                    value={handleGetFieldValue(
                      data?.sector?.toString?.() || '',
                      COLLABORATORS_SECTOR,
                    )}
                  />

                  <TextWithLabel
                    label="Setor"
                    value={handleGetFieldValue(
                      data?.hierarchical_Level || '',
                      COLLABORATOR_HIERARCHICAL_LEVEL,
                    )}
                  />

                  <TextWithLabel
                    label="Dias trabalhados"
                    value={data?.worked_days}
                  />

                  <TextWithLabel
                    label="Horas trabalhadas"
                    value={data?.worked_times}
                  />

                  <TextWithLabel
                    label="Vendedor"
                    value={data?.seller ? 'Sim' : 'Não'}
                  />

                  <TextWithLabel label="Email" value={data?.email} />

                  <TextWithLabel label="Telefone" value={data?.phone} />

                  <TextWithLabel label="Celular" value={data?.cell_phone} />

                  <TextWithLabel
                    label="Endereço"
                    value={
                      data?.street && data?.number
                        ? `${data?.street}, ${data?.number}`
                        : ''
                    }
                  />

                  <TextWithLabel label="Complemento" value={data?.complement} />

                  <TextWithLabel label="Bairro" value={data?.district} />

                  <TextWithLabel
                    label="Cidade"
                    value={
                      data?.city && data?.uf ? `${data?.city}/${data?.uf}` : ''
                    }
                  />

                  <TextWithLabel label="CEP" value={data?.zip_code} />
                </>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Atividades" />
                </Tabs>

                <TabPanel value={TABS.ACTIVITIES} index={currentTab}>
                  <Table
                    rows={[
                      ...(data?.activities || []),
                      ...(data?.responsibleActivities || [])
                        .filter((item) => item.activity)
                        .map((item) => ({
                          ...item.activity,
                        })),
                    ].sort((currentActivity, nextActivity) => {
                      return moment(
                        nextActivity.start_travel_date,
                        INPUT_MASK.DATE_LOCALE,
                      ).diff(
                        moment(
                          currentActivity.start_travel_date,
                          INPUT_MASK.DATE_LOCALE,
                        ),
                      )
                    })}
                    columns={activitiesColumns}
                  />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        collaborator={deleteConfirmation as Collaborator}
      />
    </>
  )
}
