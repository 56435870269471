import { useCallback, useEffect, useMemo, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import Link from '@mui/icons-material/Link'
import SearchIcon from '@mui/icons-material/Search'
import WhatsApp from '@mui/icons-material/WhatsApp'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridFilterItem,
  GridSortModel,
} from '@mui/x-data-grid'
import ConfirmationModal from 'components/ConfirmationModal'
import DataTable from 'components/DataTable'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useQueryParams } from 'hooks/useQueryParams'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import formatCurrencyUnit from 'utils/formatCurrencyUnit'
import { removeEmptyFieldsInObjectFieldsInObject } from 'utils/removeEmptyFieldsInObject'

import { FinishedButton } from './components/FinishedButton'

export const RESERVATION_SITUATION_TYPES = {
  all: 'Todas',
  waiting_payment: 'Aguardando confirmação',
  payment_confirmed: 'Reserva confirmada',
  cancelled: 'Cancelado',
  payment_reversal: 'Devolvida',
  finished: 'Concluída',
}

export const RESERVATION_SITUATIONS_OPTIONS = Object.entries(
  RESERVATION_SITUATION_TYPES,
).map(([key, value]) => ({ label: value, value: key }))

interface FormFilters {
  code?: string
}

const ACTIVITY_STATUS_MAP = {
  opened: 'Em aberto',
  confirmed: 'Confirmada',
  cancelled: 'Cancelada',
  finished: 'Concluída',
}

export default function Activities() {
  const navigate = useNavigate()
  const query = useQueryParams()

  const reactHookFormSearch = useForm<FormFilters>()
  const status_checklist = query.get('status_checklist')
  const [searchParams, setSearchParams] = useState<GenericObject[]>(() => {
    const status = query.getAll('status')
    const start_travel_date_period = query.get('start_travel_date_period')

    return [
      {
        status,
        status_checklist,
        start_travel_date_period,
      },
    ]
  })

  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [sortOptions, setSortOptions] = useState<GridSortModel>([])

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const [messageModal, setMessageModal] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [situation, setSituation] = useState<ReservationSituation | 'all'>(
    'all',
  )
  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)

  const onConfirmMessage = useCallback(async () => {
    setIsSending(true)
    await ApiService.Activities.sendMessage({
      id: Number(currentRow),
      message,
      situation,
    })
      .then(() => {
        openSuccessToast({ message: 'A mensagem foi enviada' })
        setIsSending(false)
        setMessageModal(false)
      })
      .catch((error) => {
        const message = error?.message || 'Erro ao enviar mensagem.'
        openErrorToast({ message })
        setIsSending(false)
        setMessageModal(false)
      })
  }, [currentRow, message, situation, openErrorToast, openSuccessToast])

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'code', headerName: 'Código', flex: 1 },
      {
        field: 'institution.fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        relation: {
          name: 'activity.institution',
          alias: 'institution',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => params.row?.institution?.fantasy_name,
      },
      {
        field: 'commercialUnit.fantasy_name',
        headerName: 'Unidade Comercial',
        flex: 1,
        relation: {
          name: 'activity.commercialUnit',
          alias: 'commercialUnit',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['commercialUnit']>,
        ) => params.row?.commercialUnit?.fantasy_name,
      },
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'price',
        headerName: 'Preço',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Activity>) =>
          formatCurrencyUnit(+params.row.price),
      },
      {
        field: 'start_travel_date',
        headerName: 'Data início',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Activity>) =>
          params.row.start_travel_date.substring(0, 10),
      },
      {
        field: 'end_travel_date',
        headerName: 'Data fim',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Activity>) =>
          params.row.end_travel_date.substring(0, 10),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        valueFormatter: (field) =>
          ACTIVITY_STATUS_MAP[field.value as ActivityStatus],
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 220,
        sortable: false,
        exclude: true,
        renderCell: (params: GridRenderCellParams<Institution>) => {
          return (
            <div style={{ display: 'flex', gap: '12px' }}>
              <FinishedButton
                activity={params.row}
                type="icon"
                onLoading={(value) => setIsSending(value)}
              />
              {(params.row.status === 'confirmed' ||
                params.row.status === 'finished') && (
                <FactCheckOutlinedIcon
                  fontSize="small"
                  onClick={() => {
                    navigate(ROUTES.ACTIVITY_CHECKLIST.EDIT(params.row.id))
                  }}
                />
              )}
              <WhatsApp
                fontSize="small"
                onClick={() => {
                  setCurrentRow(params.row.id)
                  setMessage('')
                  setMessageModal(true)
                }}
              />
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_SITE_URL}/a/${params.row.code}`,
                    '_blank',
                  )
                }}
              />

              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.ACTIVITIES.VIEW(params.row.id))
                }}
              />

              <EditIcon
                onClick={() => {
                  navigate(ROUTES.ACTIVITIES.EDIT(params.row.id))
                }}
              />
            </div>
          )
        },
      },
    ],
    [navigate],
  )

  const handleFilter = useCallback(() => {
    const data = removeEmptyFieldsInObjectFieldsInObject(
      reactHookFormSearch.getValues() as GenericObject,
    )
    setSearchParams([
      {
        ...data,
      },
    ])
  }, [reactHookFormSearch])

  useEffect(() => {
    if (status_checklist === 'pending') {
      setSortOptions([
        {
          field: 'start_travel_date',
          sort: 'asc',
        },
      ])
    }
  }, [status_checklist])

  if (isSending) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Atividades
              </Typography>

              <Box sx={{ m: 1 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.ACTIVITIES.CREATE)
                  }}
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
              marginTop: '20px',
            }}
          >
            <TextField
              label="Código"
              type="text"
              variant="outlined"
              {...reactHookFormSearch.register('code')}
            />

            <Button color="primary" variant="contained" onClick={handleFilter}>
              Pesquisar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setSearchParams([])}
            >
              Todos
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={() => navigate(ROUTES.ACTIVITIES.LIST_CALENDAR)}
            >
              Calendário
            </Button>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <DataTable
                columns={columns}
                entity="Activity"
                source="activity"
                onFilterModelChange={(newValue) => {
                  setFilterOptions(newValue.items)
                }}
                onSortModelChange={(newValue) => {
                  setSortOptions(newValue)
                }}
                searchParameters={searchParams}
                likeParameters={filterOptions}
                sortParameters={sortOptions}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ConfirmationModal
        title="Enviar Mensagem"
        opened={messageModal}
        onClose={() => setMessageModal(false)}
        onConfim={onConfirmMessage}
        onCancel={() => setMessageModal(false)}
      >
        <label htmlFor="message-wpp"></label>
        <textarea
          name="message-wpp"
          style={{ width: '100%' }}
          rows={5}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <Select
          id="situation"
          label={'Status da reserva'}
          onChange={(e) =>
            setSituation(String(e.target.value) as ReservationSituation)
          }
          value={situation}
          options={RESERVATION_SITUATIONS_OPTIONS}
          autoFill
        />
      </ConfirmationModal>
    </>
  )
}
