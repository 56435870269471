import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  affiliate?: Affiliate
}

export default function Delete({ opened, closeModal, affiliate }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeAffiliate = useMutation({
    mutationFn: async (affiliate: Affiliate) => {
      await ApiService.Affiliates.remove(affiliate.id.toString())

      openSuccessToast({
        message: `A afiliação ${affiliate?.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.AFFILIATES.LIST)
    },
    onSuccess: (_data, affiliate: Affiliate) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.AFFILIATES.LIST,
        (oldAffiliates) => {
          if (!oldAffiliates?.length) return

          return oldAffiliates.filter(
            (oldAffiliate) => oldAffiliate.id !== affiliate.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(affiliate.id.toString()),
      )

      openSuccessToast({
        message: `A afiliação ${affiliate.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.AFFILIATES.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeAffiliate.mutate(affiliate as Affiliate)}
      confirmColor="error"
    >
      Deseja realmente remover a afiliação{' '}
      <strong>{`${affiliate?.name}?`}</strong>
    </ConfirmationModal>
  )
}
