import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<CommercialUnit>(
    QUERY_KEYS.COMMERCIAL_UNITS.EDIT(id as string),
    () => ApiService.CommercialUnits.get(id as string),
  )

  const onSave = useCallback(
    async (commercialUnit: CommercialUnit) => {
      try {
        if (!id) {
          openErrorToast({
            message: `Erro ao editar ${commercialUnit.fantasy_name}!`,
          })

          return
        }

        await ApiService.CommercialUnits.update({
          id,
          commercialUnit,
        })

        openSuccessToast({
          message: `A unidade comercial ${commercialUnit.fantasy_name} foi atualizada com sucesso!`,
        })

        navigate(ROUTES.COMMERCIAL_UNITS.LIST)
      } catch (saveError) {
        openErrorToast({
          message: `Erro ao editar ${commercialUnit.fantasy_name}!`,
        })
      }
    },
    [id, navigate, openErrorToast, openSuccessToast],
  )

  return <Add commercialUnit={data} onSave={onSave} isEditMode />
}
