import type { PropsWithChildren } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import InputPassword from 'components/InputPassword'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { ApiService } from 'services/api'
import * as yup from 'yup'

type ChangePasswordProps = {
  opened: boolean
  title: string
  onClose?: () => void
  onClick?: () => void
}

type ChangePasswordForm = {
  password: string
}

export default function ChangePassword({
  opened,
  title,
  onClose,
  onClick,
}: PropsWithChildren<ChangePasswordProps>) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordForm>({
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().required('Campo obrigatório'),
      }),
    ),
  })

  const { logout, openSuccessToast, openErrorToast } = useGlobalState()

  return (
    <Modal
      open={opened}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
        onSubmit={handleSubmit((supplier: ChangePasswordForm) => {
          globalThis.console.log(supplier)
          ApiService.Users.changePassword({
            password: supplier.password,
          })
            .then(() => {
              openSuccessToast({
                message: 'Senha alterado com sucesso!',
              })
              setTimeout(() => {
                logout()
              }, 500)
            })
            .catch(() => {
              openErrorToast({
                message: 'Erro ao atualizar senha',
              })
            })
        })}
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '8px',
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            flexWrap={'wrap'}
          >
            {title}
          </Typography>

          <Box sx={{ mt: 2 }}></Box>

          <Grid item xs={6}>
            <InputPassword
              error={!!errors.password?.message}
              helperText={errors.password?.message}
              fullWidth
              label="Nova Senha"
              {...register('password', { required: true })}
            />
          </Grid>

          <Box
            sx={{
              mt: 2,
              flex: 1,
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              onClick={onClick}
              sx={{ flexGrow: 1, padding: '10px', maxWidth: '120px' }}
            >
              Alterar
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  )
}
