import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const get = async (): Promise<IndicatorsApiResponse> => {
  try {
    const getResponse = await apiInstance.post<IndicatorsApiResponse>(
      ENDPOINTS.INDICATORS.GET,
      {
        fields: ['average_ticket'],
      },
    )
    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
