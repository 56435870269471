import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  default: yup.string().required('Campo obrigatório'),
  active: yup.string().required('Campo obrigatório'),
  has_service_order: yup.string().required('Campo obrigatório'),
  category_id: yup.string().required('Campo obrigatório'),
})

export { schema }
