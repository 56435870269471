import { USER_SESSION_STORAGE_KEY } from 'constants/keys'
import apiInstance from 'services/api/instance'

import * as Activities from './activities'
import * as ActivityChecklists from './activity-checklists'
import * as ActivityTasks from './activity-tasks'
import * as Affiliates from './affiliates'
import * as AuditLogs from './auditlogs'
import * as Budgets from './budgets'
import * as BudgetItems from './budgets-items'
import * as Campus from './campus'
import * as Categories from './categories'
import * as CheckPermission from './check-permission'
import * as ClassesActivities from './classes-activities'
import * as ClassesInstitutions from './classes-institutions'
import * as Clients from './clients'
import * as Collaborators from './collaborators'
import * as CommercialUnits from './commercial-units'
import * as DataTable from './datatable'
import * as GlobalParameters from './globalParameters'
import * as Indicators from './indicators'
import * as Institutions from './institutions'
import * as IpsAllowed from './ips-allowed'
import * as ItemsBudget from './items-budget'
import * as Labels from './labels'
import * as Login from './login'
import * as Menus from './menus'
import * as OrderServices from './order-services'
import * as Pages from './pages'
import * as Pagseguro from './pagseguro'
import * as PaymentGateways from './paymentGateways'
import * as PaymentModules from './paymentModules'
import * as Permissions from './permissions'
import * as PermissionsAssociated from './permissions-associated'
import * as Recipients from './recipients'
import * as Reports from './reports'
import * as Reservations from './reservations'
import * as ResponsibleGuides from './responsible-guides-activity'
import * as Roles from './roles'
import * as Suppliers from './suppliers'
import * as TeachersInstitutions from './teacher-institution'
import * as Teachers from './teachers'
import * as Users from './users'
import * as Videos from './videos'

const persistToken = (token: string) => {
  apiInstance.defaults.headers['Authorization'] = `Bearer ${token}`
}

const cachedUser = sessionStorage.getItem(USER_SESSION_STORAGE_KEY)
if (cachedUser) {
  const currentUser = JSON.parse(cachedUser) as LoginApiResponse
  persistToken(currentUser.token)
}

export const ApiService = {
  persistToken,
  Login,
  AuditLogs,
  Institutions,
  OrderServices,
  Labels,
  BudgetItems,
  CommercialUnits,
  DataTable,
  Teachers,
  TeachersInstitutions,
  Suppliers,
  Collaborators,
  Categories,
  Users,
  Roles,
  CheckPermission,
  Permissions,
  PermissionsAssociated,
  Menus,
  IpsAllowed,
  Affiliates,
  Videos,
  GlobalParameters,
  PaymentModules,
  PaymentGateways,
  ItemsBudget,
  Clients,
  Campus,
  ClassesInstitutions,
  ClassesActivities,
  Budgets,
  Pages,
  Reservations,
  Activities,
  ResponsibleGuides,
  Pagseguro,
  Reports,
  Indicators,
  ActivityChecklists,
  ActivityTasks,
  Recipients,
}
