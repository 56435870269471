import { useCallback } from 'react'

import ConfirmationModal from 'components/ConfirmationModal'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  teacher?: TeacherApiResponse
}

export default function Delete({ opened, closeModal, teacher }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const navigate = useNavigate()

  const removeTeacher = useCallback(async () => {
    try {
      if (!teacher?.id) return

      await ApiService.Teachers.remove(teacher.id.toString())

      openSuccessToast({
        message: `O professor ${teacher.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.TEACHERS.LIST)
    } catch (removeError) {
      let message = `Ocorreu um erro ao remover o professor ${
        (removeError as ApiError).message
      }`
      if ((removeError as ApiError).message.includes('não autorizado')) {
        message =
          'Esta informação não pode ser removida, pois está sendo usada em outro lugar!'
      }
      closeModal()
      openErrorToast({
        message,
      })
    }
  }, [
    closeModal,
    navigate,
    openErrorToast,
    openSuccessToast,
    teacher?.id,
    teacher?.name,
  ])

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={removeTeacher}
      confirmColor="error"
    >
      Deseja realmente remover o professor(a){' '}
      <strong>{`${teacher?.name}?`}</strong>
    </ConfirmationModal>
  )
}
