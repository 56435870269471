import { useCallback, useMemo, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridRenderCellParams,
  GridFilterItem,
  GridSortModel,
} from '@mui/x-data-grid'
import DataTable from 'components/DataTable'
import { ROUTES } from 'constants/routes'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import capitalize from 'utils/capitalize'
import { cpfCnpjMask } from 'utils/mask'
import { removeEmptyFieldsInObjectFieldsInObject } from 'utils/removeEmptyFieldsInObject'

interface FormFilters {
  email?: string
  document?: string
}

export default function Clients() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useState<GenericObject[]>([])
  const reactHookFormSearch = useForm<FormFilters>()

  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [sortOptions, setSortOptions] = useState<GridSortModel>([])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'fantasy_name',
        headerName: 'Nome',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Clients>) => {
          return <span>{capitalize(params.row.fantasy_name)}</span>
        },
      },
      { field: 'document', headerName: 'Documento', flex: 1, sortable: false },
      {
        field: 'city',
        headerName: 'Cidade',
        flex: 1,
      },
      {
        field: 'uf',
        headerName: 'Estado',
      },
      { field: 'cell_phone', headerName: 'Celular', flex: 1, sortable: false },
      { field: 'phone', headerName: 'Telefone', flex: 1, sortable: false },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        exclude: true,
        renderCell: (params: GridRenderCellParams<Clients>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.CLIENTS.VIEW(params.row.id))
                }}
              />

              <EditIcon
                onClick={() => {
                  navigate(ROUTES.CLIENTS.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  const handleFilter = useCallback(() => {
    const data = removeEmptyFieldsInObjectFieldsInObject(
      reactHookFormSearch.getValues() as GenericObject,
    ) as { emaiL: string; document: string }
    setSearchParams([
      {
        ...data,
        document: data?.document?.length
          ? cpfCnpjMask(data?.document as string)
          : undefined,
      },
    ])
  }, [reactHookFormSearch])

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Clientes
            </Typography>

            <Box sx={{ m: 1 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  navigate(ROUTES.CLIENTS.CREATE)
                }}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 1,
            marginTop: '20px',
          }}
        >
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            {...reactHookFormSearch.register('email')}
          />

          <TextField
            label="CPF"
            type="document"
            variant="outlined"
            {...reactHookFormSearch.register('document')}
          />

          <Button color="primary" variant="contained" onClick={handleFilter}>
            Pesquisar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setSearchParams([])}
          >
            Todos
          </Button>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <DataTable
              entity="Client"
              source="client"
              columns={columns}
              filterMode="server"
              onFilterModelChange={(newValue) => {
                setFilterOptions(newValue.items)
              }}
              onSortModelChange={(newValue) => {
                setSortOptions(newValue)
              }}
              onlyParent
              searchParameters={searchParams}
              likeParameters={filterOptions}
              sortParameters={sortOptions}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
