import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const create = async (
  recipient: RecipientApiRequest,
): Promise<RecipientApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<RecipientApiResponse>(
      ENDPOINTS.RECIPIENTS.CREATE,
      recipient,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getBalance = async (
  data: RecipientBalanceData,
): Promise<RecipientBalanceResponse> => {
  try {
    const creationResponse = await apiInstance.post<RecipientBalanceResponse>(
      ENDPOINTS.RECIPIENTS.BALANCE,
      data,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const createWithrawals = async (
  data: RecipientWithdrowalsData,
): Promise<RecipientWithdrowalsResponse> => {
  try {
    const creationResponse =
      await apiInstance.post<RecipientWithdrowalsResponse>(
        ENDPOINTS.RECIPIENTS.CREATE_WITHDRAWALS,
        data,
      )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
