import * as yup from 'yup'

export const filterSupplierSchema = yup.object().shape({
  category_id: yup.string().required('Campo obrigatório'),
})

export const createOrderServiceSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  category_id: yup.string().required('Campo obrigatório'),
  supplier_id: yup.string().required('Campo obrigatório'),
  budget_id: yup.string().required('Campo obrigatório'),
  issue_date: yup.string().required('Campo obrigatório'),
  send_date: yup.string().optional().nullable(),
  due_date: yup.string().required('Campo obrigatório'),
  payment_date: yup.string().optional().nullable(),
  observations: yup.string().required('Campo obrigatório'),
  method_payment: yup.string().required('Campo obrigatório'),
  total_value: yup.string().optional(),
  payment_status: yup.string().required('Campo obrigatório'),
  status: yup.string().required('Campo obrigatório'),
  order_service_items: yup.array().required('Campo obrigatório'),
  additional_information: yup.object().required('Field is required'),
})

export const addItemBudgetSchema = yup.object().shape({
  id: yup.string().required('Campo obrigatório'),
  origin_id: yup.string().optional().nullable(),
  item_budget_id: yup.string().optional().nullable(),
  order_service_item_id: yup.string().optional().nullable(),
  name: yup.string().required('Campo obrigatório'),
  quantity: yup.string().required('Campo obrigatório'),
  subtotal: yup.string().required('Campo obrigatório'),
  unit_value: yup.string().required('Campo obrigatório'),
})

export const sendOrderServiceToSupplierSchema = yup.object().shape({
  sendTo: yup.string().required('Campo obrigatório'),
})
