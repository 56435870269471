import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const PermissionsRoutes: RouteObject[] = [
  {
    path: ROUTES.PERMISSIONS_ASSOCIATED.LIST,
    element: (
      <PrivateRoute>
        <Pages.PermissionsAssociated />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PERMISSIONS_ASSOCIATED.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddPermissionsAssociated />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PERMISSIONS_ASSOCIATED.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditPermissionsAssociated />
      </PrivateRoute>
    ),
  },
]

export default PermissionsRoutes
