import { useEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid'
import Select, { SelectProps } from 'components/Select'
import { removeDuplicatedArrayOfObject } from 'utils/removeDuplicatedArrayOfObject'

interface SelectFilterProps extends GridFilterInputValueProps {
  dataOptions: SelectProps['options']
}

export const SelectFilter = (props: SelectFilterProps) => {
  const { item, applyValue, focusElementRef = null, dataOptions } = props

  const filterTimeout = useRef<NodeJS.Timeout | undefined>()
  const [filterValueState, setFilterValueState] = useState<(string | number)[]>(
    item.value ?? '',
  )

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current)
    }
  }, [])

  useEffect(() => {
    const itemValue = item.value ?? [undefined]
    setFilterValueState(itemValue)
  }, [item.value])

  const updateFilterValue = (selectedValues: (string | number)[]) => {
    clearTimeout(filterTimeout.current)
    setFilterValueState([...selectedValues])

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: [...selectedValues] })
    }, 500)
  }

  const handleLowerFilterChange: SelectProps['onChange'] = (event) => {
    const selectedValue = event.target.value
    const findOption = dataOptions
      .filter(
        (filterOption) =>
          filterOption.label ===
          dataOptions.find((option) => option.value === selectedValue)?.label,
      )
      .map((filterOption) => filterOption.value)
    updateFilterValue(findOption)
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: '40px',
        marginBottom: 0,
        marginTop: '10.5px',
      }}
    >
      <Select
        label="Selecione"
        id="reservation-select-option"
        name="reservation-select-option"
        variant="standard"
        value={filterValueState[0]}
        onChange={handleLowerFilterChange}
        inputRef={focusElementRef}
        sx={{ marginBottom: 0 }}
        formControlMargin="none"
        options={
          removeDuplicatedArrayOfObject(dataOptions) as SelectProps['options']
        }
      />
    </Box>
  )
}
