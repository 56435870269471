import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

export default function PageNotFound() {
  return (
    <Container
      maxWidth={false}
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Typography sx={{ m: 1 }} variant="h4">
        Página não encontrada!
      </Typography>
    </Container>
  )
}
