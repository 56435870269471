import { useState } from 'react'

import { Button } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import CloseModal from 'components/CloseModal'
import { ENDPOINTS } from 'constants/endpoints'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import currency from 'currency.js'
import { ApiService } from 'services/api'
import { currencyMask } from 'utils/mask'

const BRL = (value: number) =>
  currency(value, {
    decimal: '.',
    separator: '',
    symbol: '',
  })

type FinishedButtonProps = {
  affiliate: Affiliate
  onLoading?: (value: boolean) => void
}

type ReleasedPaymentModalProps = {
  opened: boolean
  affiliate_name: string
  available_amount: number
  pending_amount: number
  onClose: () => void
}

const ReleasedPaymentModal = ({
  onClose,
  opened,
  affiliate_name,
  available_amount,
  pending_amount,
}: ReleasedPaymentModalProps) => {
  return (
    <CloseModal
      title={`Consultar Saldo ${affiliate_name}`}
      opened={opened}
      onClose={onClose}
    >
      <>
        <p>
          Valor disponível: <strong>R$ {currencyMask(available_amount)}</strong>
        </p>
        <p>
          Valor pendente (Não disponível para transferência):{' '}
          <strong>R$ {currencyMask(pending_amount)}</strong>
        </p>
      </>
    </CloseModal>
  )
}

export const ReleasedPayment = ({
  affiliate,
  onLoading,
}: FinishedButtonProps) => {
  const { openErrorToast, user } = useGlobalState()

  const [cancelModal, setCancelModal] = useState(false)
  const [availableAmount, setAvailableAmount] = useState<number>()
  const [pendingAmount, setPendingAmount] = useState<number>()

  const { data: checkPermission, isLoading: checkPermissionLoading } = useQuery<
    CheckPermissionApiResponse | undefined
  >({
    enabled: !!user,
    queryKey: QUERY_KEYS.CHECK_PERMISSION.GET(
      ENDPOINTS.FAKES_ROUTES.RELEASED_PAYMENT,
    ),
    queryFn: async () => {
      if (!user) return
      return await ApiService.CheckPermission.get({
        method: 'GET',
        originalUrl: ENDPOINTS.FAKES_ROUTES.RELEASED_PAYMENT,
        role_id: user?.role_id,
        user_id: user?.id,
      })
    },
  })

  if (checkPermissionLoading || !checkPermission?.approved) {
    return null
  }
  return (
    <>
      <Button
        color="info"
        variant="contained"
        onClick={async () => {
          onLoading?.(true)
          try {
            const balanceResponse = await ApiService.Recipients.getBalance({
              recipient_id: String(affiliate.recipient_id),
            })
            const availableAmount = BRL(
              balanceResponse.available_amount,
            ).divide(100).value

            const pendingAmount = BRL(
              balanceResponse.waiting_funds_amount,
            ).divide(100).value

            setAvailableAmount(availableAmount)
            setPendingAmount(pendingAmount)
          } catch (error) {
            openErrorToast({
              message: 'Erro ao consutar saldo na Pagar.me',
            })
          }
          onLoading?.(false)
          setCancelModal(true)
        }}
      >
        Consultar saldo
      </Button>

      <ReleasedPaymentModal
        affiliate_name={affiliate.name}
        available_amount={availableAmount || 0}
        pending_amount={pendingAmount || 0}
        onClose={() => {
          onLoading?.(false)
          setCancelModal(false)
        }}
        opened={cancelModal}
      />
    </>
  )
}
