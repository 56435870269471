import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import { SELECT_BRAZILIAN_STATES } from 'constants/brazilian-states'
import { Controller, useForm } from 'react-hook-form'

import Delete from './delete'
import { schemaStudent } from './form-validation'

const PERIOD_OPTIONS = {
  SCHOOL: [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: 'others', label: 'Outros' },
  ],
  FACULTY: Array.from(new Array(14)).map((_, index) => {
    const indexToCardinal = index + 1
    return {
      label: indexToCardinal.toString(),
      value: indexToCardinal.toString(),
    }
  }),
}

type OnSave = (clients: ClientsApiRequest) => Promise<void>

type FormStudentProps = {
  clients?: Clients
  onSave?: OnSave
  isEditMode?: boolean
}

export default function FormStudent({
  clients,
  onSave,
  isEditMode = false,
}: FormStudentProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<ClientsApiRequest>({
    resolver: yupResolver(schemaStudent),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Clients | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!clients && !formUpdated) {
      reset({
        fantasy_name: clients.fantasy_name,
        document: clients.document,
        birth_date: clients.birth_date,
        cell_phone: clients.cell_phone,
        phone: clients.phone,
        gender: clients.gender,
        document_type: clients.document_type,
        number_document: clients.number_document,
        rg_state_issue: clients.rg_state_issue,
        shift: clients.shift,
        period: clients.period,
      })

      setFormUpdated(true)
    }
  }, [isEditMode, clients, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof ClientsApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  if (isEditMode && !!clients && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 1 }}>
        <Container maxWidth={false}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ mt: 3 }}>
              <form
                onSubmit={handleSubmit((clients: ClientsApiRequest) => {
                  if (isEditMode) {
                    onSave?.(clients)
                    return
                  }
                })}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3} xs={12}>
                      <Grid item xs={8}>
                        <TextField
                          error={!!errors.fantasy_name?.message}
                          fullWidth
                          helperText={errors.fantasy_name?.message}
                          label="Nome"
                          margin="normal"
                          type="text"
                          variant="outlined"
                          {...inputProps('fantasy_name')}
                          {...register('fantasy_name')}
                        />
                      </Grid>

                      <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
                        <Controller
                          name="document"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputMask
                                error={!!errors.document?.message}
                                helperText={errors.document?.message}
                                fullWidth
                                label="CPF"
                                id="document"
                                maskType="cpfCnpj"
                                onChange={onChange}
                                value={value}
                                {...inputProps('document')}
                              />
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} style={{ margin: '16px 0 8px' }}>
                        <Controller
                          name="birth_date"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputMask
                                error={!!errors.birth_date?.message}
                                fullWidth
                                label="Data de nascimento"
                                id="birth_date"
                                maskType="date"
                                {...inputProps('birth_date')}
                                onChange={onChange}
                                value={value}
                              />
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          name="gender"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                label="Sexo (Biológico)"
                                id="gender"
                                onChange={onChange}
                                value={value}
                                options={[
                                  {
                                    label: 'Masculino',
                                    value: 'masculine',
                                  },
                                  {
                                    label: 'Feminino',
                                    value: 'feminine',
                                  },
                                ]}
                                autoFill={isEditMode}
                                error={!!errors.gender?.message}
                                helperText={errors.gender?.message}
                              />
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Controller
                          name="document_type"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                label="Tipo do documento"
                                id="document_type"
                                onChange={onChange}
                                value={value}
                                options={[
                                  { value: 'rg', label: 'RG' },
                                  { value: 'passport', label: 'Passaporte' },
                                  { value: 'term', label: 'Termo' },
                                ]}
                                autoFill={isEditMode}
                                error={!!errors.document_type?.message}
                                helperText={errors.document_type?.message}
                              />
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          label="Nº do documento"
                          fullWidth
                          margin="normal"
                          error={!!errors.number_document?.message}
                          helperText={errors.number_document?.message}
                          {...inputProps('number_document')}
                          {...register('number_document')}
                        />
                      </Grid>

                      {watch('document_type') === 'rg' && (
                        <Grid item xs={4}>
                          <Controller
                            name="rg_state_issue"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Estado de emissão do RG"
                                  options={SELECT_BRAZILIAN_STATES}
                                  id="rg_state_issue"
                                  onChange={onChange}
                                  value={value}
                                  autoFill={isEditMode}
                                  error={!!errors.rg_state_issue?.message}
                                  helperText={errors.rg_state_issue?.message}
                                />
                              )
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <Controller
                          name="shift"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                id="shift"
                                label="Turno"
                                onChange={onChange}
                                value={value}
                                options={[
                                  { value: 'morning', label: 'Manhã' },
                                  { value: 'afternoon', label: 'Tarde' },
                                  { value: 'night', label: 'Noite' },
                                ]}
                              />
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="period"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                id="period"
                                label="Turma"
                                onChange={onChange}
                                value={value}
                                options={[...PERIOD_OPTIONS.SCHOOL]}
                              />
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{
                          marginLeft: 'auto',
                          display: 'block',
                          marginTop: '1em',
                        }}
                      >
                        {isEditMode ? 'Salvar' : 'Adicionar'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        clients={deleteConfirmation as Clients}
      />
    </>
  )
}
