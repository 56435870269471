import { useCallback, useEffect, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

type OnSave = (paymentModule: PaymentModuleApiRequest) => Promise<void>

type AddProps = {
  paymentModule?: PaymentModule
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({
  paymentModule,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<PaymentModuleApiRequest>()

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    PaymentModule | undefined
  >()

  const inputProps = useCallback(
    (fieldName: keyof PaymentModuleApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewPaymentModule = useMutation({
    mutationFn: async (paymentModule: PaymentModuleApiRequest) => {
      await ApiService.PaymentModules.create(paymentModule)
    },
    onSuccess: (_data, paymentModule: PaymentModuleApiRequest) => {
      openSuccessToast({
        message: `O modulo de pagamento ${paymentModule.name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.PAYMENT_MODULES.LIST)
    },
    onError: (error: ApiError, paymentModule: PaymentModuleApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${paymentModule.name}!`,
      })
    },
  })

  useEffect(() => {
    if (isEditMode && !!paymentModule && !formUpdated) {
      reset({
        name: paymentModule.name,
        fields: JSON.stringify(paymentModule.fields),
        module: paymentModule.module,
      })
      setFormUpdated(true)
    }
  }, [isEditMode, paymentModule, formUpdated, reset, setValue])

  if (isEditMode && !!paymentModule && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.PAYMENT_MODULES.LIST}
            >
              Modules de Pagamento
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} Modules de Pagamento
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(paymentModule as PaymentModule)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.PAYMENT_MODULES.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit(
                (paymentModule: PaymentModuleApiRequest) => {
                  if (isEditMode) {
                    onSave?.(paymentModule)
                    return
                  }

                  addNewPaymentModule.mutate({
                    ...paymentModule,
                    type: 'credit_card',
                  })
                },
              )}
            >
              <Card>
                <CardContent>
                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.name?.message}
                      fullWidth
                      helperText={errors.name?.message}
                      label="Nome do módulo"
                      margin="normal"
                      type="text"
                      variant="outlined"
                      {...inputProps('name')}
                      {...register('name', { required: true })}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.module?.message}
                      fullWidth
                      helperText={errors.module?.message}
                      label="Módulo"
                      margin="normal"
                      type="text"
                      variant="outlined"
                      {...inputProps('module')}
                      {...register('module', { required: true })}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      error={!!errors.fields?.message}
                      fullWidth
                      helperText={errors.fields?.message}
                      label="Fields"
                      margin="normal"
                      type="text"
                      variant="outlined"
                      {...inputProps('fields')}
                      {...register('fields', { required: true })}
                    />
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        paymentModule={deleteConfirmation as PaymentModule}
      />
    </>
  )
}
