export const METHODS_PAYMENTS_ORDER_SERVICE: Record<string, string> = {
  credit_card: 'Cartão de Crédito',
  debit_card: 'Cartão de Debito',
  bank_slip: 'Boleto',
  transfer: 'Transferência',
  cash: 'Dinheiro/Pix',
}
export const METHODS_PAYMENTS_ORDER_SERVICE_OPTIONS = Object.entries(
  METHODS_PAYMENTS_ORDER_SERVICE,
).map(([key, value]) => ({
  label: value,
  value: key,
}))
