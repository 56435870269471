import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@tanstack/react-query'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import Table from 'components/Table'
import { SELECT_BRAZILIAN_STATES } from 'constants/brazilian-states'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { AddressService } from 'services/address'
import { ApiService } from 'services/api'
import { CompanyService } from 'services/company'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (
  institution: InstitutionsApiRequest,
  campus?: CampusApiRequest[],
) => Promise<void>

type AddProps = {
  institution?: Institution
  onSave?: OnSave
  isEditMode?: boolean
}

type CampusFormularyItem = {
  id: number
  name: string
}

export default function Add({
  institution,
  onSave,
  isEditMode = false,
}: AddProps) {
  const { id: institutionId } = useParams<{ id?: string }>()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<InstitutionsApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [campus, setCampus] = useState<string>('')
  const [addCampusDialogOpened, setAddCampusDialogOpened] =
    useState<boolean>(false)
  const [campusList, setCampusList] = useState<CampusFormularyItem[]>([])

  useEffect(() => {
    if (isEditMode && institution?.campusInstitutions) {
      setCampusList(
        (institution?.campusInstitutions || [])?.map((item, index) => ({
          name: item.name,
          id: index,
        })),
      )
    }
  }, [institution?.campusInstitutions, isEditMode])

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Institution | undefined
  >()

  const { data: commercialUnits } = useQuery<SelectProps['options']>(
    QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
    async () =>
      ApiService.CommercialUnits.getAllNamesAndIds({
        relations: [],
        fields: ['id', 'fantasy_name', 'document'],
      }),
  )

  useEffect(() => {
    if (isEditMode && !!institution && !formUpdated) {
      reset({
        name: institution.name,
        document: institution.document,
        password: '', // TODO: check this information with api developers
        fantasy_name: institution.fantasy_name,
        street: institution.street,
        number: institution.number,
        complement: institution.complement,
        district: institution.district,
        city: institution.city,
        uf: institution.uf,
        zip_code: institution.zip_code,
        email: institution.email,
        phone: institution.phone,
        cell_phone: institution.cell_phone,
        contact_name: institution.contact_name,
      })

      setValue('type', institution.type)
      setValue('commercial_unit_id', institution.commercial_unit_id.toString())

      setFormUpdated(true)
    }
  }, [isEditMode, institution, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof InstitutionsApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewInstitution = useMutation({
    mutationFn: async ({
      institution,
      campus,
    }: {
      institution: InstitutionsApiRequest
      campus: CampusFormularyItem[]
    }) => {
      const addInstitutionResponse = await ApiService.Institutions.create(
        institution,
      )

      const campusListToSave: CampusApiRequest[] = campus.map((item) => ({
        institution_id: addInstitutionResponse.id,
        name: item.name,
      }))

      await ApiService.Campus.create(campusListToSave)
    },
    onSuccess: (
      _data,
      {
        institution,
      }: { institution: InstitutionsApiRequest; campus: CampusFormularyItem[] },
    ) => {
      openSuccessToast({
        message: `A unidade comercial ${institution.fantasy_name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.INSTITUTIONS.LIST)
    },
    onError: (
      error: ApiError,
      {
        institution,
      }: { institution: InstitutionsApiRequest; campus: CampusFormularyItem[] },
    ) => {
      openErrorToast({
        message:
          error.message || `Erro ao adicionar ${institution.fantasy_name}!`,
      })
    },
  })

  const handleGetZipCode = useCallback(
    async (value: string) => {
      const address = await AddressService.getAddressByCEP(value)

      if (!address) return

      setValue('street', address.street, {
        shouldValidate: true,
      })
      setValue('district', address.neighborhood, {
        shouldValidate: true,
      })
      setValue('uf', address.state, {
        shouldValidate: true,
      })
      setValue('city', address.city, {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const campusInstitutionsColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Institution>) => {
          return (
            <>
              <DeleteIcon
                onClick={() => {
                  setCampusList((previousState) => {
                    return previousState.filter(
                      (item) => item.name !== params.row.name,
                    )
                  })
                }}
              />
            </>
          )
        },
      },
    ],
    [],
  )

  if (isEditMode && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.INSTITUTIONS.LIST}
            >
              Instituições
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} instituição
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(institution as Institution)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.INSTITUTIONS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((institution: InstitutionsApiRequest) => {
                if (isEditMode) {
                  const campusToUpdate: CampusApiRequest[] = campusList.map(
                    (item) => ({
                      name: item.name,
                      institution_id: +(institutionId || 0),
                    }),
                  )

                  onSave?.(institution, campusToUpdate)
                  return
                }

                addNewInstitution.mutate({ institution, campus: campusList })
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="commercial_unit_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Unidade comercial"
                              id="commercial_unit_id"
                              onChange={onChange}
                              value={value}
                              options={commercialUnits || []}
                              autoFill={isEditMode}
                              error={!!errors.commercial_unit_id?.message}
                              helperText={errors.commercial_unit_id?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Tipo"
                              id="type"
                              onChange={onChange}
                              value={value}
                              autoFill={isEditMode}
                              error={!!errors.type?.message}
                              helperText={errors.type?.message}
                              options={[
                                {
                                  label: 'Escola',
                                  value: 'school',
                                },
                                {
                                  label: 'Faculdade',
                                  value: 'college',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label="Razão Social"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name', {
                          required: true,
                        })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.fantasy_name?.message}
                        fullWidth
                        helperText={errors.fantasy_name?.message}
                        label="Nome fantasia"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('fantasy_name')}
                        {...register('fantasy_name', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="document"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.document?.message}
                              helperText={errors.document?.message}
                              fullWidth
                              label="CNPJ"
                              id="cnpj"
                              maskType="cnpj"
                              onChange={onChange}
                              value={value}
                              onBlur={async () => {
                                const company =
                                  await CompanyService.getCompanyByCNPJ(value)
                                if (!company) return

                                setValue('name', company.company_name, {
                                  shouldValidate: true,
                                })
                                setValue(
                                  'fantasy_name',
                                  company.company_social_name,
                                  {
                                    shouldValidate: true,
                                  },
                                )
                                setValue('zip_code', company.cep, {
                                  shouldValidate: true,
                                })
                                setValue('email', company.email, {
                                  shouldValidate: true,
                                })
                                await handleGetZipCode(company.cep)
                              }}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.contact_name?.message}
                        fullWidth
                        helperText={errors.contact_name?.message}
                        label="Nome de contato"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('contact_name')}
                        {...register('contact_name')}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              {...inputProps('phone')}
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        rules={{ required: false }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('cell_phone')}
                              error={!!errors.cell_phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="zip_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('zip_code')}
                              error={!!errors.zip_code?.message}
                              helperText={errors.zip_code?.message}
                              fullWidth
                              label="CEP"
                              id="cep"
                              maskType="zipCode"
                              onChange={onChange}
                              value={value}
                              onBlur={() => handleGetZipCode(value)}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.street?.message}
                        fullWidth
                        helperText={errors.street?.message}
                        label="Endereço"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('street')}
                        {...register('street', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.number?.message}
                        fullWidth
                        helperText={errors.number?.message}
                        label="Número"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('number')}
                        {...register('number', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.complement?.message}
                        fullWidth
                        helperText={errors.complement?.message}
                        label="Complemento"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('complement')}
                        {...register('complement', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.district?.message}
                        fullWidth
                        helperText={errors.district?.message}
                        label="Bairro"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('district')}
                        {...register('district', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.city?.message}
                        fullWidth
                        helperText={errors.city?.message}
                        label="Cidade"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('city')}
                        {...register('city', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="uf"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Estado"
                              id="uf"
                              onChange={onChange}
                              value={value}
                              options={SELECT_BRAZILIAN_STATES}
                              autoFill={isEditMode}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={10}>
                      <Typography sx={{ m: 1 }} variant="h4">
                        Campus
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setAddCampusDialogOpened(true)
                        }}
                      >
                        Adicionar Campus
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Table
                        rows={campusList}
                        columns={campusInstitutionsColumns}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Dialog
        open={addCampusDialogOpened}
        onClose={() => setAddCampusDialogOpened(false)}
      >
        <DialogTitle>Adicionar Campus</DialogTitle>

        <DialogContent sx={{ minWidth: 600 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setCampus(event.target.value)
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setAddCampusDialogOpened(false)}>
            Cancelar
          </Button>

          <Button
            onClick={() => {
              setCampusList((previousState) => {
                const duplicatedItem = !!previousState.filter(
                  (item) => item.name === campus,
                ).length

                if (duplicatedItem) return previousState

                return [
                  ...previousState,
                  { id: previousState.length, name: campus },
                ]
              })

              setCampus('')
              setAddCampusDialogOpened(false)
            }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        institution={deleteConfirmation as Institution}
      />
    </>
  )
}
