import { useEffect, useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { ACCOUNT_TYPES } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AddressService } from 'services/address'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type AddProps = {
  commercialUnit?: CommercialUnit
  onSave?: (commercialUnits: CommercialUnit) => Promise<void>
  isEditMode?: boolean
}

export default function Add({
  commercialUnit,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<CommercialUnit>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    CommercialUnit | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!commercialUnit && !formUpdated) {
      reset({
        name: commercialUnit.name,
        document: commercialUnit.document,
        state_registration: commercialUnit.state_registration,
        city_registration: commercialUnit.city_registration,
        fantasy_name: commercialUnit.fantasy_name,
        street: commercialUnit.street,
        number: commercialUnit.number,
        complement: commercialUnit.complement,
        district: commercialUnit.district,
        city: commercialUnit.city,
        uf: commercialUnit.uf,
        zip_code: commercialUnit.zip_code,
        email: commercialUnit.email,
        phone: commercialUnit.phone,
        cell_phone: commercialUnit.cell_phone,
        contact_name: commercialUnit.contact_name,
        account: commercialUnit?.account,
        agency: commercialUnit?.agency,
        bank_code: commercialUnit?.bank_code,
      })

      setValue('account_type', commercialUnit.account_type)
      setValue('type', commercialUnit.type, { shouldValidate: true })
      setFormUpdated(true)
    }
  }, [isEditMode, commercialUnit, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof CommercialUnit) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewCommercialUnit = useMutation({
    mutationFn: async (commercialUnit: CommercialUnit) => {
      if (!commercialUnit.complement) {
        // FIXME: make this information optional
        commercialUnit.complement = ' '
      }

      await ApiService.CommercialUnits.create(commercialUnit)
    },
    onSuccess: (_data, commercialUnit: CommercialUnit) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.COMMERCIAL_UNITS.LIST,
        (oldCommercialUnits) => {
          if (!oldCommercialUnits?.length) return

          return oldCommercialUnits.map((currentCommercialUnit) => {
            if (currentCommercialUnit.id === commercialUnit.id) {
              return commercialUnit
            }

            return currentCommercialUnit
          })
        },
      )

      if (commercialUnit.id) {
        queryClient.setQueryData<CommercialUnit>(
          QUERY_KEYS.COMMERCIAL_UNITS.EDIT(commercialUnit.id.toString()),
          (oldCommercialUnit) => {
            if (!oldCommercialUnit) return
            return commercialUnit
          },
        )
      }

      openSuccessToast({
        message: `A unidade comercial ${commercialUnit.fantasy_name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.COMMERCIAL_UNITS.LIST)
    },
    onError: (error, commercialUnit: CommercialUnit) => {
      const apiError = error as ApiError

      if (apiError?.status === 400) {
        openErrorToast({
          message: `A unidade comercial ${commercialUnit.fantasy_name} já está cadastrada!`,
        })

        return
      }

      openErrorToast({
        message: `Erro ao adicionar ${commercialUnit.fantasy_name}!`,
      })
    },
  })

  if (isEditMode && !!commercialUnit && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.COMMERCIAL_UNITS.LIST}
            >
              Unidade Comercial
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {isEditMode ? 'Editar' : 'Adicionar'} Unidade Comercial
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                {isEditMode && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setDeleteConfirmation(commercialUnit as CommercialUnit)
                      }}
                    >
                      Remover
                    </Button>
                  </Box>
                )}

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.COMMERCIAL_UNITS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((commercialUnit: CommercialUnit) => {
                if (isEditMode) {
                  onSave?.(commercialUnit)
                  return
                }

                addNewCommercialUnit.mutate(commercialUnit)
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              error={!!errors.type?.message}
                              helperText={errors.type?.message}
                              label="Tipo"
                              id="type"
                              onChange={onChange}
                              value={value}
                              autoFill={isEditMode}
                              {...inputProps('type')}
                              options={[
                                {
                                  label: 'Sede',
                                  value: 'headquarter',
                                },
                                {
                                  label: 'Subsidiário',
                                  value: 'subsidiary',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="document"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.document?.message}
                              helperText={errors.document?.message}
                              fullWidth
                              label="CNPJ"
                              id="cnpj"
                              maskType="cnpj"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label="Razão Social"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name', { required: true, minLength: 12 })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.fantasy_name?.message}
                        fullWidth
                        helperText={errors.fantasy_name?.message}
                        label="Nome fantasia"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('fantasy_name')}
                        {...register('fantasy_name', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.state_registration?.message}
                        fullWidth
                        helperText={errors.state_registration?.message}
                        label="Inscrição Estadual"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('state_registration')}
                        {...register('state_registration', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.city_registration?.message}
                        fullWidth
                        helperText={errors.city_registration?.message}
                        label="Inscrição Municipal"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('city_registration')}
                        {...register('city_registration', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.contact_name?.message}
                        fullWidth
                        helperText={errors.contact_name?.message}
                        label="Nome de contato"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('contact_name')}
                        {...register('contact_name')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.cell_phone?.message}
                              helperText={errors.cell_phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="bank_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.bank_code?.message}
                              helperText={errors.bank_code?.message}
                              fullWidth
                              label="Cod. Banco"
                              id="bank_code"
                              maskType="bank_code"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={!!errors.agency?.message}
                        fullWidth
                        helperText={errors.agency?.message}
                        label="Agência"
                        margin="normal"
                        type="number"
                        variant="outlined"
                        {...inputProps('agency')}
                        {...register('agency')}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={!!errors.account?.message}
                        fullWidth
                        helperText={errors.account?.message}
                        label="Conta"
                        margin="normal"
                        type="number"
                        variant="outlined"
                        {...inputProps('account')}
                        {...register('account')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="account_type"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              error={!!errors.account_type?.message}
                              helperText={errors.account_type?.message}
                              label="Tipo de Conta"
                              id="account_type"
                              onChange={onChange}
                              value={value}
                              autoFill={isEditMode}
                              options={ACCOUNT_TYPES}
                              {...inputProps}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="zip_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.zip_code?.message}
                              helperText={errors.zip_code?.message}
                              fullWidth
                              label="CEP"
                              id="cep"
                              maskType="zipCode"
                              onChange={onChange}
                              value={value}
                              onBlur={async () => {
                                const address =
                                  await AddressService.getAddressByCEP(value)

                                if (!address) return

                                setValue('street', address.street, {
                                  shouldValidate: true,
                                })
                                setValue('district', address.neighborhood, {
                                  shouldValidate: true,
                                })
                                setValue('uf', address.state, {
                                  shouldValidate: true,
                                })
                                setValue('city', address.city, {
                                  shouldValidate: true,
                                })
                              }}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.street?.message}
                        fullWidth
                        helperText={errors.street?.message}
                        label="Endereço"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('street')}
                        {...register('street', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.number?.message}
                        fullWidth
                        helperText={errors.number?.message}
                        label="Número"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('number')}
                        {...register('number', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.complement?.message}
                        fullWidth
                        helperText={errors.complement?.message}
                        label="Complemento"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('complement')}
                        {...register('complement', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.district?.message}
                        fullWidth
                        helperText={errors.district?.message}
                        label="Bairro"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('district')}
                        {...register('district', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.city?.message}
                        fullWidth
                        helperText={errors.city?.message}
                        label="Cidade"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('city')}
                        {...register('city', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.uf?.message}
                        fullWidth
                        helperText={errors.uf?.message}
                        label="Estado"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('uf')}
                        {...register('uf', { required: true })}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        commercialUnit={deleteConfirmation as CommercialUnit}
      />
    </>
  )
}
