import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<ItemsBudget>(
    QUERY_KEYS.ITEMS_BUDGET.EDIT(id as string),
    () => ApiService.ItemsBudget.get(id as string),
  )

  const onSave = useCallback(
    async (itemsBudget: ItemsBudget) => {
      try {
        if (!id) {
          openErrorToast({
            message: `Erro ao editar ${itemsBudget.name}!`,
          })

          return
        }

        await ApiService.ItemsBudget.update({
          id: id,
          itemsBudget: {
            name: itemsBudget.name,
            default: itemsBudget.default,
            has_service_order: itemsBudget.has_service_order,
            category_id: itemsBudget.category_id
              ? Number(itemsBudget.category_id)
              : undefined,
            active: !!Number(itemsBudget.active),
          },
        })

        openSuccessToast({
          message: `O item de orçamento ${itemsBudget.name} foi atualizado com sucesso!`,
        })

        navigate(ROUTES.ITEMS_BUDGET.LIST)
      } catch (saveError) {
        openErrorToast({
          message: `Erro ao editar ${itemsBudget.name}!`,
        })
      }
    },
    [id, navigate, openErrorToast, openSuccessToast],
  )

  return <Add itemsBudget={data} onSave={onSave} isEditMode />
}
