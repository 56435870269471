import type { PropsWithChildren } from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GlobalStateProvider } from 'contexts/global-state'
import { CacheService } from 'services/cache'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#002E39',
    },
  },
})

export default function Providers({ children }: PropsWithChildren<unknown>) {
  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStateProvider>
        <QueryClientProvider client={CacheService.queryClient}>
          {children}

          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </GlobalStateProvider>
    </ThemeProvider>
  )
}
