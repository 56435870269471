import Grid from '@mui/material/Grid'

import { AccompanyingTeacherForm } from './AccompanyingTeacherForm'
import { AffiliatesForm } from './AffiliatesForm'
import { ResponsiblesGuidesForm } from './ResponsiblesGuidesForm'
import { TeacherForm } from './TeacherForm'

export function TeachersForm() {
  return (
    <Grid container rowSpacing={3}>
      <ResponsiblesGuidesForm />
      <AffiliatesForm />
      <TeacherForm />
      <AccompanyingTeacherForm />
    </Grid>
  )
}
