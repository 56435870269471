import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const globalParametersRoutes: RouteObject[] = [
  {
    path: ROUTES.GLOBAL_PARAMETERS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddGlobalParameter />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.GLOBAL_PARAMETERS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditGlobalParameter />
      </PrivateRoute>
    ),
  },
]

export default globalParametersRoutes
