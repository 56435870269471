import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Institution>(
    QUERY_KEYS.INSTITUTIONS.EDIT(id as string),
    () => ApiService.Institutions.get(id as string),
  )

  const updateInstitution = useMutation({
    mutationFn: async ({
      id,
      institution,
      campus,
      oldData,
    }: {
      id: string
      institution: InstitutionsApiRequest
      campus?: CampusApiRequest[]
      oldData?: InstitutionApiResponse
    }) => {
      await ApiService.Institutions.update({
        id,
        institution,
      })

      const newCampusNames: string[] = campus?.map((item) => item.name) || []
      const oldCampusNames: string[] =
        oldData?.campusInstitutions?.map((item) => item.name) || []

      const campusRemoved = oldData?.campusInstitutions
        .filter((item) => !newCampusNames.includes(item.name))
        .map((item) => item.id.toString())

      if (campusRemoved?.length) {
        await ApiService.Campus.remove(campusRemoved)
      }

      const campusAdded = campus
        ?.filter((item) => !oldCampusNames.includes(item.name))
        .filter((item) => !!item.name)

      if (campusAdded?.length) {
        await ApiService.Campus.create(campusAdded)
      }
    },
    onSuccess: (_data, { id, institution }) => {
      queryClient.setQueryData<InstitutionsApiRequest>(
        QUERY_KEYS.INSTITUTIONS.EDIT(id),
        (oldInstitution) => {
          if (!oldInstitution) return
          return institution
        },
      )

      openSuccessToast({
        message: `A instituição ${institution.fantasy_name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.INSTITUTIONS.LIST)
    },
    onError: (_error, { institution }) => {
      openErrorToast({
        message: `Erro ao editar ${institution.fantasy_name}!`,
      })
    },
  })

  return (
    <Add
      institution={data}
      isEditMode
      onSave={async (
        institution: InstitutionsApiRequest,
        campus?: CampusApiRequest[],
      ) => {
        await updateInstitution.mutate({
          id: id as string,
          institution,
          campus,
          oldData: data,
        })
      }}
    />
  )
}
