import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'
export * as Permissions from './permissions'

export const getAll = async (): Promise<UserApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<UserApiResponse[]>(
      ENDPOINTS.USERS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<UserApiResponse> => {
  try {
    const getResponse = await apiInstance.get<UserApiResponse>(
      ENDPOINTS.USERS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  user: UsersApiRequest,
): Promise<UserApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<UserApiResponse>(
      ENDPOINTS.USERS.CREATE,
      user,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  user,
}: {
  user: UsersApiRequest
  id: string
}): Promise<UserApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<UserApiResponse>(
      ENDPOINTS.USERS.UPDATE(id),
      user,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(ENDPOINTS.USERS.DELETE(id))

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const changePassword = async ({
  password,
}: {
  password: string
}): Promise<UserApiResponse> => {
  try {
    const updateResponse = await apiInstance.post<UserApiResponse>(
      ENDPOINTS.USERS.CHANGE_PASSWORD,
      { password },
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
