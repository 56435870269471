import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const reservationsRoutes: RouteObject[] = [
  {
    path: ROUTES.RESERVATIONS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Reservations />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.RESERVATIONS.IMPORT,
    element: (
      <PrivateRoute>
        <Pages.ImportReservations />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.RESERVATIONS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewReservations />
      </PrivateRoute>
    ),
  },
]

export default reservationsRoutes
