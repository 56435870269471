import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const pagesRoutes: RouteObject[] = [
  {
    path: ROUTES.PAGES.LIST,
    element: (
      <PrivateRoute>
        <Pages.Pages />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PAGES.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddPages />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PAGES.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditPages />
      </PrivateRoute>
    ),
  },
]

export default pagesRoutes
