import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (role: RolesApiRequest) => Promise<void>

type AddProps = {
  role?: Role
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({ role, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<RolesApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Role | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!role && !formUpdated) {
      reset({
        name: role.name,
        description: role.description,
      })

      setFormUpdated(true)
    }
  }, [isEditMode, role, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof RolesApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewRole = useMutation({
    mutationFn: async (role: RolesApiRequest) => {
      await ApiService.Roles.create(role)
    },
    onSuccess: (_data, role: RolesApiRequest) => {
      openSuccessToast({
        message: `O grupo de usuário ${role.name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.ROLES.LIST)
    },
    onError: (error: ApiError, role: RolesApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${role.name}!`,
      })
    },
  })

  if (isEditMode && !!role && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.ROLES.LIST}>
              Grupos de usuário
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} grupos de usuário
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(role as Role)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.ROLES.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((role: RolesApiRequest) => {
                if (isEditMode) {
                  onSave?.(role)
                  return
                }

                addNewRole.mutate(role)
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name')}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label="Descrição"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('description')}
                        {...register('description')}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Editar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        role={deleteConfirmation as Role}
      />
    </>
  )
}
