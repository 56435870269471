import { useCallback, useState, forwardRef } from 'react'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'

type InputPasswordProps = Omit<OutlinedInputProps, 'endAdornment'> & {
  label?: string
  helperText?: string
}

const InputPassword = forwardRef(function InputPassword(
  {
    label = 'Senha',
    fullWidth = true,
    error,
    helperText,
    ...props
  }: InputPasswordProps,
  ref,
) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prevState) => !prevState)
  }, [])

  return (
    <FormControl
      variant="outlined"
      margin="normal"
      fullWidth={fullWidth}
      error={error}
    >
      <InputLabel htmlFor="password">{label}</InputLabel>

      <OutlinedInput
        ref={ref}
        id="password"
        type={showPassword ? 'text' : 'password'}
        fullWidth={fullWidth}
        label={label}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Alterar visibilidade"
              edge="end"
              onClick={togglePasswordVisibility}
              onMouseDown={(event) => {
                event.preventDefault()
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
})

export default InputPassword
