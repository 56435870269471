import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Permission>(
    QUERY_KEYS.PERMISSIONS.EDIT(id as string),
    () => ApiService.Permissions.get(id as string),
  )

  const updatePermission = useMutation({
    mutationFn: async ({
      id,
      permission,
    }: {
      id: string
      permission: PermissionsApiRequest
    }) => {
      await ApiService.Permissions.update({
        id,
        permission,
      })
    },
    onSuccess: (_data, { id, permission }) => {
      queryClient.setQueryData<PermissionsApiRequest>(
        QUERY_KEYS.PERMISSIONS.EDIT(id),
        (oldPermission) => {
          if (!oldPermission) return
          return permission
        },
      )

      openSuccessToast({
        message: `A permissão ${permission.name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS.LIST)
    },
    onError: (_error, { permission }) => {
      openErrorToast({
        message: `Erro ao editar ${permission.name}!`,
      })
    },
  })

  return (
    <Add
      permission={data}
      isEditMode
      onSave={async (permission: PermissionsApiRequest) =>
        updatePermission.mutate({ id: id as string, permission })
      }
    />
  )
}
