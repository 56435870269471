import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const institutionsRoutes: RouteObject[] = [
  {
    path: ROUTES.VIDEOS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Videos />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.VIDEOS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddVideo />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.VIDEOS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditVideo />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.VIDEOS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewVideo />
      </PrivateRoute>
    ),
  },
]

export default institutionsRoutes
