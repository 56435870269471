import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const reportsRoutes: RouteObject[] = [
  {
    path: ROUTES.REPORTS.SALES,
    element: (
      <PrivateRoute>
        <Pages.SalesReport />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.REPORTS.SALES_AFFILIATES,
    element: (
      <PrivateRoute>
        <Pages.SalesAffiliatesReport />
      </PrivateRoute>
    ),
  },

  {
    path: ROUTES.REPORTS.ACTIVITIES_CONTROL,
    element: (
      <PrivateRoute>
        <Pages.ActivitiesControlReport />
      </PrivateRoute>
    ),
  },

  {
    path: ROUTES.REPORTS.SCHOOL_TRIP,
    element: (
      <PrivateRoute>
        <Pages.SchoolTripReport />
      </PrivateRoute>
    ),
  },

  {
    path: ROUTES.REPORTS.FACULTY_TRIP,
    element: (
      <PrivateRoute>
        <Pages.FacultyTripReport />
      </PrivateRoute>
    ),
  },
]

export default reportsRoutes
