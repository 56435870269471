export const PAYMENTS_STATUS_ORDER_SERVICE: Record<string, string> = {
  awaiting_payment: 'Aguardando pagamento',
  paid: 'Pago',
  cancelled: 'Cancelando',
}
export const PAYMENTS_STATUS_ORDER_SERVICE_OPTIONS = Object.entries(
  PAYMENTS_STATUS_ORDER_SERVICE,
).map(([key, value]) => ({
  label: value,
  value: key,
}))
