import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  pages?: Pages
}

export default function Delete({ opened, closeModal, pages }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removePage = useMutation({
    mutationFn: async (pages: Pages) => {
      await ApiService.Pages.remove(pages.id.toString())

      openSuccessToast({
        message: `A página ${pages?.title} foi removida com sucesso!`,
      })

      navigate(ROUTES.PAGES.LIST)
    },
    onSuccess: (_data, pages: Pages) => {
      queryClient.removeQueries(QUERY_KEYS.PAGES.EDIT(pages.id.toString()))

      openSuccessToast({
        message: `A página ${pages.title} foi removida com sucesso!`,
      })

      navigate(ROUTES.PAGES.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removePage.mutate(pages as Pages)}
      confirmColor="error"
    >
      Deseja realmente remover a página <strong>{`${pages?.title}?`}</strong>
    </ConfirmationModal>
  )
}
