import * as yup from 'yup'

const schema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  fantasy_name: yup.string().required('Campo obrigatório'),
  document: yup.string().required('Campo obrigatório'),
  state_registration: yup.string().required('Campo obrigatório'),
  city_registration: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cell_phone: yup.string().required('Campo obrigatório'),
  zip_code: yup.string().required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  district: yup.string().required('Campo obrigatório'),
  city: yup.string().required('Campo obrigatório'),
  uf: yup.string().required('Campo obrigatório'),
})

export { schema }
