import { QueryClient } from '@tanstack/react-query'
import { USER_SESSION_STORAGE_KEY } from 'constants/keys'

const user = {
  saveCurrentUser: (currentUser: LoginApiResponse) => {
    window.sessionStorage.setItem(
      USER_SESSION_STORAGE_KEY,
      JSON.stringify(currentUser),
    )
  },

  clear: () => {
    window.sessionStorage.removeItem(USER_SESSION_STORAGE_KEY)
  },
}

const queryClient = new QueryClient()

export const CacheService = {
  user,
  queryClient,
}
