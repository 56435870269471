import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const activitiesRoutes: RouteObject[] = [
  {
    path: ROUTES.ACTIVITIES.LIST,
    element: (
      <PrivateRoute>
        <Pages.Activities />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ACTIVITIES.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddActivity />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ACTIVITIES.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditActivity />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ACTIVITIES.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewActivity />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ACTIVITIES.LIST_CALENDAR,
    element: (
      <PrivateRoute>
        <Pages.ListCalendarActivity />
      </PrivateRoute>
    ),
  },
]

export default activitiesRoutes
