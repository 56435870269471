import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Autocomplete } from '@mui/material'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { removeDuplicatedArrayOfObject } from 'utils/removeDuplicatedArrayOfObject'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (
  permissionAssociated: PermissionsAssociatedApiRequest,
) => Promise<void>

type AddProps = {
  permissionAssociated?: PermissionAssociated
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({
  permissionAssociated,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<
    Omit<MUIPermissionsAssociatedApiRequest, 'routes'> & {
      routes: { label: string; value: number | string; id: number }[]
    }
  >({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    PermissionAssociated | undefined
  >()
  const [permissionsRoutes, setPermissionsRoutes] = useState<
    SelectProps['options']
  >([])

  const { data: permissions, isLoading: permissionsIsLoading } = useQuery(
    QUERY_KEYS.PERMISSIONS.NAMES_AND_IDS,
    async () => ApiService.Permissions.getAll(),
  )

  const permissionsGrouped = useMemo(() => {
    return (
      removeDuplicatedArrayOfObject(permissions || [], {
        field: 'grouped',
      }) as { grouped: string }[]
    ).map((item) => ({
      label: item.grouped,
      value: item.grouped,
    }))
  }, [permissions])
  const [permissionsFunction, setPermissionsFunction] = useState<
    SelectProps['options']
  >([])

  const handleSelectFilter = useCallback(
    (
      event: SelectChangeEvent<string>,
      fieldFilter: keyof PermissionApiResponse,
    ) => {
      const filterPermissions = permissions?.filter(
        (permission: PermissionApiResponse) => {
          return (
            permission[fieldFilter]?.toString().toLowerCase() ===
            event.target.value?.toString().toLowerCase()
          )
        },
      )
      return filterPermissions
    },
    [permissions],
  )

  useEffect(() => {
    if (isEditMode && !!permissionAssociated && !formUpdated) {
      reset({
        permission: {
          label: permissionAssociated.permission?.name as string,
          value: permissionAssociated.permission_id,
        },
        routes: permissionAssociated.routesAssociated?.map((route) => {
          return {
            label: route.name,
            id: route.id,
          }
        }),
        grouped: permissionAssociated.permission?.grouped,
        function: permissionAssociated.permission?.function,
      })

      const event = {
        target: {
          value: permissionAssociated.permission?.grouped as string,
        },
      } as SelectChangeEvent<string>

      const filteredOptions = handleSelectFilter(event, 'grouped') || []
      const filtered =
        removeDuplicatedArrayOfObject(filteredOptions, {
          field: 'function',
        }) || []
      setPermissionsFunction(
        filtered
          .filter((item) => item)
          .map((permission) => {
            const permissionCopy = permission as {
              function: string
            }
            return {
              label: permissionCopy.function.toString(),
              value: permissionCopy.function.toString(),
            }
          }) || [],
      )

      const filterPermissionsPerFunction = handleSelectFilter(
        event as SelectChangeEvent<string>,
        'function',
      )?.map((item) => ({
        label: item.name.toString(),
        value: item.id,
      }))
      setPermissionsRoutes(filterPermissionsPerFunction || [])

      setFormUpdated(true)
    }
  }, [
    isEditMode,
    permissionAssociated,
    formUpdated,
    reset,
    setValue,
    handleSelectFilter,
    permissions,
  ])

  const addNewPermissionAssociated = useMutation({
    mutationFn: async (
      permissionAssociated: PermissionsAssociatedApiRequest,
    ) => {
      delete permissionAssociated.grouped
      delete permissionAssociated.function
      await ApiService.PermissionsAssociated.create(permissionAssociated)
    },
    onSuccess: (_data) => {
      openSuccessToast({
        message: `A permissão associada foi adicionada com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS_ASSOCIATED.LIST)
    },
    onError: (error: ApiError) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar permissão associada!`,
      })
    },
  })

  if (
    permissionsIsLoading ||
    (isEditMode && !!permissionAssociated && formUpdated === false)
  ) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            arial-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.PERMISSIONS_ASSOCIATED.LIST}
            >
              Permissões
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} permissões
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(
                        permissionAssociated as PermissionAssociated,
                      )
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.PERMISSIONS_ASSOCIATED.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit(
                (permissionAssociated: MUIPermissionsAssociatedApiRequest) => {
                  delete permissionAssociated.grouped
                  delete permissionAssociated.function
                  const permissionAssociatedFormated = {
                    permission_id: permissionAssociated.permission.value,
                    routes: permissionAssociated.routes.map((item) => item.id),
                  }

                  if (isEditMode) {
                    onSave?.(permissionAssociatedFormated)
                    return
                  }

                  addNewPermissionAssociated.mutate(
                    permissionAssociatedFormated,
                  )
                },
              )}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={6}>
                      <Controller
                        name="grouped"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Grupo"
                              id="grouped"
                              onChange={(event) => {
                                const filteredOptions =
                                  handleSelectFilter(
                                    event as SelectChangeEvent<string>,
                                    'grouped',
                                  ) || []
                                const filtered =
                                  removeDuplicatedArrayOfObject(
                                    filteredOptions,
                                    {
                                      field: 'function',
                                    },
                                  ) || []
                                setPermissionsFunction(
                                  filtered
                                    .filter((item) => item)
                                    .map((permission) => {
                                      const permissionCopy = permission as {
                                        function: string
                                      }
                                      return {
                                        label:
                                          permissionCopy.function.toString(),
                                        value:
                                          permissionCopy.function.toString(),
                                      }
                                    }) || [],
                                )
                                onChange(event)
                              }}
                              value={value}
                              autoFill={isEditMode}
                              options={permissionsGrouped}
                              error={!!errors.grouped?.message}
                              helperText={errors.grouped?.message}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="function"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Função"
                              id="function"
                              onChange={(event) => {
                                const filtered = handleSelectFilter(
                                  event as SelectChangeEvent<string>,
                                  'function',
                                )?.map((item) => ({
                                  label: item.name.toString(),
                                  value: item.id,
                                }))
                                onChange(event)
                                setPermissionsRoutes(filtered || [])
                              }}
                              value={value}
                              autoFill={isEditMode}
                              options={permissionsFunction}
                              error={!!errors.grouped?.message}
                              helperText={errors.grouped?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="permission"
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => {
                          return (
                            <Autocomplete
                              id="permissions-associated-permissions"
                              options={permissionsRoutes}
                              value={value}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              disabledItemsFocusable
                              noOptionsText="Nada encontrado"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Rota principal"
                                  placeholder="Selecione"
                                  margin="normal"
                                  error={!!errors.permission?.message}
                                  helperText={errors.permission?.message}
                                />
                              )}
                              onChange={(_, data) => onChange(data)}
                              {...props}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="routes"
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => {
                          return (
                            <Autocomplete
                              id="permissions-associated-permissions"
                              options={
                                permissions?.map((item) => ({
                                  label: item.name,
                                  id: item.id,
                                })) || []
                              }
                              noOptionsText="Nada encontrado"
                              value={value}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              disabledItemsFocusable
                              multiple
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Rotas auxiliares"
                                  placeholder="Selecione"
                                  margin="normal"
                                  error={!!errors.routes?.message}
                                  helperText={errors.routes?.message}
                                />
                              )}
                              onChange={(_, data) => onChange(data)}
                              {...props}
                            />
                          )
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Editar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        permissionAssociated={deleteConfirmation as PermissionAssociated}
      />
    </>
  )
}
