export const ACTIVITY_TYPES: Record<ActivityType, string> = {
  school: 'Escola',
  faculty: 'Faculdade',
  'nr graduation': 'NR Formatura',
}
export const ACTIVITY_STATUS: Record<ActivityStatus, string> = {
  opened: 'Em Aberto',
  confirmed: 'Confirmada',
  cancelled: 'Cancelada',
  finished: 'Concluída',
}

export const ACTIVITY_TYPES_OPTION: { label: string; value: ActivityType }[] = [
  {
    label: 'Escola',
    value: 'school',
  },
  {
    label: 'Faculdade',
    value: 'faculty',
  },
  {
    label: 'NR Formatura',
    value: 'nr graduation',
  },
]

export const ACTIVITY_STATUS_OPTION: {
  label: string
  value: ActivityStatus
}[] = [
  {
    label: 'Em Aberto',
    value: 'opened',
  },
  {
    label: 'Confirmada',
    value: 'confirmed',
  },
  {
    label: 'Cancelada',
    value: 'cancelled',
  },
  {
    label: 'Concluída',
    value: 'finished',
  },
]
