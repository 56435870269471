import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Budget>(QUERY_KEYS.BUDGETS.EDIT(id as string), () =>
    ApiService.Budgets.get(id as string),
  )

  const updateBudget = useMutation({
    mutationFn: async ({
      id,
      budget,
      hasOpenModal,
    }: {
      id: string
      budget: BudgetsApiRequest
      hasOpenModal?: boolean
    }) => {
      await ApiService.Budgets.update({
        id,
        budget,
      })
    },
    onSuccess: (_data, { id, budget, hasOpenModal }) => {
      queryClient.setQueryData<BudgetsApiRequest>(
        QUERY_KEYS.BUDGETS.EDIT(id),
        (oldBudget) => {
          if (!oldBudget) return
          return budget
        },
      )

      openSuccessToast({
        message: `O orçamento ${budget?.name} foi atualizada com sucesso!`,
      })

      if (!hasOpenModal) {
        navigate(ROUTES.BUDGETS.LIST)
      }
    },
    onError: (_error, { budget }) => {
      openErrorToast({
        message: `Erro ao editar ${budget.name}!`,
      })
    },
  })

  return (
    <Add
      budget={data}
      isEditMode
      onSave={async ({
        hasOpenModal,
        ...budget
      }: BudgetsApiRequest & { hasOpenModal?: boolean }) => {
        await updateBudget.mutate({
          id: id as string,
          budget,
          hasOpenModal,
        })
      }}
    />
  )
}
