import { useCallback, useMemo, useState } from 'react'

import { Sync, WhatsApp } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridFilterItem,
  GridFilterOperator,
  GridFilterInputValueProps,
  GridSortModel,
} from '@mui/x-data-grid'
import ConfirmationModal from 'components/ConfirmationModal'
import DataTable from 'components/DataTable'
import LoadingFullPage from 'components/LoadingFullPage'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useQueryParams } from 'hooks/useQueryParams'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ApiService } from 'services/api'
import { cpfCnpjMask, currencyMask } from 'utils/mask'
import { removeEmptyFieldsInObjectFieldsInObject } from 'utils/removeEmptyFieldsInObject'

import { SelectFilter } from './components/SelectFilter'
import {
  RESERVATION_SITUATIONS_OPTIONS,
  RESERVATION_SITUATION_PAYMENT_TYPES,
  RESERVATION_SITUATION_PAYMENT_TYPES_2,
  RESERVATION_SITUATION_TYPES,
} from './types'

const RESERVATION_SITUATION_PAYMENT_OPTIONS = Object.entries(
  RESERVATION_SITUATION_PAYMENT_TYPES_2,
).map(([key, value]) => ({ label: value, value: key }))

interface FormFilters {
  code?: string
  parentClient?: {
    email?: string
    document?: string
  }
}

const situationOnlyOperators: GridFilterOperator[] = [
  {
    label: 'Opções',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null
      }
      return ({ value }) => {
        return !filterItem.value.length
          ? true
          : value !== null && filterItem.value.includes(value)
      }
    },
    InputComponent: (props: GridFilterInputValueProps) => (
      <SelectFilter {...props} dataOptions={RESERVATION_SITUATIONS_OPTIONS} />
    ),
  },
]
const situationPaymentOnlyOperators: GridFilterOperator[] = [
  {
    label: 'Opções',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null
      }
      return ({ value }) => {
        return !filterItem.value.length
          ? true
          : value !== null && filterItem.value.includes(value)
      }
    },
    InputComponent: (props: GridFilterInputValueProps) => (
      <SelectFilter
        {...props}
        dataOptions={RESERVATION_SITUATION_PAYMENT_OPTIONS}
      />
    ),
  },
]

export default function Reservations() {
  const query = useQueryParams()
  const reactHookFormSearch = useForm<FormFilters>()
  const [isWaitingPayment, setIsWaitingPayment] = useState(false)
  const [searchParams, setSearchParams] = useState<GenericObject[]>(() => {
    const situation = query.getAll('situation')
    const settlement_date_period = query.get('settlement_date_period')
    let start_travel_date_period
    if (
      ['waiting_payment', 'realized'].includes(situation[0]) &&
      ['waiting_payment', 'realized'].includes(situation[1])
    ) {
      setIsWaitingPayment(true)
      const start = moment().format('YYYY-MM-DD')
      const end = moment().add(1, 'year').format('YYYY-MM-DD')

      start_travel_date_period = `${start}to${end}`
    }
    return [
      {
        situation,
        financialMovements: {
          settlement_date_period,
        },
        activity: {
          start_travel_date_period,
        },
      },
    ]
  })
  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [sortOptions, setSortOptions] = useState<GridSortModel>([])

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const [messageModal, setMessageModal] = useState(false)
  const [deadline, setDeadline] = useState(2)
  const [isSending, setIsSending] = useState(false)
  const [
    isReconciliationPagSeguroLoading,
    setIsReconciliationPagSeguroLoading,
  ] = useState(false)

  const onConfirmMessage = useCallback(async () => {
    setIsSending(true)
    await ApiService.Reservations.sendMessage({
      deadline: Number(deadline),
    })
      .then((response) => {
        openSuccessToast({ message: 'A mensagem foi enviada' })
        setIsSending(false)
        setMessageModal(false)
      })
      .catch((error) => {
        const message = error?.message || 'Erro ao enviar mensagem.'
        openErrorToast({ message })
        setIsSending(false)
        setMessageModal(false)
      })
  }, [deadline, openErrorToast, openSuccessToast])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'commercialUnit.fantasy_name',
        headerName: 'Unidade Comercial',
        flex: 1,
        relation: {
          name: 'reservation.commercialUnit',
          alias: 'commercialUnit',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['commercialUnit']>,
        ) => params.row?.commercialUnit?.fantasy_name,
        sortable: false,
      },
      {
        field: 'institution.fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        relation: {
          name: 'reservation.institution',
          alias: 'institution',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => params.row?.institution?.fantasy_name,
      },
      {
        field: 'activity.name',
        headerName: 'Atividade',
        flex: 1,
        relation: {
          name: 'reservation.activity',
          alias: 'activity',
        },
        valueGetter: (params: GridValueGetterParams<Reservation['activity']>) =>
          params.row?.activity?.name,
      },
      {
        field: 'parentClient.fantasy_name',
        headerName: 'Cliente',
        flex: 1,
        relation: {
          name: 'reservation.parentClient',
          alias: 'parentClient',
        },
        renderCell: (props: GridRenderCellParams<Reservation['client']>) => {
          return <span>{props.row?.parentClient?.fantasy_name}</span>
        },
        valueGetter: (params: GridValueGetterParams<Reservation['client']>) => {
          return `${params.row?.parentClient?.id} - ${params.row?.parentClient?.document} - ${params.row?.parentClient?.fantasy_name}`
        },
      },

      {
        field: 'code',
        headerName: 'Código',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Reservation>) =>
          params.row.code,
        sortable: false,
      },
      {
        field: 'payment_value',
        headerName: 'Valor Total',
        valueGetter: (params: GridValueGetterParams<Reservation>) =>
          currencyMask(params.row.payment_value),
        align: 'left',
        headerAlign: 'left',
        type: 'number',
        flex: 1,
        valueFormatter: ({ value }) => currencyMask(value),
      },
      {
        field: 'situation',
        headerName: 'Situação Reserva',
        flex: 1,
        filterOperators: situationOnlyOperators,
        valueFormatter: ({ value }) => {
          return RESERVATION_SITUATION_TYPES[value as ReservationSituation]
        },
        sortable: false,
      },
      {
        field: 'financialMovements',
        customField: 'financialMovements.situation',
        headerName: 'Situação Pagamento',
        flex: 1,
        filterOperators: situationPaymentOnlyOperators,
        relation: {
          name: 'reservation.financialMovements',
          alias: 'financialMovements',
        },
        valueGetter: ({ value }) => {
          return RESERVATION_SITUATION_PAYMENT_TYPES[
            value?.[0]?.situation as ReservationPaymentSituation
          ]
        },
        sortable: false,
      },
      { field: 'created_at', headerName: 'Cadastrado em', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        exclude: true,
        renderCell: (params: GridRenderCellParams<Reservation>) => {
          return (
            <>
              <Link
                style={{ all: 'unset', cursor: 'pointer' }}
                to={ROUTES.RESERVATIONS.VIEW(params.row.id)}
              >
                <SearchIcon />
              </Link>
            </>
          )
        },
      },
    ],
    [],
  )

  const handleFilter = useCallback(() => {
    const data = removeEmptyFieldsInObjectFieldsInObject(
      reactHookFormSearch.getValues() as GenericObject,
    ) as { email: string; code: string; parentClient: { document: string } }
    setSearchParams([
      {
        ...data,
        parentClient: {
          ...data.parentClient,
          document: data.parentClient?.document?.length
            ? cpfCnpjMask(data.parentClient?.document)
            : undefined,
        },
      },
    ])
  }, [reactHookFormSearch])

  const handleReconciliationPagseguroReservations = useCallback(async () => {
    setIsReconciliationPagSeguroLoading(true)
    try {
      await ApiService.Pagseguro.reconciliation()
    } catch (error: unknown) {
      openErrorToast({
        message:
          (error as { message: string }).message ||
          'Ocorreu um erro a conciliar os pagamentos',
      })
    }
    setIsReconciliationPagSeguroLoading(false)
  }, [openErrorToast])

  if (isSending || isReconciliationPagSeguroLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Reservas
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
              marginTop: '20px',
            }}
          >
            <TextField
              label="Código"
              type="text"
              variant="outlined"
              {...reactHookFormSearch.register('code')}
            />
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              {...reactHookFormSearch.register('parentClient.email')}
            />
            <TextField
              label="CPF"
              type="document"
              variant="outlined"
              {...reactHookFormSearch.register('parentClient.document')}
            />

            <Button color="primary" variant="contained" onClick={handleFilter}>
              Pesquisar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setSearchParams([])}
            >
              Todos
            </Button>
            {isWaitingPayment && (
              <Button
                color="success"
                variant="contained"
                onClick={() => setMessageModal(true)}
              >
                Enviar cobrança
                <WhatsApp fontSize="small" style={{ marginLeft: '5px' }} />
              </Button>
            )}
            {isWaitingPayment && (
              <Button
                color="info"
                variant="contained"
                onClick={handleReconciliationPagseguroReservations}
              >
                Conciliar PagSeguro
                <Sync fontSize="small" style={{ marginLeft: '5px' }} />
              </Button>
            )}
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <DataTable
                entity="Reservation"
                source="reservation"
                columns={columns}
                onFilterModelChange={(newValue) => {
                  if (newValue.items[0]?.value !== undefined) {
                    let copy = newValue.items
                    if (
                      newValue.items[0].columnField === 'financialMovements'
                    ) {
                      copy = [
                        {
                          ...copy[0],
                          columnField: 'financialMovements.situation',
                        },
                      ]
                    }
                    setFilterOptions(copy)
                  }
                }}
                onSortModelChange={(newValue) => {
                  setSortOptions(newValue)
                }}
                searchParameters={searchParams}
                likeParameters={filterOptions}
                sortParameters={sortOptions}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ConfirmationModal
        title={`Enviar Mensagem de cobrança`}
        opened={messageModal}
        onClose={() => setMessageModal(false)}
        onConfim={onConfirmMessage}
        onCancel={() => setMessageModal(false)}
      >
        <label htmlFor="message-wpp"></label>
        <TextField
          name="message-wpp"
          label="Dias desde a criação da reserva"
          type="number"
          value={deadline}
          onChange={(e) => setDeadline(Number(e.target.value))}
        ></TextField>
      </ConfirmationModal>
    </>
  )
}
