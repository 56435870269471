import React, { FormEvent } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { IMaskInput } from 'react-imask'

type InputMaskProps = Omit<OutlinedInputProps, 'inputComponent' | 'id'> & {
  id: string
  label: string
  options?: {
    label?: {
      background: {
        color?: string
      }
    }
  }
  maskType:
    | 'cellPhone'
    | 'phone'
    | 'cpf'
    | 'cnpj'
    | 'date'
    | 'zipCode'
    | 'cpfCnpj'
    | 'time'
    | 'bank_code'
  helperText?: string
}

const MASKS: Record<InputMaskProps['maskType'], string | { mask: string }[]> = {
  cellPhone: '(00) 0 0000-0000',
  phone: '(00) 0000-0000',
  cpf: '000.000.000-00',
  cnpj: '00.000.000/0000-00',
  date: '00/00/0000',
  zipCode: '00000-000',
  cpfCnpj: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }],
  time: '00:00',
  bank_code: '000',
}

export default function InputMask({
  id,
  label,
  maskType,
  helperText,
  error,
  options,
  ...props
}: InputMaskProps) {
  return (
    <FormControl fullWidth={props.fullWidth} error={error}>
      <InputLabel
        htmlFor={id}
        shrink={props.value ? true : undefined}
        style={{
          backgroundColor: options?.label?.background.color || 'white',
          padding: '0 5px',
        }}
      >
        {label}
      </InputLabel>

      <OutlinedInput
        {...props}
        id={id}
        inputComponent={({ onChange, ref, ...inputComponentProps }) => {
          return (
            <IMaskInput
              {...inputComponentProps}
              mask={MASKS[maskType] as string}
              definitions={{ '#': /[1-9]/ }}
              inputRef={ref}
              overwrite
              onAccept={(_value, _maskReference, event) => {
                if (!event) return

                onChange?.(
                  event as unknown as FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                )

                setTimeout(() => {
                  const currentInput = document.querySelector(
                    `input[id="${id}"]`,
                  ) as HTMLInputElement

                  currentInput?.focus()
                }, 50)
              }}
            />
          )
        }}
      />

      <FormHelperText id={`${id}"-helper-text"`}>{helperText}</FormHelperText>
    </FormControl>
  )
}
