import { useEffect, useRef } from 'react'

import { GridRenderEditCellParams } from '@mui/x-data-grid'
import DatePicker from 'components/DatePicker'

export const CustomEditDatePickerField = ({
  id,
  value,
  field,
  row,
  handleCal,
  activityTask,
  setActivityTask,
  error,
  onChange,
  hasFocus,
  cellMode,
  onBlur,
  ...rest
}: GridRenderEditCellParams & {
  handleCal?: (data: ActivityTaskItemsForm[]) => void
  onBlur?: () => void
  setActivityTask: React.Dispatch<React.SetStateAction<ActivityTaskItemsForm[]>>
  activityTask: ActivityTaskItemsForm[]
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef.current && row?.isNew && field === 'unit_value') {
      inputRef.current.focus()
    }
  }, [field, row?.isNew])

  return (
    <DatePicker
      fullWidth
      label=""
      id=""
      value={value}
      onChange={(event) => {
        onChange?.(event)
      }}
      inputSettings={{
        variant: 'standard',
        InputProps: { disableUnderline: true },
        sx: {
          '& .MuiInputBase-root': {
            padding: '5px !important',
          },
        },
      }}
      error={error}
    />
  )
}
