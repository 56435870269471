import { useMemo, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

export default function View() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { data, isLoading } = useQuery<SupplierViewPageApiResponse>(
    QUERY_KEYS.SUPPLIERS.VIEW_PAGE(id as string),
    () => ApiService.Suppliers.getViewPage(id as string),
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    SupplierApiResponse | undefined
  >()

  const isTypePF = useMemo(() => {
    return data?.type === 'pf'
  }, [data?.type])

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {data?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.SUPPLIERS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.SUPPLIERS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel
                label="Unidade Comercial"
                value={data?.commercialUnit?.fantasy_name}
              />

              <TextWithLabel
                label={isTypePF ? 'Nome' : 'Razão Social'}
                value={data?.name}
              />

              {!isTypePF && (
                <TextWithLabel
                  label="Nome fantasia"
                  value={data?.fantasy_name}
                />
              )}
              <TextWithLabel
                label="Categoria"
                value={
                  data?.categories?.length ? data?.categories : 'Sem fornecedor'
                }
              />

              <TextWithLabel
                label="Tipo"
                value={isTypePF ? 'Pessoa Física' : 'Pessoa Jurídica'}
              />

              <TextWithLabel
                label="Nome de contato"
                value={data?.contact_name}
              />

              <TextWithLabel
                label={isTypePF ? 'CPF' : 'CNPJ'}
                value={data?.document}
              />

              {!isTypePF && (
                <TextWithLabel
                  label="Inscrição Estadual"
                  value={data?.state_registration}
                />
              )}

              {!isTypePF && (
                <TextWithLabel
                  label="Inscrição Municipal"
                  value={data?.city_registration}
                />
              )}

              <TextWithLabel label="Email" value={data?.email} />

              {!isTypePF && (
                <TextWithLabel label="Website" value={data?.website} />
              )}

              <TextWithLabel label="Telefone" value={data?.phone} />

              <TextWithLabel label="Celular" value={data?.cell_phone} />

              <TextWithLabel
                label="Endereço"
                value={`${data?.street}, ${data?.number}`}
              />

              <TextWithLabel label="Complemento" value={data?.complement} />

              <TextWithLabel label="Bairro" value={data?.district} />

              <TextWithLabel
                label="Cidade"
                value={`${data?.city}/${data?.uf}`}
              />

              <TextWithLabel label="CEP" value={data?.zip_code} />
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        supplier={deleteConfirmation as SupplierApiResponse}
      />
    </>
  )
}
