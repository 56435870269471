import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<CampusApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<CampusApiResponse[]>(
      ENDPOINTS.CAMPUS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((campus) => ({
      label: campus.name,
      value: campus.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllByInstitution = async (
  instutionId: number,
): Promise<Campus[]> => {
  try {
    const getAllResponse = await apiInstance.get<CampusApiResponse[]>(
      ENDPOINTS.CAMPUS.NAME_AND_IDS_BY_INSTITUTION(instutionId),
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<CampusApiResponse> => {
  try {
    const getResponse = await apiInstance.get<CampusApiResponse>(
      ENDPOINTS.CAMPUS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  campus: CampusApiRequest[],
): Promise<CampusApiResponse[]> => {
  try {
    const creationResponse = await Promise.all(
      campus.map((item) =>
        apiInstance.post<CampusApiResponse>(ENDPOINTS.CAMPUS.CREATE, item),
      ),
    )

    return creationResponse.map((response) => response.data)
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  campus,
}: {
  campus: CampusApiRequest
  id: string
}): Promise<CampusApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<CampusApiResponse>(
      ENDPOINTS.CAMPUS.UPDATE(id),
      campus,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (ids: string[]) => {
  try {
    const deleteResponse = await Promise.all(
      ids.map((id) => apiInstance.delete(ENDPOINTS.CAMPUS.DELETE(id))),
    )

    return deleteResponse.map((response) => response.data)
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
