import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  institution?: Institution
}

export default function Delete({
  opened,
  closeModal,
  institution,
}: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeInstitution = useMutation({
    mutationFn: async (institution: Institution) => {
      await ApiService.Institutions.remove(institution.id.toString())

      openSuccessToast({
        message: `A instituição ${institution?.fantasy_name} foi removida com sucesso!`,
      })

      navigate(ROUTES.INSTITUTIONS.LIST)
    },
    onSuccess: (_data, institution: Institution) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.INSTITUTIONS.LIST,
        (oldInstitutions) => {
          if (!oldInstitutions?.length) return

          return oldInstitutions.filter(
            (oldInstitution) => oldInstitution.id !== institution.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(institution.id.toString()),
      )

      openSuccessToast({
        message: `A instituição ${institution.fantasy_name} foi removida com sucesso!`,
      })

      navigate(ROUTES.INSTITUTIONS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeInstitution.mutate(institution as Institution)}
      confirmColor="error"
    >
      Deseja realmente remover a instituição{' '}
      <strong>{`${institution?.fantasy_name}?`}</strong>
    </ConfirmationModal>
  )
}
