import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  budget?: Budget
}

export default function Delete({ opened, closeModal, budget }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeBudget = useMutation({
    mutationFn: async (budget: Budget) => {
      await ApiService.Budgets.remove(budget.id.toString())

      openSuccessToast({
        message: `O orçamento ${budget?.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.BUDGETS.LIST)
    },
    onSuccess: (_data, budget: Budget) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.BUDGETS.LIST,
        (oldBudgets) => {
          if (!oldBudgets?.length) return

          return oldBudgets.filter(
            (oldBudget) => Number(oldBudget.id) !== Number(budget.id),
          )
        },
      )

      queryClient.removeQueries(QUERY_KEYS.BUDGETS.EDIT(budget.id.toString()))

      openSuccessToast({
        message: `O orçamento ${budget?.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.BUDGETS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeBudget.mutate(budget as Budget)}
      confirmColor="error"
    >
      Deseja realmente remover o orçamento <strong>{`${budget?.name}?`}</strong>
    </ConfirmationModal>
  )
}
