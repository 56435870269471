import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  forwardRef,
} from 'react'
import type { PropsWithChildren } from 'react'

import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import { USER_SESSION_STORAGE_KEY } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'

import * as Types from './types'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  reference,
) {
  return <MuiAlert elevation={6} ref={reference} variant="filled" {...props} />
})

const GlobalContext = createContext<Types.GlobalState>({} as Types.GlobalState)

export const GlobalStateProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [user, setUser] = useState<User>()
  const [menus, setMenus] = useState<Menu[]>([])
  const [changePasswordOpened, setChangePasswordOpened] =
    useState<boolean>(false)
  const logged = !!user

  const [snackbarSettings, setSnackbarSettings] = useState<
    SnackbarProps & Pick<AlertProps, 'severity'>
  >({
    open: false,
  } as SnackbarProps)

  const closeChangePasswordModal = useCallback(() => {
    setChangePasswordOpened(false)
  }, [])

  const openChangePasswordModal = useCallback(() => {
    setChangePasswordOpened(true)
  }, [])

  const closeToastMessage = useCallback(() => {
    setSnackbarSettings({ open: false })
  }, [])

  const openToast = useCallback(
    (props: Types.OpenToastMessage & Pick<AlertProps, 'severity'>) => {
      setSnackbarSettings({
        open: true,
        autoHideDuration: 6_000,
        onClose: closeToastMessage,
        severity: 'success',
        ...props,
      })
    },
    [closeToastMessage],
  )

  const openSuccessToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: 'success', ...props })
    },
    [openToast],
  )

  const openErrorToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: 'error', ...props })
    },
    [openToast],
  )

  const openWarningToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: 'warning', ...props })
    },
    [openToast],
  )

  const openInfoToast = useCallback(
    (props: Types.OpenToastMessage) => {
      openToast({ severity: 'info', ...props })
    },
    [openToast],
  )

  const saveUser = useCallback((currentUser: LoginApiResponse['user']) => {
    setUser(currentUser)
  }, [])

  const saveMenus = useCallback((menus: Menu[]) => {
    setMenus(menus)
  }, [])

  const logout = useCallback(() => {
    CacheService.user.clear()
    window.location.href = ROUTES.LOGIN
  }, [])

  useEffect(() => {
    const cachedUser = sessionStorage.getItem(USER_SESSION_STORAGE_KEY)

    if (cachedUser) {
      const currentUser = JSON.parse(cachedUser) as LoginApiResponse
      setUser(currentUser.user)
      setMenus(currentUser.menus)
      ApiService.persistToken(currentUser.token)
      // TODO: handle router here
      return
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        user,
        saveUser,
        menus,
        saveMenus,
        logged,
        logout,
        openSuccessToast,
        openErrorToast,
        openWarningToast,
        openInfoToast,
        closeChangePasswordModal,
        openChangePasswordModal,
        changePasswordOpened,
      }}
    >
      {children}

      <Snackbar {...snackbarSettings}>
        <Alert
          onClose={closeToastMessage}
          severity={snackbarSettings.severity}
          sx={{ width: '100%' }}
        >
          {snackbarSettings.message}
        </Alert>
      </Snackbar>
    </GlobalContext.Provider>
  )
}

export const useGlobalState = () => {
  const context = useContext(GlobalContext)

  if (!context) {
    throw new Error(
      'useGlobalState should be encapsuled inside GlobalContextProvider',
    )
  }

  return useContext(GlobalContext)
}
