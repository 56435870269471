import { useEffect, useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery } from '@tanstack/react-query'
import DatePicker from 'components/DatePicker'
import InputCurrency from 'components/InputCurrency'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { SELECT_BRAZILIAN_STATES } from 'constants/brazilian-states'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AddressService } from 'services/address'
import { ApiService } from 'services/api'
import { formatDate } from 'utils/formatDate'
import { currencyMask, monetaryToNumber } from 'utils/mask'

import Delete from './delete'
import { schema } from './form-validation'
import {
  COLLABORATOR_HIERARCHICAL_LEVEL,
  COLLABORATORS_SECTOR,
  COLLABORATORS_TYPE,
} from './types'

type OnSave = (collaborator: CollaboratorApiRequest) => Promise<void>

type AddProps = {
  collaborator?: Collaborator
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({
  collaborator,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<CollaboratorApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Collaborator | undefined
  >()

  const typeTeam = watch('type')

  useEffect(() => {
    if (isEditMode && !!collaborator && !formUpdated) {
      reset({
        name: collaborator.name,
        cpf: collaborator.cpf,
        rg: collaborator.rg,
        rg_state_issue: collaborator.rg_state_issue,
        birth_date: collaborator.birth_date,
        email: collaborator.email,
        phone: collaborator.phone,
        cell_phone: collaborator.cell_phone,
        login: collaborator.login,
        password: collaborator.password,
        type: collaborator.type,
        account: collaborator.account,
        agency: collaborator.agency,
        bank_code: collaborator.bank_code,
        account_type: collaborator.account_type,
      })

      setValue(
        'commercial_unit_id',
        collaborator.commercial_unit_id?.toString(),
      )

      setValue('type', collaborator.type)

      if (collaborator.type === 'employee') {
        setValue('sector', collaborator.sector)
        setValue(
          'hierarchical_Level',
          collaborator.hierarchical_Level.toLowerCase() as CollaboratorHierarchyLevel,
        )
        setValue('seller', collaborator.seller.toString() as unknown as 0 | 1)
        setValue('admission_date', collaborator.admission_date)
        setValue('dismiss_date', collaborator.dismiss_date)
        setValue('monthly_salary', currencyMask(collaborator.monthly_salary))
        setValue('worked_times', collaborator.worked_times)
        setValue('worked_days', collaborator.worked_days)
        setValue('zip_code', collaborator.zip_code)
        setValue('street', collaborator.street)
        setValue('number', collaborator.number)
        setValue('complement', collaborator.complement)
        setValue('district', collaborator.district)
        setValue('city', collaborator.city)
        setValue('uf', collaborator.uf)
      }
      if (collaborator.type === 'guide') {
        setValue(
          'view_location',
          collaborator.view_location?.toString() as unknown as 0 | 1,
        )

        setValue(
          'capture_location',
          collaborator.capture_location?.toString() as unknown as 0 | 1,
        )

        setValue('geo_code', collaborator.geo_code)
      }
      if (collaborator.type === 'monitor') {
        setValue(
          'view_location',
          collaborator.view_location?.toString() as unknown as 0 | 1,
        )

        setValue(
          'capture_location',
          collaborator.capture_location?.toString() as unknown as 0 | 1,
        )

        setValue('geo_code', collaborator.geo_code)
      }
      setFormUpdated(true)
    }
  }, [isEditMode, collaborator, formUpdated, reset, setValue])

  useEffect(() => {
    if (!isEditMode) {
      if (typeTeam === 'guide') {
        setValue('view_location', '0' as unknown as 0)

        setValue('capture_location', '1' as unknown as 1)
      }
      if (typeTeam === 'monitor') {
        setValue('view_location', '0' as unknown as 0)

        setValue('capture_location', '1' as unknown as 1)
      }
      setFormUpdated(true)
    }
  }, [isEditMode, collaborator, formUpdated, reset, setValue, typeTeam])

  const { data: commercialUnits } = useQuery<SelectProps['options']>(
    QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
    () =>
      ApiService.CommercialUnits.getAllNamesAndIds({
        fields: ['id', 'fantasy_name', 'document'],
        relations: [],
      }),
  )

  const inputProps = useCallback(
    (fieldName: keyof CollaboratorApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewCollaborator = useMutation({
    mutationFn: async (collaborator: CollaboratorApiRequest) => {
      setIsLoading(true)
      if (collaborator.birth_date) {
        collaborator.birth_date = formatDate({
          date: collaborator.birth_date,
          inputFormat: INPUT_MASK.DATE_LOCALE,
          outputFormat: INPUT_MASK.DATE_EUA,
        })
      }

      if (collaborator.admission_date) {
        collaborator.admission_date = formatDate({
          date: collaborator.admission_date,
          inputFormat: INPUT_MASK.DATE_LOCALE,
          outputFormat: INPUT_MASK.DATE_EUA,
        })
      }

      if (collaborator.dismiss_date) {
        collaborator.dismiss_date = formatDate({
          date: collaborator.dismiss_date,
          inputFormat: INPUT_MASK.DATE_LOCALE,
          outputFormat: INPUT_MASK.DATE_EUA,
        })
      }

      await ApiService.Collaborators.create(collaborator)
    },
    onSuccess: (_data, collaborator: CollaboratorApiRequest) => {
      setIsLoading(false)
      openSuccessToast({
        message: `O(a) ${collaborator.name} colaborador foi adicionado(a) com sucesso!`,
      })

      navigate(ROUTES.COLLABORATORS.LIST)
    },
    onError: (error: ApiError, collaborator: CollaboratorApiRequest) => {
      setIsLoading(false)
      openErrorToast({
        message:
          error.message || `Erro ao adicionar o(a) ${collaborator.name}!`,
      })
    },
  })

  if (isEditMode && !!collaborator && formUpdated === false) {
    return <LoadingFullPage />
  }

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.COLLABORATORS.LIST}
            >
              Colaboradores
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(ROUTES.COLLABORATORS.LIST)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} Colaborador
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(collaborator as Collaborator)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.COLLABORATORS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit(
                (collaborator: CollaboratorApiRequest) => {
                  collaborator.monthly_salary = monetaryToNumber(
                    collaborator.monthly_salary as string,
                  )
                  if (isEditMode) {
                    onSave?.(collaborator)
                    return
                  }

                  addNewCollaborator.mutate(collaborator)
                },
                (a) => globalThis.console.log(a),
              )}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="commercial_unit_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Unidade comercial"
                              id="commercial_unit_id"
                              onChange={onChange}
                              value={value}
                              options={commercialUnits || []}
                              autoFill={isEditMode}
                              error={!!errors.commercial_unit_id?.message}
                              helperText={errors.commercial_unit_id?.message}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Tipo"
                              id="type"
                              onChange={onChange}
                              value={value}
                              options={COLLABORATORS_TYPE}
                              autoFill={isEditMode}
                              error={!!errors.type?.message}
                              helperText={errors.type?.message}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message as string}
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name')}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cpf"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('cpf')}
                              error={!!errors.cpf?.message}
                              helperText={errors.cpf?.message}
                              fullWidth
                              label="CPF"
                              id="cpf"
                              maskType="cpf"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.rg?.message}
                        helperText={errors.rg?.message}
                        fullWidth
                        label="RG"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('rg')}
                        {...register('rg')}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Controller
                        name="rg_state_issue"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Estado de emissão do RG"
                              options={SELECT_BRAZILIAN_STATES}
                              id="rg_state_issue"
                              onChange={onChange}
                              value={value}
                              autoFill={isEditMode}
                              error={!!errors.rg_state_issue?.message}
                              helperText={errors.rg_state_issue?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="birth_date"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <DatePicker
                              error={!!errors.birth_date?.message}
                              helperText={errors.birth_date?.message}
                              fullWidth
                              label="Data de nascimento"
                              id="birth_date"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('phone')}
                              error={!!errors.phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('cell_phone')}
                              error={!!errors.cell_phone?.message}
                              helperText={errors.cell_phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message as string}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email')}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="bank_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.bank_code?.message}
                              helperText={errors.bank_code?.message}
                              fullWidth
                              label="Cod. Banco"
                              id="bank_code"
                              maskType="bank_code"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.agency?.message}
                        fullWidth
                        helperText={errors.agency?.message}
                        label="Agência"
                        margin="normal"
                        type="number"
                        variant="outlined"
                        {...inputProps('agency')}
                        {...register('agency')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.account?.message}
                        fullWidth
                        helperText={errors.account?.message}
                        label="Conta"
                        margin="normal"
                        type="number"
                        variant="outlined"
                        {...inputProps('account')}
                        {...register('account')}
                      />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    {watch('type') === 'employee' && (
                      <>
                        <Grid item xs={3}>
                          <Controller
                            name="sector"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors.sector?.message}
                                  helperText={errors.sector?.message}
                                  label="Setor"
                                  id="sector"
                                  onChange={onChange}
                                  value={value}
                                  autoFill={isEditMode}
                                  {...inputProps('sector')}
                                  options={COLLABORATORS_SECTOR.map(
                                    (collaboratorSector) => ({
                                      label: collaboratorSector.portuguese,
                                      value: collaboratorSector.english,
                                    }),
                                  )}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="hierarchical_Level"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Nível Hierárquico"
                                  id="hierarchical_Level"
                                  onChange={onChange}
                                  value={value}
                                  autoFill={isEditMode}
                                  error={!!errors.hierarchical_Level?.message}
                                  helperText={
                                    errors.hierarchical_Level?.message
                                  }
                                  {...inputProps('hierarchical_Level')}
                                  options={COLLABORATOR_HIERARCHICAL_LEVEL.map(
                                    (hierarchicalLevel) => ({
                                      label: hierarchicalLevel.portuguese,
                                      value: hierarchicalLevel.english,
                                    }),
                                  )}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                          <Controller
                            name="admission_date"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <DatePicker
                                  error={!!errors.admission_date?.message}
                                  helperText={errors.admission_date?.message}
                                  fullWidth
                                  label="Data de Admissão"
                                  id="admission_date"
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                          <Controller
                            name="dismiss_date"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <DatePicker
                                  error={!!errors.dismiss_date?.message}
                                  helperText={errors.dismiss_date?.message}
                                  fullWidth
                                  label="Data de demissão"
                                  id="dismiss_date"
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={!!errors.worked_times?.message}
                            helperText={errors.worked_times?.message}
                            fullWidth
                            label="Horas trabalhadas/dia"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            {...inputProps('worked_times')}
                            {...register('worked_times')}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="seller"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors.seller?.message}
                                  helperText={errors.seller?.message}
                                  label="Vendedor"
                                  id="seller"
                                  onChange={onChange}
                                  value={value}
                                  autoFill={isEditMode}
                                  {...inputProps('seller')}
                                  options={[
                                    {
                                      label: 'Sim',
                                      value: '1',
                                    },
                                    {
                                      label: 'Não',
                                      value: '0',
                                    },
                                  ]}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="monthly_salary"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputCurrency
                                  error={!!errors.monthly_salary?.message}
                                  helperText={errors.monthly_salary?.message}
                                  fullWidth
                                  label="Salário Mensal"
                                  type="text"
                                  id="monthly_salary"
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={!!errors.worked_days?.message}
                            helperText={errors.worked_days?.message}
                            fullWidth
                            label="Dias trabalhados na semana"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            {...inputProps('worked_days')}
                            {...register('worked_days')}
                          />
                        </Grid>
                        <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                          <Controller
                            name="zip_code"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputMask
                                  {...inputProps('zip_code')}
                                  error={!!errors.zip_code?.message}
                                  helperText={errors.zip_code?.message}
                                  fullWidth
                                  label="CEP"
                                  id="zip_code"
                                  maskType="zipCode"
                                  onChange={onChange}
                                  value={value}
                                  onBlur={async () => {
                                    const address =
                                      await AddressService.getAddressByCEP(
                                        value,
                                      )

                                    if (!address) return

                                    setValue('street', address.street, {
                                      shouldValidate: true,
                                    })
                                    setValue('district', address.neighborhood, {
                                      shouldValidate: true,
                                    })
                                    setValue('uf', address.state, {
                                      shouldValidate: true,
                                    })
                                    setValue('city', address.city, {
                                      shouldValidate: true,
                                    })
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.street?.message}
                            fullWidth
                            helperText={errors.street?.message as string}
                            label="Endereço"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('street')}
                            {...register('street')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.number?.message}
                            fullWidth
                            helperText={errors.number?.message as string}
                            label="Número"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('number')}
                            {...register('number', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.complement?.message}
                            fullWidth
                            helperText={errors.complement?.message}
                            label="Complemento"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('complement')}
                            {...register('complement', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.district?.message}
                            fullWidth
                            helperText={errors.district?.message as string}
                            label="Bairro"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('district')}
                            {...register('district')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.uf?.message}
                            fullWidth
                            helperText={errors.uf?.message as string}
                            label="Cidade"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('city')}
                            {...register('city')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.uf?.message}
                            fullWidth
                            helperText={errors.uf?.message as string}
                            label="Estado"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('uf')}
                            {...register('uf')}
                          />
                        </Grid>
                      </>
                    )}
                    {['guide', 'monitor'].includes(watch('type')) && (
                      <>
                        <Grid item xs={3}>
                          <Controller
                            name="view_location"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors.view_location?.message}
                                  helperText={errors.view_location?.message}
                                  label="Visualiza localização"
                                  id="view_location"
                                  onChange={onChange}
                                  value={value}
                                  autoFill={isEditMode}
                                  {...inputProps('view_location')}
                                  options={[
                                    {
                                      label: 'Sim',
                                      value: '1',
                                    },
                                    {
                                      label: 'Não',
                                      value: '0',
                                    },
                                  ]}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="capture_location"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors.capture_location?.message}
                                  helperText={errors.capture_location?.message}
                                  label="Captura localização"
                                  id="capture_location"
                                  onChange={onChange}
                                  value={value}
                                  autoFill={isEditMode}
                                  {...inputProps('capture_location')}
                                  options={[
                                    {
                                      label: 'Sim',
                                      value: '1',
                                    },
                                    {
                                      label: 'Não',
                                      value: '0',
                                    },
                                  ]}
                                />
                              )
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Box
                    style={{
                      display: 'block',
                      marginTop: '1em',
                      width: 'calc(100% - 24px)',
                    }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        collaborator={deleteConfirmation as Collaborator}
      />
    </>
  )
}
