import { QUERY_KEYS } from 'constants/keys'
import { STATUS_ORDER_SERVICE_ENUM } from 'constants/order-service-status'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'

import { SendOrderServiceToSupplier } from '../@types/form'

type HandleSendOrderServiceClickProps = {
  onLoading: (loadingState: boolean) => void
  onSubmit?: () => void
  openErrorToast: (props: { message: string }) => void
  openSuccessToast: (props: { message: string }) => void
  orderService: OrderServiceWithItemsApiResponse
  itemBudget?: ActivityChecklistItemsForm
  sendTo: SendOrderServiceToSupplier['sendTo']
}

export async function handleSendOrderServiceClick({
  onLoading,
  onSubmit,
  openErrorToast,
  openSuccessToast,
  orderService,
  itemBudget,
  sendTo,
}: HandleSendOrderServiceClickProps) {
  if (!orderService) {
    return
  }
  onLoading(true)
  try {
    if (orderService.status === STATUS_ORDER_SERVICE_ENUM.NEW) {
      openErrorToast({
        message: 'Ordem de serviço ainda não foi emitida',
      })
      return
    }
    await ApiService.OrderServices.sendOrderServiceToSupplier(
      orderService.id.toString(),
      {
        sendTo,
      },
    )
    if (!itemBudget) {
      CacheService.queryClient.invalidateQueries(
        QUERY_KEYS.ORDER_SERVICES.EDIT(orderService.id.toString()),
      )
    }
    if (itemBudget) {
      CacheService.queryClient.invalidateQueries(
        QUERY_KEYS.ORDER_SERVICES.GET_WITH_ITEMS_BY_ACTIVITY_CHECKLIST_ID(
          (itemBudget.id as number).toString(),
        ),
      )
    }
    onSubmit?.()

    openSuccessToast({
      message: 'Ordem de serviço enviado com sucesso',
    })
  } catch (error) {
    openErrorToast({
      message: 'Ocorreu um erro ao enviar a ordem de serviço, tente novamente',
    })
  } finally {
    onLoading(false)
  }
}
