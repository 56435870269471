import { useState, useMemo, useCallback } from 'react'

import { Mail } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LinkIcon from '@mui/icons-material/Link'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import LoadingFullPage from 'components/LoadingFullPage'
import LoadingFullPageWithOverlay from 'components/LoadingFullPageWithOverlay'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import usePagseguro from 'hooks/usePagseguro'
import moment from 'moment'
import { SelectFilter } from 'pages/reservations/components/SelectFilter'
import {
  RESERVATION_SITUATION_PAYMENT_TYPES,
  RESERVATION_SITUATION_PAYMENT_TYPES_2,
  RESERVATION_SITUATION_TYPES,
  RESERVATION_SITUATIONS_OPTIONS,
} from 'pages/reservations/types'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'
import { encodedBase64 } from 'utils/base64'
import formatCurrencyUnit from 'utils/formatCurrencyUnit'
import { currencyMask } from 'utils/mask'

import { AddClass } from './components/AddClass'
import { FinishedButton } from './components/FinishedButton'
import { ReleasedPayment } from './components/ReleasedPayment'
import { UpdatePeriodButton } from './components/UpdatePeriodButton'
import Delete from './delete'
import { ACTIVITY_STATUS, ACTIVITY_TYPES } from './types'

enum TABS {
  RESPONSIBLE_GUIDES,
  AFFILIATES,
  TEACHERS,
  ACCOMPANYING_TEACHER,
  CLASS,
  RESERVATIONS,
}

const RESERVATION_SITUATION_PAYMENT_OPTIONS = Object.entries(
  RESERVATION_SITUATION_PAYMENT_TYPES_2,
).map(([key, value]) => ({ label: value, value: key }))

const situationOnlyOperators: GridFilterOperator[] = [
  {
    label: 'Opções',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null
      }
      return ({ value }) => {
        return !filterItem.value.length
          ? true
          : value !== null && filterItem.value.includes(value)
      }
    },
    InputComponent: (props: GridFilterInputValueProps) => (
      <SelectFilter {...props} dataOptions={RESERVATION_SITUATIONS_OPTIONS} />
    ),
  },
]

const situationPaymentOnlyOperators: GridFilterOperator[] = [
  {
    label: 'Opções',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null
      }
      return ({ value }) => {
        return !filterItem.value.length
          ? true
          : value !== null && filterItem.value.includes(value)
      }
    },
    InputComponent: (props: GridFilterInputValueProps) => (
      <SelectFilter
        {...props}
        dataOptions={RESERVATION_SITUATION_PAYMENT_OPTIONS}
      />
    ),
  },
]

export default function View() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const [currentTab, setCurrentTab] = useState<TABS>(TABS.TEACHERS)

  const { cancelActivity } = usePagseguro()

  const reactHookFormClassActivity = useForm<{
    id: string
    name: string
    activity_id: number
  }>()

  const {
    data: classActivity,
    isLoading: isLoadingClassActivity,
    refetch: refetchClassActivity,
  } = useQuery<ClassesActivities[]>(
    QUERY_KEYS.CLASSES_ACTIVITIES.GET_BY_ACTIVITY(id as string),
    () => ApiService.ClassesActivities.getByActivity(id as string),
  )

  const {
    data: activity,
    isLoading: activityLoading,
    refetch,
  } = useQuery<Activity>({
    queryKey: QUERY_KEYS.ACTIVITIES.VIEW(id as string),
    queryFn: async () => await ApiService.Activities.get(id as string),
  })

  const { data: reservations, isLoading: reservationsLoading } = useQuery<
    ReservationApiResponse[]
  >({
    queryKey: QUERY_KEYS.RESERVATIONS.LIST_BY_ACTIVITY(id as string),
    queryFn: async () =>
      await ApiService.Reservations.getAllByActivity({
        activity_id: id as string,
      }),
  })

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Activity | undefined
  >()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const [editModalClass, setEditModalClass] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [cancelReason, setCancelReason] = useState('')
  const [isReversePayment, setIsReversePayment] = useState(true)

  const [sendingCertMail, setSendingCertMail] = useState(false)
  const [sendingMail, setSendingMail] = useState(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [removeResponsibleGuideLoading, setRemoveResponsibleGuideLoading] =
    useState(false)
  const [removeResponsibleGuide, setRemoveResponsibleGuide] =
    useState<ResponsibleGuide | null>()
  const [removeResponsibleGuideModal, setRemoveResponsibleGuideModal] =
    useState(false)

  const onConfirmCancel = useCallback(async () => {
    setIsCanceling(true)
    await cancelActivity({
      id: id || '',
      message: cancelReason,
      isReversePayment,
    })
      .then((response) => {
        const message = response.userMessage
        if (message.includes('Atividade não cancelada')) {
          openErrorToast({ message })
        } else {
          openSuccessToast({ message })
        }
        refetch()
        setIsCanceling(false)
        setCancelModal(false)
      })
      .catch(() => {
        openErrorToast({ message: 'Erro ao cancelar atividade.' })
        setIsCanceling(false)
        setCancelModal(false)
      })
  }, [
    cancelReason,
    isReversePayment,
    cancelActivity,
    id,
    openErrorToast,
    openSuccessToast,
    refetch,
  ])
  const onCanceledRemoveResponsibleGuide = useCallback(() => {
    setRemoveResponsibleGuide(null)
    setRemoveResponsibleGuideModal(false)
  }, [])
  const onConfirmRemoveResponsibleGuide = useCallback(async () => {
    if (!removeResponsibleGuide?.id) return
    setRemoveResponsibleGuideLoading(true)
    try {
      await ApiService.ResponsibleGuides.remove({
        id: removeResponsibleGuide?.id,
      })
      CacheService.queryClient.invalidateQueries(
        QUERY_KEYS.ACTIVITIES.VIEW(id as string),
      )
      onCanceledRemoveResponsibleGuide()
      openSuccessToast({
        message: 'Removido com sucessos',
      })
    } catch (error) {
      openErrorToast({
        message:
          (error as ApiError)?.message || 'Ocorreu um erro ao remover o guia',
      })
    } finally {
      setRemoveResponsibleGuideLoading(false)
    }
  }, [
    id,
    onCanceledRemoveResponsibleGuide,
    openErrorToast,
    openSuccessToast,
    removeResponsibleGuide?.id,
  ])

  const sendMailGuide = useCallback(
    (responsible_guide_activity_id: string) => {
      if (!id) {
        return
      }
      setSendingMail(true)
      ApiService.Activities.sendGuideMail(responsible_guide_activity_id)
        .then(() => {
          setSendingMail(false)
          openSuccessToast({
            message: 'Email enviado para o guia',
          })
          refetch()
        })
        .catch(() => {
          setSendingMail(false)
          openErrorToast({
            message: 'Erro ao enviar email para o guia',
          })
        })
    },
    [id, openErrorToast, openSuccessToast, refetch],
  )

  const sendCertMail = useCallback(() => {
    if (!id) {
      return
    }
    setSendingCertMail(true)
    ApiService.Activities.sendCertMail(id)
      .then(() => {
        setSendingCertMail(false)
        openSuccessToast({
          message: 'Certificado enviado.',
        })
      })
      .catch(() => {
        setSendingCertMail(false)
        openErrorToast({
          message: 'Erro ao enviar o certificado.',
        })
      })
  }, [id, openErrorToast, openSuccessToast])

  const handleCloseEditModalClass = useCallback(() => {
    setEditModalClass(false)
  }, [])

  const handleOpenEditModalClass = useCallback(
    async (data: { name: string; activity_id: number; id: string }) => {
      reactHookFormClassActivity.setValue('id', data.id)
      reactHookFormClassActivity.setValue('activity_id', data.activity_id)
      reactHookFormClassActivity.setValue('name', data.name)
      setEditModalClass(true)
    },
    [reactHookFormClassActivity],
  )

  const handleEditClassesActivity = useCallback(
    async (data: { name: string; id: string; activity_id: number }) => {
      setIsLoading(true)
      try {
        await ApiService.ClassesActivities.update({
          id: data.id,
          classesActivities: {
            activity_id: data.activity_id,
            name: data.name,
          },
        })
        refetchClassActivity()
        openSuccessToast({
          message: 'Turma editada com sucesso',
        })
      } catch (error) {
        openErrorToast({
          message: 'Ocorreu um erro ao editar a turma',
        })
      } finally {
        setIsLoading(false)
        setEditModalClass(false)
      }
    },
    [openErrorToast, openSuccessToast, refetchClassActivity],
  )

  // const handleRemoveClassesActivity = useCallback(
  //   async (id: number | string) => {
  //     setIsLoading(true)
  //     try {
  //       await ApiService.ClassesActivities.remove(id)
  //       refetchClassActivity()
  //       openSuccessToast({
  //         message: 'Turma removida com sucesso',
  //       })
  //     } catch (error) {
  //       openErrorToast({
  //         message: 'Ocorreu um erro ao remover a turma',
  //       })
  //     } finally {
  //       setIsLoading(false)
  //     }
  //   },
  //   [openErrorToast, openSuccessToast, refetchClassActivity],
  // )

  const responsibleGuidesColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<ResponsibleGuide>) =>
          params.row.collaborator?.name,
      },
      {
        field: 'actions',
        headerName: 'Ações',
        renderCell: (cell) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            {!cell.row.mail_sent && (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => sendMailGuide(String(cell.row.id))}
              >
                <Mail />
              </div>
            )}
            <DeleteIcon
              onClick={() => {
                setRemoveResponsibleGuide(cell.row)
                setRemoveResponsibleGuideModal(true)
              }}
            />
          </div>
        ),
      },
    ],
    [sendMailGuide],
  )

  const accompanyingTeachersColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'document', headerName: 'Documento', flex: 1 },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
    ],
    [],
  )

  const reservationsColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'parentClient.fantasy_name',
        headerName: 'Cliente',
        flex: 1,
        renderCell: (props: GridRenderCellParams<Reservation['client']>) => {
          return <span>{props.row?.parentClient?.fantasy_name}</span>
        },
        valueGetter: (params: GridValueGetterParams<Reservation['client']>) => {
          return `${params.row?.parentClient?.id} - ${params.row?.parentClient?.document} - ${params.row?.parentClient?.fantasy_name}`
        },
      },
      {
        field: 'client.fantasy_name',
        headerName: 'Aluno',
        flex: 1,
        renderCell: (props: GridRenderCellParams<Reservation['client']>) => {
          return <span>{props.row?.client?.fantasy_name}</span>
        },
        valueGetter: (params: GridValueGetterParams<Reservation['client']>) => {
          return `${params.row?.client?.id} - ${params.row?.client?.document} - ${params.row?.client?.fantasy_name}`
        },
      },
      {
        field: 'client.period',
        headerName: 'Turma',
        flex: 1,
        renderCell: (props: GridRenderCellParams<Reservation['client']>) => {
          return <span>{props.row?.client?.period}</span>
        },
        valueGetter: (params: GridValueGetterParams<Reservation['client']>) => {
          return `${params.row?.client?.period}`
        },
      },

      {
        field: 'code',
        headerName: 'Código',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Reservation>) =>
          params.row.code,
        sortable: false,
      },
      {
        field: 'payment_value',
        headerName: 'Valor Total',
        valueGetter: (params: GridValueGetterParams<Reservation>) =>
          currencyMask(params.row.payment_value),
        align: 'left',
        headerAlign: 'left',
        type: 'number',
        flex: 1,
        valueFormatter: ({ value }) => currencyMask(value),
      },
      {
        field: 'situation',
        headerName: 'Situação Reserva',
        flex: 1,
        filterOperators: situationOnlyOperators,
        valueFormatter: ({ value }) => {
          return RESERVATION_SITUATION_TYPES[value as ReservationSituation]
        },
        sortable: false,
      },
      {
        field: 'financialMovements',
        customField: 'financialMovements.situation',
        headerName: 'Situação Pagamento',
        flex: 1,
        filterOperators: situationPaymentOnlyOperators,
        valueGetter: ({ value }) => {
          return RESERVATION_SITUATION_PAYMENT_TYPES[
            value?.[0]?.situation as ReservationPaymentSituation
          ]
        },
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: 'Cadastrado em',
        flex: 1,
        valueGetter: ({ value }) => {
          return moment(value, INPUT_MASK.DATE_LOCALE_WITH_TIME).format(
            INPUT_MASK.DATE_LOCALE_WITH_TIME,
          )
        },
      },
    ],
    [],
  )

  const teachersColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Teacher>) =>
          params.row.teacher?.name,
      },
    ],
    [],
  )
  const affiliateColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Affiliate>) =>
          params.row.affiliate?.name,
      },
      { field: 'percent', headerName: 'Percentual de ganho', flex: 1 },
      {
        field: 'affiliate_commission_value',
        headerName: 'Valor de comissão',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Affiliate>) =>
          currencyMask(params.row.affiliate_commission_value),
      },
      {
        field: 'affiliate_commission_not_released_value',
        headerName: 'Valor de comissão a liberar',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<Affiliate>) =>
          currencyMask(params.row.affiliate_commission_not_released_value),
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Affiliate>) => {
          return activity && activity?.paymentGateway?.is_split ? (
            <>
              <IconButton color="primary">
                <LinkIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_SITE_URL}/a/${
                        activity?.code
                      }?affiliate=${encodedBase64(params.row.affiliate.code)}`,
                      '_blank',
                    )
                  }}
                />
              </IconButton>
              <ReleasedPayment
                activity={activity}
                onLoading={(value) => setIsLoading(value)}
                affiliate={{
                  ...params.row.affiliate,
                  affiliate_commission_value:
                    params.row.affiliate_commission_not_released_value,
                }}
              />
              <IconButton>
                <SaveAltIcon
                  titleAccess="Baixar reservas"
                  onClick={() => {
                    ApiService.Affiliates.exportAffiliateReservations({
                      id: params.row.affiliate.id,
                      activity_id: Number(id),
                    })
                      .then((response) => {
                        const blob = new Blob(['\ufeff' + response], {
                          type: 'text/csv;charset=utf-8"',
                        })
                        const url = window.URL.createObjectURL(blob)

                        const a = document.createElement('a')
                        a.download = `afiliado-reservas-${params.row.affiliate.name}.csv`
                        a.href = url
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                      })
                      .catch((error) => {
                        openErrorToast(error)
                      })
                  }}
                />
              </IconButton>
            </>
          ) : (
            <></>
          )
        },
      },
    ],
    [activity, id, openErrorToast],
  )

  const classesActivitysColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Activity>) => {
          return (
            <>
              {activity?.status === 'opened' ? (
                <EditIcon
                  onClick={() => {
                    handleOpenEditModalClass({
                      activity_id: params.row.activity_id,
                      name: params.row.name,
                      id: params.row.id,
                    })
                  }}
                />
              ) : null}
            </>
          )
        },
      },
    ],
    [activity?.status, handleOpenEditModalClass],
  )
  if (isLoadingClassActivity) {
    return <LoadingFullPage />
  }
  if (
    !activity ||
    activityLoading ||
    isCanceling ||
    sendingMail ||
    sendingCertMail ||
    removeResponsibleGuideLoading ||
    reservationsLoading
  ) {
    return <LoadingFullPage />
  }

  return (
    <>
      {isLoading && <LoadingFullPageWithOverlay />}
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.ACTIVITIES.LIST}
            >
              Atividades
            </Link>

            <Typography color="text.primary">Visualizar</Typography>
          </Breadcrumbs>

          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {activity?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <FinishedButton activity={activity} type="button" />
                {activity?.status === 'finished' &&
                  activity.type === 'faculty' && (
                    <Box sx={{ m: 1 }}>
                      <Button
                        color="success"
                        variant="contained"
                        onClick={sendCertMail}
                      >
                        Liberar certificado
                      </Button>
                    </Box>
                  )}

                {activity?.status !== 'cancelled' && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setCancelReason('')
                        setIsReversePayment(true)
                        setCancelModal(true)
                      }}
                    >
                      Cancelar Atividade
                    </Button>
                  </Box>
                )}

                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ACTIVITIES.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>
                <UpdatePeriodButton
                  activity_id={activity?.id}
                  classes={classActivity?.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }))}
                />

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(activity as Activity)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ACTIVITIES.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel
                label="Unidade Comercial"
                value={activity?.commercialUnit?.fantasy_name}
              />
              <TextWithLabel label="Código" value={activity?.code} />
              <TextWithLabel
                label="Instituição"
                value={activity?.institution?.fantasy_name}
              />
              <TextWithLabel
                label="Unidade"
                value={activity?.campusInstitution?.name}
              />
              <TextWithLabel label="Orçamento" value={activity?.budget?.name} />
              <TextWithLabel
                label="Tipo"
                value={`${ACTIVITY_TYPES[activity?.type as ActivityType]}`}
              />

              <TextWithLabel
                label="Nome do curso"
                value={activity?.course_name}
              />

              <TextWithLabel
                label="Nome do certificado"
                value={activity?.certificate_name}
              />

              <TextWithLabel
                label="Data início"
                value={activity?.start_travel_date}
              />

              <TextWithLabel
                label="Horário partida"
                value={activity?.departure_at}
              />

              <TextWithLabel
                label="Data fim"
                value={activity?.end_travel_date}
              />

              <TextWithLabel
                label="Horário retorno"
                value={activity?.return_at}
              />
              <TextWithLabel
                label="Limite de inscrição"
                value={activity?.enrollment_limit}
              />

              <TextWithLabel
                label="Preço"
                value={formatCurrencyUnit(+(activity?.price || 0))}
              />

              <TextWithLabel
                label="Parcelamento sem juros"
                value={activity?.interest_free_installment}
              />

              <TextWithLabel
                label="Horas extracurriculares"
                value={activity?.extracurricular_hours}
              />

              <TextWithLabel label="Viabilidade" value={activity?.viability} />

              <TextWithLabel
                label="Estoque inicial"
                value={activity?.initial_stock}
              />

              <TextWithLabel
                label="Responsável pela atividade"
                value={activity?.responsible?.name}
              />

              <TextWithLabel
                label="Status"
                value={ACTIVITY_STATUS[activity?.status as ActivityStatus]}
              />
              <TextWithLabel label="" value="" />
              <div style={{ gridColumn: '1/-1' }}>
                <TextWithLabel
                  label="Observações"
                  value={activity?.observations}
                  isHtmlContent
                />
              </div>
              <div style={{ gridColumn: '1/-1' }}>
                <TextWithLabel
                  label="Descrição"
                  value={activity?.description}
                  isHtmlContent
                />
              </div>
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Guias Responsáveis" />
                  <Tab label="Afiliados" />
                  <Tab label="Professores" />
                  <Tab label="Professores Acompanhantes" />
                  <Tab label="Turmas" />
                  <Tab label="Reservas" />
                </Tabs>

                <TabPanel value={TABS.RESPONSIBLE_GUIDES} index={currentTab}>
                  <Table
                    rows={activity?.responsibleGuidesActivities}
                    columns={responsibleGuidesColumns}
                  />
                </TabPanel>

                <TabPanel value={TABS.AFFILIATES} index={currentTab}>
                  <Table
                    rows={activity?.affiliatesActivities}
                    columns={affiliateColumns}
                  />
                </TabPanel>

                <TabPanel value={TABS.TEACHERS} index={currentTab}>
                  <Table
                    rows={activity?.teachersActivities}
                    columns={teachersColumns}
                  />
                </TabPanel>

                <TabPanel value={TABS.ACCOMPANYING_TEACHER} index={currentTab}>
                  <Table
                    rows={activity?.accompanying_teacher?.map?.((item) => ({
                      id: item.document,
                      ...item,
                    }))}
                    columns={accompanyingTeachersColumns}
                  />
                </TabPanel>
                <TabPanel value={TABS.CLASS} index={currentTab}>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <AddClass activity_id={Number(id)} />
                  </Grid>
                  <Table
                    rows={classActivity}
                    columns={classesActivitysColumns}
                  />
                </TabPanel>
                <TabPanel value={TABS.RESERVATIONS} index={currentTab}>
                  <Table rows={reservations} columns={reservationsColumns} />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        activity={deleteConfirmation as Activity}
      />

      <ConfirmationModal
        title={`Editar turma`}
        opened={editModalClass}
        onClose={handleCloseEditModalClass}
        onConfim={reactHookFormClassActivity.handleSubmit(
          handleEditClassesActivity,
        )}
        onCancel={handleCloseEditModalClass}
      >
        <form>
          <TextField
            error={!!reactHookFormClassActivity.formState.errors.name?.message}
            fullWidth
            helperText={
              reactHookFormClassActivity.formState.errors.name?.message
            }
            label="Nome"
            margin="normal"
            type="text"
            variant="outlined"
            {...reactHookFormClassActivity.register('name', { required: true })}
          />
        </form>
      </ConfirmationModal>

      <ConfirmationModal
        title="Remover guia responsável"
        opened={removeResponsibleGuideModal}
        onClose={onCanceledRemoveResponsibleGuide}
        onConfim={onConfirmRemoveResponsibleGuide}
        onCancel={onCanceledRemoveResponsibleGuide}
      >
        <p>
          Tem certeza que deseja remover o guia:{' '}
          {removeResponsibleGuide?.collaborator?.name}
        </p>
      </ConfirmationModal>

      <ConfirmationModal
        title="Cancelar atividade"
        opened={cancelModal}
        onClose={() => setCancelModal(false)}
        onConfim={onConfirmCancel}
        onCancel={() => setCancelModal(false)}
      >
        <label htmlFor="cancel-reason"></label>
        <textarea
          name="cancel-reason"
          style={{ width: '100%' }}
          rows={3}
          onChange={(e) => setCancelReason(e.target.value)}
        ></textarea>
        <input
          type="checkbox"
          name="is-reverse-payment"
          style={{ width: '15px', height: '15px', marginTop: '20px' }}
          checked={isReversePayment}
          onChange={() => setIsReversePayment((prev) => !prev)}
        ></input>
        <label htmlFor="is-reverse-payment">Devolver pagamento</label>
      </ConfirmationModal>
    </>
  )
}
