import { STATUS_ORDER_SERVICE_ENUM } from 'constants/order-service-status'
import moment from 'moment'
import { ApiService } from 'services/api'

type handlePrintOrderServiceClickProps = {
  onLoading: (loadingState: boolean) => void
  openErrorToast: (props: { message: string }) => void
  orderService: OrderServiceWithItemsApiResponse
}
export async function handlePrintOrderServiceClick({
  onLoading,
  openErrorToast,
  orderService,
}: handlePrintOrderServiceClickProps) {
  if (!orderService) {
    return
  }
  onLoading(true)
  try {
    if (orderService.status === STATUS_ORDER_SERVICE_ENUM.NEW) {
      openErrorToast({
        message: 'Ordem de serviço ainda não foi emitida',
      })
      return
    }
    const currentDate = moment().format('DD-MM-YYYY')
    await ApiService.OrderServices.downloadPrint(orderService.id.toString(), {
      fileName: `ordem-de-serviço-${orderService.number_order_service}-${orderService.supplier?.name}-${currentDate}`,
    })
    onLoading(false)
  } catch (error) {
    openErrorToast({
      message:
        'Ocorreu um erro ao imprimir a ordem de serviço, tente novamente',
    })
  } finally {
    onLoading(false)
  }
}
