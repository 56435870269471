import { useMemo, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models'
import InputMask from 'components/InputMask'
import Table from 'components/Table'
import { useFormContext } from 'react-hook-form'

type AccompanyingTeacherData = {
  name: string
  document: string
  phone: string
}

export const AccompanyingTeacherForm = () => {
  const { setValue, watch, getValues } = useFormContext<ActivityApiRequest>()
  const [open, setOpenDialog] = useState(false)

  const accompanyingTeachersColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'document', headerName: 'Documento', flex: 1 },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<AccompanyingTeacherData>) => {
          return (
            <DeleteIcon
              onClick={() => {
                const teachers = getValues('accompanying_teacher') || []
                const updatedTeachers = teachers.filter(
                  (teacher) => teacher.document !== params.row.id,
                )
                setValue('accompanying_teacher', updatedTeachers)
              }}
            />
          )
        },
      },
    ],
    [getValues, setValue],
  )

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <Typography variant="h6" component="h2" sx={{ my: 2 }}>
            Professores Acompanhantes
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="end" alignItems="center">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenDialog(true)
            }}
          >
            Criar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={
              watch('accompanying_teacher')?.map?.((item) => ({
                ...item,
                id: item.document,
              })) || []
            }
            columns={accompanyingTeachersColumns}
          />
        </Grid>
      </Grid>
      <TeacherDialogForm open={open} onClose={() => setOpenDialog(false)} />
    </>
  )
}

const teacherInitialState = {
  phone: '',
  document: '',
  name: '',
}

function TeacherDialogForm({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const { setValue, getValues } = useFormContext<ActivityApiRequest>()

  const [teacher, setTeacher] =
    useState<AccompanyingTeacherData>(teacherInitialState)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Dados do Professor acompanhante
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome Completo"
              margin="normal"
              type="text"
              variant="outlined"
              onChange={(event) =>
                setTeacher({ ...teacher, name: event.target.value })
              }
              value={teacher.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Documento"
              margin="normal"
              type="text"
              variant="outlined"
              onChange={(event) =>
                setTeacher({ ...teacher, document: event.target.value })
              }
              value={teacher.document}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: '16px 0 8px' }}>
            <InputMask
              fullWidth
              label="Celular"
              id="phone"
              maskType="cellPhone"
              onChange={(event) =>
                setTeacher({ ...teacher, phone: event.target.value })
              }
              value={teacher.phone}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const accompanyingTeachers = getValues('accompanying_teacher') || []
            accompanyingTeachers.push(teacher)
            setValue('accompanying_teacher', accompanyingTeachers)
            setTeacher(teacherInitialState)
            onClose()
          }}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
