import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery } from '@tanstack/react-query'
import InputPassword from 'components/InputPassword'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (user: UsersApiRequest) => Promise<void>

type AddProps = {
  user?: User
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({ user, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<UsersApiRequest>({
    resolver: yupResolver(schema(isEditMode)),
    defaultValues: {
      commercial_units: user?.commercial_units,
    },
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    User | undefined
  >()

  const { data: roles } = useQuery<SelectProps['options']>(
    QUERY_KEYS.ROLES.NAMES_AND_IDS,
    () => ApiService.Roles.getAllNamesAndIds(),
  )

  const { data: commercialUnits } = useQuery<SelectProps['options']>(
    QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
    async () =>
      ApiService.CommercialUnits.getAllNamesAndIds({
        fields: ['id', 'fantasy_name', 'document'],
        relations: [],
      }),
  )

  useEffect(() => {
    if (isEditMode && !!user && !formUpdated) {
      reset({
        role_id: user.role_id,
        name: user.name,
        username: user.username,
        email: user.email,
        avatar: user.avatar,
        active: user.active,
        last_acess_at: user.last_acess_at,
        last_access_ip: user.last_access_ip,
        remote_access: user.remote_access,
        avatar_url: user.avatar_url,
        commercial_units: user.commercial_units,
      })

      setValue('role_id', user.role_id.toString())

      setFormUpdated(true)
    }
  }, [isEditMode, user, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof UsersApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewUser = useMutation({
    mutationFn: async (user: UsersApiRequest) => {
      await ApiService.Users.create(user)
    },
    onSuccess: (_data, user: UsersApiRequest) => {
      openSuccessToast({
        message: `O usuário ${user.name} foi adicionado com sucesso!`,
      })

      navigate(ROUTES.USERS.LIST)
    },
    onError: (error: ApiError, user: UsersApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${user.name}!`,
      })
    },
  })

  if (
    isEditMode &&
    (!user ||
      formUpdated === false ||
      !commercialUnits?.length ||
      !roles?.length)
  ) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.USERS.LIST}>
              Usuários
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} usuário
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(user as User)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.USERS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((user: UsersApiRequest) => {
                if (isEditMode) {
                  onSave?.(user)
                  return
                }

                addNewUser.mutate(user)
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="role_id"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Grupo"
                              id="role_id"
                              onChange={onChange}
                              value={value}
                              autoFill={isEditMode}
                              options={roles || []}
                              error={!!errors.role_id?.message}
                              helperText={errors.role_id?.message}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={9}>
                      <TextField
                        error={!!errors.name?.message}
                        helperText={errors.name?.message}
                        fullWidth
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.username?.message}
                        fullWidth
                        helperText={errors.username?.message}
                        label="Usuário"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('username')}
                        {...register('username')}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <InputPassword
                        error={!!errors.password?.message}
                        helperText={errors.password?.message}
                        {...inputProps('password')}
                        {...register('password')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Controller
                        name="commercial_units"
                        control={control}
                        render={({ field: { onChange, value }, ...props }) => {
                          return (
                            <Autocomplete
                              multiple
                              id="user-commercial-units"
                              options={commercialUnits || []}
                              noOptionsText="Nada encontrado"
                              value={value || []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Unidades comerciais"
                                  placeholder="Selecione"
                                  margin="normal"
                                />
                              )}
                              onChange={(_, data) => onChange(data)}
                              {...props}
                            />
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        user={deleteConfirmation as User}
      />
    </>
  )
}
