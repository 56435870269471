import { useEffect, useMemo, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models'
import { useQuery } from '@tanstack/react-query'
import Table from 'components/Table'
import { useActivityContext } from 'pages/activities/add'
import { useFormContext } from 'react-hook-form'
import { ApiService } from 'services/api'

export const TeacherForm = () => {
  const { isEditMode, institution_id, activity } = useActivityContext()
  const { setValue, getValues } = useFormContext<ActivityApiRequest>()

  const [selectedTeacher, setSelectedTeacher] = useState<TeacherApiResponse>()
  const [teachersList, setTeachersList] = useState<TeacherApiResponse[]>([])
  const [isLoaded, setIsLoaded] = useState(false)

  const {
    data: teachers,
    isLoading: isLoadingTeachers,
    isFetched,
    refetch,
  } = useQuery<TeacherApiResponse[]>(['activity-teachers'], async () => {
    if (!institution_id) return []
    const teacher = await ApiService.TeachersInstitutions.getAllByInstitution(
      Number(institution_id),
    )
    return teacher.map((item) => item.teacher)
  })

  useEffect(() => {
    refetch()
  }, [refetch, institution_id])

  useEffect(() => {
    if (isEditMode) {
      const teachersActivities = activity?.teachersActivities?.map((item) => ({
        ...item,
        name: item?.teacher?.name,
        id: item?.teacher?.id,
      }))
      setTeachersList(teachersActivities as unknown as TeacherApiResponse[])
    }
  }, [isEditMode, activity])

  const teachersColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<TeacherApiResponse>) => {
          return (
            <DeleteIcon
              onClick={() => {
                const updatedTeachersList = teachersList.filter(
                  (teacher) => teacher.id !== params.row.id,
                )
                setTeachersList(updatedTeachersList)
              }}
            />
          )
        },
      },
    ],
    [teachersList],
  )

  useEffect(() => {
    setValue(
      'teachers',
      teachersList.map((teacher) => teacher.id),
    )
  }, [setValue, teachersList])

  useEffect(() => {
    if (
      isEditMode &&
      isFetched &&
      teachers &&
      !isLoaded &&
      getValues('teachersActivities')
    ) {
      const teachersActivity =
        (getValues('teachersActivities')
          ?.map((teacher) => {
            const hasTeacher = teachers?.find(
              (t) => t.id === teacher.teacher_id,
            )
            return hasTeacher
          })
          .filter((teacher) => !!teacher) as TeacherApiResponse[]) || []
      setValue(
        'teachers',
        teachersActivity.map((teacher) => teacher.id),
      )
      setTeachersList(teachersActivity)
      setIsLoaded(true)
    }
  }, [getValues, isEditMode, isFetched, isLoaded, setValue, teachers])

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" component="h2">
          Professores
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <Autocomplete
          disablePortal
          id="teachers"
          disabled={isLoadingTeachers}
          options={
            teachers?.length
              ? teachers?.map((teacher) => {
                  return {
                    label: teacher?.name,
                    id: teacher?.id,
                  }
                })
              : []
          }
          getOptionLabel={(option) => option.label || ''}
          onChange={(_, newTeacher) => {
            const teacherToAdd = teachers?.find(
              (teacher) => newTeacher && teacher.id === newTeacher.id,
            )
            setSelectedTeacher(teacherToAdd)
          }}
          noOptionsText="Nada foi encontrado"
          renderInput={(params) => (
            <TextField {...params} label="Professores" />
          )}
        />
      </Grid>

      <Grid container item xs={2} alignItems="center" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const updatedTeacherList = selectedTeacher
              ? [...teachersList, selectedTeacher]
              : teachersList
            setTeachersList(updatedTeacherList)
            setSelectedTeacher(undefined)
          }}
        >
          Adicionar
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Table rows={teachersList} columns={teachersColumns} />
      </Grid>
    </Grid>
  )
}
