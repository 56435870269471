import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<GlobalParameter>(
    QUERY_KEYS.GLOBAL_PARAMETERS.EDIT(id as string),
    () => ApiService.GlobalParameters.get(id as string),
  )

  const updateGlobalParameter = useMutation({
    mutationFn: async ({
      id,
      globalParameter,
    }: {
      id: string
      globalParameter: GlobalParametersApiRequest
    }) => {
      await ApiService.GlobalParameters.update({
        id,
        globalParameter,
      })
    },
    onSuccess: (_data, { id, globalParameter }) => {
      queryClient.setQueryData<GlobalParametersApiRequest>(
        QUERY_KEYS.GLOBAL_PARAMETERS.EDIT(id),
        (oldGlobalParameter) => {
          if (!oldGlobalParameter) return
          return globalParameter
        },
      )

      queryClient.removeQueries(QUERY_KEYS.GLOBAL_PARAMETERS.EDIT(id))

      openSuccessToast({
        message: `O parâmetro global foi atualizada com sucesso!`,
      })
    },
    onError: (_error) => {
      openErrorToast({
        message: `Erro ao editar o parâmetro global!`,
      })
    },
  })

  return (
    <Add
      globalParameter={data}
      onSave={async (globalParameter: GlobalParametersApiRequest) =>
        updateGlobalParameter.mutate({ id: id as string, globalParameter })
      }
      isEditMode
    />
  )
}
