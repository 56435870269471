import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  clients?: Clients
}

export default function Delete({ opened, closeModal, clients }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeClient = useMutation({
    mutationFn: async (clients: Clients) => {
      await ApiService.Clients.remove(clients.id.toString())

      openSuccessToast({
        message: `O cliente ${clients?.fantasy_name} foi removido com sucesso!`,
      })

      navigate(ROUTES.CLIENTS.LIST)
    },
    onSuccess: (_data, clients: Clients) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.CLIENTS.LIST,
        (oldClients) => {
          if (!oldClients?.length) return

          return oldClients.filter((oldClient) => oldClient.id !== clients.id)
        },
      )
      openSuccessToast({
        message: `O cliente ${clients.fantasy_name} foi removido com sucesso!`,
      })

      navigate(ROUTES.CLIENTS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeClient.mutate(clients as Clients)}
      confirmColor="error"
    >
      Deseja realmente remover o cliente{' '}
      <strong>{`${clients?.fantasy_name}?`}</strong>
    </ConfirmationModal>
  )
}
