import * as yup from 'yup'

const schema = yup.object().shape({
  grouped: yup.string().required('Campo obrigatório'),
  function: yup.string().required('Campo obrigatório'),
  permission: yup.object().required('Campo obrigatório'),
  routes: yup.array().required('Campo obrigatório'),
})

export { schema }
