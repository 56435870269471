import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { TextFieldProps } from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'moment/locale/pt-br'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker as ContainerDatePicker } from '@mui/x-date-pickers/DatePicker'
import { INPUT_MASK } from 'constants/masks'
import moment from 'moment'

type DatePickerProps = {
  id: string
  label: string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  disabled?: boolean
  value?: string | Date | null
  maxDate?: string | Date
  minDate?: string | Date
  onChange: (value?: string | Date | null) => void
  inputSettings?: TextFieldProps
}

export default function DatePicker({
  id,
  label,
  helperText,
  value,
  error,
  maxDate,
  minDate,
  inputSettings,
  onChange,
  ...props
}: DatePickerProps) {
  return (
    <FormControl fullWidth={props.fullWidth} error={error}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
        <ContainerDatePicker
          {...props}
          label={label}
          inputFormat={INPUT_MASK.DATE_LOCALE}
          value={
            value
              ? moment(
                  value,
                  [INPUT_MASK.DATE_LOCALE, INPUT_MASK.DATE_EUA],
                  true,
                ).toDate()
              : null
          }
          onChange={(newValue) => {
            const newDate = moment(
              newValue,
              [INPUT_MASK.DATE_LOCALE, INPUT_MASK.DATE_EUA],
              true,
            )
            let modifyValue = null
            if (newDate.isValid()) {
              modifyValue = newDate.format(INPUT_MASK.DATE_LOCALE)
            }
            onChange(modifyValue)
          }}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={({ inputProps, InputProps, ...rest }) => {
            return (
              <TextField
                {...rest}
                helperText={helperText}
                error={error}
                inputProps={{
                  ...inputProps,
                  placeholder: 'DD/MM/AAAA',
                }}
                {...inputSettings}
                InputProps={{
                  ...InputProps,
                  ...inputSettings?.InputProps,
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    </FormControl>
  )
}
