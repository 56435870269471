import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<PaymentGateway>(
    QUERY_KEYS.PAYMENT_GATEWAYS.EDIT(id as string),
    () => ApiService.PaymentGateways.get(id as string),
  )

  const updatePaymentGateway = useMutation({
    mutationFn: async ({
      id,
      paymentGateway,
    }: {
      id: string
      paymentGateway: PaymentGatewayApiRequest
    }) => {
      await ApiService.PaymentGateways.update({
        id,
        paymentGateway,
      })
    },
    onSuccess: (_data, { id, paymentGateway }) => {
      queryClient.setQueryData<PaymentGatewayApiRequest>(
        QUERY_KEYS.PAYMENT_GATEWAYS.EDIT(id),
        (oldPaymentGateway) => {
          if (!oldPaymentGateway) return
          return paymentGateway
        },
      )

      openSuccessToast({
        message: `A forma de pagamento ${paymentGateway.account_name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.PAYMENT_GATEWAYS.LIST)
    },
    onError: (_error, { paymentGateway }) => {
      openErrorToast({
        message: `Erro ao editar ${paymentGateway.account_name}!`,
      })
    },
  })

  return (
    <Add
      paymentGateway={data}
      isEditMode
      onSave={async (paymentGateway: PaymentGatewayApiRequest) =>
        updatePaymentGateway.mutate({ id: id as string, paymentGateway })
      }
    />
  )
}
