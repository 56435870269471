import { Button } from '@mui/material'
import { handlePrintOrderServiceClick } from 'components/CreateOrderService/utils/handle-print-order-service'
import { ALLOWED_STATUS_ORDER_SERVICE_SEND_OR_PRINT } from 'constants/order-service-status'

type ButtonPrintOrderServiceProps = {
  onLoading: (loadingState: boolean) => void
  openErrorToast: (props: { message: string }) => void
  orderService?: OrderServiceWithItemsApiResponse
}

export const ButtonPrintOrderService = ({
  onLoading,
  openErrorToast,
  orderService,
}: ButtonPrintOrderServiceProps) => {
  if (!orderService) {
    return null
  }
  if (
    !ALLOWED_STATUS_ORDER_SERVICE_SEND_OR_PRINT.includes(orderService?.status)
  ) {
    return null
  }
  return (
    <Button
      onClick={() =>
        handlePrintOrderServiceClick({
          onLoading,
          openErrorToast,
          orderService,
        })
      }
      color="primary"
      variant="contained"
    >
      Imprimir
    </Button>
  )
}
