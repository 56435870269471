import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Activity>(
    QUERY_KEYS.ACTIVITIES.EDIT(id as string),
    () => ApiService.Activities.get(id as string),
  )

  const updateAffiliate = useMutation({
    mutationFn: async ({
      id,
      activity,
    }: {
      id: string
      activity: ActivityApiRequest
    }) => {
      await ApiService.Activities.update({
        id,
        activity,
      })
    },
    onSuccess: (_data, { id, activity }) => {
      queryClient.setQueryData<ActivityApiRequest>(
        QUERY_KEYS.ACTIVITIES.EDIT(id),
        (oldActivity) => {
          if (!oldActivity) return
          return activity
        },
      )

      openSuccessToast({
        message: `A atividade ${activity.name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.ACTIVITIES.LIST)
    },
    onError: (_error, { activity }) => {
      openErrorToast({
        message: `Erro ao editar ${activity.name}!`,
      })
    },
  })

  return (
    <Add
      activity={data}
      isEditMode
      onSave={async (activity: ActivityApiRequest) =>
        updateAffiliate.mutate({ id: id as string, activity })
      }
    />
  )
}
