import { Fragment, useCallback, useState } from 'react'

import * as MUIicon from '@mui/icons-material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { ListItemIcon } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'

type MenuProps = {
  opened: boolean
  onClose: () => void
}

const INDENT_SIZE = 2

export default function Menu({ opened, onClose }: MenuProps) {
  const navigate = useNavigate()
  const { menus } = useGlobalState()
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({})

  const handleToggleSubMenu = useCallback(
    ({ name, active }: { name: string; active: boolean }) => {
      setOpenMenus((oldOpenMenus) => ({
        ...oldOpenMenus,
        [name]: active,
      }))
    },
    [],
  )

  const renderMenuItems = (items: Menu[], level = 0) => {
    return items.map((item) => {
      const { name, icon, children, permission, method } = item
      if (!permission) return null
      const hasChildren = children && children.length > 0
      const isOpen = openMenus[name] || false
      const paddingLeft = level * INDENT_SIZE
      const Icon = MUIicon[icon as keyof typeof MUIicon]
      let url = item.url as string
      if (url && url.includes('commercial/globalParameters')) {
        url = `${url}/1`
      }
      if (url && method === 'GET' && url === '/commercial/orderServices/') {
        url = `/commercial/orderServices?clear=true/`
      }
      if (hasChildren) {
        return (
          <Fragment key={name}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ paddingLeft }}
                onClick={() => handleToggleSubMenu({ name, active: !isOpen })}
              >
                {icon && Icon && (
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={name} />
                {isOpen ? (
                  <KeyboardArrowUp color="inherit" />
                ) : (
                  <KeyboardArrowDown color="inherit" />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(children, level + 1)}
              </List>
            </Collapse>
          </Fragment>
        )
      }

      return (
        <ListItem key={name} disablePadding>
          <ListItemButton
            sx={{ paddingLeft }}
            onClick={() => {
              navigate(url as string)
              onClose()
            }}
          >
            {icon && Icon && (
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText primary={name} />
          </ListItemButton>
        </ListItem>
      )
    })
  }

  return (
    <Drawer
      anchor="left"
      variant="temporary"
      open={opened}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: 220,
          padding: '0.5rem',
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>{menus && renderMenuItems(menus)}</Box>
    </Drawer>
  )
}
