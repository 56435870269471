import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const reportsRoutes: RouteObject[] = [
  {
    path: ROUTES.CHARTS.SALES,
    element: (
      <PrivateRoute>
        <Pages.SalesCharts />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CHARTS.AVERAGE_TICKET,
    element: (
      <PrivateRoute>
        <Pages.AverageTicketCharts />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CHARTS.SALES_AFFILIATES,
    element: (
      <PrivateRoute>
        <Pages.SalesAffiliatesCharts />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CHARTS.AFFILIATE_AVERAGE_TICKET,
    element: (
      <PrivateRoute>
        <Pages.AffiliateAverageTicketCharts />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CHARTS.RESERVATIONS,
    element: (
      <PrivateRoute>
        <Pages.ReservationsCharts />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CHARTS.SHIPPING_VALUE,
    element: (
      <PrivateRoute>
        <Pages.ShippingValueCharts />
      </PrivateRoute>
    ),
  },
]

export default reportsRoutes
