import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  commercialUnit: CommercialUnitApiResponse
}

export default function Delete({
  opened,
  closeModal,
  commercialUnit,
}: DeleteProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { openErrorToast, openSuccessToast } = useGlobalState()

  const removeCommercialUnit = useMutation({
    mutationFn: async (commercialUnit: CommercialUnit) => {
      await ApiService.CommercialUnits.remove(commercialUnit.id.toString())
    },
    onSuccess: (_data, commercialUnit: CommercialUnit) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.COMMERCIAL_UNITS.LIST,
        (oldCommercialUnits) => {
          if (!oldCommercialUnits?.length) return

          return oldCommercialUnits.filter(
            (oldCommercialUnit) => oldCommercialUnit.id !== commercialUnit.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(commercialUnit.id.toString()),
      )

      openSuccessToast({
        message: `A unidade comercial ${commercialUnit.fantasy_name} foi removida com sucesso!`,
      })

      navigate(ROUTES.COMMERCIAL_UNITS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeCommercialUnit.mutate(commercialUnit)}
      confirmColor="error"
    >
      Deseja realmente remover a unidade commercial{' '}
      <strong>{`${commercialUnit?.fantasy_name}?`}</strong>
    </ConfirmationModal>
  )
}
