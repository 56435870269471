import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Video>(QUERY_KEYS.VIDEOS.EDIT(id as string), () =>
    ApiService.Videos.get(id as string),
  )

  const updateVideo = useMutation({
    mutationFn: async ({
      id,
      video,
    }: {
      id: string
      video: VideosApiRequest
    }) => {
      await ApiService.Videos.update({
        id,
        video,
      })
    },
    onSuccess: (_data, { id, video }) => {
      queryClient.setQueryData<VideosApiRequest>(
        QUERY_KEYS.VIDEOS.EDIT(id),
        (oldVideo) => {
          if (!oldVideo) return
          return video
        },
      )

      openSuccessToast({
        message: `O video ${video.title} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.VIDEOS.LIST)
    },
    onError: (_error, { video }) => {
      openErrorToast({
        message: `Erro ao editar ${video.title}!`,
      })
    },
  })

  return (
    <Add
      video={data}
      isEditMode
      onSave={async (video: VideosApiRequest) =>
        updateVideo.mutate({ id: id as string, video })
      }
    />
  )
}
