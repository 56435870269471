import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import AddAffiliatePagarMe from 'components/AddAffiliatePagarMeModal'
import LoadingFullPage from 'components/LoadingFullPage'
import LoadingFullPageWithOverlay from 'components/LoadingFullPageWithOverlay'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import { ReleasedPayment } from './components/ReleasedPayment'
import Delete from './delete'

export default function View() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  const [modalAddPagarMe, setModalAddPagarMe] = useState(false)

  const { data, isLoading, refetch } = useQuery<Affiliate>(
    QUERY_KEYS.AFFILIATES.VIEW(id as string),
    () => ApiService.Affiliates.get(id as string),
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Affiliate | undefined
  >()

  const [isLoading2, setIsLoading] = useState<boolean>(false)

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      {isLoading2 && <LoadingFullPageWithOverlay />}
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {data?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.AFFILIATES.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(data as Affiliate)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.AFFILIATES.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
                {data?.recipient_id && (
                  <Box sx={{ m: 1 }}>
                    <ReleasedPayment
                      onLoading={(value) => setIsLoading(value)}
                      affiliate={data}
                    />
                  </Box>
                )}

                {!data?.recipient_id && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => {
                        setModalAddPagarMe(true)
                      }}
                    >
                      Adicionar ao Pagar.me
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel
                label="Unidade Comercial"
                value={data?.commercialUnit?.fantasy_name}
              />

              <TextWithLabel label="Nome" value={data?.name} />

              <TextWithLabel label="Documento" value={data?.document} />

              <TextWithLabel label="Código de Afiliação" value={data?.code} />

              <TextWithLabel label="Email" value={data?.email} />

              <TextWithLabel label="Telefone" value={data?.phone} />

              <TextWithLabel label="Celular" value={data?.cell_phone} />

              <TextWithLabel
                label="Endereço"
                value={`${data?.street}, ${data?.number}`}
              />

              <TextWithLabel label="Complemento" value={data?.complement} />

              <TextWithLabel label="Bairro" value={data?.district} />

              <TextWithLabel
                label="Cidade"
                value={`${data?.city}/${data?.uf}`}
              />

              <TextWithLabel label="CEP" value={data?.zip_code} />
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        affiliate={deleteConfirmation as Affiliate}
      />

      <AddAffiliatePagarMe
        affiliate={data}
        opened={modalAddPagarMe}
        title="Adicionar afiliado ao Pagar.me"
        onClose={() => setModalAddPagarMe(false)}
        onClick={() => {
          setModalAddPagarMe(false)
          refetch()
        }}
      />
    </>
  )
}
