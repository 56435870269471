export const RESERVATION_SITUATION_TYPES: Record<ReservationSituation, string> =
  {
    waiting_payment: 'Aguardando confirmação de pagamento',
    payment_confirmed: 'Reserva confirmada',
    cancelled: 'Cancelado',
    realized: 'Aguardando confirmação de pagamento',
    payment_reversal: 'Cancelado',
    finished: 'Concluída',
  }
export const FINANCIAL_SITUATION_TYPES: Record<
  FinancialMovementSituation,
  string
> = {
  'Awaiting payment': 'Aguardando confirmação de pagamento',
  'Payment reversal': 'Pagamento recusado',
  Cancelled: 'Cancelado',
  Denied: 'Negado pela empresa de cartão de crédito',
  Paid: 'Pagamento confirmado',
}

export const RESERVATION_SITUATIONS_OPTIONS = Object.entries(
  RESERVATION_SITUATION_TYPES,
).map(([key, value]) => ({ label: value, value: key }))

export const FINANCIAL_SITUATION_OPTIONS = Object.entries(
  FINANCIAL_SITUATION_TYPES,
).map(([key, value]) => ({ label: value, value: key }))

export const RESERVATION_SITUATION_PAYMENT_TYPES: Record<
  ReservationPaymentSituation,
  string
> = {
  'Esperando pagamento': 'Aguardando pagamento',
  Pago: 'Pagamento confirmado',
  Cancelado: 'Cancelado',
  Devolvido: 'Devolvido',
  Negado: 'Negado pela empresa de cartão',
  'Em processamento': 'Em processamento',
}

export const RESERVATION_SITUATION_PAYMENT_TYPES_2 = {
  'Awaiting payment': 'Aguardando pagamento',
  Paid: 'Pagamento confirmado',
  cancelled: 'Cancelado',
  'Payment reversal': 'Devolvido',
  Denied: 'Negado pela empresa de cartão',
}
