import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const clientsRoutes: RouteObject[] = [
  {
    path: ROUTES.CLIENTS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Clients />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CLIENTS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddClients />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CLIENTS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditClients />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CLIENTS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewClients />
      </PrivateRoute>
    ),
  },
]

export default clientsRoutes
