import { useEffect, useRef } from 'react'

import { GridRenderEditCellParams } from '@mui/x-data-grid'
import DatePicker from 'components/DatePicker'

export const CustomEditDatePickerFieldChecklist = ({
  id,
  value,
  field,
  row,
  handleCal,
  activityChecklist,
  setActivityChecklist,
  error,
  onChange,
  hasFocus,
  cellMode,
  onBlur,
  ...rest
}: GridRenderEditCellParams & {
  handleCal?: (data: ActivityChecklistItemsForm[]) => void
  onBlur?: () => void
  setActivityChecklist: React.Dispatch<
    React.SetStateAction<ActivityChecklistItemsForm[]>
  >
  activityChecklist: ActivityChecklistItemsForm[]
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  // const apiRef = useGridApiContext()

  // const handleValueChange = useCallback(
  //   (date: string | Date | null | undefined) => {
  //     apiRef.current.setEditCellValue({ id, field, value: date })
  //     const updated = activityChecklist.map((item) =>
  //       Number(item.id) === Number(id)
  //         ? {
  //             ...row,
  //             [field]: date,
  //           }
  //         : item,
  //     )
  //     setActivityChecklist(updated)
  //   },
  //   [apiRef, activityChecklist, field, id, row, setActivityChecklist],
  // )
  useEffect(() => {
    if (inputRef.current && row?.isNew && field === 'unit_value') {
      inputRef.current.focus()
    }
  }, [field, row?.isNew])

  return (
    <DatePicker
      fullWidth
      label=""
      id=""
      value={value}
      onChange={(event) => {
        onChange?.(event)
      }}
      inputSettings={{
        variant: 'standard',
        InputProps: { disableUnderline: true },
        sx: {
          '& .MuiInputBase-root': {
            padding: '5px !important',
          },
        },
      }}
      error={error}
    />
  )
}
