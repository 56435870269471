import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<PermissionAssociatedApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<
      PermissionAssociatedApiResponse[]
    >(ENDPOINTS.PERMISSIONS_ASSOCIATED.GET_ALL)

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (
  id: string,
): Promise<PermissionAssociatedApiResponse> => {
  try {
    const getResponse = await apiInstance.get<PermissionAssociatedApiResponse>(
      ENDPOINTS.PERMISSIONS_ASSOCIATED.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  permissionAssociated: PermissionsAssociatedApiRequest,
): Promise<PermissionAssociatedApiResponse> => {
  try {
    const creationResponse =
      await apiInstance.post<PermissionAssociatedApiResponse>(
        ENDPOINTS.PERMISSIONS_ASSOCIATED.CREATE,
        permissionAssociated,
      )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  permissionAssociated,
}: {
  permissionAssociated: PermissionsAssociatedApiRequest
  id: string
}): Promise<PermissionAssociatedApiResponse> => {
  try {
    const updateResponse =
      await apiInstance.put<PermissionAssociatedApiResponse>(
        ENDPOINTS.PERMISSIONS_ASSOCIATED.UPDATE(id),
        permissionAssociated,
      )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.PERMISSIONS_ASSOCIATED.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
