import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const generateActivitiesControl = async (
  params: ActivitiesControlReportRequest & DashboardReportRequestGeneric,
): Promise<ActivitiesControlReportResponse[]> => {
  const { signal, ...restParams } = params
  try {
    const generate = await apiInstance.post<ActivitiesControlReportResponse[]>(
      ENDPOINTS.REPORTS.ACTIVITIES_CONTROL,
      restParams,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateActivityChecklistStatus = async (
  params: { status?: string } & DashboardReportRequestGeneric,
): Promise<number> => {
  const { signal, ...restParams } = params
  try {
    const generate = await apiInstance.post<number>(
      ENDPOINTS.REPORTS.ACTIVITIES_CHECKLIST,
      restParams,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateActivitiesControlByParams = async (
  params: ActivitiesControlReportRequest & DashboardReportRequestGeneric,
): Promise<ActivitiesControlReportResponse[]> => {
  const { signal, ...restParams } = params
  try {
    const generate = await apiInstance.post<ActivitiesControlReportResponse[]>(
      ENDPOINTS.REPORTS.ACTIVITIES_CONTROL_BY_PARAMS,
      restParams,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateActivityCancelled = async ({
  signal,
}: DashboardReportRequestGeneric): Promise<DashboardReportResponse> => {
  try {
    const generate = await apiInstance.get<DashboardReportResponse>(
      ENDPOINTS.REPORTS.ACTIVITY_CANCELLED,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateActivityOpened = async ({
  signal,
}: DashboardReportRequestGeneric): Promise<DashboardReportResponse> => {
  try {
    const generate = await apiInstance.get<DashboardReportResponse>(
      ENDPOINTS.REPORTS.ACTIVITY_OPENED,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateActivityConfirmed = async ({
  signal,
}: DashboardReportRequestGeneric): Promise<DashboardReportResponse> => {
  try {
    const generate = await apiInstance.get<DashboardReportResponse>(
      ENDPOINTS.REPORTS.ACTIVITY_CONFIRMED,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateBudgetAwaitingApproval = async ({
  signal,
}: DashboardReportRequestGeneric): Promise<DashboardReportResponse> => {
  try {
    const generate = await apiInstance.get<DashboardReportResponse>(
      ENDPOINTS.REPORTS.BUDGET_AWAITING_APPROVAL,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateReservationPaid = async ({
  signal,
}: DashboardReportRequestGeneric): Promise<DashboardReportResponse> => {
  try {
    const generate = await apiInstance.get<DashboardReportResponse>(
      ENDPOINTS.REPORTS.RESERVATION_PAID,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateReservationAwaitingPayment = async ({
  signal,
}: DashboardReportRequestGeneric): Promise<DashboardReportResponse> => {
  try {
    const generate = await apiInstance.get<DashboardReportResponse>(
      ENDPOINTS.REPORTS.RESERVATION_AWAITING_PAYMENT,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateSchoolTrip = async (
  params: SchoolTripReportRequest & DashboardReportRequestGeneric,
): Promise<SchoolTripReportResponse[]> => {
  const { signal, ...restParams } = params
  try {
    const generate = await apiInstance.post<SchoolTripReportResponse[]>(
      ENDPOINTS.REPORTS.SCHOOL_TRIP,
      restParams,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateFacultyTrip = async (
  params: FacultyTripReportRequest & DashboardReportRequestGeneric,
): Promise<FacultyTripReportResponse[]> => {
  const { signal, ...restParams } = params
  try {
    const generate = await apiInstance.post<FacultyTripReportResponse[]>(
      ENDPOINTS.REPORTS.FACULTY_TRIP,
      restParams,
      {
        signal,
      },
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateSales = async (
  params: SalesReportRequest,
): Promise<SalesReportResponse[]> => {
  try {
    const generate = await apiInstance.post<SalesReportResponse[]>(
      ENDPOINTS.REPORTS.SALES,
      params,
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const generateSalesAffiliates = async (
  params: SalesAffiliatesReportRequest,
): Promise<SalesReportResponse[]> => {
  try {
    const generate = await apiInstance.post<SalesReportResponse[]>(
      ENDPOINTS.REPORTS.SALES_AFFILIATES,
      params,
    )

    return generate.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
