import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Role>(QUERY_KEYS.ROLES.EDIT(id as string), () =>
    ApiService.Roles.get(id as string),
  )

  const updateRole = useMutation({
    mutationFn: async ({ id, role }: { id: string; role: RolesApiRequest }) => {
      await ApiService.Roles.update({
        id,
        role,
      })
    },
    onSuccess: (_data, { id, role }) => {
      queryClient.setQueryData<RolesApiRequest>(
        QUERY_KEYS.ROLES.EDIT(id),
        (oldRole) => {
          if (!oldRole) return
          return role
        },
      )

      openSuccessToast({
        message: `O grupos de usuário ${role.name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.ROLES.LIST)
    },
    onError: (_error, { role }) => {
      openErrorToast({
        message: `Erro ao editar ${role.name}!`,
      })
    },
  })

  return (
    <Add
      role={data}
      isEditMode
      onSave={async (role: RolesApiRequest) =>
        updateRole.mutate({ id: id as string, role })
      }
    />
  )
}
