import fetchJsonp from 'fetch-jsonp'

export const getCompanyByCNPJ = async (
  cnpj: string,
): Promise<CnpjSearchResponse | void> => {
  const cnpjSearch = cnpj?.replaceAll(/[.\-/]/g, '')
  const response = await fetchJsonp(
    `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
  )
  const responseJson = await response.json()

  const company = {
    company_social_name: responseJson.fantasia,
    company_name: responseJson.nome,
    cnpj: responseJson?.cnpj,
    cep: responseJson?.cep,
    email: responseJson?.email,
  }

  return company
}

export const CompanyService = {
  getCompanyByCNPJ,
}
