import { useCallback, useEffect, useMemo, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import {
  AddItemBudgetRequest,
  CreateOrderServiceRequest,
  ItemsBudgetOrderService,
} from 'components/CreateOrderService/@types/form'
import DatePicker from 'components/DatePicker'
import Select, { SelectProps } from 'components/Select'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { METHODS_PAYMENTS_ORDER_SERVICE_OPTIONS } from 'constants/methods-payments'
import {
  STATUS_ORDER_SERVICE_ENUM,
  STATUS_ORDER_SERVICE_OPTIONS,
} from 'constants/order-service-status'
import { PAYMENTS_STATUS_ORDER_SERVICE_OPTIONS } from 'constants/payments-status'
import { useGlobalState } from 'contexts/global-state'
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form'
import { ApiService } from 'services/api'
import { currencyMask, monetaryToNumber } from 'utils/mask'

import { AddItemBudget } from '../AddItemBudget'

type OrderServiceProps = {
  isEditMode: boolean
  type: 'checklist' | 'individual'
  orderService?: OrderServiceWithItemsApiResponse
  itemsBudget: ItemsBudgetOrderService[]
  reactHookFormOrderService: UseFormReturn<CreateOrderServiceRequest>
  reactHookFormAddItemBudget: UseFormReturn<AddItemBudgetRequest>
}

export const OrderServiceForm = ({
  reactHookFormOrderService,
  reactHookFormAddItemBudget,
  isEditMode,
  itemsBudget,
  type,
  orderService,
}: OrderServiceProps) => {
  const { openErrorToast } = useGlobalState()
  const selectedCategoryId = reactHookFormOrderService.watch('category_id')

  const selectedSupplierId = reactHookFormOrderService.watch('supplier_id')

  const paymentStatus = reactHookFormOrderService.watch('payment_status')

  const items = reactHookFormOrderService.watch('order_service_items')

  const disabledChecklist = useMemo(() => {
    return type === 'checklist'
  }, [type])
  const [addCampusDialogOpened, setAddCampusDialogOpened] =
    useState<boolean>(false)
  const [isEditModeAddItem, setIsEditModeAddItem] = useState<boolean>(false)
  const isOrderServiceCancelled = useMemo(() => {
    return orderService?.status === STATUS_ORDER_SERVICE_ENUM.CANCELLED
  }, [orderService?.status])
  const { data: categories, isLoading: isLoadingCategories } = useQuery<
    CategoriesGetAllNamesIdsFieldsApiResponse[]
  >(QUERY_KEYS.CATEGORIES.NAMES_IDS_FIELDS, () =>
    ApiService.Categories.getAllNamesIdsFields(),
  )

  const { data: suppliers, isLoading: isLoadingSuppliers } = useQuery<
    SelectProps['options']
  >(
    QUERY_KEYS.SUPPLIERS.NAME_AND_IDS_BY_CATEGORY(selectedCategoryId),
    () =>
      ApiService.Suppliers.getOptionsByCategory({
        category_id: selectedCategoryId,
        supplier_id: selectedSupplierId,
      }),
    {
      enabled: !!selectedCategoryId,
    },
  )

  const orderServiceItems = useFieldArray({
    control: reactHookFormOrderService.control,
    name: 'order_service_items',
    keyName: 'order_service_item_id',
  })

  const activityChecklistItemsFiltered = useMemo(() => {
    const filtered = itemsBudget.filter((item) => {
      return !orderServiceItems.fields.find((findItem) => {
        return Number(findItem.id) === Number(item.id)
      })
    })
    return filtered.map((item) => ({
      label: item.name,
      value: (item.id as number).toString(),
    }))
  }, [itemsBudget, orderServiceItems.fields])

  const additionalFields = useMemo(() => {
    const findCategory = categories?.find(
      (category) => Number(category.value) === Number(selectedCategoryId),
    )

    if (!findCategory) {
      return []
    }
    return findCategory.fields || []
  }, [categories, selectedCategoryId])

  const handleOpenModalAddItem = useCallback(() => {
    setAddCampusDialogOpened(true)
  }, [])

  const columns = useMemo<GridColDef[]>(() => {
    const defaultColumns: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Item',
        flex: 1,
      },
      {
        field: 'quantity',
        headerName: 'Quantidade',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        flex: 1,
      },
      {
        field: 'unit_value',
        headerName: 'Valor Unitário',
        flex: 1,
        type: 'string',
      },
      {
        field: 'subtotal',
        headerName: 'Subtotal',
        flex: 1,
        valueGetter: (params) => params.row.subtotal,
      },
    ]
    if (type === 'individual') {
      defaultColumns.push({
        field: 'actions',
        headerName: 'Ações',
        width: 100,
        renderCell: (params: GridRenderCellParams<Budget>) => {
          return (
            <>
              <EditIcon
                sx={{
                  marginRight: '10px',
                }}
                onClick={() => {
                  reactHookFormAddItemBudget.reset({
                    id: params.row.id,
                    name: params.row.name,
                    quantity: params.row.quantity,
                    subtotal: params.row.subtotal,
                    unit_value: params.row.unit_value,
                    order_service_item_id: params.row.order_service_item_id,
                    origin_id: params.row.origin_id,
                  })
                  setIsEditModeAddItem(true)
                  handleOpenModalAddItem()
                }}
              />
              <DeleteIcon
                onClick={() => {
                  const findIndexItem = orderServiceItems.fields.findIndex(
                    (item) =>
                      item.order_service_item_id ===
                      params.row.order_service_item_id,
                  )
                  orderServiceItems.remove(findIndexItem)
                }}
              />
            </>
          )
        },
      })
    }
    return defaultColumns
  }, [
    handleOpenModalAddItem,
    orderServiceItems,
    reactHookFormAddItemBudget,
    type,
  ])

  useEffect(() => {
    const totalValue = items?.reduce((acc, item) => {
      return Number(acc) + monetaryToNumber(item.subtotal)
    }, 0)
    reactHookFormOrderService.setValue('total_value', currencyMask(totalValue))
  }, [items, reactHookFormOrderService])

  useEffect(() => {
    if (isEditMode && !!orderService) {
      reactHookFormOrderService.reset({
        budget_id: orderService.budget_id,
        category_id: orderService.category_id,
        supplier_id: orderService.supplier_id,
        issue_date: orderService.issue_date,
        send_date: orderService.send_date,
        name: orderService.name,
        due_date: orderService.due_date,
        payment_date: orderService.payment_date,
        observations: orderService.observations,
        method_payment: orderService.method_payment,
        status: orderService.status,
        payment_status: orderService.payment_status,
        additional_information: orderService.additional_information,
        order_service_items: orderService.orderServiceItems.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          unit_value: currencyMask(item.unit_value),
          subtotal: currencyMask(item.subtotal),
          id: item.activity_checklist_item_id ?? item.id,
          item_budget_id: item.item_budget_id,
          origin_id: item.id,
        })),
      })
    }
  }, [orderService, isEditMode, reactHookFormOrderService])

  const handleAddItemBudget = (data: AddItemBudgetRequest) => {
    setIsEditModeAddItem(false)

    const findItemBudget = itemsBudget.find(
      (item) => item.id === Number(data.id),
    )
    if (!findItemBudget) {
      openErrorToast({
        message: 'Item não encontrado',
      })
      return
    }
    orderServiceItems.append({ ...data, name: findItemBudget.name })
    setAddCampusDialogOpened(false)
  }

  const handleUpdateItemBudget = (data: AddItemBudgetRequest) => {
    const findIndexItem = orderServiceItems.fields.findIndex(
      (item) => item.order_service_item_id === data.order_service_item_id,
    )
    orderServiceItems.update(findIndexItem, data)
    setAddCampusDialogOpened(false)
    setIsEditModeAddItem(false)
  }

  if (
    isLoadingCategories ||
    (!!selectedCategoryId && isLoadingSuppliers) ||
    (isEditMode && !orderService)
  ) {
    return <div style={{ height: '700px' }}></div>
  }

  return (
    <>
      <form>
        <Grid container spacing={2} xs={12}>
          <Grid item xs={12}>
            <TextField
              label="Nome"
              fullWidth
              disabled={isOrderServiceCancelled}
              margin="normal"
              error={!!reactHookFormOrderService.formState.errors.name?.message}
              helperText={
                reactHookFormOrderService.formState.errors.name?.message
              }
              {...reactHookFormOrderService.register('name')}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="category_id"
              control={reactHookFormOrderService.control}
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Categorias"
                    id="category_id"
                    disabled={!!isEditMode || disabledChecklist}
                    autoFill={isEditMode}
                    onChange={(event) => {
                      reactHookFormOrderService.setValue('supplier_id', '')
                      orderServiceItems.remove()
                      onChange(event)
                    }}
                    value={value}
                    options={categories || []}
                    error={
                      !!reactHookFormOrderService.formState.errors.category_id
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState.errors.category_id
                        ?.message
                    }
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="supplier_id"
              control={reactHookFormOrderService.control}
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Fornecedor"
                    id="supplier_id"
                    onChange={onChange}
                    value={value}
                    options={suppliers || []}
                    disabled={
                      !selectedCategoryId?.length ||
                      !!isEditMode ||
                      disabledChecklist
                    }
                    error={
                      !!reactHookFormOrderService.formState.errors.supplier_id
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState.errors.supplier_id
                        ?.message
                    }
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
            <Controller
              name="issue_date"
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              control={reactHookFormOrderService.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    error={
                      !!reactHookFormOrderService.formState?.errors?.issue_date
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState?.errors?.issue_date
                        ?.message
                    }
                    fullWidth
                    label="Data de emissão"
                    id="issue_date"
                    onChange={onChange}
                    value={value}
                    disabled={isOrderServiceCancelled}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="method_payment"
              control={reactHookFormOrderService.control}
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Método de pagamento"
                    id="method_payment"
                    onChange={onChange}
                    value={value}
                    disabled={isOrderServiceCancelled}
                    options={METHODS_PAYMENTS_ORDER_SERVICE_OPTIONS}
                    error={
                      !!reactHookFormOrderService.formState.errors
                        .method_payment?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState.errors.method_payment
                        ?.message
                    }
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
            <Controller
              name="due_date"
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              control={reactHookFormOrderService.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    error={
                      !!reactHookFormOrderService.formState?.errors?.due_date
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState?.errors?.due_date
                        ?.message
                    }
                    fullWidth
                    label="Data de vencimento"
                    id="due_date"
                    onChange={onChange}
                    value={value}
                    minDate={new Date()}
                    disabled={isOrderServiceCancelled}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="payment_status"
              control={reactHookFormOrderService.control}
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Status de pagamento"
                    id="payment_status"
                    onChange={onChange}
                    disabled={isOrderServiceCancelled}
                    value={value}
                    options={PAYMENTS_STATUS_ORDER_SERVICE_OPTIONS}
                    error={
                      !!reactHookFormOrderService.formState.errors
                        .payment_status?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState.errors.payment_status
                        ?.message
                    }
                  />
                )
              }}
            />
          </Grid>
          {paymentStatus === 'paid' && (
            <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
              <Controller
                name="payment_date"
                rules={{
                  required: {
                    message: 'Campo obrigatório',
                    value: true,
                  },
                }}
                control={reactHookFormOrderService.control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatePicker
                      error={
                        !!reactHookFormOrderService.formState?.errors
                          ?.payment_date?.message
                      }
                      helperText={
                        reactHookFormOrderService.formState?.errors
                          ?.payment_date?.message
                      }
                      fullWidth
                      label="Data de pagamento"
                      id="payment_date"
                      onChange={onChange}
                      disabled={isOrderServiceCancelled}
                      value={value}
                    />
                  )
                }}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Controller
              name="status"
              control={reactHookFormOrderService.control}
              rules={{
                required: {
                  message: 'Campo obrigatório',
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Status"
                    id="status"
                    onChange={onChange}
                    value={value}
                    options={STATUS_ORDER_SERVICE_OPTIONS}
                    error={
                      !!reactHookFormOrderService.formState.errors.status
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState.errors.status?.message
                    }
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
            <Controller
              name="send_date"
              control={reactHookFormOrderService.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    error={
                      !!reactHookFormOrderService.formState?.errors?.send_date
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState?.errors?.send_date
                        ?.message
                    }
                    fullWidth
                    label="Data de envio"
                    id="send_date"
                    onChange={onChange}
                    value={value}
                    disabled
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="total_value"
              control={reactHookFormOrderService.control}
              rules={{ required: true }}
              render={() => {
                return (
                  <TextField
                    error={
                      !!reactHookFormOrderService.formState?.errors?.total_value
                        ?.message
                    }
                    helperText={
                      reactHookFormOrderService.formState?.errors?.total_value
                        ?.message
                    }
                    fullWidth
                    label="Total"
                    id="total_value"
                    margin="normal"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    {...reactHookFormOrderService.register('total_value')}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Observações"
              fullWidth
              multiline
              rows={3}
              inputProps={{
                maxLength: Number(selectedCategoryId) === 18 ? 1200 : undefined,
              }}
              disabled={isOrderServiceCancelled}
              margin="normal"
              error={
                !!reactHookFormOrderService.formState.errors.observations
                  ?.message
              }
              helperText={
                reactHookFormOrderService.formState.errors.observations?.message
              }
              {...reactHookFormOrderService.register('observations')}
            />
          </Grid>
          {additionalFields.map((additionalField) => (
            <Grid item xs={additionalField.xs || 4} key={additionalField.field}>
              <TextField
                label={`${additionalField.label} (Digite como vai aparecer na OS)`}
                fullWidth
                multiline={additionalField.multiline}
                rows={additionalField.rows}
                margin="normal"
                disabled={isOrderServiceCancelled}
                inputProps={{ maxLength: additionalField.maxLength }}
                error={
                  !!reactHookFormOrderService.formState.errors
                    ?.additional_information?.[additionalField.field]?.message
                }
                helperText={
                  reactHookFormOrderService.formState?.errors
                    ?.additional_information?.[additionalField.field]?.message
                }
                {...reactHookFormOrderService.register(
                  `additional_information.${additionalField.field}`,
                  {
                    required: {
                      message: 'Campo obrigatório',
                      value: true,
                    },
                  },
                )}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Grid container xs={12}>
              <Grid item xs={9}>
                <Typography sx={{ m: 1 }} variant="h6">
                  Itens
                </Typography>
              </Grid>
              {type === 'individual' && (
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleOpenModalAddItem}
                  >
                    Adicionar
                  </Button>
                </Grid>
              )}
            </Grid>
            <Table
              hideFooterPagination
              rows={orderServiceItems.fields}
              columns={columns}
              getRowId={(item) => item.order_service_item_id}
            />
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={addCampusDialogOpened}
        onClose={() => setAddCampusDialogOpened(false)}
        maxWidth="md"
      >
        <DialogTitle>Adicionar Campus</DialogTitle>

        <DialogContent sx={{ minWidth: 600 }}>
          <AddItemBudget
            activityChecklistItemsOptions={activityChecklistItemsFiltered}
            onClickAdd={handleAddItemBudget}
            onClickUpdate={handleUpdateItemBudget}
            onCLickClose={() => setAddCampusDialogOpened(false)}
            reactHookFormAddItemBudget={reactHookFormAddItemBudget}
            isEditMode={isEditModeAddItem}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
