import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getItemByBudgetId = async ({
  budget_id,
  item_budget_id,
}: BudgetItemsGetItemByBudgetIdApiRequest): Promise<
  BudgetItemsApiResponse | undefined
> => {
  try {
    const getAllResponse = await apiInstance.post<
      BudgetItemsApiResponse | undefined
    >(ENDPOINTS.BUDGETS_ITEMS.GET_ITEM_BY_BUDGET_ID, {
      budget_id,
      item_budget_id,
    })

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
