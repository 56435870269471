import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const budgetsRoutes: RouteObject[] = [
  {
    path: ROUTES.ACTIVITY_CHECKLIST.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditActivityChecklist />
      </PrivateRoute>
    ),
  },
]

export default budgetsRoutes
