import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAllByInstitution = async (
  institution_id: number,
): Promise<TeacherInstitutionApiResponse[]> => {
  try {
    const getAllByInstitutionResponse = await apiInstance.get<
      TeacherInstitutionApiResponse[]
    >(ENDPOINTS.TEACHERS_INSTITUTIONS.GET_BY_INSTITUTION(institution_id))

    return getAllByInstitutionResponse.data.sort((currentItem, nextItem) => {
      return currentItem?.teacher?.name.localeCompare(nextItem?.teacher?.name)
    })
  } catch (getAllByInstitutionError) {
    const axiosError = getAllByInstitutionError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async ({
  institution_id,
  teacher_id,
}: TeacherInstitutionRemoveApiRequest): Promise<boolean> => {
  try {
    const removeTeacherInstitutionResponse = await apiInstance.delete<
      TeacherInstitutionApiResponse[]
    >(ENDPOINTS.TEACHERS_INSTITUTIONS.DELETE, {
      data: {
        institution_id,
        teacher_id,
      },
    })

    return !!removeTeacherInstitutionResponse.data
  } catch (getAllByInstitutionError) {
    const axiosError = getAllByInstitutionError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
