import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const itemsBudgetRoutes: RouteObject[] = [
  {
    path: ROUTES.ITEMS_BUDGET.LIST,
    element: (
      <PrivateRoute>
        <Pages.ItemsBudget />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ITEMS_BUDGET.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddItemsBudget />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ITEMS_BUDGET.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditItemsBudget />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ITEMS_BUDGET.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewItemsBudget />
      </PrivateRoute>
    ),
  },
]

export default itemsBudgetRoutes
