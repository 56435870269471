import { useState } from 'react'

import { Button } from '@mui/material'
import { SendOrderServiceToSupplier } from 'components/CreateOrderService/@types/form'
import { handleSendOrderServiceClick } from 'components/CreateOrderService/utils/handle-send-to-supplier-order-service'
import { ALLOWED_STATUS_ORDER_SERVICE_SEND_OR_PRINT } from 'constants/order-service-status'
import { UseFormReturn } from 'react-hook-form'

import { DialogContactSupplier } from '../DialogContactSupplier'

type ButtonPrintOrderServiceProps = {
  onLoading: (loadingState: boolean) => void
  onSubmit?: () => void
  openErrorToast: (props: { message: string }) => void
  openSuccessToast: (props: { message: string }) => void
  text?: string
  disabled?: boolean
  orderService?: OrderServiceWithItemsApiResponse
  itemBudget?: ActivityChecklistItemsForm
  reactHookFormSendOrderServiceToSupplier: UseFormReturn<SendOrderServiceToSupplier>
}

export const ButtonSendForSupplierOrderService = ({
  onLoading,
  text,
  onSubmit,
  disabled,
  openErrorToast,
  openSuccessToast,
  reactHookFormSendOrderServiceToSupplier,
  orderService,
  itemBudget,
}: ButtonPrintOrderServiceProps) => {
  const [openModalSelectContact, setOpenModalSelectContact] = useState({
    isActive: false,
  })
  if (!orderService) {
    return null
  }
  if (
    !ALLOWED_STATUS_ORDER_SERVICE_SEND_OR_PRINT.includes(orderService?.status)
  ) {
    return null
  }
  return (
    <>
      <Button
        onClick={() => {
          setOpenModalSelectContact({
            isActive: true,
          })
        }}
        disabled={disabled}
        color="primary"
        variant="contained"
      >
        {'Enviar' || text}
      </Button>
      <DialogContactSupplier
        onClose={() => {
          setOpenModalSelectContact({
            isActive: false,
          })
        }}
        onSubmit={async ({ sendTo }) => {
          await handleSendOrderServiceClick({
            onLoading,
            openErrorToast,
            openSuccessToast,
            orderService,
            itemBudget,
            sendTo,
            onSubmit,
          })
        }}
        openModalSelectContact={openModalSelectContact}
        reactHookFormSendOrderServiceToSupplier={
          reactHookFormSendOrderServiceToSupplier
        }
      />
    </>
  )
}
