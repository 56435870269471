import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<PagesApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<PagesApiResponse[]>(
      ENDPOINTS.PAGES.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<PagesApiResponse> => {
  try {
    const getResponse = await apiInstance.get<PagesApiResponse>(
      ENDPOINTS.PAGES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  pages: PagesApiRequest,
): Promise<PagesApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<PagesApiResponse>(
      ENDPOINTS.PAGES.CREATE,
      pages,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  pages,
}: {
  pages: PagesApiRequest
  id: string
}): Promise<PagesApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<PagesApiResponse>(
      ENDPOINTS.PAGES.UPDATE(id),
      pages,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const updateByLink = async ({
  link,
  pages,
}: {
  pages: PagesApiRequest
  link: string
}): Promise<PagesApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<PagesApiResponse>(
      ENDPOINTS.PAGES.UPDATE_BY_LINK(link),
      pages,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(ENDPOINTS.PAGES.DELETE(id))

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const removeByLink = async (link: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.PAGES.DELETE_BY_LINK(link),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
