import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<TeacherApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<TeacherApiResponse[]>(
      ENDPOINTS.TEACHERS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<TeacherApiResponse> => {
  try {
    const getResponse = await apiInstance.get<TeacherApiResponse>(
      ENDPOINTS.TEACHERS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  teacher: TeacherApiRequest,
): Promise<TeacherApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<TeacherApiResponse>(
      ENDPOINTS.TEACHERS.CREATE,
      teacher,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  teacher,
}: {
  teacher: TeacherApiRequest
  id: string
}): Promise<TeacherApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<TeacherApiResponse>(
      ENDPOINTS.TEACHERS.UPDATE(id),
      teacher,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.TEACHERS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const sendPassword = async ({
  id,
  email,
}: TeacherSendPasswordRequest) => {
  try {
    const sendPasswordResponse = await apiInstance.post(
      ENDPOINTS.TEACHERS.SEND_PASSWORD,
      {
        id,
        email,
      },
    )
    return sendPasswordResponse.data
  } catch (sendPasswordError) {
    const axiosError = sendPasswordError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const sendMessage = async ({
  id,
  message,
}: TeacherSendMessageRequest) => {
  try {
    const sendMessageResponse = await apiInstance.post(
      ENDPOINTS.TEACHERS.SEND_MESSAGE,
      {
        id,
        message,
      },
    )
    return sendMessageResponse.data
  } catch (sendMessageError) {
    const axiosError = sendMessageError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
