import { useCallback, useState } from 'react'

import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseModal from 'components/CloseModal'
import { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'
import * as yup from 'yup'

const createClassSchema = yup.object({
  name: yup.string().required(),
  campus_id: yup.number(),
})

type AddClassProps = {
  activity_id: number
  campusData?: SelectProps['options']
  onLoading?: (value: boolean) => void
}

type AddClassModalProps = {
  opened: boolean
  activity_id: number
  campusData?: SelectProps['options']
  onClose: () => void
  onCancel: () => void
}

const AddClassModal = ({
  onClose,
  opened,
  campusData,
  activity_id,
}: AddClassModalProps) => {
  const { openErrorToast, openSuccessToast } = useGlobalState()

  const reactHookForm = useForm<yup.InferType<typeof createClassSchema>>()

  const onSubmit = useCallback(
    async ({ name, campus_id }: yup.InferType<typeof createClassSchema>) => {
      try {
        await ApiService.ClassesActivities.create({
          activity_id,
          campus_id,
          name,
        })
        CacheService.queryClient.invalidateQueries(
          QUERY_KEYS.CLASSES_ACTIVITIES.GET_BY_ACTIVITY(activity_id.toString()),
        )
        reactHookForm.reset()
        onClose()
        openSuccessToast({ message: 'Turma adicionada com sucesso' })
      } catch (error) {
        openErrorToast({ message: 'Ocorreu um erro ao adicionar a turma' })
      }
    },
    [activity_id, onClose, openErrorToast, openSuccessToast, reactHookForm],
  )

  return (
    <CloseModal title={`Adicionar turma`} opened={opened} onClose={onClose}>
      <div>
        <form onSubmit={reactHookForm.handleSubmit(onSubmit)}>
          {/* <Controller
            name="campus_id"
            control={reactHookForm.control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  label="Campus"
                  id="campus_id"
                  onChange={onChange}
                  value={value}
                  options={campusData || []}
                  error={!!reactHookForm.formState.errors.campus_id?.message}
                  helperText={reactHookForm.formState.errors.campus_id?.message}
                />
              )
            }}
          /> */}
          <TextField
            error={!!reactHookForm.formState.errors.name?.message}
            fullWidth
            helperText={reactHookForm.formState.errors.name?.message}
            label="Nome"
            margin="normal"
            type="text"
            variant="outlined"
            {...reactHookForm.register('name', { required: true })}
          />
          <Box>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{
                marginLeft: 'auto',
                display: 'block',
                marginTop: '1em',
                marginRight: '24px',
              }}
            >
              Adicionar
            </Button>
          </Box>
        </form>
      </div>
    </CloseModal>
  )
}

export const AddClass = ({ activity_id, campusData }: AddClassProps) => {
  const [cancelModal, setCancelModal] = useState(false)
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setCancelModal(true)
        }}
      >
        Adicionar turma
      </Button>

      <AddClassModal
        campusData={campusData}
        activity_id={activity_id}
        onCancel={() => setCancelModal(false)}
        onClose={() => setCancelModal(false)}
        opened={cancelModal}
      />
    </>
  )
}
