import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const suppliersRoutes: RouteObject[] = [
  {
    path: ROUTES.SUPPLIERS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Suppliers />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.SUPPLIERS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddSuppliers />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.SUPPLIERS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditSuppliers />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.SUPPLIERS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewSuppliers />
      </PrivateRoute>
    ),
  },
]

export default suppliersRoutes
