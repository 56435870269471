import { useCallback } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Logo from 'assets/logo'
import InputPassword from 'components/InputPassword'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginApiRequest>()

  const { saveUser, openErrorToast, saveMenus } = useGlobalState()
  const navigate = useNavigate()

  const onSubmit = useCallback(
    async (data: LoginApiRequest) => {
      ApiService.Login.login(data)
        .then((loginResponse) => {
          loginResponse.menus.unshift({
            name: 'Dashboard',
            url: '/',
            icon: 'Home',
            children: [],
            permission: true,
          })
          ApiService.persistToken(loginResponse.token)
          CacheService.user.saveCurrentUser(loginResponse)

          saveUser(loginResponse.user)
          saveMenus(loginResponse.menus)
          navigate(ROUTES.ROOT)
        })
        .catch((loginError) => {
          openErrorToast({ message: loginError.message })
        })
    },
    [navigate, openErrorToast, saveMenus, saveUser],
  )

  return (
    <Box
      component="section"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ m: 3, flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Logo height={150} width={150} />
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            error={!!errors.username?.message}
            fullWidth
            helperText={errors.username?.message}
            label="Usuário"
            margin="normal"
            type="text"
            variant="outlined"
            {...register('username', { required: true })}
          />

          <InputPassword {...register('password', { required: false })} />

          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Entrar
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  )
}
