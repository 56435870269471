import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<ClassesInstitutionsApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<
      ClassesInstitutionsApiResponse[]
    >(ENDPOINTS.CLASSES_INSTITUTIONS.GET_ALL)

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((classesinstitutions) => ({
      label: classesinstitutions.name,
      value: classesinstitutions.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllByInstitution = async (
  instutionId: number,
): Promise<ClassesInstitutions[]> => {
  try {
    const getAllResponse = await getAll()

    return getAllResponse.filter(
      (classesinstitutions) =>
        classesinstitutions.institution_id === +instutionId,
    )
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (
  id: string,
): Promise<ClassesInstitutionsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ClassesInstitutionsApiResponse>(
      ENDPOINTS.CLASSES_INSTITUTIONS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getByInstitution = async (
  institution_id: string | number,
  campus_id?: string | number,
): Promise<ClassesInstitutionsApiResponse[]> => {
  try {
    const getResponse = await apiInstance.post<
      ClassesInstitutionsApiResponse[]
    >(ENDPOINTS.CLASSES_INSTITUTIONS.GET_BY_INSTITUTION(institution_id), {
      campus_id,
    })

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  classeInstitution: ClassesInstitutionsApiRequest,
): Promise<ClassesInstitutionsApiResponse> => {
  try {
    const creationResponse =
      await apiInstance.post<ClassesInstitutionsApiResponse>(
        ENDPOINTS.CLASSES_INSTITUTIONS.CREATE,
        classeInstitution,
      )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  classesinstitutions,
}: {
  classesinstitutions: ClassesInstitutionsApiRequest
  id: string
}): Promise<ClassesInstitutionsApiResponse> => {
  try {
    const updateResponse =
      await apiInstance.put<ClassesInstitutionsApiResponse>(
        ENDPOINTS.CLASSES_INSTITUTIONS.UPDATE(id),
        classesinstitutions,
      )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (ids: string[]) => {
  try {
    const deleteResponse = await Promise.all(
      ids.map((id) =>
        apiInstance.delete(ENDPOINTS.CLASSES_INSTITUTIONS.DELETE(id)),
      ),
    )

    return deleteResponse.map((response) => response.data)
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
