import type { AxiosError } from 'axios'

type ErrorMessage = Record<
  number,
  {
    status: number
    message: string
  }
>

const errors: ErrorMessage = {
  401: {
    status: 401,
    message: 'Usuário não autorizado',
  },
  403: {
    status: 403,
    message: 'Usuário não autorizado',
  },
  404: {
    status: 404,
    message: 'Erro na requisição',
  },
}

const handleGetErroMessage = (message: string) => {
  const value = message.toLowerCase()
  if (value.includes('already exists')) {
    return 'Esse registro já foi cadastrado'
  }
  if (value.includes('code activity incorrect')) {
    return 'O Código da atividade no arquivo é diferente do escolhido'
  }
  if (
    value.includes(
      'esta informação não pode ser removida, pois está sendo usada por outra tabela',
    )
  ) {
    return 'Esta informação não pode ser removida, pois está sendo usada em outro lugar!'
  }
  if (value.includes('this affiliate is being used in activity')) {
    return 'Esta informação não pode ser removida, pois está sendo usada em uma atividade!'
  }
  return message
}

export const handleErrorMessage = ({
  axiosError,
  customErrors = {},
}: {
  axiosError: AxiosError
  customErrors?: ErrorMessage
}) => {
  const message = handleGetErroMessage(
    (axiosError.response?.data as { message: string })?.message || '',
  )
  return (
    { ...errors, ...customErrors }[axiosError.response?.status as number] || {
      status: axiosError.response?.status,
      message,
      data: (axiosError.response?.data as { data: ApiError['data'] })?.data,
    }
  )
}
