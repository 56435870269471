export const USER_SESSION_STORAGE_KEY = 'user'
export const TOAST_NOT_AUTHORIZED_ROUTE = 'not-authorized-route'
export const HAS_AUTHORIZATION_ERROR = 'has-authorization-error'

export const PATHS_ROUTE = [
  { name: 'create', method: 'POST' },
  { name: 'view', method: 'GET' },
  { name: 'update', method: 'PUT' },
  { name: 'delete', method: 'DELETE' },
]

export const QUERY_KEYS = {
  CHECK_PERMISSION: {
    GET: (route: string) => ['check-permission', route],
    VERIFY: (
      route: string,
      userId?: string | number,
      roleId?: string | number,
    ) => ['check-permission', route, userId, roleId],
  },
  INSTITUTIONS: {
    LIST: ['institutions'],
    EDIT: (id: string) => ['institution', id],
    VIEW: (id: string) => ['institution', id],
    NAMES_AND_IDS: ['institutions-names-and-ids'],
  },
  CLASSES_INSTITUTIONS: {
    LIST: ['classes-institutions'],
    EDIT: (id: string) => ['classes-institution', id],
    VIEW: (id: string) => ['classes-institution', id],
    GET_BY_INSTITUTION: (id: string | number, campusId?: string | number) => [
      'classes-institution-by-institution',
      id,
      campusId,
    ],
    NAMES_AND_IDS: ['classes-institutions-names-and-ids'],
  },
  CLASSES_ACTIVITIES: {
    LIST: ['classes-activities'],
    EDIT: (id: string) => ['classes-activity', id],
    VIEW: (id: string) => ['classes-activity', id],
    GET_BY_ACTIVITY: (id: string | number, campusId?: string | number) => [
      'classes-activity-by-activity',
      id,
      campusId,
    ],
    NAMES_AND_IDS: ['classes-activities-names-and-ids'],
  },
  LABELS: {
    LIST: ['labels'],
    EDIT: (id: string) => ['label', id],
    VIEW: (id: string) => ['label', id],
    NAMES_AND_IDS: ['labels-names-and-ids'],
    ENTITIES_COLUMNS: ['entities-columns-names-and-ids'],
  },
  ORDER_SERVICES: {
    LIST: ['order-services'],
    EDIT: (id: string) => ['order-service', id],
    VIEW: (id: string) => ['order-service', id],
    GET_WITH_ITEMS_BY_ACTIVITY_CHECKLIST_ID: (
      activity_checklist_item_id: string,
    ) => ['order-service-with-items', activity_checklist_item_id],
    NAMES_AND_IDS: ['order-services-names-and-ids'],
  },
  INDICATORS: {
    VIEW: ['institution'],
  },
  VIDEOS: {
    LIST: ['videos'],
    EDIT: (id: string) => ['video', id],
    VIEW: (id: string) => ['video', id],
    NAMES_AND_IDS: ['videos-names-and-ids'],
  },

  RESERVATIONS: {
    LIST: ['reservations'],
    LIST_BY_ACTIVITY: (id: string | number) => [
      'reservations-list-by-activity',
      id,
    ],
    EDIT: (id: string) => ['reservation', id],
    VIEW: (id: string) => ['reservation', id],
    NAMES_AND_IDS: ['reservations-names-and-ids'],
  },

  AFFILIATES: {
    LIST: ['affiliates'],
    EDIT: (id: string) => ['affiliate', id],
    VIEW: (id: string) => ['affiliate', id],
    NAME_AND_IDS: ['affiliate-names-ands-ids'],
    NAME_AND_IDS_OPTIONS: ['affiliate-names-ands-ids-options'],
  },

  COMMERCIAL_UNITS: {
    LIST: ['commercialUnits'],
    EDIT: (id: string) => ['commercial-units', id],
    VIEW: (id: string) => ['commercial-units', id],
    NAMES_AND_IDS: ['commercial-units-names-and-ids'],
  },

  PAYMENT_MODULES: {
    LIST: ['payment-modules'],
    EDIT: (id: string) => ['payment-modules', id],
    VIEW: (id: string) => ['payment-modules', id],
    NAMES_AND_IDS: ['payment-modules-names-and-ids'],
  },

  PAYMENT_GATEWAYS: {
    LIST: ['payment-gateways'],
    EDIT: (id: string) => ['payment-gateways', id],
    VIEW: (id: string) => ['payment-gateways', id],
    NAMES_AND_IDS: ['payment-gateways-names-and-ids'],
  },

  TEACHERS: {
    LIST: ['teachers'],
    EDIT: (id: string) => ['teachers', id],
    VIEW: (id: string) => ['teachers', id],
  },

  SUPPLIERS: {
    LIST: ['suppliers'],
    EDIT: (id: string) => ['suppliers', id],
    VIEW: (id: string) => ['suppliers', id],
    VIEW_PAGE: (id: string) => ['suppliers-with-relations', id],
    GET_CATEGORY_BY_SUPPLIER: (id: string) => [
      'suppliers-get-category-by-supplier',
      id,
    ],
    NAME_AND_IDS: ['suppliers-name-and-ids-by-category'],
    NAME_AND_IDS_BY_CATEGORY: (category_id: string) => [
      'suppliers-name-and-ids-by-category',
      category_id,
    ],
  },

  COLLABORATORS: {
    LIST: ['collaborators'],
    NAME_AND_IDS_ONLY_EMPLOYEE: ['name=-and-ids-collaborators-only-employee'],
    NAME_AND_IDS: ['name=-and-ids-collaborators'],
    ONLY_EMPLOYEE: ['collaborators-only-employee'],
    EDIT: (id: string) => ['collaborator', id],
    VIEW: (id: string) => ['collaborator', id],
  },

  CATEGORIES: {
    LIST: ['categories'],
    EDIT: (id: string) => ['category', id],
    VIEW: (id: string) => ['category', id],
    NAMES_AND_IDS: ['categories-names-and-ids'],
    NAMES_IDS_FIELDS: ['categories-names-ids-fields'],
  },

  ROLES: {
    LIST: ['roles'],
    EDIT: (id: string) => ['role', id],
    VIEW: (id: string) => ['role', id],
    NAMES_AND_IDS: ['roles-names-and-ids'],
    PERMISSIONS: (roleId: string) => ['role-permissions', roleId],
  },

  PERMISSIONS: {
    LIST: ['permissions'],
    EDIT: (id: string) => ['permission', id],
    VIEW: (id: string) => ['permission', id],
    NAMES_AND_IDS: ['permissions-names-and-ids'],
  },

  PERMISSIONS_ASSOCIATED: {
    LIST: ['permissions-associated'],
    EDIT: (id: string) => ['permission-associated', id],
    VIEW: (id: string) => ['permission-associated', id],
    NAMES_AND_IDS: ['permissions-associated-names-and-ids'],
  },

  USERS: {
    LIST: ['users'],
    NAMES: ['users-names'],
    EDIT: (id: string) => ['user', id],
    VIEW: (id: string) => ['user', id],
    PERMISSIONS: (userId: string, roleId: string) => [
      'user-permissions',
      roleId,
      userId,
    ],
  },

  MENUS: {
    LIST: ['menus'],
    EDIT: (id: string) => ['menu', id],
    VIEW: (id: string) => ['menu', id],
  },

  IPS_ALLOWED: {
    LIST: ['ipsAllowed'],
    EDIT: (id: string) => ['ipAllowed', id],
    VIEW: (id: string) => ['ipAllowed', id],
  },

  GLOBAL_PARAMETERS: {
    LIST: ['global-parameters'],
    EDIT: (id: string) => ['global-parameter', id],
    VIEW: (id: string) => ['global-parameter', id],
  },

  CLIENTS: {
    LIST: ['clients'],
    EDIT: (id: string) => ['clients', id],
    VIEW: (id: string) => ['clients', id],
    PARENT_IS_NULL: ['clients-parent-id-is-null'],
    NAMES: ['clients-names'],
  },

  ITEMS_BUDGET: {
    LIST: ['itens-budget'],
    LIST_PAGE: ['itens-budget-page'],
    LIST_IS_DEFAULT: ['itens-budget-is-default'],
    EDIT: (id: string) => ['itens-budget', id],
    VIEW: (id: string) => ['itens-budget', id],
  },

  BUDGETS: {
    LIST: ['budget'],
    NAME_AND_IDS_CURRENT_YEAR: ['budget-name-and-ids-current-year'],
    EDIT: (id: string) => ['budget', id],
    VIEW: (id: string) => ['budget', id],
  },

  ACTIVITY_CHECKLIST: {
    LIST: ['activity-checklist'],
    EDIT: (id: string) => ['activity-checklist', id],
    VIEW: (id: string) => ['activity-checklist', id],
  },

  ACTIVITY_TASKS: {
    LIST: ['activity-task'],
    EDIT: (id: string) => ['activity-task', id],
    VIEW: (id: string) => ['activity-task', id],
  },

  PAGES: {
    LIST: ['pages'],
    EDIT: (id: string) => ['pages', id],
    VIEW: (id: string) => ['pages', id],
  },

  ACTIVITIES: {
    LIST: ['activities'],
    LIST_CALENDAR: ['activities-calendar'],
    NAMES_AND_IDS: ['activities-names-and-ids'],
    EDIT: (id: string) => ['activities', id],
    EDIT_CHECKLIST: (id: string) => ['activities-checklist', id],
    VIEW: (id: string) => ['activities', id],
  },

  CAMPUS: {
    LIST: ['campus'],
    EDIT: (id: string) => ['campus', id],
    VIEW: (id: string) => ['campus', id],
    BY_INSTITUTION: (id: number) => ['campus-by-institution', id],
  },

  RESPONSIBLES_GUIDE: {
    LIST: ['responsibles-guide'],
    EDIT: (id: string) => ['responsible-guide', id],
    VIEW: (id: string) => ['responsible-guide', id],
  },

  REPORTS: {
    RESERVATION_AWAITING_PAYMENT: ['report-reservation-awaiting-payment'],
    RESERVATION_PAID: ['report-reservation-paid'],
    BUDGET_AWAITING_APPROVAL: ['budget-awaiting-approval'],
    ACTIVITY_CONFIRMED: ['report-activity-confirmed'],
    ACTIVITY_OPENED: ['report-activity-opened'],
    ACTIVITY_CANCELLED: ['report-activity-cancelled'],
    SCHOOL_TRIP: ['report-school-trip'],
    FACULTY_TRIP: ['report-faculty-trip'],
    ACTIVITY_CONTROL: ['report-activity-control'],
    ACTIVITY_CHECKLIST: ['report-activity-checklist'],
  },
}
