export const STATUS_ORDER_SERVICE_ENUM = {
  NEW: 'new',
  ISSUED: 'issued',
  SENDED: 'sended',
  CANCELLED: 'cancelled',
}
export const STATUS_ORDER_SERVICE: Record<string, string> = {
  new: 'Novo',
  issued: 'Emitido',
  sended: 'Enviado',
  cancelled: 'Cancelando',
}
export const ALLOWED_STATUS_ORDER_SERVICE_SEND_OR_PRINT = [
  STATUS_ORDER_SERVICE_ENUM.ISSUED,
  STATUS_ORDER_SERVICE_ENUM.SENDED,
]
export const STATUS_ORDER_SERVICE_OPTIONS = Object.entries(
  STATUS_ORDER_SERVICE,
).map(([key, value]) => ({
  label: value,
  value: key,
}))
