import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<VideoApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<VideoApiResponse[]>(
      ENDPOINTS.VIDEOS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<VideoApiResponse> => {
  try {
    const getResponse = await apiInstance.get<VideoApiResponse>(
      ENDPOINTS.VIDEOS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  video: VideosApiRequest,
): Promise<VideoApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<VideoApiResponse>(
      ENDPOINTS.VIDEOS.CREATE,
      video,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  video,
}: {
  video: VideosApiRequest
  id: string
}): Promise<VideoApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<VideoApiResponse>(
      ENDPOINTS.VIDEOS.UPDATE(id),
      video,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(ENDPOINTS.VIDEOS.DELETE(id))

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
