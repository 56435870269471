import type { PropsWithChildren } from 'react'

import Box from '@mui/material/Box'
import { ButtonProps } from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

type CloseModalProps = {
  opened: boolean
  title: string
  confirmColor?: ButtonProps['color']
  onClose?: () => void
}

export default function CloseModal({
  opened,
  title,
  onClose,
  children,
}: PropsWithChildren<CloseModalProps>) {
  return (
    <Modal
      open={opened}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '8px',
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          flexWrap={'wrap'}
        >
          {title}
        </Typography>

        <Box sx={{ mt: 2 }}>{children}</Box>
      </Box>
    </Modal>
  )
}
