import * as yup from 'yup'

const schema = yup.object().shape({
  commercial_unit_id: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  document: yup.string().required('Campo obrigatório'),
})

export { schema }
