import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'
import capitalize from 'utils/capitalize'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<AffiliateApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<AffiliateApiResponse[]>(
      ENDPOINTS.AFFILIATES.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIdsOptions = async (): Promise<
  AffiliateApiResponse[]
> => {
  try {
    const getAllResponse = await apiInstance.get<AffiliateApiResponse[]>(
      ENDPOINTS.AFFILIATES.GET_NAME_AND_IDS_OPTIONS,
    )

    return getAllResponse.data.sort((currentItem, nextItem) => {
      return currentItem.name.localeCompare(nextItem.name)
    })
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<AffiliateApiResponse> => {
  try {
    const getResponse = await apiInstance.get<AffiliateApiResponse>(
      ENDPOINTS.AFFILIATES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((affiliate) => ({
      label: capitalize(affiliate.name),
      value: affiliate.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  affiliate: AffiliatesApiRequest,
): Promise<AffiliateApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<AffiliateApiResponse>(
      ENDPOINTS.AFFILIATES.CREATE,
      affiliate,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  affiliate,
}: {
  affiliate: AffiliatesApiRequest
  id: string
}): Promise<AffiliateApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<AffiliateApiResponse>(
      ENDPOINTS.AFFILIATES.UPDATE(id),
      affiliate,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.AFFILIATES.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const sendMessage = async ({
  id,
  message,
}: AffiliateSendMessageRequest) => {
  try {
    const sendMessageResponse = await apiInstance.post(
      ENDPOINTS.AFFILIATES.SEND_MESSAGE,
      {
        id,
        message,
      },
    )
    return sendMessageResponse.data
  } catch (sendMessageError) {
    const axiosError = sendMessageError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const exportAffiliateReservations = async ({
  id,
  activity_id,
}: AffiliateExportReservationsRequest) => {
  try {
    const exportReservationsResponse = await apiInstance.post(
      ENDPOINTS.AFFILIATES.EXPORT_AFFILIATE_RESERVATIONS,
      {
        id,
        activity_id,
      },
    )
    return exportReservationsResponse.data
  } catch (sendMessageError) {
    const axiosError = sendMessageError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
