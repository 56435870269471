export const CONTACTS_SUPPLIER_TYPES_OPTIONS = [
  {
    label: 'Email + Whatsapp',
    value: 'mail_message',
  },
  {
    label: 'Email',
    value: 'mail',
  },
]
