import { useCallback, useEffect, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import HtmlEditor from 'components/HtmlEditor'
import LoadingFullPage from 'components/LoadingFullPage'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

type OnSave = (pages: PagesApiRequest) => Promise<void>

type AddProps = {
  pages?: Pages
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({ pages, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<PagesApiRequest>()

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Pages | undefined
  >()

  const inputProps = useCallback(
    (fieldName: keyof PagesApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewPages = useMutation({
    mutationFn: async (pages: PagesApiRequest) => {
      await ApiService.Pages.create(pages)
    },
    onSuccess: (_data, pages: PagesApiRequest) => {
      openSuccessToast({
        message: `A página ${pages.title} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.PAGES.LIST)
    },
    onError: (error: ApiError, pages: PagesApiRequest) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${pages.title}!`,
      })
    },
  })

  useEffect(() => {
    if (isEditMode && !!pages && !formUpdated) {
      reset({
        title: pages.title,
        description: pages.description,
        category: pages.category,
        language: pages.language,
        order: pages.order,
        active: pages.active,
      })
      setFormUpdated(true)
    }
  }, [isEditMode, pages, formUpdated, reset, setValue])

  if (isEditMode && !!pages && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.PAGES.LIST}>
              Página
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} Página
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(pages as Pages)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.PAGES.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((pages: PagesApiRequest) => {
                if (isEditMode) {
                  onSave?.({
                    ...pages,
                  })
                  return
                }

                addNewPages.mutate({
                  ...pages,
                })
              })}
            >
              <Card style={{ marginBottom: '30px' }}>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.title?.message}
                        fullWidth
                        helperText={errors.title?.message}
                        label="Título da Página"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('title')}
                        {...register('title', { required: true })}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Grid container xs={12}>
                    <Grid style={{ minHeight: '400px' }} item xs={12}>
                      <HtmlEditor
                        defaultValue={getValues('description')}
                        onChange={(observationValue) =>
                          setValue('description', observationValue)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        pages={deleteConfirmation as Pages}
      />
    </>
  )
}
