import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<User>(QUERY_KEYS.USERS.EDIT(id as string), () =>
    ApiService.Users.get(id as string),
  )

  useQuery<SelectProps['options']>(QUERY_KEYS.ROLES.NAMES_AND_IDS, () =>
    ApiService.Roles.getAllNamesAndIds(),
  )

  const updateUser = useMutation({
    mutationFn: async ({ id, user }: { id: string; user: UsersApiRequest }) => {
      await ApiService.Users.update({
        id,
        user,
      })
    },
    onSuccess: (_data, { id, user }) => {
      queryClient.setQueryData<UsersApiRequest>(
        QUERY_KEYS.USERS.EDIT(id),
        (oldUser) => {
          if (!oldUser) return
          return user
        },
      )

      openSuccessToast({
        message: `O usuário ${user.name} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.USERS.LIST)
    },
    onError: (_error, { user }) => {
      openErrorToast({
        message: `Erro ao editar ${user.name}!`,
      })
    },
  })

  return (
    <Add
      user={data}
      isEditMode
      onSave={async (user: UsersApiRequest) =>
        updateUser.mutate({ id: id as string, user })
      }
    />
  )
}
