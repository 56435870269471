import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

export default function View() {
  const navigate = useNavigate()
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Permission | undefined
  >()
  const { id } = useParams<{ id: string }>()
  const { data: permission, isLoading } = useQuery<Permission>(
    QUERY_KEYS.PERMISSIONS.VIEW(id as string),
    () => ApiService.Permissions.get(id as string),
  )

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {permission?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.PERMISSIONS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(permission as Permission)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.PERMISSIONS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel label="Nome" value={permission?.name} />

              <TextWithLabel label="Método" value={permission?.method} />

              <TextWithLabel label="Url de base" value={permission?.base_url} />

              <TextWithLabel label="Caminho" value={permission?.path} />

              <TextWithLabel label="Grupo" value={permission?.grouped} />

              <TextWithLabel label="Função" value={permission?.function} />

              <div style={{ gridColumn: '1/-1' }}>
                <TextWithLabel
                  label="Descrição"
                  value={permission?.description}
                />
              </div>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        permission={deleteConfirmation as Permission}
      />
    </>
  )
}
