import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<ActivityChecklistApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<
      ActivityChecklistApiResponse[]
    >(ENDPOINTS.ACTIVITY_CHECKLIST.GET_ALL)

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).map((paymentGateway) => ({
      label: '',
      // label: paymentGateway.name,
      value: paymentGateway.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (
  id: string,
): Promise<ActivityChecklistApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ActivityChecklistApiResponse>(
      ENDPOINTS.ACTIVITY_CHECKLIST.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  activityChecklist: ItemsActivityChecklist,
): Promise<ActivityChecklistApiResponse> => {
  try {
    const creationResponse =
      await apiInstance.post<ActivityChecklistApiResponse>(
        ENDPOINTS.ACTIVITY_CHECKLIST.CREATE,
        activityChecklist,
      )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  data,
}: {
  data: ActivityChecklistsApiRequest
  id: string
}): Promise<ActivityChecklistApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<ActivityChecklistApiResponse>(
      ENDPOINTS.ACTIVITY_CHECKLIST.UPDATE(id),
      { ...data },
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.ACTIVITY_CHECKLIST.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
