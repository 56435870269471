import { useCallback, useEffect, useMemo, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid'
import DataTable from 'components/DataTable'
import { ROUTES } from 'constants/routes'
import { useQueryParams } from 'hooks/useQueryParams'
import { useNavigate } from 'react-router-dom'

const handleGetTranslatedStatus: Record<StatusBudget, string> = {
  awaiting_approval: 'Aguardando Aprovação',
  approved: 'Aprovado',
  reproved: 'Reprovado',
}

export default function Budgets() {
  const navigate = useNavigate()
  const query = useQueryParams()

  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: '',
        id: 0,
        operatorValue: '',
        value: '',
      },
    ],
    quickFilterValues: [],
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  useEffect(() => {
    const status = query.get('status')
    setFilterModel({
      items: [
        {
          columnField: 'status',
          value: status,
        },
      ],

      quickFilterValues: [],
    })
    setFilterOptions([
      {
        columnField: 'status',
        value: status,
      },
    ])
  }, [query])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'commercialUnit.fantasy_name',
        headerName: 'Unidade Comercial',
        flex: 1,
        relation: {
          name: 'budget.commercialUnit',
          alias: 'commercialUnit',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['commercialUnit']>,
        ) => params.row?.commercialUnit?.fantasy_name,
      },
      {
        field: 'institution.fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        relation: {
          name: 'budget.institution',
          alias: 'institution',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => params.row?.institution?.fantasy_name,
      },
      { field: 'name', headerName: 'Nome da atividade', width: 500 },
      {
        field: 'number_of_people',
        headerName: 'Nº mín. de pessoas',
        width: 170,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        type: 'singleSelect',
        valueOptions: [
          { value: 'awaiting_approval', label: 'Aguardando Aprovação' },
          { value: 'approved', label: 'Aprovado' },
          { value: 'reproved', label: 'Reprovado' },
        ],
        valueFormatter: ({ value }) =>
          `${handleGetTranslatedStatus[value as StatusBudget]}`,
      },
      {
        field: 'created_at',
        headerName: 'Criado em',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 100,
        sortable: false,
        exclude: true,
        renderCell: (params: GridRenderCellParams<Budget>) => {
          return (
            <>
              <EditIcon
                sx={{
                  marginRight: '10px',
                }}
                onClick={() => {
                  navigate(ROUTES.BUDGETS.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filter = filterModel.items
      if (!filter?.[0]?.value === null || !filter?.[0]?.value === undefined)
        return
      setFilterOptions(filter)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [filterModel.items])

  const handleFilter = useCallback(async (filter: GridFilterModel) => {
    if (!filter?.items?.length) return
    setFilterModel(filter)
  }, [])

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Orçamentos
            </Typography>

            <Box sx={{ m: 1 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  navigate(ROUTES.BUDGETS.CREATE)
                }}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <DataTable
              columns={columns}
              entity="Budget"
              source="budget"
              onSortModelChange={(newSortModel) => {
                setSortModel(newSortModel)
              }}
              sortParameters={sortModel}
              filterModel={filterModel}
              onFilterModelChange={handleFilter}
              likeParameters={filterOptions}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
