import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  activity?: Activity
}

export default function Delete({ opened, closeModal, activity }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeActivity = useMutation({
    mutationFn: async (activity: Activity) => {
      await ApiService.Activities.remove(activity.id.toString())
    },
    onSuccess: (_data, activity: Activity) => {
      queryClient.setQueryData<Activity[]>(
        QUERY_KEYS.ACTIVITIES.LIST,
        (oldActivities) => {
          if (!oldActivities?.length) return

          return oldActivities.filter(
            (oldActivity) => oldActivity.id !== activity.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.ACTIVITIES.EDIT(activity.id.toString()),
      )

      openSuccessToast({
        message: `A atividade ${activity.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.ACTIVITIES.LIST)
    },
    onError: (removeError: { status: number }) => {
      closeModal()

      openErrorToast({
        message:
          removeError.status === 403
            ? 'Essa atividade não pode ser removida porque esta atrelada a uma reserva'
            : (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeActivity.mutate(activity as Activity)}
      confirmColor="error"
    >
      Deseja realmente remover a atividade{' '}
      <strong>{`${activity?.name}?`}</strong>
    </ConfirmationModal>
  )
}
