import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'
import capitalize from 'utils/capitalize'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<CollaboratorApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<CollaboratorApiResponse[]>(
      ENDPOINTS.COLLABORATORS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await apiInstance.get<CollaboratorApiResponse[]>(
      ENDPOINTS.COLLABORATORS.NAME_AND_IDS_OPTIONS,
    )
    return (getAllResponse.data || [])
      .sort((currentItem, nextItem) => {
        return currentItem.name.localeCompare(nextItem?.name)
      })
      .map((collaborator) => ({
        label: capitalize(collaborator.name),
        value: collaborator.id.toString(),
      }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIdsOnlyEmployeeOptions = async (): Promise<
  SelectProps['options']
> => {
  try {
    const getAllNamesAndIdsOnlyEmployee = await apiInstance.get<
      CollaboratorApiResponse[]
    >(ENDPOINTS.COLLABORATORS.NAME_AND_IDS_ONLY_EMPLOYEE_OPTIONS)

    return (getAllNamesAndIdsOnlyEmployee.data || []).map((collaborator) => ({
      label: capitalize(collaborator.name),
      value: collaborator.id.toString(),
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIdsOnlyEmployee = async (): Promise<
  SelectProps['options']
> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || [])
      .filter((collaborator) => collaborator.type === 'employee')
      .map((collaborator) => ({
        label: capitalize(collaborator.name),
        value: collaborator.id.toString(),
      }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<CollaboratorApiResponse> => {
  try {
    const getResponse = await apiInstance.get<CollaboratorApiResponse>(
      ENDPOINTS.COLLABORATORS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  collaborator: CollaboratorApiRequest,
): Promise<CollaboratorApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<CollaboratorApiResponse>(
      ENDPOINTS.COLLABORATORS.CREATE,
      collaborator,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  collaborator,
}: {
  collaborator: CollaboratorApiRequest
  id: string
}): Promise<CollaboratorApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<CollaboratorApiResponse>(
      ENDPOINTS.COLLABORATORS.UPDATE(id),
      collaborator,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.COLLABORATORS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const sendMessage = async ({
  id,
  message,
}: CollaboratorSendMessageRequest) => {
  try {
    const sendMessageResponse = await apiInstance.post(
      ENDPOINTS.COLLABORATORS.SEND_MESSAGE,
      {
        id,
        message,
      },
    )
    return sendMessageResponse.data
  } catch (sendMessageError) {
    const axiosError = sendMessageError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
