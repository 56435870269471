import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const commercialUnitsRoutes: RouteObject[] = [
  {
    path: ROUTES.COMMERCIAL_UNITS.LIST,
    element: (
      <PrivateRoute>
        <Pages.CommercialUnits />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.COMMERCIAL_UNITS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddCommercialUnits />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.COMMERCIAL_UNITS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditCommercialUnits />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.COMMERCIAL_UNITS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewCommercialUnits />
      </PrivateRoute>
    ),
  },
]

export default commercialUnitsRoutes
