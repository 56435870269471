import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const teachersRoutes: RouteObject[] = [
  {
    path: ROUTES.TEACHERS.LIST,
    element: (
      <PrivateRoute>
        <Pages.Teachers />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.TEACHERS.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddTeachers />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.TEACHERS.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditTeachers />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.TEACHERS.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewTeachers />
      </PrivateRoute>
    ),
  },
]

export default teachersRoutes
