import { useEffect, useRef } from 'react'

import { GridRenderEditCellParams } from '@mui/x-data-grid'
import Select from 'components/Select'
import { ACTIVITY_TASK_STATUS_OPTIONS } from 'constants/types'

export const CustomEditSelectFieldChecklist = ({
  value,
  field,
  row,
  error,
  onBlur,
  onChange,
}: GridRenderEditCellParams & {
  setActivityChecklist: React.Dispatch<
    React.SetStateAction<ActivityChecklistItemsForm[]>
  >
  activityChecklist: ActivityChecklistItemsForm[]
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current && row?.isNew && field === 'unit_value') {
      inputRef.current.focus()
    }
  }, [field, row?.isNew])

  return (
    <Select
      fullWidth
      label=""
      id=""
      value={value}
      onChange={(event) => {
        onChange?.(event)
      }}
      inputRef={inputRef}
      error={error}
      onBlur={onBlur}
      options={ACTIVITY_TASK_STATUS_OPTIONS}
      sx={{
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
          borderRadius: '0px !important',
        },
        '&.MuiOutlinedInput-root': {
          border: 0,
          borderRadius: '0px !important',
        },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 0,
          borderRadius: '0px !important',
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 0,
            borderRadius: '0px !important',
          },
      }}
    />
  )
}
