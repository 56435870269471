import * as yup from 'yup'

const MESSAGE_REQUIRED = 'Campo obrigatório'

const schema = yup.object().shape({
  type_institution: yup.string().required(MESSAGE_REQUIRED),
  name: yup.string().required(MESSAGE_REQUIRED),
  number_of_people: yup.string().required(MESSAGE_REQUIRED),
  payment_gateway_id: yup.string().required(MESSAGE_REQUIRED),
  type_activity: yup.string().required(MESSAGE_REQUIRED),
  // budgetsItems: yup.array().of(
  //   yup.object().shape({
  //     unit_value: yup.string().required(MESSAGE_REQUIRED),
  //     quantity: yup.string().required(MESSAGE_REQUIRED),
  //   }),
  // ),
})

const addSupplierSchema = yup.object().shape({
  supplier_name: yup.string().required(MESSAGE_REQUIRED),
  item_budget_id: yup.string().required(MESSAGE_REQUIRED),
  category_id: yup.string().required(MESSAGE_REQUIRED),
  supplier_id: yup.string().optional(),
})

export { schema, addSupplierSchema }
