import { useMemo, useState, useCallback, useEffect } from 'react'

import { Box } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import DataTable from 'components/DataTable'
import { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ApiService } from 'services/api'

export default function AuditLogs() {
  const { data: userOptions } = useQuery<SelectProps['options']>(
    QUERY_KEYS.USERS.NAMES,
    async () => {
      const responseUsers = await ApiService.Users.getAll()
      return responseUsers.map((user) => ({
        label: user.name,
        value: user.name,
      }))
    },
  )
  const { data: clientOptions } = useQuery<SelectProps['options']>(
    QUERY_KEYS.CLIENTS.NAMES,
    async () => {
      const responseUsers = await ApiService.Clients.getAll()
      return responseUsers.map((client) => ({
        label: client.fantasy_name,
        value: client.fantasy_name,
      }))
    },
  )
  const tableColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'type',
        headerName: 'Tipo',
        width: 200,
        type: 'singleSelect',
        valueOptions: [
          {
            value: 'create',
            label: 'Criação',
          },
          { value: 'update', label: 'Edição' },
          { value: 'delete', label: 'Remoção' },
          { value: 'report', label: 'Relatório' },
          { value: 'export', label: 'Exportação' },
        ],
      },
      {
        field: 'label.entity_translated',
        headerName: 'Entidade',
        width: 200,
        relation: {},
        valueGetter: (params) => params.row.label?.entity_translated,
      },
      {
        field: 'entity_id',
        headerName: 'Cod.',
        width: 200,
        valueGetter: (params) =>
          params.row.entity_id === 0 ? '' : params.row.entity_id,
      },
      {
        field: 'descriptions',
        headerName: 'Descrição',
        filterable: false,
        flex: 1,
        renderCell: (cell) => {
          const arrayMap = cell.row.descriptions.split('\n')
          return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {arrayMap.map((item: string) => (
                <p
                  style={
                    cell.colDef.computedWidth
                      ? {
                          maxWidth: cell.colDef.computedWidth,
                          wordWrap: 'break-word',
                        }
                      : undefined
                  }
                  key={item}
                >
                  {item}
                </p>
              ))}
            </div>
          )
        },
      },
      {
        field: 'user.name',
        headerName: 'Usuário',
        width: 200,
        valueGetter: (params) => {
          return params.row?.user?.name
        },
        type: 'singleSelect',
        valueOptions: userOptions,
        exclude: true,
      },
      {
        field: 'client.fantasy_name',
        headerName: 'Cliente',
        width: 200,
        valueGetter: (params) => {
          return params.row?.client?.fantasy_name
        },
        type: 'singleSelect',
        valueOptions: clientOptions,
        exclude: true,
      },
      {
        field: 'created_at',
        type: 'date',
        headerName: 'Criado em',
        width: 200,
      },
    ],
    [clientOptions, userOptions],
  )

  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: '',
        id: 0,
        operatorValue: '',
        value: '',
      },
    ],
    quickFilterValues: [],
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filter = filterModel.items
      if (!filter?.[0]?.value === null || !filter?.[0]?.value === undefined)
        return
      setFilterOptions(filter)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [filterModel, filterModel.items])

  const handleFilter = useCallback(async (filter: GridFilterModel) => {
    if (!filter?.items?.length) return
    setFilterModel(filter)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Auditoria
            </Typography>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <DataTable
              columns={tableColumns}
              entity="AuditLog"
              source="auditLog"
              onSortModelChange={(newSortModel) => {
                setSortModel(newSortModel)
              }}
              sortParameters={sortModel}
              filterModel={filterModel}
              onFilterModelChange={handleFilter}
              likeParameters={filterOptions}
              getEstimatedRowHeight={() => 100}
              getRowHeight={() => 'auto'}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
