import { useEffect, useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type AddProps = {
  itemsBudget?: ItemsBudget
  onSave?: (itemsBudgets: ItemsBudget) => Promise<void>
  isEditMode?: boolean
}

export default function Add({
  itemsBudget,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<ItemsBudget>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    ItemsBudget | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!itemsBudget && !formUpdated) {
      reset({
        name: itemsBudget.name,
        category_id: itemsBudget.category_id,
      })
      setValue('default', itemsBudget.default ? 1 : 0)
      setValue('active', itemsBudget.active ? 1 : 0)
      setValue(
        'has_service_order',
        itemsBudget.has_service_order?.toString() as unknown as number,
      )
      setFormUpdated(true)
    }
  }, [isEditMode, itemsBudget, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof ItemsBudget) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const { data: categories, isLoading: isLoadingCategories } = useQuery<
    SelectProps['options']
  >(QUERY_KEYS.CATEGORIES.NAMES_AND_IDS, () =>
    ApiService.Categories.getAllNamesAndIds(),
  )

  const addNewItemsBudget = useMutation({
    mutationFn: async (itemsBudget: ItemsBudget) => {
      await ApiService.ItemsBudget.create({
        name: itemsBudget.name,
        default: itemsBudget.default,
        has_service_order: itemsBudget.has_service_order,
        category_id: itemsBudget.category_id
          ? Number(itemsBudget.category_id)
          : undefined,
        active: !!Number(itemsBudget.active),
      })
    },
    onSuccess: (_data, itemsBudget: ItemsBudget) => {
      queryClient.setQueryData<ItemsBudget[]>(
        QUERY_KEYS.ITEMS_BUDGET.LIST,
        (oldItemsBudget) => {
          if (!oldItemsBudget?.length) return

          return oldItemsBudget.map((currentItemsBudget) => {
            if (currentItemsBudget.id === itemsBudget.id) {
              return itemsBudget
            }

            return currentItemsBudget
          })
        },
      )

      openSuccessToast({
        message: `O item de orçamento ${itemsBudget.name} foi adicionado com sucesso!`,
      })

      navigate(ROUTES.ITEMS_BUDGET.LIST)
    },
    onError: (error, itemsBudget: ItemsBudget) => {
      const apiError = error as ApiError

      if (apiError?.status === 400) {
        openErrorToast({
          message: `O item de orçamento ${itemsBudget.name} já está cadastrado!`,
        })

        return
      }

      openErrorToast({
        message: `Erro ao adicionar ${itemsBudget.name}!`,
      })
    },
  })

  if (
    isEditMode &&
    !!itemsBudget &&
    formUpdated === false &&
    isLoadingCategories
  ) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.ITEMS_BUDGET.LIST}
            >
              Item de Orçamento
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {isEditMode ? 'Editar' : 'Adicionar'} Item de Orçamento
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                {isEditMode && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setDeleteConfirmation(itemsBudget as ItemsBudget)
                      }}
                    >
                      Remover
                    </Button>
                  </Box>
                )}

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ITEMS_BUDGET.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((itemsBudget: ItemsBudget) => {
                if (isEditMode) {
                  onSave?.(itemsBudget)
                  return
                }

                addNewItemsBudget.mutate(itemsBudget)
              })}
            >
              <Card>
                <CardContent>
                  <Grid item container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="category_id"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Categorias"
                              id="category_id"
                              autoFill={isEditMode}
                              onChange={onChange}
                              value={value}
                              options={categories || []}
                              error={!!errors.category_id?.message}
                              helperText={errors.category_id?.message}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        helperText={errors.name?.message}
                        fullWidth
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="default"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Padrão"
                              id="default"
                              onChange={onChange}
                              value={value?.toString()}
                              {...inputProps('default')}
                              autoFill={isEditMode}
                              error={!!errors.default?.message}
                              helperText={errors.default?.message}
                              options={[
                                {
                                  label: 'Sim',
                                  value: '1',
                                },
                                {
                                  label: 'Não',
                                  value: '0',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="active"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Ativo"
                              id="active"
                              error={!!errors.active?.message}
                              helperText={errors.active?.message}
                              onChange={onChange}
                              value={value?.toString()}
                              {...inputProps('active')}
                              autoFill={isEditMode}
                              options={[
                                {
                                  label: 'Sim',
                                  value: '1',
                                },
                                {
                                  label: 'Não',
                                  value: '0',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="has_service_order"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Ordem de serviço"
                              id="has_service_order"
                              error={!!errors.has_service_order?.message}
                              helperText={errors.has_service_order?.message}
                              onChange={onChange}
                              value={value?.toString()}
                              {...inputProps('has_service_order')}
                              autoFill={isEditMode}
                              options={[
                                {
                                  label: 'Sim',
                                  value: '1',
                                },
                                {
                                  label: 'Não',
                                  value: '0',
                                },
                              ]}
                            />
                          )
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        itemsBudget={deleteConfirmation as ItemsBudget}
      />
    </>
  )
}
