import { useState, useMemo } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

enum TABS {
  TEACHERS,
  CAMPUS,
  CLASS,
}

export default function View() {
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { data, isLoading } = useQuery<Institution>(
    QUERY_KEYS.INSTITUTIONS.VIEW(id as string),
    () => ApiService.Institutions.get(id as string),
  )

  const [currentTab, setCurrentTab] = useState<TABS>(TABS.TEACHERS)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Institution | undefined
  >()

  const teachersColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'cell_phone', headerName: 'Celular', flex: 1 },
      { field: 'phone', headerName: 'Telefone', flex: 1 },
      {
        field: 'active',
        headerName: 'Ativo',
        valueGetter: (params: GridValueGetterParams<Institution['teachers']>) =>
          params.row.active ? 'Sim' : 'Não',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Institution>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.TEACHERS.VIEW(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  const campusInstitutionsColumns = useMemo<GridColDef[]>(
    () => [{ field: 'name', headerName: 'Nome', flex: 1 }],
    [],
  )

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {data?.fantasy_name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.INSTITUTIONS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(data as Institution)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.INSTITUTIONS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel
                label="Unidade Comercial"
                value={data?.commercialUnit?.fantasy_name}
              />

              <TextWithLabel label="Razão Social" value={data?.name} />

              <TextWithLabel
                label="Tipo"
                value={data?.type === 'college' ? 'Faculdade' : 'Escola'}
              />

              <TextWithLabel
                label="Nome de contato"
                value={data?.contact_name}
              />

              <TextWithLabel label="CNPJ" value={data?.document} />

              <TextWithLabel label="Email" value={data?.email} />

              <TextWithLabel label="Telefone" value={data?.phone} />

              <TextWithLabel label="Celular" value={data?.cell_phone} />

              <TextWithLabel
                label="Endereço"
                value={`${data?.street}, ${data?.number}`}
              />

              <TextWithLabel label="Complemento" value={data?.complement} />

              <TextWithLabel label="Bairro" value={data?.district} />

              <TextWithLabel
                label="Cidade"
                value={`${data?.city}/${data?.uf}`}
              />

              <TextWithLabel label="CEP" value={data?.zip_code} />
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Professores" />
                  <Tab label="Campus" />
                  <Tab label="Turmas" />
                </Tabs>

                <TabPanel value={TABS.TEACHERS} index={currentTab}>
                  <Table rows={data?.teachers} columns={teachersColumns} />
                </TabPanel>

                <TabPanel value={TABS.CAMPUS} index={currentTab}>
                  <Table
                    rows={data?.campusInstitutions}
                    columns={campusInstitutionsColumns}
                  />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        institution={deleteConfirmation as Institution}
      />
    </>
  )
}
