import { AxiosError } from 'axios'
import type { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (
  type?: Category['type'],
): Promise<CategoriesApiResponse> => {
  try {
    const getAllResponse = await apiInstance.get<CategoriesApiResponse>(
      ENDPOINTS.CATEGORIES.GET_ALL,
    )

    if (type) {
      return getAllResponse.data.filter((category) => category.type === type)
    }

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (
  type?: Category['type'],
): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll(type)

    return (getAllResponse || []).map((category) => ({
      label: category.name,
      value: category.id.toString(),
    }))
  } catch (_getAllError) {
    return Promise.reject([])
  }
}

export const getAllNamesIdsFields = async (): Promise<
  CategoriesGetAllNamesIdsFieldsApiResponse[]
> => {
  try {
    const getAllResponse = await getAll()

    return (getAllResponse || []).map((category) => ({
      label: category.name,
      value: category.id.toString(),
      fields: category.fields,
    }))
  } catch (_getAllError) {
    return Promise.reject([])
  }
}

export const getAllWithSubCategories = async (
  type?: Category['type'],
): Promise<CategoryWithSubCategory[]> => {
  try {
    const getAllResponse = await apiInstance.get<CategoryWithSubCategory[]>(
      ENDPOINTS.CATEGORIES.GET_ALL,
    )

    if (type) {
      return getAllResponse.data.filter((category) => category.type === type)
    }

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<CategoriesApiResponse> => {
  try {
    const getResponse = await apiInstance.get<CategoriesApiResponse>(
      ENDPOINTS.CATEGORIES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  category: CategoriesApiRequest,
): Promise<CategoriesApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<CategoriesApiResponse>(
      ENDPOINTS.CATEGORIES.CREATE,
      category,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  category,
}: {
  category: CategoriesApiRequest
  id: string
}): Promise<CategoriesApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<CategoriesApiResponse>(
      ENDPOINTS.CATEGORIES.UPDATE(id),
      category,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.CATEGORIES.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
