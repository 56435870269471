import React from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export type SelectWithCheckboxOptions = Array<{
  label: string
  value: string | number
}>

type SelectWithCheckboxProps = {
  options: SelectWithCheckboxOptions
  value: SelectWithCheckboxOptions
  onSelect: (value: React.SetStateAction<SelectWithCheckboxOptions>) => void
  selectAllLabel: string
  placeholder: string
}

export default function SelectWithCheckbox({
  options = [],
  value = [],
  onSelect,
  selectAllLabel,
  placeholder,
}: SelectWithCheckboxProps) {
  const allSelected = options.length === value.length

  return (
    <Autocomplete
      multiple
      openOnFocus
      options={[{ label: selectAllLabel, value: 'ALL', type: '' }, ...options]}
      limitTags={1}
      value={value}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_event, selectedOptions, reason) => {
        if (reason === 'clear') {
          return onSelect([])
        }

        if (reason === 'removeOption') {
          return onSelect(selectedOptions)
        }

        if (
          reason === 'selectOption' &&
          selectedOptions.find((option) => option.value === 'ALL')
        ) {
          return onSelect(options)
        }

        onSelect(selectedOptions)
      }}
      renderOption={(props, option, { selected }) => {
        const selectAllProps =
          option.value === 'ALL' ? { checked: allSelected } : {}

        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {option.label}
          </li>
        )
      }}
      style={{ minWidth: 250 }}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap',
        },
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={placeholder}
            sx={{
              '& .MuiAutocomplete-input ': {
                minWidth: '100px !important',
              },
            }}
          />
        )
      }}
      noOptionsText="Nada foi encontrado"
      renderTags={(value) => {
        const numTags = value.length

        return (
          <Typography>
            {value
              .slice(0, 2)
              .map((option, _) => option.label)
              .join(', ')}

            {numTags > 2 && ` e outras ${numTags - 2}`}
          </Typography>
        )
      }}
    />
  )
}
