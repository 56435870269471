import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const { data } = useQuery<SupplierApiResponse>(
    QUERY_KEYS.SUPPLIERS.EDIT(id as string),
    () => ApiService.Suppliers.get(id as string),
  )

  const onSave = useCallback(
    async (supplier: SupplierApiRequest) => {
      if (!id) return

      await ApiService.Suppliers.update({
        id,
        supplier,
      })

      CacheService.queryClient.invalidateQueries(
        QUERY_KEYS.SUPPLIERS.EDIT(id as string),
      )

      navigate(ROUTES.SUPPLIERS.LIST)
    },
    [id, navigate],
  )

  return <Add supplier={data} onSave={onSave} isEditMode />
}
