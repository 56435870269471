import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import type { RouteObject } from 'react-router-dom'

import { PrivateRoute } from './private'

const institutionsRoutes: RouteObject[] = [
  {
    path: ROUTES.AFFILIATES.LIST,
    element: (
      <PrivateRoute>
        <Pages.Affiliates />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.AFFILIATES.CREATE,
    element: (
      <PrivateRoute>
        <Pages.AddAffiliate />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.AFFILIATES.EDIT(':id'),
    element: (
      <PrivateRoute>
        <Pages.EditAffiliate />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.AFFILIATES.VIEW(':id'),
    element: (
      <PrivateRoute>
        <Pages.ViewAffiliate />
      </PrivateRoute>
    ),
  },
]

export default institutionsRoutes
