export const ACCOUNT_TYPES: { label: string; value: AccountTypes }[] = [
  {
    label: 'Conta Corrente',
    value: 'current_account',
  },
  {
    label: 'Conta Poupança',
    value: 'savings_account',
  },
]

export const FINANCIAL_MOVEMENT_PAYMENT_METHODS = {
  C: 'Cartão de Crédito',
  B: 'Boleto',
  P: 'Pix',
  TR: 'Transferência de Reserva',
}

export const ACTIVITY_TASK_STATUS = {
  realized: 'Realizado',
  pending: 'Pendente',
  cancelled: 'Cancelado',
}

export const PERMISSIONS_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const CLIENTS_GENDER = {
  not_inform: 'Não informado',
  masculine: 'Masculino',
  feminine: 'Feminino',
}

export const PATH_METHODS = {
  create: 'POST',
  view: 'GET',
  update: 'PUT',
  delete: 'DELETE',
}
export const RESERVATION_SITUATION: Record<string, string> = {
  waiting_payment: 'Aguardando pagamento',
  payment_confirmed: 'Pago',
  realized: 'Realizada',
  cancelled: 'Cancelada',
  payment_reversal: 'Pagamento estornado',
  finished: 'Finalizado',
}

export const FINANCIAL_MOVEMENT_PAYMENT_METHODS_OPTIONS = Object.entries(
  FINANCIAL_MOVEMENT_PAYMENT_METHODS,
).map(([keyField, valueField]) => ({ label: valueField, value: keyField }))

export const ACTIVITY_TASK_STATUS_OPTIONS = Object.entries(
  ACTIVITY_TASK_STATUS,
).map(([keyField, valueField]) => ({ label: valueField, value: keyField }))

export const PERMISSIONS_METHODS_OPTIONS = Object.entries(
  PERMISSIONS_METHODS,
).map(([key, value]) => ({ label: key, value: value }))
export const RESERVATION_SITUATION_OPTIONS = Object.entries(
  RESERVATION_SITUATION,
).map(([keyField, valueField]) => ({ label: valueField, value: keyField }))

export const ACTIVITY_SITATUS_MAP_COLOR = {
  opened: '#d8b400',
  cancelled: 'red',
  confirmed: 'green',
  finished: 'blue',
}
