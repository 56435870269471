import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { OnSaveProps } from 'components/CreateOrderService/@types/form'
import { handleUpdateOrderService } from 'components/CreateOrderService/utils/handle-update-order-service'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<OrderServiceWithItemsApiResponse>(
    QUERY_KEYS.ORDER_SERVICES.EDIT(id as string),
    () => ApiService.OrderServices.getWithItemsById(id as string),
  )

  const handleUpdateModal = useCallback(
    async ({ id, orderService }: OnSaveProps) => {
      await handleUpdateOrderService({
        id,
        openErrorToast,
        openSuccessToast,
        orderService,
        onClose: () => {
          navigate(ROUTES.ORDER_SERVICES.LIST)
        },
      })
    },
    [navigate, openErrorToast, openSuccessToast],
  )

  return <Add orderService={data} isEditMode onSave={handleUpdateModal} />
}
