import { useMemo } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

export const PAYMENT_GATEWAYS_TYPES: Record<string, paymentGatewaysTypes> = {
  credit_card: 'Cartão de crédito',
  pix: 'Pix',
}

export default function PaymentGateways() {
  const navigate = useNavigate()

  const { data } = useQuery<PaymentGateway[]>(
    QUERY_KEYS.PAYMENT_GATEWAYS.LIST,
    () => ApiService.PaymentGateways.getAll(),
  )

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'paymentModule.name',
        headerName: 'Modulo',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentGateway>) =>
          params.row.paymentModule?.name,
      },
      {
        field: 'paymentModule.type',
        headerName: 'Tipo',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentGateway>) => {
          return PAYMENT_GATEWAYS_TYPES[params?.row?.paymentModule?.type]
        },
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<PaymentGateway>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.PAYMENT_GATEWAYS.VIEW(params.row.id))
                }}
              />

              <EditIcon
                onClick={() => {
                  navigate(ROUTES.PAYMENT_GATEWAYS.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Gateways de Pagamento
            </Typography>

            <Box sx={{ m: 1 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  navigate(ROUTES.PAYMENT_GATEWAYS.CREATE)
                }}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Table rows={data} columns={columns} />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
