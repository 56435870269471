import { useCallback, useState } from 'react'

import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import MUIAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MUIMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Logo from 'assets/logo'
import ChangePassword from 'components/ChangePasswordModal'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'

import Menu from './Menu'

export default function AppBar() {
  const {
    logout,
    changePasswordOpened,
    openChangePasswordModal,
    closeChangePasswordModal,
  } = useGlobalState()
  const navigate = useNavigate()

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorElement(null)
  }, [])

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <MUIAppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                setMenuOpened((prevState) => !prevState)
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(ROUTES.ROOT)
              }}
            >
              <Logo
                height={35}
                width={35}
                owlColor="white"
                noseColor="transparente"
                style={{ marginRight: 10 }}
              />
              Painel Administrativo
            </Typography>

            <div>
              <IconButton
                size="large"
                aria-label="Conta do usuário"
                aria-controls="menu-app_bar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <MUIMenu
                id="menu-app_bar"
                anchorEl={anchorElement}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElement)}
                onClose={handleClose}
              >
                <MenuItem onClick={openChangePasswordModal}>
                  Alterar Senha
                </MenuItem>
                <MenuItem onClick={logout}>Sair</MenuItem>
              </MUIMenu>
            </div>
          </Toolbar>
        </MUIAppBar>
      </Box>

      <Menu
        opened={menuOpened}
        onClose={() => {
          setMenuOpened(false)
        }}
      />

      <ChangePassword
        opened={changePasswordOpened}
        title={'Alterar Senha'}
        onClose={closeChangePasswordModal}
      />
    </>
  )
}
