export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  NOT_FOUND: '*',

  AUDITLOGS: {
    LIST: '/auditlogs',
  },

  LABELS: {
    LIST: '/labels',
    CREATE: '/labels/create',
    EDIT: (id: string) => `/labels/update/${id}`,
    VIEW: (id: string) => `/labels/view/${id}`,
  },

  ORDER_SERVICES: {
    LIST: '/commercial/orderServices',
    CREATE: '/commercial/orderServices/create',
    EDIT: (id: string) => `/commercial/orderServices/update/${id}`,
    VIEW: (id: string) => `/commercial/orderServices/view/${id}`,
  },

  ACTIVITY_TASKS: {
    LIST: '/commercial/activityTasks',
    CREATE: '/commercial/activityTasks/create',
    EDIT: (id: string) => `/commercial/activityTasks/update/${id}`,
    VIEW: (id: string) => `/commercial/activityTasks/view/${id}`,
  },

  ACTIVITIES: {
    LIST: '/commercial/activities',
    CREATE: '/commercial/activities/create',
    EDIT: (id: string) => `/commercial/activities/update/${id}`,
    VIEW: (id: string) => `/commercial/activities/view/${id}`,
    LIST_CALENDAR: `/commercial/activities/listCalendar`,
  },

  INSTITUTIONS: {
    LIST: '/commercial/institutions',
    CREATE: '/commercial/institutions/create',
    EDIT: (id: string) => `/commercial/institutions/update/${id}`,
    VIEW: (id: string) => `/commercial/institutions/view/${id}`,
  },

  VIDEOS: {
    LIST: '/commercial/videos',
    CREATE: '/commercial/videos/create',
    EDIT: (id: string) => `/commercial/videos/update/${id}`,
    VIEW: (id: string) => `/commercial/videos/view/${id}`,
  },

  RESERVATIONS: {
    LIST: '/commercial/reservations',
    CREATE: '/commercial/reservations/create',
    EDIT: (id: string) => `/commercial/reservations/update/${id}`,
    VIEW: (id: string) => `/commercial/reservations/view/${id}`,
    IMPORT: '/commercial/reservations/import',
  },

  COMMERCIAL_UNITS: {
    LIST: '/commercial/commercialUnits',
    CREATE: '/commercial/commercialUnits/create',
    EDIT: (id: string) => `/commercial/commercialUnits/update/${id}`,
    VIEW: (id: string) => `/commercial/commercialUnits/view/${id}`,
  },

  TEACHERS: {
    LIST: '/commercial/teachers',
    CREATE: '/commercial/teachers/create',
    EDIT: (id: string) => `/commercial/teachers/update/${id}`,
    VIEW: (id: string) => `/commercial/teachers/view/${id}`,
  },

  SUPPLIERS: {
    LIST: '/commercial/suppliers',
    CREATE: '/commercial/suppliers/create',
    EDIT: (id: string) => `/commercial/suppliers/update/${id}`,
    VIEW: (id: string) => `/commercial/suppliers/view/${id}`,
  },

  COLLABORATORS: {
    LIST: '/commercial/teams',
    CREATE: '/commercial/teams/create',
    EDIT: (id: string) => `/commercial/teams/update/${id}`,
    VIEW: (id: string) => `/commercial/teams/view/${id}`,
  },

  CAMPUS_INSTITUTIONS: {
    LIST: '/commercial/campusInstitutions',
    CREATE: '/commercial/campusInstitutions/create',
    EDIT: (id: string) => `/commercial/campusInstitutions/update/${id}`,
    VIEW: (id: string) => `/commercial/campusInstitutions/view/${id}`,
  },

  ROLES: {
    LIST: '/users/roles',
    CREATE: '/users/roles/create',
    EDIT: (id: string) => `/users/roles/update/${id}`,
    VIEW: (id: string) => `/users/roles/view/${id}`,
  },

  PERMISSIONS: {
    LIST: '/users/permissions',
    CREATE: '/users/permissions/create',
    EDIT: (id: string) => `/users/permissions/update/${id}`,
    VIEW: (id: string) => `/users/permissions/view/${id}`,
  },

  PERMISSIONS_ASSOCIATED: {
    LIST: '/users/permissionsAssociated',
    CREATE: '/users/permissionsAssociated/create',
    EDIT: (id: string) => `/users/permissionsAssociated/update/${id}`,
    VIEW: (id: string) => `/users/permissionsAssociated/view/${id}`,
  },

  USERS: {
    LIST: '/users',
    CREATE: '/users/create',
    EDIT: (id: string) => `/users/update/${id}`,
    VIEW: (id: string) => `/users/view/${id}`,
  },

  MENUS: {
    LIST: '/users/menus',
    CREATE: '/users/menus/create',
    EDIT: (id: string) => `/users/menus/update/${id}`,
    VIEW: (id: string) => `/users/menus/view/${id}`,
  },

  IPS_ALLOWED: {
    LIST: '/users/ipAllowed',
    CREATE: '/users/ipAllowed/create',
    EDIT: (id: string) => `/users/ipAllowed/update/${id}`,
    VIEW: (id: string) => `/users/ipAllowed/view/${id}`,
  },

  AFFILIATES: {
    LIST: '/commercial/affiliates',
    CREATE: '/commercial/affiliates/create',
    EDIT: (id: string) => `/commercial/affiliates/update/${id}`,
    VIEW: (id: string) => `/commercial/affiliates/view/${id}`,
  },

  GLOBAL_PARAMETERS: {
    LIST: '/commercial/globalParameters',
    CREATE: '/commercial/globalParameters/create',
    EDIT: (id: string) => `/commercial/globalParameters/update/${id}`,
    VIEW: (id: string) => `/commercial/globalParameters/view/${id}`,
  },

  PAYMENT_GATEWAYS: {
    LIST: '/financial/paymentGateways',
    CREATE: '/financial/paymentGateways/create',
    EDIT: (id: string) => `/financial/paymentGateways/update/${id}`,
    VIEW: (id: string) => `/financial/paymentGateways/view/${id}`,
  },

  PAYMENT_MODULES: {
    LIST: '/financial/paymentModules',
    CREATE: '/financial/paymentModules/create',
    EDIT: (id: string) => `/financial/paymentModules/update/${id}`,
    VIEW: (id: string) => `/financial/paymentModules/view/${id}`,
  },

  ITEMS_BUDGET: {
    LIST: '/commercial/itemsBudget',
    CREATE: '/commercial/itemsBudget/create',
    EDIT: (id: string) => `/commercial/itemsBudget/update/${id}`,
    VIEW: (id: string) => `/commercial/itemsBudget/view/${id}`,
  },

  CLIENTS: {
    LIST: '/commercial/clients',
    CREATE: '/commercial/clients/create',
    EDIT: (id: string) => `/commercial/clients/update/${id}`,
    VIEW: (id: string) => `/commercial/clients/view/${id}`,
  },

  BUDGETS: {
    LIST: '/commercial/budgets',
    CREATE: '/commercial/budgets/create',
    EDIT: (id: string) => `/commercial/budgets/update/${id}`,
    VIEW: (id: string) => `/commercial/budgets/view/${id}`,
  },

  ACTIVITY_CHECKLIST: {
    LIST: '/commercial/activityChecklists',
    CREATE: '/commercial/activityChecklists/create',
    EDIT: (id: string) => `/commercial/activityChecklists/update/${id}`,
    VIEW: (id: string) => `/commercial/activityChecklists/view/${id}`,
  },

  PAGES: {
    LIST: '/commercial/pages',
    CREATE: '/commercial/pages/create',
    EDIT: (id: string) => `/commercial/pages/update/${id}`,
  },

  REPORTS: {
    SALES: '/commercial/reports/sales',
    SALES_AFFILIATES: '/commercial/reports/salesAffiliates',
    ACTIVITIES_CONTROL: '/commercial/reports/activitiesControl',
    SCHOOL_TRIP: '/commercial/reports/schoolTrip',
    FACULTY_TRIP: '/commercial/reports/facultyTrip',
  },

  CHARTS: {
    SALES: '/charts/sales',
    AVERAGE_TICKET: '/charts/averageTicket',
    SALES_AFFILIATES: '/charts/salesAffiliate',
    AFFILIATE_AVERAGE_TICKET: '/charts/affiliateAverageTicket',
    RESERVATIONS: '/charts/reservations',
    SHIPPING_VALUE: '/charts/shippingValue',
  },
}
