import Box from '@mui/material/Box'
import Loading from 'components/Loading'

export default function LoadingFullPage() {
  return (
    <Box
      component="main"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading />
    </Box>
  )
}
