import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  paymentModule?: PaymentModule
}

export default function Delete({
  opened,
  closeModal,
  paymentModule,
}: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removePaymentModule = useMutation({
    mutationFn: async (paymentModule: PaymentModule) => {
      await ApiService.PaymentModules.remove(paymentModule.id.toString())

      openSuccessToast({
        message: `A forma de pagamento ${paymentModule?.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.PAYMENT_GATEWAYS.LIST)
    },
    onSuccess: (_data, paymentModule: PaymentModule) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.PAYMENT_GATEWAYS.LIST,
        (oldPaymentModules) => {
          if (!oldPaymentModules?.length) return

          return oldPaymentModules.filter(
            (oldPaymentModule) => oldPaymentModule.id !== paymentModule.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(paymentModule.id.toString()),
      )

      openSuccessToast({
        message: `O módulo de pagamento ${paymentModule.name} foi removida com sucesso!`,
      })

      navigate(ROUTES.PAYMENT_GATEWAYS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() =>
        removePaymentModule.mutate(paymentModule as PaymentModule)
      }
      confirmColor="error"
    >
      Deseja realmente remover a forma de pagamento{' '}
      <strong>{`${paymentModule?.name}?`}</strong>
    </ConfirmationModal>
  )
}
