import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<
  ResponsibleGuideActivityApiResponse[]
> => {
  try {
    const getAllResponse = await apiInstance.get<
      ResponsibleGuideActivityApiResponse[]
    >(ENDPOINTS.RESPONSIBLE_GUIDES.GET_ALL)

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async ({
  id,
}: {
  id: number | string
}): Promise<boolean> => {
  try {
    const removeResponse = await apiInstance.delete<boolean>(
      ENDPOINTS.RESPONSIBLE_GUIDES.DELETE(id),
    )

    return removeResponse.data
  } catch (removeError) {
    const axiosError = removeError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
