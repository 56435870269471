import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputCurrency from 'components/InputCurrency'
import { Controller, useFormContext } from 'react-hook-form'

type ActivityFormProps = {
  inputProps: (fieldName: keyof ActivityApiRequest) => {
    InputLabelProps: {
      shrink: boolean
    }
  }
}

export const TransportScheduleForm = ({ inputProps }: ActivityFormProps) => {
  const { register, control, formState } = useFormContext<ActivityApiRequest>()

  return (
    <Grid container spacing={3} rowSpacing={2} marginTop={1}>
      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.departure_at?.message}
          helperText={formState?.errors?.departure_at?.message}
          fullWidth
          label="Saída às (hs)"
          InputLabelProps={{ shrink: true }}
          placeholder=""
          margin="normal"
          type="time"
          variant="outlined"
          {...register('departure_at')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.shipping_company?.message}
          helperText={formState?.errors?.shipping_company?.message}
          fullWidth
          label="Transportadora"
          margin="normal"
          type="text"
          variant="outlined"
          {...inputProps('shipping_company')}
          {...register('shipping_company')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.type_shipping_company?.message}
          helperText={formState?.errors?.type_shipping_company?.message}
          fullWidth
          label="Tipo Transporte"
          margin="normal"
          type="text"
          variant="outlined"
          {...inputProps('type_shipping_company')}
          {...register('type_shipping_company')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.return_at?.message}
          helperText={formState?.errors?.return_at?.message}
          fullWidth
          label="Retorno às (hs)"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          type="time"
          variant="outlined"
          {...register('return_at')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.driver_name?.message}
          helperText={formState?.errors?.driver_name?.message}
          fullWidth
          label="Nome Motorista"
          margin="normal"
          type="text"
          variant="outlined"
          {...inputProps('driver_name')}
          {...register('driver_name')}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="value_shipping_company"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputCurrency
                error={!!formState?.errors?.value_shipping_company?.message}
                helperText={formState?.errors?.value_shipping_company?.message}
                fullWidth
                label="Valor Transporte"
                id="value_shipping_company"
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Restaurante"
          type="text"
          margin="normal"
          variant="outlined"
          error={!!formState?.errors?.restaurant?.message}
          helperText={formState?.errors?.restaurant?.message}
          {...inputProps('restaurant')}
          {...register('restaurant')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Pacote Restaurante"
          type="text"
          margin="normal"
          variant="outlined"
          error={!!formState?.errors?.restaurant_package?.message}
          helperText={formState?.errors?.restaurant_package?.message}
          {...inputProps('restaurant_package')}
          {...register('restaurant_package')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Previsão de Chegada na Unidade"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          type="time"
          variant="outlined"
          sx={{
            svg: {
              color: formState?.errors?.restaurant_package?.message
                ? 'red'
                : undefined,
            },
          }}
          error={!!formState?.errors?.arrival_forecast_last_unit?.message}
          helperText={formState?.errors?.arrival_forecast_last_unit?.message}
          {...register('arrival_forecast_last_unit')}
        />
      </Grid>
    </Grid>
  )
}
