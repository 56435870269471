import { Button } from '@mui/material'
import {
  CreateOrderServiceRequest,
  OnSaveProps,
} from 'components/CreateOrderService/@types/form'
import { UseFormReturn } from 'react-hook-form'

type ButtonPrintOrderServiceProps = {
  onSave?: (data: OnSaveProps) => Promise<void>
  openErrorToast: (props: { message: string }) => void
  handleCreateOrderService: (data: CreateOrderServiceRequest) => Promise<void>
  items: unknown[]
  activity?: { id: number }
  orderService: OrderServiceWithItemsApiResponse | undefined
  reactHookFormOrderService: UseFormReturn<CreateOrderServiceRequest>
  style?: React.CSSProperties
}

export const ButtonSubmitOrderService = ({
  openErrorToast,
  reactHookFormOrderService,
  orderService,
  items,
  handleCreateOrderService,
  onSave,
  activity,
  style,
}: ButtonPrintOrderServiceProps) => {
  return (
    <Button
      type="button"
      color="primary"
      variant="contained"
      style={style}
      onClick={reactHookFormOrderService.handleSubmit(async (data) => {
        if (!items?.length) {
          openErrorToast({
            message: 'Você não pode criar uma ordem de serviço sem itens',
          })
          return
        }
        if (orderService) {
          await onSave?.({
            id: orderService.id.toString(),
            orderService: { ...data, activity_id: activity?.id },
          })
          return
        }
        await handleCreateOrderService(data)
      })}
    >
      {orderService ? 'Salvar' : 'Adicionar'}
    </Button>
  )
}
