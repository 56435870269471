import { useState, useMemo } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

export default function ItemsBudget() {
  const navigate = useNavigate()

  const { data } = useQuery<ItemsBudget[]>(
    QUERY_KEYS.ITEMS_BUDGET.LIST_PAGE,
    () => ApiService.ItemsBudget.listPage(),
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    ItemsBudget | undefined
  >()

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'category',
        headerName: 'Categoria',
        flex: 1,
        valueGetter: (params) => params.row?.category?.name || 'Sem categoria',
      },
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'default',
        headerName: 'Padrão',
        valueGetter: (params: GridValueGetterParams<Institution['teachers']>) =>
          params.row.default ? 'Sim' : 'Não',
        flex: 1,
      },
      {
        field: 'active',
        headerName: 'Ativo',
        valueGetter: (params: GridValueGetterParams<Institution['teachers']>) =>
          params.row.active ? 'Sim' : 'Não',
        flex: 1,
      },
      {
        field: 'has_service_order',
        headerName: 'Ordem de serviço',
        valueGetter: (params) => (params.row.has_service_order ? 'Sim' : 'Não'),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<ItemsBudget>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.ITEMS_BUDGET.VIEW(params.row.id))
                }}
              />
              <EditIcon
                onClick={() => {
                  navigate(ROUTES.ITEMS_BUDGET.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Itens de Orçamento
              </Typography>

              <Box sx={{ m: 1 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.ITEMS_BUDGET.CREATE)
                  }}
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Table rows={data} columns={columns} />
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        itemsBudget={deleteConfirmation as ItemsBudget}
      />
    </>
  )
}
