import { useMemo, useState, useCallback, useEffect } from 'react'

import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import {
  useGridApiContext,
  type GridColDef,
  type GridToolbarExportProps,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import {
  FINANCIAL_MOVEMENT_PAYMENT_METHODS,
  RESERVATION_SITUATION,
  RESERVATION_SITUATION_OPTIONS,
} from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { useSearchParams } from 'react-router-dom'
import { ApiService } from 'services/api'
type GenerateResultsParams = {
  querySituation?: { value: string }[] | null
  queryStartDate?: string | null
  queryEndDate?: string | null
}

export default function ActivitiesControlReport() {
  const [generateResult, setGenerateResult] = useState<SalesReportResponse[]>(
    [],
  )
  const [querySituationData, setQuerySituationData] =
    useState<GenerateResultsParams['querySituation']>(null)
  const [queryStartDateData, setQueryStartDateData] =
    useState<GenerateResultsParams['queryStartDate']>('')
  const [queryEndDateData, setQueryEndDateData] =
    useState<GenerateResultsParams['queryEndDate']>('')

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedReservationSituation, setSelectedReservationSituation] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedCollaborators, setSelectedCollaborators] =
    useState<SelectWithCheckboxOptions>([])

  const [activityCode, setActivityCode] = useState<string>('')

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { openErrorToast } = useGlobalState()

  const tableColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: '#',
        headerName: '#',
        filterable: false,
        width: 70,
        renderCell: (cell) => cell.api.getRowIndex(cell.row.id) + 1,
      },
      {
        field: 'code',
        headerName: 'Reserva',
        width: 200,
      },
      {
        field: 'parent_client_name',
        headerName: 'Cliente',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'financialMovements_payment_method',
        headerName: 'Forma de pagamento',
        valueGetter: (param) =>
          FINANCIAL_MOVEMENT_PAYMENT_METHODS[
            param.value as FinancialMovementPaymentForm
          ],
        width: 200,
      },
      {
        field: 'value',
        headerName: 'Valor',
        width: 200,
      },
      {
        field: 'date',
        headerName: 'Data',
        width: 200,
      },
      {
        field: 'institution_fantasy_name',
        headerName: 'Instituição',
        flex: 1,
        minWidth: 300,
      },
    ],
    [],
  )

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const { data: collaborators } = useQuery<SelectWithCheckboxOptions>(
    QUERY_KEYS.COLLABORATORS.ONLY_EMPLOYEE,
    () => ApiService.Collaborators.getAllNamesAndIdsOnlyEmployee(),
  )

  const [queryParas] = useSearchParams()

  const generateReport = useCallback(
    ({
      querySituation,
      queryStartDate,
      queryEndDate,
    }: GenerateResultsParams) => {
      setQuerySituationData(querySituation)
      setQueryStartDateData(queryStartDate)
      setQueryEndDateData(queryEndDate)
      setIsLoading(true)
      ApiService.Reports.generateSales({
        institutions: selectedInstitutions.map((si) => Number(si.value)),
        status: (querySituation || selectedReservationSituation).map((rs) =>
          String(rs.value),
        ),
        collaborators: selectedCollaborators.map((c) => Number(c.value)),
        activityCode,
        start: queryStartDate
          ? queryStartDate
          : startDate
          ? String(startDate).split('/').reverse().join('-')
          : undefined,
        end: queryEndDate
          ? queryEndDate
          : endDate
          ? String(endDate).split('/').reverse().join('-')
          : undefined,
      })
        .then((response) => {
          setGenerateResult(response)
          if (!response.length) {
            openErrorToast({ message: 'Nenhum resultado encontrado.' })
          }
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [
      selectedInstitutions,
      selectedReservationSituation,
      selectedCollaborators,
      activityCode,
      startDate,
      endDate,
      openErrorToast,
    ],
  )

  useEffect(() => {
    const queryStartDate = queryParas.get('start_date')
    const queryEndDate = queryParas.get('end_date')
    const querySituation = queryParas.get('situation') as
      | ReservationSituation
      | undefined
    const reservationsSituation = []
    if (querySituation) {
      const situation = {
        value: querySituation,
        label: RESERVATION_SITUATION[querySituation],
      }
      setSelectedReservationSituation([situation])
      reservationsSituation.push(situation)
    }
    if (queryStartDate) {
      setStartDate(queryStartDate)
    }
    if (queryEndDate) {
      setEndDate(queryEndDate)
    }
    generateReport({
      queryStartDate,
      queryEndDate,
      querySituation: reservationsSituation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParas])

  function CustomToolbar(props: GridToolbarExportProps) {
    const { user, openErrorToast } = useGlobalState()
    const apiRef = useGridApiContext()

    const generateExportCsv = useCallback(
      () =>
        apiRef.current.exportDataAsCsv({
          ...props.csvOptions,
          utf8WithBom: true,
          delimiter: ';',
        }),
      [apiRef, props.csvOptions],
    )

    const handleExport = useCallback(async () => {
      try {
        await ApiService.AuditLogs.create({
          entity: 'Report',
          type: 'export',
          changes: JSON.stringify([
            {
              valueAfter: {
                institutions: selectedInstitutions.map((si) =>
                  Number(si.value),
                ),
                status: (
                  querySituationData || selectedReservationSituation
                ).map((rs) => String(rs.value)),
                collaborators: selectedCollaborators.map((c) =>
                  Number(c.value),
                ),
                activityCode,
                start: queryStartDateData
                  ? queryStartDateData
                  : startDate
                  ? String(startDate).split('/').reverse().join('-')
                  : undefined,
                end: queryEndDateData
                  ? queryEndDateData
                  : endDate
                  ? String(endDate).split('/').reverse().join('-')
                  : undefined,
              },
            },
          ]),
          client_id: null,
          entity_id: 0,
          user_id: user?.id,
        })
        generateExportCsv()
      } catch (error: unknown) {
        openErrorToast({
          message: `Ocorreu um erro ao criar um registro na auditoria: ${
            (error as ApiError).message
          }`,
        })
      }
    }, [generateExportCsv, openErrorToast, user?.id])

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <SaveAltIcon />,
    }
    return (
      <GridToolbarContainer>
        <GridToolbar
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
        <Button {...buttonBaseProps} onClick={() => handleExport()}>
          Exportar
        </Button>
      </GridToolbarContainer>
    )
  }

  if (!institutions?.length || isLoading) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Relatório de vendas
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <SelectWithCheckbox
              options={RESERVATION_SITUATION_OPTIONS}
              value={selectedReservationSituation}
              onSelect={setSelectedReservationSituation}
              selectAllLabel="Todas as situações"
              placeholder="Status da reserva"
            />

            <SelectWithCheckbox
              options={collaborators || []}
              value={selectedCollaborators}
              onSelect={setSelectedCollaborators}
              selectAllLabel="Todas os colaboradores"
              placeholder="Responsável pela atividade"
            />

            <TextField
              label="Código da atividade"
              type="text"
              variant="outlined"
              value={activityCode}
              onChange={(event) => {
                setActivityCode(event.target.value)
              }}
            />

            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={() => generateReport({})}
              disabled={
                !selectedInstitutions.length &&
                !selectedCollaborators.length &&
                !selectedReservationSituation.length &&
                !startDate &&
                !endDate
              }
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Table
              rows={generateResult}
              columns={tableColumns}
              customToolbar={() => <CustomToolbar />}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
