import { ROUTES } from 'constants/routes'
import * as Pages from 'pages'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import activitiesRoutes from './activities'
import activityChecklistRoutes from './activityChecklist'
import affiliatesRoutes from './affiliates'
import auditLogsRoutes from './audit-logs'
import budgetsRoutes from './budgets'
import chartsRoutes from './charts'
import clientsRoutes from './clients'
import collaboratorsRoutes from './collaborators'
import commercialUnitsRoutes from './commercial-units'
import globalParametersRoutes from './global-parameters'
import institutionsRoutes from './institutions'
import itemsBudgetRoutes from './itens-budget'
import labelsRoutes from './labels'
import orderServicesRoutes from './order-services'
import pagesRoutes from './pages'
import paymentGatewaysRoutes from './payment-gateways'
import paymentModulesRoutes from './payment-modules'
import permissionsRoutes from './permissions'
import permissionsAssociatedRoutes from './permissions-associated'
import { PrivateRoute } from './private'
import reportsRoutes from './reports'
import reservationsRoutes from './reservations'
import rolesRoutes from './roles'
import suppliersRoutes from './suppliers'
import activityTasksRoutes from './tasks'
import teachersRoutes from './teachers'
import usersRoutes from './users'
import videosRoutes from './videos'

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <Pages.Login />,
  },
  {
    path: ROUTES.ROOT,
    element: (
      <PrivateRoute>
        <Pages.Dashboard />
      </PrivateRoute>
    ),
  },
  ...institutionsRoutes,
  ...activitiesRoutes,
  ...commercialUnitsRoutes,
  ...suppliersRoutes,
  ...teachersRoutes,
  ...collaboratorsRoutes,
  ...usersRoutes,
  ...rolesRoutes,
  ...affiliatesRoutes,
  ...videosRoutes,
  ...globalParametersRoutes,
  ...paymentGatewaysRoutes,
  ...paymentModulesRoutes,
  ...itemsBudgetRoutes,
  ...clientsRoutes,
  ...budgetsRoutes,
  ...pagesRoutes,
  ...reservationsRoutes,
  ...reportsRoutes,
  ...auditLogsRoutes,
  ...permissionsRoutes,
  ...permissionsAssociatedRoutes,
  ...labelsRoutes,
  ...chartsRoutes,
  ...activityChecklistRoutes,
  ...activityTasksRoutes,
  ...orderServicesRoutes,
  {
    path: ROUTES.NOT_FOUND,
    element: (
      <PrivateRoute>
        <Pages.PageNotFound />
      </PrivateRoute>
    ),
  },
])

export default function Router() {
  return <RouterProvider router={router} />
}
