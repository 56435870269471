import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  user?: User
}

export default function Delete({ opened, closeModal, user }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeUser = useMutation({
    mutationFn: async (user: User) => {
      await ApiService.Users.remove(user.id.toString())

      openSuccessToast({
        message: `O usuário ${user?.name} foi removido com sucesso!`,
      })

      navigate(ROUTES.USERS.LIST)
    },
    onSuccess: (_data, user: User) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.USERS.LIST,
        (oldUsers) => {
          if (!oldUsers?.length) return

          return oldUsers.filter((oldUser) => oldUser.id !== user.id)
        },
      )

      queryClient.removeQueries(QUERY_KEYS.USERS.EDIT(user.id.toString()))

      openSuccessToast({
        message: `O usuário ${user.name} foi removido com sucesso!`,
      })

      navigate(ROUTES.USERS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeUser.mutate(user as User)}
      confirmColor="error"
    >
      Deseja realmente remover a instituição <strong>{`${user?.name}?`}</strong>
    </ConfirmationModal>
  )
}
