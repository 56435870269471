import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  label?: Label
}

export default function Delete({ opened, closeModal, label }: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeLabel = useMutation({
    mutationFn: async (label: Label) => {
      await ApiService.Labels.remove(label.id.toString())

      openSuccessToast({
        message: `O label para ${label?.entity_translated} foi removida com sucesso!`,
      })

      navigate(ROUTES.LABELS.LIST)
    },
    onSuccess: (_data, label: Label) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.LABELS.LIST,
        (oldLabels) => {
          if (!oldLabels?.length) return

          return oldLabels.filter((oldLabel) => oldLabel.id !== label.id)
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(label.id.toString()),
      )

      openSuccessToast({
        message: `O label para ${label.entity_translated} foi removida com sucesso!`,
      })

      navigate(ROUTES.LABELS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeLabel.mutate(label as Label)}
      confirmColor="error"
    >
      Deseja realmente remover a instituição{' '}
      <strong>{`${label?.entity_translated}?`}</strong>
    </ConfirmationModal>
  )
}
