import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  permissionAssociated?: PermissionAssociated
}

export default function Delete({
  opened,
  closeModal,
  permissionAssociated,
}: DeleteProps) {
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removePermission = useMutation({
    mutationFn: async (
      permissionAssociated: PermissionAssociatedApiResponse,
    ) => {
      await ApiService.PermissionsAssociated.remove(
        permissionAssociated.id.toString(),
      )

      openSuccessToast({
        message: `A permissão associada foi removido com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS_ASSOCIATED.LIST)
    },
    onSuccess: (
      _data,
      permissionAssociated: PermissionAssociatedApiResponse,
    ) => {
      queryClient.setQueryData<CommercialUnit[]>(
        QUERY_KEYS.PERMISSIONS_ASSOCIATED.LIST,
        (oldPermissionsAssociated) => {
          if (!oldPermissionsAssociated?.length) return

          return oldPermissionsAssociated.filter(
            (oldPermission) => oldPermission.id !== permissionAssociated.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.PERMISSIONS_ASSOCIATED.EDIT(
          permissionAssociated.id.toString(),
        ),
      )

      openSuccessToast({
        message: `A permissão associada foi removido com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS_ASSOCIATED.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() =>
        removePermission.mutate(
          permissionAssociated as PermissionAssociatedApiResponse,
        )
      }
      confirmColor="error"
    >
      Deseja realmente remover a permissão?
    </ConfirmationModal>
  )
}
