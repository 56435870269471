import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Pages>(QUERY_KEYS.PAGES.EDIT(id as string), () =>
    ApiService.Pages.get(id as string),
  )

  const updatePages = useMutation({
    mutationFn: async ({
      id,
      pages,
    }: {
      id: string
      pages: PagesApiRequest
    }) => {
      await ApiService.Pages.update({
        id,
        pages,
      })
    },
    onSuccess: (_data, { id, pages }) => {
      queryClient.setQueryData<PagesApiRequest>(
        QUERY_KEYS.PAGES.EDIT(id),
        (oldPages) => {
          if (!oldPages) return
          return pages
        },
      )

      openSuccessToast({
        message: `A página ${pages.title} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.PAGES.LIST)
    },
    onError: (_error, { pages }) => {
      openErrorToast({
        message: `Erro ao editar ${pages.title}!`,
      })
    },
  })

  return (
    <Add
      pages={data}
      isEditMode
      onSave={async (pages: PagesApiRequest) =>
        updatePages.mutate({ id: id as string, pages })
      }
    />
  )
}
