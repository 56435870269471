import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  email: yup.string().email().required('Campo obrigatório'),
  cell_phone: yup.string().required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  district: yup.string().required('Campo obrigatório'),
  city: yup.string().required('Campo obrigatório'),
  uf: yup.string().required('Campo obrigatório'),
  code: yup.string().required('Campo obrigatório'),
})

export { schema }
