import { useEffect } from 'react'

import { InputBase } from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'

export const CustomEditTextAreaField = ({
  id,
  value,
  error,
  onChange,
  onBlur,
  multiline,
}: GridRenderEditCellParams & {
  handleCal?: (data: ActivityTaskItemsForm[]) => void
  onBlur?: () => void
  multiline?: boolean
  setActivityTask: React.Dispatch<React.SetStateAction<ActivityTaskItemsForm[]>>
  activityTask: ActivityTaskItemsForm[]
}) => {
  const apiRef = useGridApiContext()
  useEffect(() => {
    apiRef.current.startRowEditMode({ id })
  }, [apiRef, id])

  return (
    <InputBase
      sx={{
        flexGrow: 1,
        padding: '5px 20px 5px 5px',
      }}
      fullWidth
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
        // handleValueChange(event)
      }}
      error={error}
      onBlur={onBlur}
      multiline={multiline}
    />
  )
}
