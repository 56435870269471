import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const get = async (user: CheckPermissionApiRequest) => {
  try {
    const loginResponse = await apiInstance.post<CheckPermissionApiResponse>(
      ENDPOINTS.CHECK_PERMISSION,
      user,
    )

    return loginResponse.data
  } catch (loginError) {
    const axiosError = loginError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const verify = async (data: CheckPermissionApiRequest) => {
  try {
    const loginResponse = await apiInstance.post<CheckPermissionApiResponse>(
      ENDPOINTS.CHECK_PERMISSION,
      data,
    )

    return loginResponse.data
  } catch (loginError) {
    const axiosError = loginError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
