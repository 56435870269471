import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<PermissionAssociatedApiResponse>(
    QUERY_KEYS.PERMISSIONS_ASSOCIATED.EDIT(id as string),
    () => ApiService.PermissionsAssociated.get(id as string),
  )

  const updatePermission = useMutation({
    mutationFn: async ({
      id,
      permissionAssociated,
    }: {
      id: string
      permissionAssociated: PermissionsAssociatedApiRequest
    }) => {
      await ApiService.PermissionsAssociated.update({
        id,
        permissionAssociated,
      })
    },
    onSuccess: (_data, { id, permissionAssociated }) => {
      queryClient.setQueryData<PermissionsAssociatedApiRequest>(
        QUERY_KEYS.PERMISSIONS_ASSOCIATED.EDIT(id),
        (oldPermission) => {
          if (!oldPermission) return
          return permissionAssociated
        },
      )

      openSuccessToast({
        message: `A permissão associada foi atualizada com sucesso!`,
      })

      navigate(ROUTES.PERMISSIONS_ASSOCIATED.LIST)
    },
    onError: (_error) => {
      openErrorToast({
        message: `Erro ao editar permissão associada!`,
      })
    },
  })

  return (
    <Add
      permissionAssociated={data}
      isEditMode
      onSave={async (permissionAssociated: PermissionsAssociatedApiRequest) =>
        updatePermission.mutate({ id: id as string, permissionAssociated })
      }
    />
  )
}
