import Box from '@mui/material/Box'
import Loading from 'components/Loading'

export default function LoadingFullPage() {
  return (
    <Box
      component="main"
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0,0.2)',
        zIndex: 10000,
      }}
    >
      <Loading />
    </Box>
  )
}
