import { forwardRef } from 'react'

import { FormHelperText, InputBase } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { currencyMask } from 'utils/mask'

type InputCurrencyProps = Omit<OutlinedInputProps, 'endAdornment'> & {
  label?: string
  id: string
  outlined?: boolean
  settings?: Intl.NumberFormatOptions
  helperText?: string
}

const InputCurrency = forwardRef(function InputCurrency(
  {
    id,
    label,
    fullWidth = true,
    outlined = true,
    settings,
    value,
    error,
    helperText,
    ...props
  }: InputCurrencyProps,
  ref,
) {
  const InputComponent = outlined ? OutlinedInput : InputBase

  return (
    <FormControl
      variant="outlined"
      margin="normal"
      fullWidth={fullWidth}
      error={error}
    >
      <InputLabel
        htmlFor={id}
        shrink={!!value}
        style={{
          backgroundColor: 'white',
          padding: '0 5px',
        }}
      >
        {label}
      </InputLabel>
      <InputComponent
        {...props}
        id={id}
        ref={ref}
        type="text"
        fullWidth={fullWidth}
        value={value}
        notched={!!value}
        onChange={(event) => {
          const value = event.target.value?.replace(/\D/g, '')
          event.target.value = currencyMask(
            value.length ? value : '0',
            settings,
          )
          props?.onChange?.(event)
        }}
        error={error}
      />

      <FormHelperText id={`${id}"-helper-text"`}>{helperText}</FormHelperText>
    </FormControl>
  )
})

export default InputCurrency
