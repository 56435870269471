import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<ActivityApiResponse>(
    QUERY_KEYS.ACTIVITIES.VIEW(id as string),
    () => ApiService.Activities.get(id as string),
  )

  const updateActivityChecklist = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: string
      data: ActivityChecklistsApiRequest
    }) => {
      await ApiService.ActivityChecklists.update({
        id,
        data,
      })
    },
    onSuccess: (_data, { id, data }) => {
      queryClient.setQueryData<ActivityChecklistsApiRequest>(
        QUERY_KEYS.ACTIVITY_CHECKLIST.EDIT(id),
        (oldActivityChecklist) => {
          queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.ACTIVITIES.VIEW(id as string),
          })
          if (!oldActivityChecklist) return
          return data
        },
      )

      openSuccessToast({
        message: `A checklist foi atualizada com sucesso`,
      })

      const start = moment().format('YYYY-MM-DD')
      const period = `${start}to`
      navigate(
        ROUTES.ACTIVITIES.LIST +
          `?status_checklist=pending&start_travel_date_period=${period}`,
      )
    },
    onError: (_error) => {
      openErrorToast({
        message:
          (_error as ApiError).message ||
          `Erro ao editar a checklist!, ${(_error as ApiError).message}`,
      })
    },
  })

  return (
    <Add
      activity={data as ActivityApiResponse}
      isEditMode
      onSave={async (activityChecklist: ActivityChecklistsApiRequest) => {
        await updateActivityChecklist.mutate({
          id: id as string,
          data: { ...activityChecklist },
        })
      }}
    />
  )
}
