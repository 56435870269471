import { useState, Fragment, useCallback, useEffect, useMemo } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Tooltip } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { GridColDef } from '@mui/x-data-grid'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import LoadingFullPageWithOverlay from 'components/LoadingFullPageWithOverlay'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

const PERMISSIONS_VALUES: Record<
  string,
  { hasPermission: boolean; approved_role: boolean; approved_user: boolean }
> = {}

enum TABS {
  PERMISSIONS,
  COMMERCIAL_UNITS,
}

export default function View() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [, setUpdate] = useState<number>(0)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState<TABS>(TABS.PERMISSIONS)
  const { id } = useParams<{ id: string }>()

  const { data: user, isLoading } = useQuery<User>(
    QUERY_KEYS.USERS.VIEW(id as string),
    () => ApiService.Users.get(id as string),
  )

  const commercialUnits = useMemo<GridColDef[]>(
    () => [
      { field: 'value', headerName: 'Cod.', flex: 1 },
      { field: 'label', headerName: 'Nome', flex: 1 },
    ],
    [],
  )

  const { data: userPermissions, isLoading: userPermissionsLoading } = useQuery<
    UserPermissionsApiResponse | undefined
  >(
    QUERY_KEYS.USERS.PERMISSIONS(
      user?.role_id.toString() as string,
      id as string,
    ),
    () => {
      if (!id || !user) return undefined

      return ApiService.Users.Permissions.listGrouped({
        userId: id as string,
        roleId: user?.role_id.toString(),
      })
    },
  )

  useEffect(() => {
    userPermissions?.forEach((firstLevel) => {
      firstLevel.subItems.forEach((secondLevel) => {
        secondLevel.subItems.forEach((permission) => {
          PERMISSIONS_VALUES[
            `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`
          ] = {
            ...permission,
          }
        })
      })
    })

    setUpdate((prevState) => prevState + 1)
  }, [userPermissions])

  const updatePermission = useCallback(
    async ({
      permissionId,
      hashKey,
    }: {
      permissionId: string
      hashKey: string
    }) => {
      setUpdateLoading(true)
      try {
        const isCreation = !PERMISSIONS_VALUES[hashKey].hasPermission
        const action = isCreation
          ? ApiService.Users.Permissions.create
          : ApiService.Users.Permissions.remove

        await action({
          permissionId,
          userId: id as string,
        })

        PERMISSIONS_VALUES[hashKey].hasPermission = isCreation

        queryClient.invalidateQueries(
          QUERY_KEYS.USERS.PERMISSIONS(
            user?.role_id.toString() as string,
            id as string,
          ),
        )
      } catch (_updateError) {
        // TODO: handle error
      }
      setUpdateLoading(false)
    },
    [id, queryClient, user?.role_id],
  )

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    User | undefined
  >()

  if (isLoading || userPermissionsLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      {updateLoading && <LoadingFullPageWithOverlay />}
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {user?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.USERS.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(user as User)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.USERS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel label="Nome" value={user?.name} />
              <TextWithLabel label="Username" value={user?.username} />
              <TextWithLabel label="Email" value={user?.email} />
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Permissões" />
                  <Tab label="Unidades Comerciais" />
                </Tabs>

                <TabPanel value={TABS.PERMISSIONS} index={currentTab}>
                  {userPermissions?.length ? (
                    <>
                      <div
                        style={{
                          gridColumn: '1/-1',
                          display: 'flex',
                          flexFlow: 'column wrap',
                          gap: '12px',
                        }}
                      >
                        {userPermissions?.map((firstLevel) => (
                          <Fragment key={firstLevel.column1}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{firstLevel.column1}</Typography>
                              </AccordionSummary>

                              <AccordionDetails>
                                {firstLevel.subItems.map((secondLevel) => (
                                  <Fragment key={secondLevel.column1}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                      >
                                        <Typography>
                                          {secondLevel.column1}
                                        </Typography>
                                      </AccordionSummary>

                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexFlow: 'column wrap',
                                          }}
                                        >
                                          {secondLevel.subItems.map(
                                            (permission) => {
                                              const hasApprovedPerRole =
                                                PERMISSIONS_VALUES[
                                                  `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`
                                                ]?.approved_role
                                              return (
                                                <Fragment
                                                  key={`${firstLevel.column1}-${secondLevel.column1}-${permission.id}`}
                                                >
                                                  <Tooltip
                                                    placement="top-start"
                                                    title={
                                                      hasApprovedPerRole
                                                        ? 'Rota permitida pelo grupo do usuário por isso não pode ser alterada'
                                                        : ''
                                                    }
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Switch color="primary" />
                                                      }
                                                      label={permission.name}
                                                      disabled={
                                                        hasApprovedPerRole
                                                      }
                                                      checked={
                                                        PERMISSIONS_VALUES[
                                                          `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`
                                                        ]?.hasPermission ||
                                                        false
                                                      }
                                                      onChange={() => {
                                                        updatePermission({
                                                          permissionId:
                                                            permission.id.toString(),
                                                          hashKey: `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`,
                                                        })
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </Fragment>
                                              )
                                            },
                                          )}
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Fragment>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          </Fragment>
                        ))}
                      </div>
                    </>
                  ) : null}
                </TabPanel>
                <TabPanel value={TABS.COMMERCIAL_UNITS} index={currentTab}>
                  <Table
                    rows={user?.commercial_units || []}
                    columns={commercialUnits}
                    getRowId={(commercialUnit) => Number(commercialUnit.value)}
                  />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        user={deleteConfirmation as User}
      />
    </>
  )
}
