import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { openErrorToast } = useGlobalState()
  const { data } = useQuery<Teacher>(
    QUERY_KEYS.TEACHERS.EDIT(id as string),
    () => ApiService.Teachers.get(id as string),
  )

  const onSave = useCallback(
    async (teacher: TeacherApiRequest) => {
      if (!id) return

      try {
        await ApiService.Teachers.update({
          id,
          teacher,
        })
        navigate(ROUTES.TEACHERS.LIST)
      } catch (error) {
        const message =
          (error as { message?: string })?.message ||
          'Erro ao editar um professor'
        openErrorToast({
          message,
        })
      }
    },
    [id, navigate, openErrorToast],
  )

  return <Add teacher={data} onSave={onSave} isEditMode />
}
