import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<LabelsApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<LabelsApiResponse[]>(
      ENDPOINTS.LABELS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllColumnsEntities = async (): Promise<
  LabelsEntitiesColumns[]
> => {
  try {
    const getAllResponse = await apiInstance.get<LabelsEntitiesColumns[]>(
      ENDPOINTS.LABELS.GET_ALL_COLUMNS_ENTITIES,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<LabelsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<LabelsApiResponse>(
      ENDPOINTS.LABELS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  label: LabelsApiRequest,
): Promise<LabelsApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<LabelsApiResponse>(
      ENDPOINTS.LABELS.CREATE,
      label,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  label,
}: {
  label: LabelsApiRequest
  id: string
}): Promise<LabelsApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<LabelsApiResponse>(
      ENDPOINTS.LABELS.UPDATE(id),
      label,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(ENDPOINTS.LABELS.DELETE(id))

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
