import { useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import { ApiService } from 'services/api'
import { CacheService } from 'services/cache'
import * as yup from 'yup'
type UpdatePeriodButtonProps = {
  activity_id: number | string
  classes?: SelectProps['options']
  onLoading?: (value: boolean) => void
}

const createClassSchema = yup.object({
  name: yup.string().required(),
})

type CreateClass = yup.InferType<typeof createClassSchema>

type UpdatePeriodModalProps = {
  opened: boolean
  activity_id: number | string
  classes?: SelectProps['options']
  onClose: () => void
}

const UpdatePeriodForm = ({
  classes = [],
  reactHookForm,
}: {
  reactHookForm: UseFormReturn<CreateClass>
  classes?: SelectProps['options']
}) => {
  if (classes.length) {
    return (
      <Controller
        name="name"
        control={reactHookForm.control}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              label="Turma"
              id="name"
              onChange={onChange}
              value={value}
              options={classes}
              error={!!reactHookForm.formState.errors.name?.message}
              helperText={reactHookForm.formState.errors.name?.message}
            />
          )
        }}
      />
    )
  }
  return (
    <TextField
      error={!!reactHookForm.formState.errors.name?.message}
      fullWidth
      helperText={reactHookForm.formState.errors.name?.message}
      label="Nome"
      margin="normal"
      type="text"
      variant="outlined"
      {...reactHookForm.register('name')}
    />
  )
}

const UpdatePeriodModal = ({
  onClose,
  opened,
  activity_id,
  classes = [],
}: UpdatePeriodModalProps) => {
  const { openErrorToast, openSuccessToast } = useGlobalState()

  const reactHookForm = useForm<CreateClass>({
    resolver: yupResolver(createClassSchema),
  })

  const onSubmit = useCallback(
    async ({ name }: CreateClass) => {
      try {
        await ApiService.Reservations.updatePeriod({
          activity_id,
          period: name,
        })
        CacheService.queryClient.invalidateQueries(
          QUERY_KEYS.RESERVATIONS.LIST_BY_ACTIVITY(activity_id),
        )
        reactHookForm.reset()
        onClose()
        openSuccessToast({ message: 'Turma adicionada com sucesso' })
      } catch (error) {
        openErrorToast({ message: 'Ocorreu um erro ao adicionar a turma' })
      }
    },
    [activity_id, onClose, openErrorToast, openSuccessToast, reactHookForm],
  )
  return (
    <Dialog
      open={opened}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Atualizar turmas</DialogTitle>
      <DialogContent>
        <form onSubmit={reactHookForm.handleSubmit(onSubmit)}>
          <UpdatePeriodForm reactHookForm={reactHookForm} classes={classes} />
          <Box>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{
                marginLeft: 'auto',
                display: 'block',
                marginTop: '1em',
                marginRight: '24px',
              }}
            >
              Atualizar
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const UpdatePeriodButton = ({
  classes,
  activity_id,
}: UpdatePeriodButtonProps) => {
  const [updatePeriodModal, setUpdatePeriodModal] = useState(false)

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            setUpdatePeriodModal(true)
          }}
        >
          Atualizar turmas
        </Button>
      </Box>
      <UpdatePeriodModal
        onClose={() => setUpdatePeriodModal(false)}
        opened={updatePeriodModal}
        classes={classes}
        activity_id={activity_id}
      />
    </>
  )
}
