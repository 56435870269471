import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<ClientsApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<ClientsApiResponse[]>(
      ENDPOINTS.CLIENTS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllParentIsNull = async (): Promise<ClientsApiResponse[]> => {
  try {
    const getAllResponse = await getAll()
    return (getAllResponse || []).filter((client) => !client.parent_id)
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<ClientsApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ClientsApiResponse>(
      ENDPOINTS.CLIENTS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  client: ClientsApiRequest,
): Promise<ClientsApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<ClientsApiResponse>(
      ENDPOINTS.CLIENTS.CREATE,
      client,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  client,
}: {
  client: ClientsApiRequest
  id: string
}): Promise<ClientsApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<ClientsApiResponse>(
      ENDPOINTS.CLIENTS.UPDATE(id),
      client,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.CLIENTS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
