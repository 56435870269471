import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import DeleteIcon from '@mui/icons-material/Delete'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import PrintIcon from '@mui/icons-material/Print'
import { TextField } from '@mui/material'
import { Box } from '@mui/material'
import { Button } from '@mui/material'
import { Card } from '@mui/material'
import { CardContent } from '@mui/material'
import { Container } from '@mui/material'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridToolbarContainer,
  GridValidRowModel,
  ptBR,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { DialogContactSupplier } from 'components/CreateOrderService/components/DialogContactSupplier'
import { sendOrderServiceToSupplierSchema } from 'components/CreateOrderService/components/form-validation'
import LoadingFullPage from 'components/LoadingFullPageWithOverlay'
import { QUERY_KEYS } from 'constants/keys'
import { STATUS_ORDER_SERVICE_ENUM } from 'constants/order-service-status'
import { useGlobalState } from 'contexts/global-state'
import moment from 'moment'
import { Controller, useFormContext, useForm } from 'react-hook-form'
import { ApiService } from 'services/api'
import { roundUpperNumber } from 'utils/mask'
import * as yup from 'yup'

import { useActivityChecklistContext } from '../add'
import { CreateOrderService } from './CreateOrderService'
import { CustomEditDatePickerFieldChecklist } from './EditDatePickerFieldChecklist'
import { CustomEditField } from './EditField'
import { CustomEditSelectFieldChecklist } from './EditSelectFieldChecklist'

export type SendOrderServiceToSupplier = yup.InferType<
  typeof sendOrderServiceToSupplierSchema
>

type OnSave = (activityChecklist: ActivityChecklistsApiRequest) => Promise<void>

type AddProps = {
  activity?: ActivityApiResponse
  activityChecklist?: ActivityChecklist
  onSave?: OnSave
  isEditMode?: boolean
}

const filter = createFilterOptions<ActivityChecklistItemsForm>()

export function monetaryMask(value: string | number): string {
  let newValue = value

  if (typeof newValue === 'string') {
    newValue = newValue?.replace('.', '').replace(',', '').replace(/\D/g, '')
  }

  if (Number.isNaN(newValue)) return '0,00'

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    roundUpperNumber(Number(newValue)),
  )

  return result
}

export default function ChecklistItem({
  activity,
  isEditMode = false,
}: AddProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [openModalSelectContact, setOpenModalSelectContact] = useState({
    isActive: false,
    id: 0,
  })
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const { setActivityChecklistsItems, activityChecklistItems } =
    useActivityChecklistContext()

  const {
    formState: { errors },

    control,
  } = useFormContext<ActivityChecklistForm>()

  const dialogMethods = useForm<{
    data: string | ItemsBudget
    name: string
  }>()
  const reactHookFormSendOrderServiceToSupplier =
    useForm<SendOrderServiceToSupplier>({
      resolver: yupResolver(sendOrderServiceToSupplierSchema),
    })

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

  useEffect(() => {
    if (isEditMode && activity?.activityChecklist) {
      setIsLoading(true)
      const data = (activity?.activityChecklist || [])
        .filter((item) => !item.activity_task_id)
        ?.map((item) => ({
          ...item,
          item_id: item.id,
          quantity: item.details.quantity,
          subtotal: item.details.subtotal,
          unit_value: item.details.unit_value,
          date_payment: item.details.date_payment,
          schedule: item.details.schedule,
          status: item.details.status,
          default: 0,
          name: item?.itemBudget?.name || item.details?.name || '',
        }))
      setActivityChecklistsItems(data)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [activity?.activityChecklist, isEditMode, setActivityChecklistsItems])

  const { data: autoCompleteBudgetItems } = useQuery<BudgetItemsForm[]>(
    QUERY_KEYS.ITEMS_BUDGET.LIST,
    async () => {
      const responseItemsBudget = await ApiService.ItemsBudget.getAll()

      return responseItemsBudget
    },
  )

  const AddToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => setOpenAddDialog(true)}
          style={{
            marginLeft: 'auto',
            display: 'block',
            marginTop: '1em',
          }}
        >
          Adicionar
        </Button>
      </GridToolbarContainer>
    )
  }

  const handleStartEdit = useCallback(
    (id: GridRowId) => {
      setActivityChecklistsItems((old) =>
        old.map((oldActivityChecklistItem) =>
          oldActivityChecklistItem.id === id
            ? {
                ...oldActivityChecklistItem,
                hasEdit: true,
              }
            : { ...oldActivityChecklistItem },
        ),
      )
    },
    [setActivityChecklistsItems],
  )

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      const filteredActivityChecklistItems = activityChecklistItems.filter(
        (row) => row.id !== id,
      )
      setActivityChecklistsItems(filteredActivityChecklistItems)
    },
    [activityChecklistItems, setActivityChecklistsItems],
  )

  const handlePrintOrderServiceClick = useCallback(
    async (id: GridRowId) => {
      setIsLoading(true)
      try {
        const responseOrderService =
          await ApiService.OrderServices.getByActivityChecklistItemId(
            id.toString(),
          )
        if (responseOrderService.status === STATUS_ORDER_SERVICE_ENUM.NEW) {
          openErrorToast({
            message: 'Ordem de serviço ainda não foi emitida',
          })
          return
        }
        const currentDate = moment().format('DD-MM-YYYY')
        await ApiService.OrderServices.downloadPrint(
          responseOrderService.id.toString(),
          {
            fileName: `ordem-de-serviço-${responseOrderService.number_order_service}-${responseOrderService.supplier?.name}-${currentDate}`,
          },
        )
        setIsLoading(false)
      } catch (error) {
        openErrorToast({
          message:
            'Ocorreu um erro ao imprimir a ordem de serviço, tente novamente',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [openErrorToast],
  )

  const handleSendOrderServiceClick = useCallback(
    async ({ sendTo }: SendOrderServiceToSupplier) => {
      const id = openModalSelectContact.id.toString()
      setIsLoading(true)
      try {
        const responseOrderService =
          await ApiService.OrderServices.getByActivityChecklistItemId(id)
        if (responseOrderService.status === STATUS_ORDER_SERVICE_ENUM.NEW) {
          openErrorToast({
            message: 'Ordem de serviço ainda não foi emitida',
          })
          return
        }
        await ApiService.OrderServices.sendOrderServiceToSupplier(
          responseOrderService.id.toString(),
          {
            sendTo,
          },
        )
        openSuccessToast({
          message: 'Ordem de serviço enviado com sucesso',
        })
        setIsLoading(false)
      } catch (error) {
        openErrorToast({
          message:
            'Ocorreu um erro ao enviar a ordem de serviço, tente novamente',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [openErrorToast, openModalSelectContact.id, openSuccessToast],
  )

  return (
    <>
      {isLoading && <LoadingFullPage />}
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Box>
                      <Typography variant="h5">Itens</Typography>
                    </Box>
                    <Grid container>
                      <DataGrid
                        sx={{
                          border: 0,
                          '& .MuiFormControl-root': {
                            marginTop: 0,
                            marginBottom: 0,
                          },
                          '& .MuiDataGrid-cell--editing': {
                            padding: '0 10px !important',
                          },
                          '& .Mui-error': {
                            outline: 'red 1px solid',
                          },
                          '& .custom-erro-input': {
                            outline: 'none !important',
                          },
                          '& .MuiFormHelperText-root': {
                            display: 'none !important',
                          },
                          '& .MuiFormLabel-root': {
                            display: 'none !important',
                          },
                          '& .MuiSelect-select': {
                            padding: '10px !important',
                          },
                        }}
                        rows={activityChecklistItems as GridValidRowModel[]}
                        onRowEditStart={(_, event) => {
                          event.defaultMuiPrevented = true
                        }}
                        onRowClick={(params) => handleStartEdit(params.id)}
                        disableIgnoreModificationsIfProcessingProps
                        experimentalFeatures={{ newEditingApi: true }}
                        getEstimatedRowHeight={() => 100}
                        getRowHeight={() => 'auto'}
                        columns={[
                          {
                            field: 'name',
                            headerName: 'Item',
                            flex: 1,
                            renderCell: (params) => {
                              return (
                                <div
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  <p style={{ width: '100%' }}>
                                    {params.row?.itemBudget?.name ||
                                      params.row?.name}
                                  </p>
                                </div>
                              )
                            },
                          },
                          {
                            field: 'quantity',
                            headerName: 'Quantidade',
                            type: 'number',
                            align: 'left',
                            headerAlign: 'left',
                            flex: 1,
                            editable: true,
                            renderCell: (props: GridRenderEditCellParams) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.quantity` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.quantity?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditField
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>,
                                        ) => {
                                          event.target.value =
                                            event.target.value?.replace(
                                              /\D/g,
                                              '',
                                            )
                                          onChange(event)
                                        }}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.quantity
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.quantity` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditField
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>,
                                        ) => {
                                          event.target.value =
                                            event.target.value?.replace(
                                              /\D/g,
                                              '',
                                            )
                                          onChange(event)
                                        }}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.quantity
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityChecklistsItems?.[
                                  params.id as number
                                ]?.quantity
                              return hasErro ? 'custom-erro-input' : ''
                            },
                          },
                          {
                            field: 'unit_value',
                            headerName: 'Valor Unitário',
                            flex: 1,
                            editable: true,
                            type: 'string',
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityChecklistsItems?.[
                                  params.id as number
                                ]?.unit_value
                              return hasErro ? 'custom-erro-input' : ''
                            },
                            renderCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.unit_value` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.unit_value?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditField
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.unit_value
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.unit_value` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditField
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.unit_value
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                          },
                          {
                            field: 'subtotal',
                            headerName: 'Subtotal',
                            flex: 1,
                            valueGetter: (params) => params.row.subtotal,
                          },
                          {
                            field: 'schedule',
                            headerName: 'Agendamento',
                            width: 220,
                            editable: true,
                            type: 'string',
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityChecklistsItems?.[
                                  params.id as number
                                ]?.schedule
                              return hasErro ? 'custom-erro-input' : ''
                            },
                            renderCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.schedule` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.schedule?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditSelectFieldChecklist
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.schedule
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.status` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditSelectFieldChecklist
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.status
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                          },
                          {
                            field: 'date_payment',
                            headerName: 'Data de pagamento',
                            type: 'date',
                            align: 'left',
                            headerAlign: 'left',
                            width: 220,
                            editable: true,
                            renderCell: (props: GridRenderEditCellParams) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.date_payment` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.date_payment?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditDatePickerFieldChecklist
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.date_payment
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.date_payment` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditDatePickerFieldChecklist
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.date_payment
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityChecklistsItems?.[
                                  params.id as number
                                ]?.date_payment
                              return hasErro ? 'custom-erro-input' : ''
                            },
                          },
                          {
                            field: 'status',
                            headerName: 'Status',
                            width: 220,
                            editable: true,
                            type: 'string',
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityChecklistsItems?.[
                                  params.id as number
                                ]?.status
                              return hasErro ? 'custom-erro-input' : ''
                            },
                            renderCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.status` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.status?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditSelectFieldChecklist
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.status
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityChecklistsItems.${props.id}.status` as keyof ActivityChecklistForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditSelectFieldChecklist
                                        {...props}
                                        setActivityChecklist={
                                          setActivityChecklistsItems
                                        }
                                        activityChecklist={
                                          activityChecklistItems
                                        }
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityChecklistsItems?.[
                                            props.id as number
                                          ]?.status
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                          },
                          {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Ações',
                            flex: 1,
                            cellClassName: 'actions',
                            renderCell: ({ id, row }) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '1rem',
                                    width: '100%',
                                  }}
                                >
                                  <CreateOrderService
                                    itemBudget={
                                      row as ActivityChecklistItemsForm
                                    }
                                    disabled={!row.activity_id}
                                    activity={activity as ActivityApiResponse}
                                    activityChecklistItems={
                                      activityChecklistItems
                                    }
                                    onLoading={(isLoading) =>
                                      setIsLoading(isLoading)
                                    }
                                    isEditMode={row.order_service}
                                  />

                                  <GridActionsCellItem
                                    key={`print-${id}`}
                                    icon={<PrintIcon />}
                                    label="Imprimir OS"
                                    onClick={async () => {
                                      await handlePrintOrderServiceClick(id)
                                    }}
                                    color="inherit"
                                    disabled={!row.order_service}
                                  />

                                  <GridActionsCellItem
                                    key={`send-order-service-${id}`}
                                    icon={<ForwardToInboxIcon />}
                                    label="Enviar OS"
                                    onClick={() => {
                                      setOpenModalSelectContact({
                                        id: Number(id),
                                        isActive: true,
                                      })
                                    }}
                                    color="inherit"
                                    disabled={!row.order_service}
                                  />

                                  <GridActionsCellItem
                                    key={`cancel-${id}`}
                                    icon={<DeleteIcon />}
                                    label="Delete"
                                    onClick={handleDeleteClick(id)}
                                    color="inherit"
                                    disabled={row.default}
                                  />
                                </div>
                              )
                            },
                          },
                        ]}
                        editMode="row"
                        autoHeight
                        localeText={
                          ptBR.components.MuiDataGrid.defaultProps.localeText
                        }
                        components={{ Footer: () => <AddToolbar /> }}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Adicionar Item</DialogTitle>
        <Box sx={{ py: 1, px: 1 }}>
          <Container maxWidth={false}>
            <Box>
              <form>
                <Grid container>
                  <Grid item xs={12}>
                    <Controller
                      name="data"
                      control={dialogMethods.control}
                      shouldUnregister
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Autocomplete
                            filterSelectedOptions
                            value={value as BudgetItemsForm}
                            onChange={(_, newValue) => {
                              if (newValue?.inputValue) {
                                onChange(newValue.inputValue)
                                return
                              }

                              onChange(newValue)
                            }}
                            noOptionsText="Nada foi encontrado"
                            options={
                              autoCompleteBudgetItems as BudgetItemsForm[]
                            }
                            getOptionDisabled={(option) => {
                              const selectedOption =
                                activityChecklistItems.find(
                                  (item) => item.name === option.name,
                                )
                              return !!selectedOption
                            }}
                            getOptionLabel={(option) => {
                              if (option.inputValue) {
                                return option.inputValue
                              }

                              return option.name
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params)
                              const { inputValue } = params
                              const isExisting = options.some(
                                (option) => inputValue === option.name,
                              )
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  name: `Adicionar "${inputValue}"`,
                                  id: Math.random() * 999,
                                })
                              }

                              return filtered
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                              <li {...props}>{option.name}</li>
                            )}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  dialogMethods.formState.errors.data?.type ===
                                  'required'
                                }
                                fullWidth
                                helperText={
                                  dialogMethods.formState.errors.data?.type ===
                                  'required'
                                    ? 'Campo obrigatório'
                                    : ''
                                }
                                label="Nome"
                                type="text"
                                variant="outlined"
                              />
                            )}
                          />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    style={{
                      marginLeft: 'auto',
                      display: 'block',
                      marginTop: '1em',
                    }}
                    onClick={dialogMethods?.handleSubmit(
                      ({ data }: { data: string | ItemsBudget }) => {
                        if (typeof data === 'string') {
                          setActivityChecklistsItems(
                            (oldActivityChecklistsItems) => [
                              ...oldActivityChecklistsItems,
                              {
                                name: data,
                                default: 0,
                                hasEdit: true,
                                inputValue: '',
                                itemBudget: { name: data },
                                quantity: '0',
                                subtotal: '0',
                                unit_value: '0',
                                id: Math.floor(9999 * Math.random()),
                              },
                            ],
                          )
                          setOpenAddDialog(false)

                          return
                        }
                        setActivityChecklistsItems(
                          (oldActivityChecklistsItems) => [
                            ...oldActivityChecklistsItems,
                            {
                              name: data.name,
                              default: 0,
                              hasEdit: true,
                              inputValue: '',
                              itemBudget: { ...data },
                              quantity: '0',
                              subtotal: '0',
                              unit_value: '0',
                              id: Math.floor(9999 * Math.random()),
                            },
                          ],
                        )
                        setOpenAddDialog(false)
                      },
                    )}
                  >
                    Adicionar
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Dialog>

      <DialogContactSupplier
        onClose={() => {
          setOpenModalSelectContact({
            id: 0,
            isActive: false,
          })
        }}
        onSubmit={handleSendOrderServiceClick}
        openModalSelectContact={openModalSelectContact}
        reactHookFormSendOrderServiceToSupplier={
          reactHookFormSendOrderServiceToSupplier
        }
      />
    </>
  )
}
