import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<ReservationApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<ReservationApiResponse[]>(
      ENDPOINTS.RESERVATIONS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllByActivity = async ({
  activity_id,
}: {
  activity_id: number | string
}): Promise<ReservationApiResponse[]> => {
  try {
    const getAllByActivityResponse = await apiInstance.get<
      ReservationApiResponse[]
    >(ENDPOINTS.RESERVATIONS.GET_ALL_BY_ACTIVITY(activity_id))

    return getAllByActivityResponse.data
  } catch (getAllByActivityError) {
    const axiosError = getAllByActivityError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllByParams = async (
  params?: GetAllParams,
): Promise<ReservationApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.post<ReservationApiResponse[]>(
      ENDPOINTS.RESERVATIONS.GET_ALL_WITH_PARAMS,
      params,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<ReservationApiResponse> => {
  try {
    const getResponse = await apiInstance.get<ReservationApiResponse>(
      ENDPOINTS.RESERVATIONS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  reservation: ReservationsApiRequest,
): Promise<ReservationApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<ReservationApiResponse>(
      ENDPOINTS.RESERVATIONS.CREATE,
      reservation,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const downloadPaymentManual = async ({
  id,
  ...restData
}: DownloadPaymentManualReservationsApiRequest): Promise<ReservationApiResponse> => {
  try {
    const downloadPaymentManualResponse =
      await apiInstance.post<ReservationApiResponse>(
        ENDPOINTS.RESERVATIONS.DOWNLOAD_PAYMENT_MANUAL(id),
        restData,
      )

    return downloadPaymentManualResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const createPerImport = async (
  formData: FormData,
): Promise<ReservationApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<ReservationApiResponse>(
      ENDPOINTS.RESERVATIONS.CREATE_PER_IMPORT,
      formData,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const updatePeriod = async ({
  activity_id,
  period,
}: {
  period: string
  activity_id: string | number
}): Promise<ReservationApiResponse> => {
  try {
    const updatePeriodResponse = await apiInstance.put<ReservationApiResponse>(
      ENDPOINTS.RESERVATIONS.UPDATE_PERIOD_BY_ACTIVITY(activity_id),
      { period },
    )

    return updatePeriodResponse.data
  } catch (updatePeriodError) {
    const axiosError = updatePeriodError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  reservation,
}: {
  reservation: Partial<ReservationsApiRequest>
  id: string
}): Promise<ReservationApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<ReservationApiResponse>(
      ENDPOINTS.RESERVATIONS.UPDATE(id),
      reservation,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const updatePaymentStatus = async ({
  reservation_id,
  status,
}: {
  reservation_id: number
  status: string
}): Promise<boolean> => {
  try {
    const updateResponse = await apiInstance.post<boolean>(
      ENDPOINTS.RESERVATIONS.UPDATE_PAYMENT_STATUS,
      {
        reservation_id,
        status,
      },
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const updatePaymentValue = async ({
  reservation_id,
  value,
}: {
  reservation_id: number
  value: number
}): Promise<boolean> => {
  try {
    const updateResponse = await apiInstance.post<boolean>(
      ENDPOINTS.RESERVATIONS.UPDATE_PAYMENT_VALUE,
      {
        reservation_id,
        value,
      },
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.RESERVATIONS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const addObservation = async (data: ReservationObservation) => {
  try {
    const createResponse = await apiInstance.post(
      ENDPOINTS.RESERVATIONS.ADD_OBSERVATION,
      data,
    )

    return createResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const updateObservation = async ({
  id,
  data,
}: {
  id: string
  data: ReservationObservation
}) => {
  try {
    const updateResponse = await apiInstance.put(
      ENDPOINTS.RESERVATIONS.UPDATE_OBSERVATION(id),
      data,
    )

    return updateResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const deleteObservation = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.RESERVATIONS.DELETE_OBSERVATION(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getCertPdf = async (id: string) => {
  try {
    const getResponse = await apiInstance.get(
      ENDPOINTS.RESERVATIONS.GET_CERT_PDF(id),
      {
        responseType: 'arraybuffer',
      },
    )

    return getResponse
  } catch (getError) {
    const axiosError = getError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const sendMessage = async ({
  deadline,
}: ReservationSendMessageRequest) => {
  try {
    const sendMessageResponse = await apiInstance.post(
      ENDPOINTS.RESERVATIONS.SEND_MESSAGE,
      {
        deadline,
      },
    )
    return sendMessageResponse.data
  } catch (sendMessageError) {
    const axiosError = sendMessageError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
