import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { SendOrderServiceToSupplier } from 'components/CreateOrderService/@types/form'
import { ButtonSendForSupplierOrderService } from 'components/CreateOrderService/components/ButtonSendForSupplierOrderService'
import { sendOrderServiceToSupplierSchema } from 'components/CreateOrderService/components/form-validation'
import { useGlobalState } from 'contexts/global-state'
import { useFieldArray, useForm } from 'react-hook-form'

type ResendOrderServiceListProps = {
  items: OrderServiceApiResponse[]
  onLoading: (loadingState: boolean) => void
}

export const ResendOrderServiceList = ({
  items = [],
  onLoading,
}: ResendOrderServiceListProps) => {
  const { openSuccessToast, openErrorToast } = useGlobalState()
  const reactHookFormSendOrderServiceToSupplier =
    useForm<SendOrderServiceToSupplier>({
      resolver: yupResolver(sendOrderServiceToSupplierSchema),
    })
  const reactHookForm = useForm({
    defaultValues: {
      orderService: [...items],
    },
  })

  const orderServices = useFieldArray({
    name: 'orderService',
    keyName: 'order_service_id',
    control: reactHookForm.control,
  })

  return (
    <div style={{ padding: '1rem' }}>
      {orderServices.fields.map((item, index) => (
        <Box key={item.id}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxHeight: '32px',
            }}
          >
            <p>
              Nº {item.number_order_service} - {item.name}
            </p>
            <ButtonSendForSupplierOrderService
              onLoading={onLoading}
              openErrorToast={openErrorToast}
              openSuccessToast={openSuccessToast}
              reactHookFormSendOrderServiceToSupplier={
                reactHookFormSendOrderServiceToSupplier
              }
              orderService={{
                ...(item as unknown as OrderServiceWithItemsApiResponse),
              }}
              onSubmit={() => {
                orderServices.remove(index)
              }}
              text="Reenviar"
              disabled={index !== 0}
            />
          </Box>
          <Box></Box>
        </Box>
      ))}
    </div>
  )
}
