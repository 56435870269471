import { useEffect, useCallback, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'
import HtmlEditor from 'components/HtmlEditor'
import LoadingFullPage from 'components/LoadingFullPage'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

type OnSave = (video: VideosApiRequest) => Promise<void>

type AddProps = {
  video?: Video
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({ video, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<VideosApiRequest>()

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Video | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!video && !formUpdated) {
      reset({
        title: video?.title,
        link: video?.link,
        description: video?.description,
      })

      setFormUpdated(true)
    }
  }, [isEditMode, video, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof VideosApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewVideo = useMutation({
    mutationFn: async (video: VideosApiRequest) => {
      await ApiService.Videos.create(video)
    },
    onSuccess: (_data, video: VideosApiRequest) => {
      openSuccessToast({
        message: `O video ${video?.title} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.VIDEOS.LIST)
    },
    onError: (_error, video: VideosApiRequest) => {
      openErrorToast({
        message: `Erro ao adicionar ${video?.title}!`,
      })
    },
  })

  if (isEditMode && !!video && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.VIDEOS.LIST}>
              Videos
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} video
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(video as Video)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.VIDEOS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((video: VideosApiRequest) => {
                if (isEditMode) {
                  onSave?.(video)
                  return
                }

                addNewVideo.mutate(video)
              })}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.title?.message}
                            fullWidth
                            helperText={errors.title?.message}
                            label="Titulo"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('title')}
                            {...register('title', { required: true })}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.link?.message}
                            fullWidth
                            helperText={errors.link?.message}
                            label="Link"
                            margin="normal"
                            variant="outlined"
                            {...inputProps('link')}
                            {...register('link', { required: true })}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Box>
                        <Typography variant="h6" component="h2">
                          Descrição
                        </Typography>

                        <Grid marginTop={2}>
                          <HtmlEditor
                            defaultValue={getValues('description')}
                            onChange={(observationValue) =>
                              setValue('description', observationValue)
                            }
                          />
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Box>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{
                    marginLeft: 'auto',
                    display: 'block',
                    marginTop: '1em',
                    marginRight: '24px',
                  }}
                >
                  {isEditMode ? 'Salvar' : 'Adicionar'}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        video={deleteConfirmation as Video}
      />
    </>
  )
}
