export const INPUT_MASK = {
  CPF: '999.999.999-99',
  CNPJ: '99.999.999/9999-99',
  ZIP_CODE: '99999-999',
  CELL_PHONE: '(99) 99999-9999',
  PHONE: '(99) 9999-9999',
  DATE_LOCALE: 'DD/MM/YYYY',
  DATE_LOCALE_WITH_TIME: 'DD/MM/YYYY HH:mm:ss',
  DATE_EUA: 'YYYY-MM-DD',
  TIME: 'HH:mm:ss',
}
