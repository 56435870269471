import { useCallback, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  AddItemBudgetRequest,
  CreateOrderServiceRequest,
  OnSaveProps,
  SendOrderServiceToSupplier,
} from 'components/CreateOrderService/@types/form'
import { ButtonPrintOrderService } from 'components/CreateOrderService/components/ButtonPrintOrderService'
import { ButtonSendForSupplierOrderService } from 'components/CreateOrderService/components/ButtonSendForSupplierOrderService'
import { ButtonSubmitOrderService } from 'components/CreateOrderService/components/ButtonSubmitOrderService'
import { OrderServiceForm } from 'components/CreateOrderService/components/Form'
import {
  addItemBudgetSchema,
  sendOrderServiceToSupplierSchema,
} from 'components/CreateOrderService/components/form-validation'
import { handleCreateOrderService } from 'components/CreateOrderService/utils/handle-create-order-service'
import LoadingFullPage from 'components/LoadingFullPageWithOverlay'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type AddProps = {
  orderService?: OrderServiceWithItemsApiResponse
  onSave?: (data: OnSaveProps) => Promise<void>
  isEditMode?: boolean
}

export default function Add({
  orderService,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const [isLoading, setIsLoading] = useState(false)
  const reactHookFormSendOrderServiceToSupplier =
    useForm<SendOrderServiceToSupplier>({
      resolver: yupResolver(sendOrderServiceToSupplierSchema),
    })

  const reactHookFormOrderService = useForm<CreateOrderServiceRequest>()
  const reactHookFormAddItemBudget = useForm<AddItemBudgetRequest>({
    resolver: yupResolver(addItemBudgetSchema),
    defaultValues: {
      subtotal: '0,00',
    },
  })

  const items = reactHookFormOrderService.watch('order_service_items')
  const selectedCategoryId = reactHookFormOrderService.watch('category_id')

  const { data: itemsBudget } = useQuery<ItemsBudget[]>(
    QUERY_KEYS.ITEMS_BUDGET.LIST,
    () => ApiService.ItemsBudget.getAll(),
  )

  const itemsBudgetOptions = useMemo(() => {
    return (
      itemsBudget
        ?.filter(
          (item) =>
            !!item.has_service_order &&
            Number(selectedCategoryId) === item.category_id,
        )
        ?.map((item) => ({
          id: item.id,
          name: item.name,
        })) || []
    )
  }, [itemsBudget, selectedCategoryId])

  const onLoading = useCallback((loadingState: boolean) => {
    setIsLoading(loadingState)
  }, [])

  if (isLoading || (isEditMode && !orderService)) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.ORDER_SERVICES.LIST}
            >
              Ordem de serviço
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} Ordem de serviço{' '}
                {orderService?.name ? `- ${orderService?.name}` : ''}
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              <Box sx={{ m: 1 }}>
                <ButtonSendForSupplierOrderService
                  onLoading={onLoading}
                  openErrorToast={openErrorToast}
                  openSuccessToast={openSuccessToast}
                  reactHookFormSendOrderServiceToSupplier={
                    reactHookFormSendOrderServiceToSupplier
                  }
                  orderService={orderService}
                />
              </Box>
              <Box sx={{ m: 1 }}>
                <ButtonPrintOrderService
                  onLoading={onLoading}
                  openErrorToast={openErrorToast}
                  orderService={orderService}
                />
              </Box>

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.ORDER_SERVICES.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <OrderServiceForm
              type="individual"
              orderService={orderService}
              isEditMode={isEditMode}
              itemsBudget={itemsBudgetOptions}
              reactHookFormAddItemBudget={reactHookFormAddItemBudget}
              reactHookFormOrderService={reactHookFormOrderService}
            />
          </Box>
          <Box
            style={{
              display: 'block',
              marginTop: '1em',
              width: 'calc(100% - 16px)',
            }}
          >
            <ButtonSubmitOrderService
              style={{
                marginLeft: 'auto',
                display: 'block',
                marginTop: '1em',
              }}
              handleCreateOrderService={async (data) => {
                await handleCreateOrderService({
                  data,
                  onLoading: (loadingState) => setIsLoading(loadingState),
                  openErrorToast,
                  openSuccessToast,
                  onClose: () => {
                    navigate(ROUTES.ORDER_SERVICES.LIST)
                  },
                })
              }}
              items={items}
              onSave={onSave}
              openErrorToast={openErrorToast}
              orderService={orderService}
              reactHookFormOrderService={reactHookFormOrderService}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}
