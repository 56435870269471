import { validateCnpj } from 'utils/validate-cnpj'
import { validateCpf } from 'utils/validate-cpf'
import * as yup from 'yup'

export const schema = yup.object().shape({
  commercial_unit_id: yup.number().required('Campo obrigatório'),
  // category_id: yup.number().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  active: yup
    .number()
    .transform((value) => {
      return Number(value)
    })
    .optional(),
  document: yup
    .string()
    .when('type', {
      is: (value: 'pf' | 'pj') => value === 'pj',
      then: (schema) =>
        schema.test('test-invalid-cnpj', 'CNPJ invalido', (document) => {
          if (!document?.length) return true
          return validateCnpj({ cnpj: document })
        }),
      otherwise: (schema) =>
        schema.test('test-invalid-cpf', 'CPF invalido', (document) => {
          if (!document?.length) return true
          return validateCpf({ cpf: document })
        }),
    })

    .required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  state_registration: yup.string().nullable().optional(),
  city_registration: yup.string().nullable().optional(),
  fantasy_name: yup
    .string()
    .nullable()
    .when('type', {
      is: (value: 'pf' | 'pj') => value === 'pj',
      then: (schema) => schema.required('Campo obrigatório'),
    }),
  street: yup.string().required('Campo obrigatório'),
  number: yup.string().required('Campo obrigatório'),
  complement: yup.string().nullable(),
  district: yup.string().required('Campo obrigatório'),
  city: yup.string().required('Campo obrigatório'),
  uf: yup.string().required('Campo obrigatório'),
  zip_code: yup.string().required('Campo obrigatório'),
  email: yup.string().required('Campo obrigatório'),
  website: yup.string().nullable(),
  phone: yup.string().nullable(),
  cell_phone: yup.string().required('Campo obrigatório'),
  contact_name: yup.string().nullable(),
  categories: yup.array().nullable(),
})
