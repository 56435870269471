import { useCallback, type PropsWithChildren, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import InputMask from 'components/InputMask'
import Select from 'components/Select'
import { ACCOUNT_TYPES } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { ApiService } from 'services/api'

import { schema } from './form-validation'

type AddAffiliatePagarMeProps = {
  affiliate?: Affiliate
  opened: boolean
  title: string
  onClose?: () => void
  onClick?: () => void
}

export default function AddAffiliatePagarMe({
  affiliate,
  opened,
  title,
  onClose,
  onClick,
}: PropsWithChildren<AddAffiliatePagarMeProps>) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<RecipientApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!!affiliate && !formUpdated) {
      reset({
        name: affiliate.name,
        document: affiliate.document,
        email: affiliate.email,
        account: affiliate?.account,
        agency: affiliate?.agency,
        account_digit: affiliate?.account_digit,
        agency_digit: affiliate?.agency_digit,
        bank_code: affiliate?.bank_code,
        account_type: affiliate?.account_type,
      })

      setFormUpdated(true)
    }
  }, [affiliate, formUpdated, reset])

  const inputProps = useCallback(
    (fieldName: keyof RecipientApiRequest) => {
      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [watch],
  )

  return (
    <Modal
      open={opened}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
        onSubmit={handleSubmit((recipient: RecipientApiRequest) => {
          if (!recipient.account_type || !affiliate?.id) {
            return
          }
          setIsLoading(true)
          ApiService.Recipients.create({
            ...recipient,
            affiliate_id: affiliate.id,
          })
            .then(() => {
              onClick && onClick()
              setIsLoading(false)
              openSuccessToast({
                message: 'Adicionado com sucesso!',
              })
            })
            .catch(() => {
              setIsLoading(false)
              openErrorToast({
                message: 'Erro ao atualizar',
              })
            })
        })}
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '8px',
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            flexWrap={'wrap'}
          >
            {title}
          </Typography>

          <Grid container spacing={3} xs={12}>
            <Grid item xs={4} style={{ margin: '16px 0 8px' }}>
              <Controller
                name="document"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputMask
                      error={!!errors.document?.message}
                      helperText={errors.document?.message}
                      fullWidth
                      label="Documento"
                      id="document"
                      maskType="cpfCnpj"
                      onChange={onChange}
                      value={value}
                      {...inputProps('document')}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={!!errors.name?.message}
                fullWidth
                helperText={errors.name?.message}
                label="Nome"
                margin="normal"
                type="text"
                variant="outlined"
                {...inputProps('name')}
                {...register('name', { required: true })}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                error={!!errors.email?.message}
                fullWidth
                helperText={errors.email?.message}
                label="Email"
                margin="normal"
                type="email"
                variant="outlined"
                {...inputProps('email')}
                {...register('email', { required: true })}
              />
            </Grid>

            <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
              <Controller
                name="bank_code"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputMask
                      error={!!errors.bank_code?.message}
                      helperText={errors.bank_code?.message}
                      fullWidth
                      label="Cod. Banco"
                      id="bank_code"
                      maskType="bank_code"
                      onChange={onChange}
                      value={value}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                error={!!errors.agency?.message}
                fullWidth
                helperText={errors.agency?.message}
                label="Agência"
                margin="normal"
                type="number"
                variant="outlined"
                {...inputProps('agency')}
                {...register('agency')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                error={!!errors.agency_digit?.message}
                fullWidth
                helperText={errors.agency_digit?.message}
                label="Agência - Digito"
                margin="normal"
                type="number"
                variant="outlined"
                {...inputProps('agency_digit')}
                {...register('agency_digit')}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                error={!!errors.account?.message}
                fullWidth
                helperText={errors.account?.message}
                label="Conta"
                margin="normal"
                type="number"
                variant="outlined"
                {...inputProps('account')}
                {...register('account')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                error={!!errors.account_digit?.message}
                fullWidth
                helperText={errors.account_digit?.message}
                label="Conta - Digito"
                margin="normal"
                type="number"
                variant="outlined"
                {...inputProps('account_digit')}
                {...register('account_digit')}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="account_type"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      label="Tipo de Conta"
                      id="account_type"
                      onChange={onChange}
                      value={value}
                      {...inputProps}
                      autoFill={true}
                      options={ACCOUNT_TYPES}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={9}></Grid>

            <Box
              sx={{
                mt: 2,
                flex: 1,
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                sx={{ flexGrow: 1, padding: '10px', maxWidth: '120px' }}
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </Modal>
  )
}
