import { useState, useCallback } from 'react'

import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import {
  Chart as ChartJS,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  SubTitle,
} from 'chart.js'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import { QUERY_KEYS } from 'constants/keys'
import { RESERVATION_SITUATION } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { Pie } from 'react-chartjs-2'
import { ApiService } from 'services/api'
import { generateHexColorsArray } from 'utils/chartColors'
import { currencyMask } from 'utils/mask'

export const CHART_COLORS: Record<string, string> = {
  'Aguardando pagamento': '#FF7538',
  Pago: '#55DD33',
  Realizada: '#FF7538',
  Cancelada: '#ED2939',
  'Pagamento estornado': '#ED2939',
  Finalizado: '#0070FF',
}

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle)

export default function Reservations() {
  const [total, setTotal] = useState<number>(0)

  const [salesChartData, setSalesChartData] = useState<
    ChartData<'pie', number[], unknown> | undefined
  >()

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [activityCode, setActivityCode] = useState<string>('')

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { openErrorToast } = useGlobalState()

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const generateReport = useCallback(() => {
    setIsLoading(true)
    ApiService.Reservations.getAllByParams({
      institutions: selectedInstitutions.map((si) => Number(si.value)),
      code: activityCode,
      fields: [
        'Reservation.id',
        'Reservation.situation',
        'Reservation.created_at',
      ],

      start_date: startDate
        ? String(startDate).split('/').reverse().join('-')
        : undefined,
      end_date: endDate
        ? String(endDate).split('/').reverse().join('-')
        : undefined,
    })
      .then((response) => {
        const totalSales = response.reduce((accumulator, currentValue) => {
          accumulator += 1
          return accumulator
        }, 0)

        const totalSalesBySituation = response.reduce(
          (accumulator: Record<string, number>, currentValue) => {
            if (
              accumulator[
                (currentValue.situation || '').replaceAll('"', '').trim()
              ]
            ) {
              accumulator[
                (currentValue.situation || '').replaceAll('"', '').trim()
              ] += 1

              return accumulator
            }

            accumulator[
              (currentValue.situation || '').replaceAll('"', '').trim()
            ] = 1

            return accumulator
          },
          {},
        )

        const sortedBySales = Object.entries(totalSalesBySituation).sort(
          (a, b) => b[1] - a[1],
        )

        const situationsNames = sortedBySales.map((s) => s[0])
        const situationsValues = sortedBySales.map((s) => s[1])

        const totalSalesBySituationWithPercentage = Object.entries(
          totalSalesBySituation,
        ).reduce((accumulator: Record<string, string>, [key, value]) => {
          accumulator[key] = ((value / totalSales) * 100).toFixed(2)
          return accumulator
        }, {})

        setSalesChartData({
          labels: situationsNames.map(
            (situation) =>
              `${RESERVATION_SITUATION[situation]} - ${
                totalSalesBySituationWithPercentage[situation]
              }% - ${currencyMask(totalSalesBySituation[situation], {
                minimumFractionDigits: 0,
              })}`,
          ),
          datasets: [
            {
              data: situationsValues,
              backgroundColor: (context) => {
                const index = context.dataIndex
                const legendItem = context.chart?.legend?.legendItems?.[index]

                if (legendItem) {
                  const legend = legendItem.text.split('-')[0].trim()
                  return CHART_COLORS[legend]
                }
                return generateHexColorsArray()[0]
              },
              borderWidth: 1,
            },
          ],
        })

        setTotal(totalSales)

        if (!response.length) {
          openErrorToast({ message: 'Nenhum resultado encontrado.' })
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [selectedInstitutions, activityCode, startDate, endDate, openErrorToast])

  if (!institutions?.length || isLoading) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Gráfico de reservas
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <TextField
              label="Código da atividade"
              type="text"
              variant="outlined"
              value={activityCode}
              onChange={(event) => {
                setActivityCode(event.target.value)
              }}
            />

            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={generateReport}
              disabled={!selectedInstitutions.length && !startDate && !endDate}
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        {salesChartData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Pie
                data={salesChartData}
                width={600}
                height={600}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) =>
                          currencyMask(context.parsed, {
                            minimumFractionDigits: 0,
                          }),
                      },
                    },
                    title: {
                      display: true,
                      font: {
                        size: 16,
                      },
                      text: `Total - ${currencyMask(total, {
                        minimumFractionDigits: 0,
                      })}`,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </Box>
  )
}
