export const removeDuplicatedArrayOfObject = (
  arrayData: { [key: string]: string | number | boolean }[],
  options?: { field?: string },
) => {
  const filterField = options?.field || 'label'
  return Array.from(new Set(arrayData.map((a) => a[filterField]))).map(
    (field) => {
      return arrayData.find((a) => a[filterField] === field)
    },
  )
}
