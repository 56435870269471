import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  method: yup.string().required('Campo obrigatório'),
  base_url: yup.string().required('Campo obrigatório'),
  grouped: yup.string().required('Campo obrigatório'),
  function: yup.string().required('Campo obrigatório'),
})

export { schema }
