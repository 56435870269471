import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<PermissionApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<PermissionApiResponse[]>(
      ENDPOINTS.PERMISSIONS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()

    return (getAllResponse || []).map((permission) => ({
      label: permission.name,
      value: permission.id.toString(),
    }))
  } catch (_getAllError) {
    return Promise.reject([])
  }
}

export const get = async (id: string): Promise<PermissionApiResponse> => {
  try {
    const getResponse = await apiInstance.get<PermissionApiResponse>(
      ENDPOINTS.PERMISSIONS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  permission: PermissionsApiRequest,
): Promise<PermissionApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<PermissionApiResponse>(
      ENDPOINTS.PERMISSIONS.CREATE,
      permission,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  permission,
}: {
  permission: PermissionsApiRequest
  id: string
}): Promise<PermissionApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<PermissionApiResponse>(
      ENDPOINTS.PERMISSIONS.UPDATE(id),
      permission,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.PERMISSIONS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
