import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<PaymentModuleApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<PaymentModuleApiResponse[]>(
      ENDPOINTS.PAYMENT_MODULES.GET_ALL,
    )
    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
export const getAllNamesAndIds = async (): Promise<PaymentModuleOptions[]> => {
  try {
    const getAllResponse = await getAll()
    const filterResponsePerActive = getAllResponse.filter((item) => item.active)
    const options = filterResponsePerActive.map(
      (filterItem) =>
        filterItem && {
          label: filterItem.name,
          value: filterItem.id,
          fields: filterItem.fields,
        },
    )
    return options
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<PaymentModuleApiResponse> => {
  try {
    const getResponse = await apiInstance.get<PaymentModuleApiResponse>(
      ENDPOINTS.PAYMENT_MODULES.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  paymentModule: PaymentModuleApiRequest,
): Promise<PaymentModuleApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<PaymentModuleApiResponse>(
      ENDPOINTS.PAYMENT_MODULES.CREATE,
      paymentModule,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  paymentModule,
}: {
  paymentModule: PaymentModuleApiRequest
  id: string
}): Promise<PaymentModuleApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<PaymentModuleApiResponse>(
      ENDPOINTS.PAYMENT_MODULES.UPDATE(id),
      paymentModule,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.PAYMENT_MODULES.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
