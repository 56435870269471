import { GridRowModes } from '@mui/x-data-grid'

interface convertArrayOfObjectInSingleObjectToGridRowModeProps {
  data: { id: number; hasEdit: boolean }[]
  field: string
}
export const convertArrayOfObjectInSingleObjectToGridRowMode = ({
  data,
  field,
}: convertArrayOfObjectInSingleObjectToGridRowModeProps) => {
  return data.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.id]: {
          mode: item.hasEdit ? GridRowModes.Edit : GridRowModes.View,
          fieldToFocus: field,
        },
      }),
    {},
  )
}
