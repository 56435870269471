import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Label>(QUERY_KEYS.LABELS.EDIT(id as string), () =>
    ApiService.Labels.get(id as string),
  )

  const updateLabel = useMutation({
    mutationFn: async ({
      id,
      label,
    }: {
      id: string
      label: LabelsApiRequest
    }) => {
      await ApiService.Labels.update({
        id,
        label,
      })
    },
    onSuccess: (_data, { id, label }) => {
      queryClient.setQueryData<LabelsApiRequest>(
        QUERY_KEYS.LABELS.EDIT(id),
        (oldLabel) => {
          if (!oldLabel) return
          return label
        },
      )

      openSuccessToast({
        message: `O label para ${label.entity_translated} foi atualizada com sucesso!`,
      })

      navigate(ROUTES.LABELS.LIST)
    },
    onError: (_error, { label }) => {
      openErrorToast({
        message: `Erro ao editar ${label.entity_translated}!`,
      })
    },
  })

  return (
    <Add
      label={data}
      isEditMode
      onSave={async (label: LabelsApiRequest, campus?: CampusApiRequest[]) => {
        updateLabel.mutate({
          id: id as string,
          label,
        })
      }}
    />
  )
}
