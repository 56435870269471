import { useEffect, useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import InputMask from 'components/InputMask'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { ACCOUNT_TYPES } from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AddressService } from 'services/address'
import { ApiService } from 'services/api'

import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (affiliate: AffiliatesApiRequest) => Promise<void>

type AddProps = {
  affiliate?: Affiliate
  onSave?: OnSave
  isEditMode?: boolean
}

export default function Add({
  affiliate,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<AffiliatesApiRequest>({
    resolver: yupResolver(schema),
  })

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [modalSavePagarMe, setModalSavePagarMe] = useState<{
    open: boolean
    affiliate?: AffiliatesApiRequest
  }>({
    open: false,
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Affiliate | undefined
  >()

  const { data: commercialUnits, isLoading: commercialUnitsIsLoading } =
    useQuery<SelectProps['options']>(
      QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
      () => ApiService.CommercialUnits.getAllNamesAndIds(),
    )

  useEffect(() => {
    if (isEditMode && !!affiliate && !formUpdated) {
      reset({
        commercial_unit_id: affiliate.commercial_unit_id,
        code: affiliate.code,
        name: affiliate.name,
        document: affiliate.document,
        street: affiliate.street,
        number: affiliate.number,
        complement: affiliate.complement,
        district: affiliate.district,
        city: affiliate.city,
        uf: affiliate.uf,
        zip_code: affiliate.zip_code,
        email: affiliate.email,
        phone: affiliate.phone,
        cell_phone: affiliate.cell_phone,
        active: affiliate.active,
        account: affiliate?.account,
        agency: affiliate?.agency,
        account_digit: affiliate?.account_digit,
        agency_digit: affiliate?.agency_digit,
        bank_code: affiliate?.bank_code,
        account_type: affiliate?.account_type,
      })

      setValue(
        'commercial_unit_id',
        affiliate.commercial_unit_id?.toString() as unknown as number,
      )

      setValue('active', affiliate.active)

      setFormUpdated(true)
    }
  }, [isEditMode, affiliate, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof AffiliatesApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewAffiliate = useMutation({
    mutationFn: async (affiliate: AffiliatesApiRequest) => {
      setIsLoading(true)
      await ApiService.Affiliates.create(affiliate)
    },
    onSuccess: (_data, affiliate: AffiliatesApiRequest) => {
      setIsLoading(false)
      queryClient.setQueryData<AffiliatesApiRequest[]>(
        QUERY_KEYS.AFFILIATES.LIST,
        (oldAffiliates) => {
          if (!oldAffiliates?.length) return

          return oldAffiliates.map((currentAffiliate) =>
            currentAffiliate.document === affiliate.document
              ? affiliate
              : currentAffiliate,
          )
        },
      )
      setModalSavePagarMe({
        open: false,
      })

      openSuccessToast({
        message: `A afiliação ${affiliate?.name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.AFFILIATES.LIST)
    },
    onError: (error: ApiError, affiliate: AffiliatesApiRequest) => {
      setIsLoading(false)
      setModalSavePagarMe({
        open: false,
      })
      openErrorToast({
        message: error.message || `Erro ao adicionar ${affiliate?.name}!`,
      })
    },
  })

  const handleSaveAffiliate = ({
    affiliate,
    saveOnPagarMe,
  }: {
    affiliate?: AffiliatesApiRequest
    saveOnPagarMe: boolean
  }) => {
    if (!affiliate) {
      return
    }
    if (saveOnPagarMe) {
      if (!modalSavePagarMe.affiliate?.account) {
        openErrorToast({ message: 'Informe os dados bancários' })
        setModalSavePagarMe({ open: false })
        return
      }
    }
    addNewAffiliate.mutate({ ...affiliate, save_in_pagarme: saveOnPagarMe })
  }

  if (
    commercialUnitsIsLoading ||
    (isEditMode && !!affiliate && formUpdated === false)
  ) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.AFFILIATES.LIST}
            >
              Afiliações
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} afiliação
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(affiliate as Affiliate)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.AFFILIATES.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((affiliate: AffiliatesApiRequest) => {
                if (isEditMode) {
                  onSave?.(affiliate)
                  return
                }
                setModalSavePagarMe({
                  open: true,
                  affiliate,
                })
              })}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3} xs={12}>
                    <Grid item xs={3}>
                      <Controller
                        name="commercial_unit_id"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              label="Unidade comercial"
                              id="commercial_unit_id"
                              onChange={onChange}
                              value={value}
                              options={commercialUnits || []}
                              autoFill={isEditMode}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="document"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              error={!!errors.document?.message}
                              helperText={errors.document?.message}
                              fullWidth
                              label="Documento"
                              id="document"
                              maskType="cpfCnpj"
                              onChange={onChange}
                              value={value}
                              {...inputProps('document')}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.name?.message}
                        fullWidth
                        helperText={errors.name?.message}
                        label="Nome"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('name')}
                        {...register('name', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.email?.message}
                        fullWidth
                        helperText={errors.email?.message}
                        label="Email"
                        margin="normal"
                        type="email"
                        variant="outlined"
                        {...inputProps('email')}
                        {...register('email', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('phone')}
                              error={!!errors.phone?.message}
                              helperText={errors.phone?.message}
                              fullWidth
                              label="Telefone"
                              id="phone"
                              maskType="phone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="cell_phone"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('cell_phone')}
                              error={!!errors.cell_phone?.message}
                              helperText={errors.cell_phone?.message}
                              fullWidth
                              label="Celular"
                              id="cell_phone"
                              maskType="cellPhone"
                              onChange={onChange}
                              value={value}
                            />
                          )
                        }}
                      />
                    </Grid>
                    {!isEditMode && (
                      <>
                        <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                          <Controller
                            name="bank_code"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputMask
                                  error={!!errors.bank_code?.message}
                                  helperText={errors.bank_code?.message}
                                  fullWidth
                                  label="Cod. Banco"
                                  id="bank_code"
                                  maskType="bank_code"
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={!!errors.agency?.message}
                            fullWidth
                            helperText={errors.agency?.message}
                            label="Agência"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            {...inputProps('agency')}
                            {...register('agency')}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            error={!!errors.agency_digit?.message}
                            fullWidth
                            helperText={errors.agency_digit?.message}
                            label="Agência - Digito"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            {...inputProps('agency_digit')}
                            {...register('agency_digit')}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={!!errors.account?.message}
                            fullWidth
                            helperText={errors.account?.message}
                            label="Conta"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            {...inputProps('account')}
                            {...register('account')}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            error={!!errors.account_digit?.message}
                            fullWidth
                            helperText={errors.account_digit?.message}
                            label="Conta - Digito"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            {...inputProps('account_digit')}
                            {...register('account_digit')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="account_type"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Tipo de Conta"
                                  id="account_type"
                                  onChange={onChange}
                                  value={value}
                                  {...inputProps}
                                  autoFill={isEditMode}
                                  options={ACCOUNT_TYPES}
                                />
                              )
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={3} style={{ margin: '16px 0 8px' }}>
                      <Controller
                        name="zip_code"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputMask
                              {...inputProps('zip_code')}
                              error={!!errors.zip_code?.message}
                              helperText={errors.zip_code?.message}
                              fullWidth
                              label="CEP"
                              id="zip_code"
                              maskType="zipCode"
                              onChange={onChange}
                              value={value}
                              onBlur={async () => {
                                const address =
                                  await AddressService.getAddressByCEP(value)

                                if (!address) return

                                setValue('street', address.street, {
                                  shouldValidate: true,
                                })
                                setValue('district', address.neighborhood, {
                                  shouldValidate: true,
                                })
                                setValue('uf', address.state, {
                                  shouldValidate: true,
                                })
                                setValue('city', address.city, {
                                  shouldValidate: true,
                                })
                              }}
                            />
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.street?.message}
                        fullWidth
                        helperText={errors.street?.message}
                        label="Endereço"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('street')}
                        {...register('street', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        error={!!errors.number?.message}
                        fullWidth
                        helperText={errors.number?.message}
                        label="Número"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('number')}
                        {...register('number', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.complement?.message}
                        fullWidth
                        helperText={errors.complement?.message}
                        label="Complemento"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('complement')}
                        {...register('complement', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.district?.message}
                        fullWidth
                        helperText={errors.district?.message}
                        label="Bairro"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('district')}
                        {...register('district', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        error={!!errors.city?.message}
                        fullWidth
                        helperText={errors.city?.message}
                        label="Cidade"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('city')}
                        {...register('city', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={isEditMode ? 2 : 3}>
                      <TextField
                        error={!!errors.uf?.message}
                        fullWidth
                        helperText={errors.uf?.message}
                        label="Estado"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        {...inputProps('uf')}
                        {...register('uf', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={isEditMode ? 2 : 3}>
                      <TextField
                        error={!!errors.code?.message}
                        fullWidth
                        helperText={errors.code?.message}
                        label="Código de Afiliação"
                        margin="normal"
                        type="text"
                        variant="outlined"
                        defaultValue={Math.random()
                          .toString(36)
                          .slice(2, 8)
                          .toUpperCase()}
                        InputLabelProps={{ shrink: true }}
                        {...register('code', { required: true })}
                      />
                    </Grid>
                    {isEditMode && (
                      <Grid item xs={2}>
                        <Controller
                          name="active"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                label="Ativo"
                                id="active"
                                onChange={onChange}
                                value={value}
                                autoFill={isEditMode}
                                {...inputProps('active')}
                                options={[
                                  {
                                    label: 'Sim',
                                    value: 1,
                                  },
                                  {
                                    label: 'Não',
                                    value: 0,
                                  },
                                ]}
                              />
                            )
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Box>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: 'auto',
                        display: 'block',
                        marginTop: '1em',
                        marginRight: '24px',
                      }}
                    >
                      {isEditMode ? 'Salvar' : 'Adicionar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        affiliate={deleteConfirmation as Affiliate}
      />
      <ConfirmationModal
        opened={modalSavePagarMe.open}
        loading={isLoading}
        title="Deseja salvar afiliado ao pagar.me?"
        text={{ confirm: 'Sim', cancel: 'Não' }}
        onClose={() => setModalSavePagarMe({ open: false })}
        onCancel={() =>
          handleSaveAffiliate({
            affiliate: modalSavePagarMe.affiliate,
            saveOnPagarMe: false,
          })
        }
        onConfim={() =>
          handleSaveAffiliate({
            affiliate: modalSavePagarMe.affiliate,
            saveOnPagarMe: true,
          })
        }
        confirmColor="success"
      >
        Deseja realmente salvar afiliado ao pagar.me?
      </ConfirmationModal>
    </>
  )
}
