import { useCallback, useEffect, useState } from 'react'

import { Delete as DeleteIcon } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { Box } from '@mui/material'
import { Button } from '@mui/material'
import { Card } from '@mui/material'
import { CardContent } from '@mui/material'
import { Container } from '@mui/material'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridToolbarContainer,
  GridValidRowModel,
  ptBR,
} from '@mui/x-data-grid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { ApiService } from 'services/api'

import { useActivityChecklistContext } from '../add'
import { CustomEditDatePickerField } from './EditDatePickerField'
import { CustomEditSelectField } from './EditSelectField'
import { CustomEditTextAreaField } from './EditTextAreaField'

type OnSave = (activityTask: ActivityChecklistsApiRequest) => Promise<void>

type AddProps = {
  activity?: ActivityApiResponse
  activityTask?: ActivityTask
  onSave?: OnSave
  isEditMode?: boolean
}

const filter = createFilterOptions<ActivityTaskApiResponse>()

export default function TaskItem({ activity, isEditMode = false }: AddProps) {
  const { setActivityTaskItems, activityTaskItems } =
    useActivityChecklistContext()
  const queryClient = useQueryClient()
  const { openErrorToast } = useGlobalState()

  const {
    formState: { errors },
    control,
  } = useFormContext<ActivityTaskForm>()

  const dialogMethods = useForm<{
    data: string | ActivityTaskApiResponse
    name: string
  }>()

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

  useEffect(() => {
    if (isEditMode && activity?.activityChecklist) {
      setActivityTaskItems(
        (activity?.activityChecklist || [])
          .filter((item) => !!item.activity_task_id)
          ?.map((item) => ({
            ...item,
            item_id: item.id,
            date_realized: item.details.date_realized,
            status: item.details.status,
            description: item.details.description,
            default: false,
            name: item?.details?.name || '',
          })),
      )
    }
  }, [activity?.activityChecklist, isEditMode, setActivityTaskItems])

  const { data: autoCompleteTaskItems } = useQuery<ActivityTaskApiResponse[]>(
    QUERY_KEYS.ACTIVITY_TASKS.LIST,
    async () => {
      const responseItemsBudget = await ApiService.ActivityTasks.getAll()

      return responseItemsBudget
    },
  )

  const addTaskItem = useMutation({
    mutationFn: async (taskItem: ActivityTaskItemsForm) => {
      const data = await ApiService.ActivityTasks.create({
        ...taskItem,
      })
      return data
    },
    onSuccess: (_data, itemsBudget: ActivityTaskItemsForm) => {
      queryClient.setQueryData<ActivityTaskItemsForm[]>(
        QUERY_KEYS.ITEMS_BUDGET.LIST,
        (oldItemsBudget) => {
          if (!oldItemsBudget?.length) return

          return oldItemsBudget.map((currentItemsBudget) => {
            if (currentItemsBudget.id === itemsBudget.id) {
              return itemsBudget
            }

            return currentItemsBudget
          })
        },
      )

      autoCompleteTaskItems?.push({
        ..._data,
      })
      setActivityTaskItems((oldActivityTasksItems) => [
        ...oldActivityTasksItems,
        {
          id: _data.id,
          name: _data.name,
          default: false,
          active: true,
          status: _data.status,
          description: _data.description,
          date_realized: _data.date_realized,
        },
      ])
      setOpenAddDialog(false)
    },
    onError: (error, itemsBudget: ActivityTaskItemsForm) => {
      const apiError = error as ApiError

      if (apiError?.status === 400) {
        openErrorToast({
          message: `A tarefa ${itemsBudget.name} já está cadastrada!`,
        })

        return
      }

      openErrorToast({
        message: `Erro ao adicionar ${itemsBudget.name}!`,
      })
    },
  })

  const AddToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => setOpenAddDialog(true)}
          style={{
            marginLeft: 'auto',
            display: 'block',
            marginTop: '1em',
          }}
        >
          Adicionar
        </Button>
      </GridToolbarContainer>
    )
  }

  const handleStartEdit = useCallback(
    (id: GridRowId) => {
      setActivityTaskItems((old) =>
        old.map((oldActivityTaskItem) =>
          oldActivityTaskItem.id === id
            ? {
                ...oldActivityTaskItem,
                hasEdit: true,
              }
            : { ...oldActivityTaskItem },
        ),
      )
    },
    [setActivityTaskItems],
  )

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      const filteredActivityTaskItems = activityTaskItems.filter(
        (row) => row.id !== id,
      )
      setActivityTaskItems(filteredActivityTaskItems)
    },
    [activityTaskItems, setActivityTaskItems],
  )

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Box>
                      <Typography variant="h5">Tarefas</Typography>
                    </Box>
                    <Grid container>
                      <DataGrid
                        sx={{
                          border: 0,
                          '& .MuiFormControl-root': {
                            marginTop: 0,
                            marginBottom: 0,
                          },
                          '& .MuiDataGrid-cell--editing': {
                            padding: '0 10px !important',
                          },
                          '& .Mui-error': {
                            outline: 'red 1px solid',
                          },
                          '& .custom-erro-input': {
                            outline: 'none !important',
                          },
                          '& .MuiFormLabel-root': {
                            display: 'none ',
                          },
                          '& .MuiFormHelperText-root': {
                            display: 'none !important',
                          },
                          '& .MuiSelect-select': {
                            padding: '10px !important',
                          },
                        }}
                        rows={activityTaskItems as GridValidRowModel[]}
                        onRowEditStart={(_, event) => {
                          event.defaultMuiPrevented = true
                        }}
                        onRowClick={(params) => handleStartEdit(params.id)}
                        disableIgnoreModificationsIfProcessingProps
                        experimentalFeatures={{ newEditingApi: true }}
                        getEstimatedRowHeight={() => 100}
                        getRowHeight={() => 'auto'}
                        columns={[
                          {
                            field: 'name',
                            headerName: 'Nome',
                            flex: 1,
                            maxWidth: 400,
                            renderCell: (params) => {
                              return (
                                <div
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  <p style={{ width: '100%' }}>
                                    {params.row?.name || ''}
                                  </p>
                                </div>
                              )
                            },
                          },
                          {
                            field: 'date_realized',
                            headerName: 'Data realizada',
                            type: 'date',
                            align: 'left',
                            headerAlign: 'left',
                            width: 220,
                            editable: true,
                            renderCell: (props: GridRenderEditCellParams) => {
                              const fieldName =
                                `activityTasksItems.${props.id}.date_realized` as keyof ActivityTaskForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.date_realized?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditDatePickerField
                                        {...props}
                                        setActivityTask={setActivityTaskItems}
                                        activityTask={activityTaskItems}
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityTasksItems?.[
                                            props.id as number
                                          ]?.date_realized
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityTasksItems.${props.id}.date_realized` as keyof ActivityTaskForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditDatePickerField
                                        {...props}
                                        setActivityTask={setActivityTaskItems}
                                        activityTask={activityTaskItems}
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityTasksItems?.[
                                            props.id as number
                                          ]?.date_realized
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityTasksItems?.[
                                  params.id as number
                                ]?.date_realized
                              return hasErro ? 'custom-erro-input' : ''
                            },
                          },
                          {
                            field: 'status',
                            headerName: 'Status',
                            width: 220,
                            editable: true,
                            type: 'string',
                            cellClassName: (
                              params: GridCellParams<{ id: number }>,
                            ) => {
                              const hasErro =
                                !!errors?.activityTasksItems?.[
                                  params.id as number
                                ]?.status
                              return hasErro ? 'custom-erro-input' : ''
                            },
                            renderCell: (props) => {
                              const fieldName =
                                `activityTasksItems.${props.id}.status` as keyof ActivityTaskForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={activity?.activityChecklist
                                    .find((item) => item.id === props.id)
                                    ?.details?.status?.toString()}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditSelectField
                                        {...props}
                                        setActivityTask={setActivityTaskItems}
                                        activityTask={activityTaskItems}
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityTasksItems?.[
                                            props.id as number
                                          ]?.status
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityTasksItems.${props.id}.status` as keyof ActivityTaskForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditSelectField
                                        {...props}
                                        setActivityTask={setActivityTaskItems}
                                        activityTask={activityTaskItems}
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityTasksItems?.[
                                            props.id as number
                                          ]?.status
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                          },
                          {
                            field: 'description',
                            headerName: 'Descrição',
                            flex: 1,
                            editable: true,
                            renderCell: (props: GridRenderEditCellParams) => {
                              const fieldName =
                                `activityTasksItems.${props.id}.description` as keyof ActivityTaskForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  defaultValue={
                                    activity?.activityChecklist.find(
                                      (item) => item.id === props.id,
                                    )?.details?.description
                                  }
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditTextAreaField
                                        {...props}
                                        setActivityTask={setActivityTaskItems}
                                        activityTask={activityTaskItems}
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityTasksItems?.[
                                            props.id as number
                                          ]?.description
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                            renderEditCell: (props) => {
                              const fieldName =
                                `activityTasksItems.${props.id}.description` as keyof ActivityTaskForm
                              return (
                                <Controller
                                  name={fieldName}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <CustomEditTextAreaField
                                        {...props}
                                        setActivityTask={setActivityTaskItems}
                                        activityTask={activityTaskItems}
                                        value={value}
                                        onChange={onChange}
                                        error={
                                          !!errors?.activityTasksItems?.[
                                            props.id as number
                                          ]?.description
                                        }
                                      />
                                    )
                                  }}
                                />
                              )
                            },
                          },
                          {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Ações',
                            width: 100,
                            cellClassName: 'actions',
                            renderCell: ({ id, row }) => {
                              return (
                                <GridActionsCellItem
                                  key={`cancel-${id}`}
                                  icon={<DeleteIcon />}
                                  label="Delete"
                                  onClick={handleDeleteClick(id)}
                                  color="inherit"
                                  disabled={row.default}
                                />
                              )
                            },
                          },
                        ]}
                        editMode="row"
                        autoHeight
                        localeText={
                          ptBR.components.MuiDataGrid.defaultProps.localeText
                        }
                        components={{ Footer: () => <AddToolbar /> }}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Adicionar Tarefa</DialogTitle>
        <Box sx={{ py: 1, px: 1 }}>
          <Container maxWidth={false}>
            <Box>
              <form>
                <Grid container>
                  <Grid item xs={12}>
                    <Controller
                      name="data"
                      control={dialogMethods.control}
                      shouldUnregister
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Autocomplete
                            filterSelectedOptions
                            value={value as ActivityTaskApiResponse}
                            onChange={(_, newValue) => {
                              if (newValue?.inputValue) {
                                onChange(newValue.inputValue)
                                return
                              }

                              onChange(newValue)
                            }}
                            noOptionsText="Nada foi encontrado"
                            options={autoCompleteTaskItems || []}
                            getOptionDisabled={(option) => {
                              const selectedOption = activityTaskItems.find(
                                (item) => item.name === option.name,
                              )
                              return !!selectedOption
                            }}
                            getOptionLabel={(option) => {
                              if (option.inputValue) {
                                return option.inputValue || ''
                              }

                              return option.name || ''
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params)
                              const { inputValue } = params
                              const isExisting = options.some(
                                (option) => inputValue === option.name,
                              )
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  name: `Adicionar "${inputValue}"`,
                                  id: Math.random() * 999,
                                })
                              }

                              return filtered
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                              <li {...props}>{option.name}</li>
                            )}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  dialogMethods.formState.errors.data?.type ===
                                  'required'
                                }
                                fullWidth
                                helperText={
                                  dialogMethods.formState.errors.data?.type ===
                                  'required'
                                    ? 'Campo obrigatório'
                                    : ''
                                }
                                label="Nome"
                                type="text"
                                variant="outlined"
                              />
                            )}
                          />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    style={{
                      marginLeft: 'auto',
                      display: 'block',
                      marginTop: '1em',
                    }}
                    onClick={dialogMethods?.handleSubmit(
                      ({
                        data,
                      }: {
                        data: string | ActivityTaskApiResponse
                      }) => {
                        if (typeof data === 'string') {
                          addTaskItem.mutate({
                            default: false,
                            name: data,
                            status: 'pending',
                            active: true,
                          })
                          return
                        }
                        setActivityTaskItems((oldActivityTasksItems) => [
                          ...oldActivityTasksItems,
                          {
                            date_realized: '',
                            status: 'pending',
                            description: '',
                            default: false,
                            name: data?.name || '',
                            id: data.id,
                          },
                        ])
                        setOpenAddDialog(false)
                      },
                    )}
                  >
                    {isEditMode ? 'Salvar' : 'Adicionar'}
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Dialog>
    </>
  )
}
