import { useCallback } from 'react'

import { Button, DialogActions, Grid, TextField } from '@mui/material'
import { AddItemBudgetRequest } from 'components/CreateOrderService/@types/form'
import InputCurrency from 'components/InputCurrency'
import Select, { SelectProps } from 'components/Select'
import { Controller, UseFormReturn } from 'react-hook-form'
import { currencyMask, monetaryToNumber } from 'utils/mask'

export const AddItemBudget = ({
  activityChecklistItemsOptions,
  onClickAdd,
  onClickUpdate,
  onCLickClose,
  reactHookFormAddItemBudget,
  isEditMode = false,
}: {
  activityChecklistItemsOptions: SelectProps['options']
  isEditMode?: boolean
  onClickAdd: (data: AddItemBudgetRequest) => void
  onClickUpdate: (data: AddItemBudgetRequest) => void
  onCLickClose: () => void
  reactHookFormAddItemBudget: UseFormReturn<AddItemBudgetRequest>
}) => {
  const handleCalc = useCallback(
    ({ unitValue, quantity }: { unitValue?: string; quantity?: string }) => {
      reactHookFormAddItemBudget.setValue(
        'subtotal',
        currencyMask(
          monetaryToNumber(
            unitValue || reactHookFormAddItemBudget.watch('unit_value') || '0',
          ) *
            monetaryToNumber(
              quantity || reactHookFormAddItemBudget.watch('quantity') || '0',
            ),
        ),
      )
    },
    [reactHookFormAddItemBudget],
  )

  return (
    <form>
      <Grid container spacing={3}>
        {!isEditMode && (
          <Grid item xs={12}>
            <Controller
              name="name"
              control={reactHookFormAddItemBudget.control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Item"
                    id="name"
                    onChange={(event) => {
                      reactHookFormAddItemBudget.setValue(
                        'id',
                        event.target.value as string,
                      )
                      onChange(event)
                    }}
                    // onChange={(event) => {
                    //   reactHookFormAddItemBudget.setValue(
                    //     'id',
                    //     event.target.value as string,
                    //   )
                    //   const findActivityChecklistItem =
                    //     activityChecklistItemsOptions.find(
                    //       (item) => item.value === event.target.value,
                    //     )
                    //   event.target.value = 'LABEL'
                    //   console.log(
                    //     findActivityChecklistItem,
                    //     'findActivityChecklistItem',
                    //   )
                    //   onChange(event)
                    // }}
                    value={value}
                    options={activityChecklistItemsOptions || []}
                    error={
                      !!reactHookFormAddItemBudget.formState.errors.name
                        ?.message
                    }
                    helperText={
                      reactHookFormAddItemBudget.formState.errors.name?.message
                    }
                  />
                )
              }}
            />
          </Grid>
        )}

        <Grid item xs={4}>
          <Controller
            name="quantity"
            control={reactHookFormAddItemBudget.control}
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <TextField
                  error={
                    !!reactHookFormAddItemBudget.formState?.errors?.quantity
                      ?.message
                  }
                  helperText={
                    reactHookFormAddItemBudget.formState?.errors?.quantity
                      ?.message
                  }
                  fullWidth
                  margin="normal"
                  label="Quantidade"
                  id="quantity"
                  onChange={(event) => {
                    event.target.value = event.target.value?.replace(
                      /[a-z, A-Z]/g,
                      '',
                    )
                    handleCalc({
                      quantity: event.target.value,
                    })
                    onChange(event)
                  }}
                  value={value}
                  {...rest}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="unit_value"
            control={reactHookFormAddItemBudget.control}
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <InputCurrency
                  error={
                    !!reactHookFormAddItemBudget.formState?.errors?.unit_value
                      ?.message
                  }
                  helperText={
                    reactHookFormAddItemBudget.formState?.errors?.unit_value
                      ?.message
                  }
                  fullWidth
                  label="Valor Unitário"
                  id="unit_value"
                  onChange={(event) => {
                    handleCalc({
                      unitValue: event.target.value,
                    })
                    onChange(event)
                  }}
                  value={value}
                  {...rest}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={
              !!reactHookFormAddItemBudget.formState?.errors?.subtotal?.message
            }
            helperText={
              reactHookFormAddItemBudget.formState?.errors?.subtotal?.message
            }
            fullWidth
            label="Subtotal"
            id="subtotal"
            margin="normal"
            disabled
            InputLabelProps={{ shrink: true }}
            {...reactHookFormAddItemBudget.register('subtotal')}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onCLickClose}>
          Cancelar
        </Button>

        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={reactHookFormAddItemBudget.handleSubmit((data) => {
            reactHookFormAddItemBudget.reset({
              id: '',
              name: '',
              quantity: '',
              unit_value: '',
              subtotal: '0,00',
            })
            if (isEditMode) {
              onClickUpdate(data)
              return
            }
            onClickAdd(data)
          })}
        >
          {isEditMode ? 'Salvar' : 'Adicionar'}
        </Button>
      </DialogActions>
    </form>
  )
}
