import { AxiosError } from 'axios'
import type { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (
  data?: GetAllParams,
): Promise<CommercialUnit[]> => {
  try {
    const getAllResponse = await apiInstance.post<CommercialUnitApiResponse[]>(
      ENDPOINTS.COMMERCIAL_UNITS.GET_ALL,
      {
        ...data,
      },
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (
  data?: GetAllParams,
): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll({
      ...data,
    })

    return (getAllResponse || []).map((commercialUnit) => ({
      label: `${commercialUnit.fantasy_name} - ${commercialUnit.document}`,
      value: commercialUnit.id.toString(),
    }))
  } catch (_getAllError) {
    return Promise.reject([])
  }
}

export const get = async (id: string): Promise<CommercialUnit> => {
  try {
    const getResponse = await apiInstance.get<CommercialUnitApiResponse>(
      ENDPOINTS.COMMERCIAL_UNITS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  commercialUnit: CommercialUnitApiRequest,
): Promise<CommercialUnit> => {
  try {
    const creationResponse = await apiInstance.post<CommercialUnitApiResponse>(
      ENDPOINTS.COMMERCIAL_UNITS.CREATE,
      commercialUnit,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  commercialUnit,
}: {
  commercialUnit: CommercialUnitApiRequest
  id: string
}): Promise<CommercialUnit> => {
  try {
    const updateResponse = await apiInstance.put<CommercialUnitApiResponse>(
      ENDPOINTS.COMMERCIAL_UNITS.UPDATE(id),
      commercialUnit,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.COMMERCIAL_UNITS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
