import { useMemo } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import Table from 'components/Table'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

export default function Users() {
  const navigate = useNavigate()

  const { data } = useQuery<User[]>(QUERY_KEYS.USERS.LIST, () =>
    ApiService.Users.getAll(),
  )

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'role',
        headerName: 'Grupo de usuário',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<User>) => {
          return params.row.role.name
        },
      },
      { field: 'name', headerName: 'Nome', flex: 1 },
      { field: 'username', headerName: 'Username', flex: 1 },
      { field: 'email', headerName: 'Email', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<User>) => {
          return (
            <>
              <SearchIcon
                onClick={() => {
                  navigate(ROUTES.USERS.VIEW(params.row.id))
                }}
              />

              <EditIcon
                onClick={() => {
                  navigate(ROUTES.USERS.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [navigate],
  )

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Usuários
            </Typography>

            <Box sx={{ m: 1 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  navigate(ROUTES.USERS.CREATE)
                }}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Table rows={data} columns={columns} />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
