import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services/api'

import Add from './add'

export default function Edit() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const { data } = useQuery<Collaborator>(
    QUERY_KEYS.COLLABORATORS.EDIT(id as string),
    () => ApiService.Collaborators.get(id as string),
  )

  const updateCollaborator = useMutation({
    mutationFn: async ({
      id,
      collaborator,
    }: {
      id: string
      collaborator: CollaboratorApiRequest
    }) => {
      await ApiService.Collaborators.update({
        id,
        collaborator,
      })
    },
    onSuccess: (_data, { id, collaborator }) => {
      queryClient.setQueryData<CollaboratorApiRequest>(
        QUERY_KEYS.COLLABORATORS.EDIT(id),
        (oldCollaborator) => {
          if (!oldCollaborator) return
          return collaborator
        },
      )

      openSuccessToast({
        message: `O(a) collaborator(a) ${collaborator.name} foi atualizado(a) com sucesso!`,
      })

      navigate(ROUTES.COLLABORATORS.LIST)
    },
    onError: (_error, { collaborator }) => {
      openErrorToast({
        message: `Erro ao editar ${collaborator.name}!`,
      })
    },
  })

  return (
    <Add
      collaborator={data}
      onSave={async (collaborator: CollaboratorApiRequest) =>
        updateCollaborator.mutate({ id: id as string, collaborator })
      }
      isEditMode
    />
  )
}
