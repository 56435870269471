import { useMutation, useQueryClient } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

type DeleteProps = {
  opened: boolean
  closeModal: () => void
  supplier?: SupplierApiResponse
}

export default function Delete({ opened, closeModal, supplier }: DeleteProps) {
  const navigate = useNavigate()
  const { openErrorToast, openSuccessToast } = useGlobalState()
  const queryClient = useQueryClient()

  const removeSupplier = useMutation({
    mutationFn: async (supplier: SupplierApiResponse) => {
      await ApiService.Suppliers.remove(supplier.id.toString())

      openSuccessToast({
        message: `O fornecedor ${supplier?.fantasy_name} foi removido com sucesso!`,
      })

      navigate(ROUTES.SUPPLIERS.LIST)
    },
    onSuccess: (_data, supplier: SupplierApiResponse) => {
      queryClient.setQueryData<SupplierApiResponse[]>(
        QUERY_KEYS.SUPPLIERS.LIST,
        (oldSuppliers) => {
          if (!oldSuppliers?.length) return

          return oldSuppliers.filter(
            (oldSupplier) => oldSupplier.id !== supplier.id,
          )
        },
      )

      queryClient.removeQueries(
        QUERY_KEYS.COMMERCIAL_UNITS.EDIT(supplier.id.toString()),
      )

      openSuccessToast({
        message: `A unidade comercial ${supplier.fantasy_name} foi removida com sucesso!`,
      })

      navigate(ROUTES.COMMERCIAL_UNITS.LIST)
    },
    onError: (removeError) => {
      closeModal()

      openErrorToast({
        message: (removeError as ApiError).message,
      })
    },
  })

  if (!opened) {
    return <></>
  }

  return (
    <ConfirmationModal
      opened
      title="Confirmar remoção"
      onClose={closeModal}
      onCancel={closeModal}
      onConfim={() => removeSupplier.mutate(supplier as SupplierApiResponse)}
      confirmColor="error"
    >
      Deseja realmente remover o fornecedor{' '}
      <strong>{`${supplier?.fantasy_name}?`}</strong>
    </ConfirmationModal>
  )
}
