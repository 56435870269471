import type { PropsWithChildren } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type TabPanelProps = PropsWithChildren<{
  index: number
  value: number
}>

export default function TabPanel({
  children,
  value,
  index,
  ...other
}: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
