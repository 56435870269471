import * as yup from 'yup'

const schema = yup.object().shape({
  commercial_unit_id: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  rg: yup.string().required('Campo obrigatório'),
  rg_state_issue: yup.string().required('Campo obrigatório'),
  cpf: yup.string().required('Campo obrigatório'),
  birth_date: yup.string().required('Campo obrigatório'),
  cell_phone: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  worked_times: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  admission_date: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  seller: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  sector: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  hierarchical_Level: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  worked_days: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  monthly_salary: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  zip_code: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  street: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  district: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  city: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  uf: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'employee'
        ? schema.required('Campo obrigatório')
        : schema,
    ),
  view_location: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'guide' ? schema.required('Campo obrigatório') : schema,
    ),

  capture_location: yup
    .string()
    .when('type', (type, schema) =>
      String(type) === 'guide' ? schema.required('Campo obrigatório') : schema,
    ),
})

export { schema }
