import { AxiosError } from 'axios'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const listGrouped = async ({
  roleId,
  userId,
}: {
  roleId: string
  userId: string
}): Promise<UserPermissionsApiResponse> => {
  try {
    const getAllResponse = await apiInstance.get<UserPermissionsApiResponse>(
      ENDPOINTS.USERS.PERMISSIONS.LIST_GROUPED(roleId, userId),
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async ({
  permissionId,
  userId,
}: {
  permissionId: string
  userId: string
}): Promise<UserPermissionsApiResponse> => {
  try {
    const getAllResponse = await apiInstance.delete<UserPermissionsApiResponse>(
      ENDPOINTS.USERS.PERMISSIONS.DELETE(permissionId, userId),
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async ({
  permissionId,
  userId,
}: {
  permissionId: string
  userId: string
}): Promise<UserPermissionsApiResponse> => {
  try {
    const getAllResponse = await apiInstance.post<UserPermissionsApiResponse>(
      ENDPOINTS.USERS.PERMISSIONS.CREATE,
      {
        user_id: userId,
        permission_id: permissionId,
      },
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
