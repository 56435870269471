import { useEffect, useRef, useState } from 'react'

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import FullCalendar from '@fullcalendar/react' // must go before plugins
import Box from '@mui/material/Box'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import { QUERY_KEYS } from 'constants/keys'
import { ACTIVITY_SITATUS_MAP_COLOR } from 'constants/types'
import moment from 'moment'
import { ApiService } from 'services/api'

function getRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

type Event = {
  id: string
  start: string
  end: string
  title: string
}

export default function ActivitiesCalendar() {
  const { data: activities, isLoading } = useQuery<Activity[]>({
    queryKey: QUERY_KEYS.ACTIVITIES.LIST_CALENDAR,
    queryFn: async () => await ApiService.Activities.getAllCalendar(),
  })

  const [events, setEvents] = useState<Event[]>([])

  const calendarRef = useRef<HTMLDivElement>()

  if (calendarRef.current) {
    calendarRef.current.children[0].setAttribute('style', 'min-height: 100vh;')
  }

  useEffect(() => {
    if (events.length) {
      const colors: { [key: string]: string } = {}
      events.forEach((event) => {
        colors[event.id] = getRandomColor()
      })
    }
  }, [events])

  useEffect(() => {
    if (activities?.length) {
      const events = activities.map((activity) => ({
        id: String(activity.id),
        title: `${activity.institution?.fantasy_name} - ${activity.name}`,
        start: moment(activity.start_travel_date, 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        ),
        end: moment(activity.end_travel_date, 'DD/MM/YYYY')
          .add(1, 'd')
          .format('YYYY-MM-DD'),
        backgroundColor: ACTIVITY_SITATUS_MAP_COLOR[activity.status],
      }))
      setEvents(events)
    }
  }, [activities])

  if (isLoading) {
    return <LoadingFullPage />
  }

  const week = document.getElementsByClassName('fc-dayGridWeek-button')[0]
  const month = document.getElementsByClassName('fc-dayGridMonth-button')[0]

  if (week) {
    week.setAttribute(
      'style',
      'width: 85px;overflow: hidden;white-space: nowrap; word-spacing: 20px',
    )
    week.textContent = 'Semana '
  }
  if (month) {
    month.setAttribute(
      'style',
      'width: 55px;overflow: hidden;white-space: nowrap; word-spacing: 20px',
    )
    month.innerHTML = 'Mês '
  }

  return (
    <>
      <Box component="main" py={{ md: 8 }} px={{ md: 8 }} ref={calendarRef}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          locale="pt"
          initialView="dayGridMonth"
          timeZone="-03:00"
          events={events}
          eventContent={(info) => (
            <div className="fc-event-main-frame">
              <div className="fc-event-title-container">
                <a
                  href={`/commercial/activities/view/${info.event.id}`}
                  style={{
                    textDecoration: 'none',
                    whiteSpace: 'normal',
                    margin: '5px 0px',
                    color: '#fff',
                  }}
                  className="fc-event-title fc-sticky"
                >
                  {info.event.title}
                </a>
              </div>
            </div>
          )}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridWeek,dayGridMonth',
          }}
        />
      </Box>
    </>
  )
}
