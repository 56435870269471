import { Fragment, useCallback, useEffect, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import LoadingFullPageWithOverlay from 'components/LoadingFullPageWithOverlay'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

import Delete from './delete'

const PERMISSIONS_VALUES: Record<string, boolean> = {}

export default function View() {
  const navigate = useNavigate()
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Role | undefined
  >()
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const [, setUpdate] = useState<number>(0)
  const [updateLoading, setUpdateLoading] = useState(false)
  const { data: role, isLoading } = useQuery<Role>(
    QUERY_KEYS.ROLES.VIEW(id as string),
    () => ApiService.Roles.get(id as string),
  )

  const { data: rolePermissions, isLoading: rolePermissionsLoading } = useQuery<
    RolePermissionsApiResponse | undefined
  >(QUERY_KEYS.ROLES.PERMISSIONS(role?.id?.toString() || ''), () => {
    if (!id || !role) return undefined

    return ApiService.Roles.Permissions.listGrouped({
      roleId: role.id?.toString(),
    })
  })

  useEffect(() => {
    rolePermissions?.forEach((firstLevel) => {
      firstLevel.subItems.forEach((secondLevel) => {
        secondLevel.subItems.forEach((permission) => {
          PERMISSIONS_VALUES[
            `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`
          ] = permission.hasPermission
        })
      })
    })

    setUpdate((prevState) => prevState + 1)
  }, [rolePermissions])

  const updatePermission = useCallback(
    async ({
      permissionId,
      hashKey,
    }: {
      permissionId: string
      hashKey: string
    }) => {
      setUpdateLoading(true)
      try {
        const isCreation = !PERMISSIONS_VALUES[hashKey]
        const action = isCreation
          ? ApiService.Roles.Permissions.create
          : ApiService.Roles.Permissions.remove

        await action({
          permissionId,
          roleId: id as string,
        })

        PERMISSIONS_VALUES[hashKey] = isCreation

        queryClient.invalidateQueries(
          QUERY_KEYS.ROLES.PERMISSIONS((role?.id || '')?.toString()),
        )
      } catch (_updateError) {
        // TODO: handle error
      }
      setUpdateLoading(false)
    },
    [id, queryClient, role?.id],
  )

  if (isLoading || rolePermissionsLoading) {
    return <LoadingFullPage />
  }

  return (
    <>
      {updateLoading && <LoadingFullPageWithOverlay />}
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  {role?.name}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ROLES.EDIT(id as string))
                    }}
                  >
                    Editar
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(role as Role)
                    }}
                  >
                    Remover
                  </Button>
                </Box>

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ROLES.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel label="Nome" value={role?.name} />

              <TextWithLabel label="Descrição" value={role?.description} />
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs value={0}>
                  <Tab label="Permissões" />
                </Tabs>

                <TabPanel value={0} index={0}>
                  {rolePermissions?.length ? (
                    <>
                      <div style={{ gridColumn: '1/-1' }}>
                        <Typography sx={{ m: 1 }} variant="h6">
                          Permissões
                        </Typography>
                      </div>

                      <div
                        style={{
                          gridColumn: '1/-1',
                          display: 'flex',
                          flexFlow: 'column wrap',
                          gap: '12px',
                        }}
                      >
                        {rolePermissions?.map((firstLevel) => (
                          <Fragment key={firstLevel.column1}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{firstLevel.column1}</Typography>
                              </AccordionSummary>

                              <AccordionDetails>
                                {firstLevel.subItems.map((secondLevel) => (
                                  <Fragment key={secondLevel.column1}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                      >
                                        <Typography>
                                          {secondLevel.column1}
                                        </Typography>
                                      </AccordionSummary>

                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexFlow: 'column wrap',
                                          }}
                                        >
                                          {secondLevel.subItems.map(
                                            (permission) => (
                                              <Fragment key={permission.name}>
                                                <Tooltip
                                                  placement="top-start"
                                                  title={
                                                    id === '1'
                                                      ? `Grupo de usuário ${role?.name}, tem todas as rotas permitidas por padrão`
                                                      : ''
                                                  }
                                                >
                                                  <FormControlLabel
                                                    control={
                                                      <Switch color="primary" />
                                                    }
                                                    label={permission.name}
                                                    disabled={id === '1'}
                                                    checked={
                                                      PERMISSIONS_VALUES[
                                                        `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`
                                                      ]
                                                    }
                                                    onChange={() =>
                                                      updatePermission({
                                                        permissionId:
                                                          permission.id?.toString(),
                                                        hashKey: `${firstLevel.column1}-${secondLevel.column1}-${permission.id}`,
                                                      })
                                                    }
                                                  />
                                                </Tooltip>
                                              </Fragment>
                                            ),
                                          )}
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Fragment>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          </Fragment>
                        ))}
                      </div>
                    </>
                  ) : null}
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        role={deleteConfirmation as Role}
      />
    </>
  )
}
