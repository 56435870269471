import { useEffect, useMemo, useState, useCallback } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models'
import { useQuery } from '@tanstack/react-query'
import Table from 'components/Table'
import { useActivityContext } from 'pages/activities/add'
import { useFormContext } from 'react-hook-form'
import { ApiService } from 'services/api'

export const ResponsiblesGuidesForm = () => {
  const { isEditMode, activity } = useActivityContext()
  const { setValue, getValues } = useFormContext<ActivityApiRequest>()
  const [isLoaded, setIsLoaded] = useState(false)

  const [selectedCollaborator, setSelectedCollaborator] =
    useState<CollaboratorApiResponse>()

  const [collaboratorList, setCollaboratorList] = useState<
    CollaboratorApiResponse[]
  >([])

  useEffect(() => {
    if (isEditMode) {
      const responsibleGuidesActivities =
        activity?.responsibleGuidesActivities?.map((item) => ({
          ...item,
          name: item.collaborator.name,
          id: item.collaborator.id,
        }))

      setCollaboratorList(
        responsibleGuidesActivities as unknown as CollaboratorApiResponse[],
      )
    }
  }, [isEditMode, activity])

  const {
    data: collaborators,
    isLoading: isLoadingCollaborators,
    isFetched,
  } = useQuery<CollaboratorApiResponse[]>(['activity-collaborators'], () =>
    ApiService.Collaborators.getAll(),
  )

  const collaboratorsColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Nome', flex: 1 },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<TeacherApiResponse>) => {
          return (
            <DeleteIcon
              onClick={() => {
                const updatedCollaboratorsList = collaboratorList.filter(
                  (collaborator) => collaborator.id !== params.row.id,
                )
                setCollaboratorList(updatedCollaboratorsList)
              }}
            />
          )
        },
      },
    ],
    [collaboratorList],
  )

  useEffect(() => {
    if (!collaboratorList) return

    setValue(
      'responsibleGuides',
      collaboratorList.map((collaborator) => collaborator.id),
    )
  }, [collaboratorList, setValue])

  useEffect(() => {
    if (
      isEditMode &&
      isFetched &&
      collaborators &&
      !isLoaded &&
      getValues('responsibleGuidesActivities')
    ) {
      const collaboratorsActivity = getValues('responsibleGuidesActivities')
        ?.map((collaborator) => {
          const hasCollaborator = collaborators?.find(
            (c) => c.id === collaborator.collaborator.id,
          )
          return hasCollaborator
        })
        .filter((collaborator) => !!collaborator) as CollaboratorApiResponse[]

      setValue(
        'responsibleGuides',
        collaboratorsActivity.map((collaborator) => collaborator.id),
      )
      setCollaboratorList(collaboratorsActivity)
      setIsLoaded(true)
    }
  }, [getValues, isEditMode, isFetched, isLoaded, setValue, collaborators])

  const addCollaborator = useCallback(() => {
    const alreadyOnTheList = collaboratorList?.find(
      (collaborator) => collaborator.id === selectedCollaborator?.id,
    )

    if (!selectedCollaborator || alreadyOnTheList !== undefined) {
      return
    }

    setCollaboratorList((previousState) => {
      if (previousState) {
        return [...previousState, selectedCollaborator]
      }

      return [selectedCollaborator]
    })

    setSelectedCollaborator(undefined)
  }, [collaboratorList, selectedCollaborator, setCollaboratorList])

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" component="h2">
          Guias Responsáveis
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <Autocomplete
          disablePortal
          id="responsibleGuides"
          disabled={isLoadingCollaborators}
          options={
            collaborators?.length
              ? collaborators?.map((collaborator) => ({
                  label: collaborator.name,
                  id: collaborator.id,
                }))
              : []
          }
          onChange={(_, newCollaborator) => {
            const collaboratorToAdd = collaborators?.find(
              (collaborator) =>
                newCollaborator && collaborator.id === newCollaborator.id,
            )
            setSelectedCollaborator(collaboratorToAdd)
          }}
          noOptionsText="Nada foi encontrado"
          renderInput={(params) => (
            <TextField {...params} label="Guias Responsáveis" />
          )}
          getOptionLabel={(option) => option.label || ''}
        />
      </Grid>

      <Grid container item xs={2} alignItems="center" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            addCollaborator()
          }}
        >
          Adicionar
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Table rows={collaboratorList} columns={collaboratorsColumns} />
      </Grid>
    </Grid>
  )
}
