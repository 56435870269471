import { useState, useCallback } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import {
  Chart as ChartJS,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js'
import DatePicker from 'components/DatePicker'
import LoadingFullPage from 'components/LoadingFullPage'
import SelectWithCheckbox, {
  SelectWithCheckboxOptions,
} from 'components/SelectWithCheckbox'
import { QUERY_KEYS } from 'constants/keys'
import { useGlobalState } from 'contexts/global-state'
import { Pie } from 'react-chartjs-2'
import { ApiService } from 'services/api'
import { CHART_COLORS } from 'utils/chartColors'
import formatCurrencyUnit from 'utils/formatCurrencyUnit'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

export default function SalesCharts() {
  const [total, setTotal] = useState<number>(0)

  const [salesChartData, setSalesChartData] = useState<
    ChartData<'pie', number[], unknown> | undefined
  >()

  const [selectedInstitutions, setSelectedInstitutions] =
    useState<SelectWithCheckboxOptions>([])

  const [selectedAffiliates, setSelectedAffiliates] =
    useState<SelectWithCheckboxOptions>([])

  const [startDate, setStartDate] = useState<string | Date | null | undefined>(
    null,
  )

  const [endDate, setEndDate] = useState<string | Date | null | undefined>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { openErrorToast } = useGlobalState()

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    () => ApiService.Institutions.getAllNamesAndIds(),
  )

  const { data: affiliates } = useQuery<SelectWithCheckboxOptions>(
    QUERY_KEYS.AFFILIATES.NAME_AND_IDS,
    () => ApiService.Affiliates.getAllNamesAndIds(),
  )

  const generateReport = useCallback(() => {
    setIsLoading(true)
    ApiService.Reports.generateSalesAffiliates({
      institutions: selectedInstitutions.map((si) => Number(si.value)),

      affiliates: selectedAffiliates.map((c) => Number(c.value)),

      start: startDate
        ? String(startDate).split('/').reverse().join('-')
        : undefined,
      end: endDate ? String(endDate).split('/').reverse().join('-') : undefined,
    })
      .then((response) => {
        const totalSales = response.reduce((accumulator, currentValue) => {
          accumulator += parseFloat(currentValue.reservation_payment_value)
          return accumulator
        }, 0)

        const totalSalesByAffiliate = response.reduce(
          (accumulator: Record<string, number>, currentValue) => {
            if (
              accumulator[
                (currentValue.affiliate_name || '').replaceAll('"', '').trim()
              ]
            ) {
              accumulator[
                (currentValue.affiliate_name || '').replaceAll('"', '').trim()
              ] += parseFloat(currentValue.reservation_payment_value)

              return accumulator
            }

            accumulator[
              (currentValue.affiliate_name || '').replaceAll('"', '').trim()
            ] = parseFloat(currentValue.reservation_payment_value)

            return accumulator
          },
          {},
        )

        const sortedBySales = Object.entries(totalSalesByAffiliate).sort(
          (a, b) => b[1] - a[1],
        )

        const affiliatesNames = sortedBySales.map((s) => s[0])
        const affiliatesValues = sortedBySales.map((s) => s[1])

        const totalSalesByAffiliateWithPercentage = Object.entries(
          totalSalesByAffiliate,
        ).reduce((accumulator: Record<string, string>, [key, value]) => {
          accumulator[key] = ((value / totalSales) * 100).toFixed(2)
          return accumulator
        }, {})

        setSalesChartData({
          labels: affiliatesNames.map(
            (affiliate) =>
              `${affiliate} - ${
                totalSalesByAffiliateWithPercentage[affiliate]
              }% - ${formatCurrencyUnit(totalSalesByAffiliate[affiliate])}`,
          ),
          datasets: [
            {
              data: affiliatesValues,
              backgroundColor: CHART_COLORS,
              borderWidth: 1,
            },
          ],
        })

        setTotal(totalSales)

        if (!response.length) {
          openErrorToast({ message: 'Nenhum resultado encontrado.' })
        }
        setIsLoading(false)
      })
      .catch((error) => {
        openErrorToast({ message: 'Ocorreu um erro ao gerar gráfico!' })
        setIsLoading(false)
      })
  }, [
    selectedInstitutions,
    selectedAffiliates,
    startDate,
    endDate,
    openErrorToast,
  ])

  if (!institutions?.length || isLoading) {
    return <LoadingFullPage />
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
              Gráfico de vendas por afiliados
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <SelectWithCheckbox
              options={institutions}
              value={selectedInstitutions}
              onSelect={setSelectedInstitutions}
              selectAllLabel="Todas as instituições"
              placeholder="Instituições"
            />

            <SelectWithCheckbox
              options={affiliates || []}
              value={selectedAffiliates}
              onSelect={setSelectedAffiliates}
              selectAllLabel="Todas os afiliados"
              placeholder="Afiliados"
            />

            <DatePicker
              label="Data inicial"
              id="startDate"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue)
              }}
            />

            <DatePicker
              label="Data final"
              id="endDate"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
            />

            <Button
              color="primary"
              variant="contained"
              onClick={generateReport}
              disabled={!selectedInstitutions.length && !startDate && !endDate}
            >
              Gerar relatório
            </Button>
          </Box>
        </Box>

        {salesChartData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Pie
                data={salesChartData}
                width={600}
                height={600}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => formatCurrencyUnit(context.parsed),
                      },
                    },
                    title: {
                      display: true,
                      font: {
                        size: 16,
                      },
                      text: `Total por afiliado - ${formatCurrencyUnit(total)}`,
                    },
                  },
                }}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </Box>
  )
}
