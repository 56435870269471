import { AxiosError } from 'axios'
import { SelectProps } from 'components/Select'
import { ENDPOINTS } from 'constants/endpoints'
import apiInstance from 'services/api/instance'

import { handleErrorMessage } from '../errors'

export const getAll = async (): Promise<SupplierApiResponse[]> => {
  try {
    const getAllResponse = await apiInstance.get<SupplierApiResponse[]>(
      ENDPOINTS.SUPPLIERS.GET_ALL,
    )

    return getAllResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getAllNamesAndIds = async (): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await getAll()

    return (getAllResponse || []).map((supplier) => ({
      label: `${supplier.name} - ${supplier.document}`,
      value: supplier.id.toString(),
    }))
  } catch (_getAllError) {
    return Promise.reject([])
  }
}

export const getCategoryBySupplier = async ({
  supplier_id,
}: {
  supplier_id: string
}): Promise<Pick<SupplierApiResponse, 'category_id'>> => {
  try {
    const getCategoryBySupplierResponse = await apiInstance.get<
      Pick<SupplierApiResponse, 'category_id'>
    >(ENDPOINTS.SUPPLIERS.GET_CATEGORY_BY_SUPPLIER(supplier_id))

    return getCategoryBySupplierResponse.data
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getOptionsByCategory = async ({
  category_id,
  supplier_id,
}: {
  category_id: string
  supplier_id?: string
}): Promise<SelectProps['options']> => {
  try {
    const getAllResponse = await apiInstance.post<SelectProps['options']>(
      ENDPOINTS.SUPPLIERS.GET_OPTIONS_BY_CATEGORY(category_id),
      {
        supplier_id,
      },
    )

    return getAllResponse.data.map((item) => ({
      ...item,
    }))
  } catch (getAllError) {
    const axiosError = getAllError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const get = async (id: string): Promise<SupplierApiResponse> => {
  try {
    const getResponse = await apiInstance.get<SupplierApiResponse>(
      ENDPOINTS.SUPPLIERS.GET(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const getViewPage = async (
  id: string,
  options?: ISupplierFindByIdParams,
): Promise<SupplierViewPageApiResponse> => {
  try {
    const getResponse = await apiInstance.post<SupplierViewPageApiResponse>(
      ENDPOINTS.SUPPLIERS.GET_VIEW_PAGE(id),
    )

    return getResponse.data
  } catch (getError) {
    const axiosError = getError as AxiosError
    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const create = async (
  supplier: SupplierApiRequest,
): Promise<SupplierApiResponse> => {
  try {
    const creationResponse = await apiInstance.post<SupplierApiResponse>(
      ENDPOINTS.SUPPLIERS.CREATE,
      supplier,
    )

    return creationResponse.data
  } catch (creationError) {
    const axiosError = creationError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const update = async ({
  id,
  supplier,
}: {
  supplier: SupplierApiRequest
  id: string
}): Promise<SupplierApiResponse> => {
  try {
    const updateResponse = await apiInstance.put<SupplierApiResponse>(
      ENDPOINTS.SUPPLIERS.UPDATE(id),
      supplier,
    )

    return updateResponse.data
  } catch (updateError) {
    const axiosError = updateError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}

export const remove = async (id: string) => {
  try {
    const deleteResponse = await apiInstance.delete(
      ENDPOINTS.SUPPLIERS.DELETE(id),
    )

    return deleteResponse.data
  } catch (deleteError) {
    const axiosError = deleteError as AxiosError

    return Promise.reject(handleErrorMessage({ axiosError }))
  }
}
