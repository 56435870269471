import { useState, useMemo, useCallback, useEffect } from 'react'

import {
  DataGrid,
  ptBR,
  DataGridProps,
  GridSelectionModel,
  GridFilterModel,
  GridToolbar,
  GridEnrichedColDef,
  GridFilterItem,
  GridSortModel,
} from '@mui/x-data-grid'
import type { GridColumns, GridValidRowModel } from '@mui/x-data-grid'
import { ApiService } from 'services/api'

type ColumnItemProps = GridEnrichedColDef & ColumnProps

interface TableProps extends Omit<Omit<DataGridProps, 'rows'>, 'columns'> {
  rows?: unknown[]
  columns: GridColumns<GridValidRowModel>
  enableCheckBox?: boolean
  selectedRowKey?: string
  onSelectRow?: (selectedRows: unknown[]) => void
  selectedItems?: number[]
  filterModel?: GridFilterModel
  toolbar?: boolean
  entity: string
  source: string
  searchParameters?: GenericObject[]
  likeParameters?: GridFilterItem[]
  sortParameters?: GridSortModel
  onlyParent?: boolean
  disableSelectionOnClick?: boolean
  customToolbar?: React.JSXElementConstructor<unknown>
}

export default function DataTable({
  rows = [],
  columns,
  enableCheckBox = false,
  selectedRowKey = 'id',
  onSelectRow,
  disableSelectionOnClick,
  selectedItems,
  filterModel,
  onFilterModelChange,
  onSortModelChange,
  toolbar,
  entity,
  source,
  searchParameters,
  likeParameters,
  sortParameters,
  customToolbar,
  onlyParent,
  ...rest
}: TableProps) {
  const [items, setItems] = useState<unknown[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [pageSize, setPageSize] = useState<number>(30)
  const [changedParams, setParams] = useState<{ page: number }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadParams = useCallback(() => {
    const newParams = {
      entity,
      source,
      page: changedParams?.page || 1,
      perPage: pageSize,
      searchParameters,
      likeParameters,
      sortParameters,
      onlyParent,
      selectFields: columns
        .filter((column: ColumnItemProps) => !column.exclude)
        .map((column: ColumnItemProps) => {
          const field = column.customField || column.field
          if (!column.relation) {
            return `${source}.${field}`
          }
          return field
        }),
      relations: columns
        .filter((column: ColumnItemProps) => !!column.relation)
        .map((column: ColumnItemProps) => ({
          ...column.relation,
        })),
    }
    return newParams
  }, [
    entity,
    source,
    changedParams?.page,
    pageSize,
    searchParameters,
    likeParameters,
    sortParameters,
    onlyParent,
    columns,
  ])

  const handleChangePage = (page: number) => {
    setParams((old) => ({
      ...old,
      page,
    }))
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        setIsLoading(true)
        const params = loadParams()
        const response = await ApiService.DataTable.getAll(params)
        setItems(response.items)
        setTotalItems(response.totalItens)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    })()
  }, [loadParams])

  const checkboxProps = useMemo<Partial<DataGridProps>>(() => {
    if (enableCheckBox) {
      return {
        checkboxSelection: true,
        disableSelectionOnClick: true,
        selectionModel: selectedItems,
        onSelectionModelChange: (selectionModel: GridSelectionModel) => {
          // const selectedRows = rows.filter((row) =>
          //   selectionModel.includes(
          //     (row as Record<string, string>)[selectedRowKey],
          //   ),
          // )

          onSelectRow?.(selectionModel)
        },
      }
    }

    return {}
  }, [enableCheckBox, onSelectRow, selectedItems])

  return (
    <DataGrid
      loading={isLoading}
      rows={items as GridValidRowModel[]}
      columns={columns}
      pageSize={pageSize}
      rowCount={totalItems}
      sortingMode="server"
      filterMode="server"
      autoHeight
      paginationMode="server"
      rowsPerPageOptions={
        [5, 10, 15, 30, 50, 100, { value: -1, label: 'Todos' }] as number[]
      }
      onPageSizeChange={(newPageSize) => {
        setPageSize(newPageSize)
      }}
      onPageChange={(page) => {
        handleChangePage(page + 1)
      }}
      onFilterModelChange={onFilterModelChange}
      onSortModelChange={onSortModelChange}
      disableSelectionOnClick={disableSelectionOnClick}
      filterModel={filterModel}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      {...checkboxProps}
      components={{
        ...(toolbar
          ? {
              Toolbar: GridToolbar,
            }
          : undefined),
        ...(customToolbar
          ? {
              Toolbar: customToolbar,
            }
          : undefined),
        NoRowsOverlay: () => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '100%',
                marginTop: '2rem',
              }}
            >
              <p>Nenhum registro encontrado</p>
            </div>
          )
        },
      }}
      componentsProps={{
        toolbar: {
          csvOptions: { utf8WithBom: true, delimiter: ';' },
        },
      }}
      {...rest}
    />
  )
}
