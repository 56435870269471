import { useState, useMemo, useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Delete, EditOutlined, QrCode, WhatsApp } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import DatePicker from 'components/DatePicker'
import InputCurrency from 'components/InputCurrency'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import Table from 'components/Table'
import TabPanel from 'components/TabPanel'
import TextWithLabel from 'components/TextWithLabel'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import { ROUTES } from 'constants/routes'
import {
  FINANCIAL_MOVEMENT_PAYMENT_METHODS,
  FINANCIAL_MOVEMENT_PAYMENT_METHODS_OPTIONS,
} from 'constants/types'
import { useGlobalState } from 'contexts/global-state'
import usePagseguro from 'hooks/usePagseguro'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { currencyMask, monetaryToNumber } from 'utils/mask'
import * as yup from 'yup'

import DeleteModal from './components/delete'
import {
  FINANCIAL_SITUATION_OPTIONS,
  RESERVATION_SITUATION_PAYMENT_TYPES,
  RESERVATION_SITUATION_TYPES,
} from './types'

enum TABS {
  FINANCIAL_MOVEMENTS,
  OBSERVATIONS,
}

const RESERVATION_SITUATION_ALLOWED_DOWNLOAD_PAYMENT_MANUAL = [
  'waiting_payment',
  'realized',
]

export default function View() {
  const reactHookFormDownloadPaymentManual =
    useForm<DownloadPaymentManualReservationsApiRequest>({
      resolver: yupResolver(
        yup.object().shape({
          payment_method: yup.string().required('Este campo é obrigatório'),
          settlement_date: yup.string().required('Este campo é obrigatório'),
        }),
      ),
      mode: 'onChange',
    })
  const reactHookFormReservation = useForm<ReservationsApiRequest>()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { data, isLoading, refetch } = useQuery<Reservation>(
    QUERY_KEYS.RESERVATIONS.VIEW(id as string),
    () => ApiService.Reservations.get(id as string),
  )

  const [payments, setPayments] = useState<
    FinancialMovement['financialMovementsPayments']
  >([])

  useEffect(() => {
    if (data) {
      const payments: FinancialMovement['financialMovementsPayments'] = []
      data.financialMovements.forEach((financialMovement) => {
        financialMovement.financialMovementsPayments.forEach((payment) => {
          payments.push({
            ...payment,
            settlement_date:
              payment.situation === 'Pago'
                ? financialMovement.settlement_date
                : '',
          })
        })
      })
      setPayments(payments)
    }
  }, [data])

  const [currentTab, setCurrentTab] = useState<TABS>(TABS.FINANCIAL_MOVEMENTS)
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Reservation | undefined
  >()
  const { openSuccessToast, openErrorToast, user } = useGlobalState()

  const { cancelTransaction } = usePagseguro()

  const [cancelModal, setCancelModal] = useState(false)
  const [obsModal, setObsModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [downloadPaymentManualModal, setDownloadPaymentManualModal] =
    useState(false)
  const [
    sendEmailConfirmationPaymentModal,
    setSendEmailConfirmationPaymentModal,
  ] = useState(false)
  const [
    changeSituationToPaymentConfirmed,
    setChangeSituationToPaymentConfirmed,
    ,
  ] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [cancelReason, setCancelReason] = useState('')
  const [isReversePayment, setIsReversePayment] = useState(true)

  const [obsReservation, setObsReservation] = useState('')
  const [obsId, setObsId] = useState('')

  const onConfirmEditReservation = useCallback(async () => {
    const dataForm = reactHookFormReservation.getValues()
    if (!id || !data) return
    setIsCanceling(true)
    try {
      await ApiService.Reservations.update({
        reservation: {
          value: dataForm.value
            ? monetaryToNumber(dataForm.value.toString())
            : data?.value,
        },
        id,
      })
      await ApiService.Reservations.updatePaymentStatus({
        reservation_id: Number(id),
        status: dataForm.situation as string,
      })
      await ApiService.Reservations.updatePaymentValue({
        reservation_id: Number(id),
        value: dataForm.value
          ? monetaryToNumber(dataForm.value.toString())
          : data?.value,
      })
      if ((dataForm.situation as unknown as string) === 'Paid') {
        setChangeSituationToPaymentConfirmed(true)
      }
      refetch()
      setIsCanceling(false)
      setEditModal(false)
      reactHookFormReservation.reset()
    } catch (error) {
      openErrorToast({ message: 'Erro ao editar reserva.' })
      setIsCanceling(false)
    }
  }, [
    data,
    id,
    openErrorToast,
    reactHookFormReservation,
    refetch,
    setChangeSituationToPaymentConfirmed,
  ])

  const onConfirmCancel = useCallback(async () => {
    setIsCanceling(true)
    await cancelTransaction({
      id: id || '',
      message: cancelReason,
      isReversePayment,
    })
      .then((response) => {
        const message = response.userMessage
        if (message.includes('O pagamento da reserva não foi devolvido')) {
          openErrorToast({ message })
        } else {
          openSuccessToast({ message })
        }
        refetch()
        setIsCanceling(false)
        setCancelModal(false)
      })
      .catch(() => {
        openErrorToast({ message: 'Erro ao cancelar reserva.' })
        setIsCanceling(false)
        setCancelModal(false)
      })
  }, [
    cancelReason,
    isReversePayment,
    cancelTransaction,
    id,
    openErrorToast,
    openSuccessToast,
    refetch,
  ])

  const onAddObservation = useCallback(async () => {
    if (!user?.id) {
      return
    }
    setIsCanceling(true)
    if (obsId) {
      await ApiService.Reservations.updateObservation({
        id: obsId,
        data: {
          reservation_id: Number(id),
          user_id: Number(user.id),
          description: obsReservation,
        },
      })
        .then(() => {
          openSuccessToast({ message: 'Observação adicionada.' })
          refetch()
          setIsCanceling(false)
          setObsModal(false)
        })
        .catch(() => {
          openErrorToast({ message: 'Erro ao adicionar observação à reserva.' })
          setIsCanceling(false)
          setObsModal(false)
        })
    } else {
      await ApiService.Reservations.addObservation({
        reservation_id: Number(id),
        user_id: user?.id || 1,
        description: obsReservation,
      })
        .then(() => {
          openSuccessToast({ message: 'Observação adicionada.' })
          refetch()
          setIsCanceling(false)
          setObsModal(false)
        })
        .catch(() => {
          openErrorToast({ message: 'Erro ao adicionar observação à reserva.' })
          setIsCanceling(false)
          setObsModal(false)
        })
    }
    setObsId('')
    setObsReservation('')
  }, [
    id,
    user?.id,
    obsReservation,
    obsId,
    openSuccessToast,
    refetch,
    openErrorToast,
  ])

  const observatinsColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'created_at',
        headerName: 'Data',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'Descrição',
        flex: 1,
      },
      {
        field: 'user',
        headerName: 'Usuário',
        width: 200,
        renderCell: (cell) => (
          <Typography style={{ padding: '20px 0px' }}>
            {cell.value.name}
          </Typography>
        ),
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 200,
        renderCell: (cell) => {
          return (
            <>
              {cell.row.user_id === user?.id && (
                <>
                  <div
                    onClick={() => {
                      setObsReservation(cell.row.description)
                      setObsId(String(cell.row.id))
                      setObsModal(true)
                    }}
                  >
                    <EditOutlined />
                  </div>
                  <div
                    onClick={() => {
                      ApiService.Reservations.deleteObservation(
                        cell.row.id,
                      ).then(() => {
                        refetch()
                      })
                    }}
                  >
                    <Delete />
                  </div>
                </>
              )}
            </>
          )
        },
      },
    ],
    [refetch, user?.id],
  )

  const financialMovementsColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'due_date',
        headerName: 'Data de vencimento',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<FinancialMovement>) =>
          params.row.due_date
            ? moment(params.row.due_date, INPUT_MASK.DATE_LOCALE).format(
                INPUT_MASK.DATE_LOCALE,
              )
            : '',
      },
      {
        field: 'payment_method',
        headerName: 'Forma de pagamento',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<FinancialMovement>) =>
          FINANCIAL_MOVEMENT_PAYMENT_METHODS[
            params.row.payment_method as FinancialMovementPaymentForm
          ],
      },
      {
        field: 'value',
        headerName: 'Valor',
        flex: 1,
        renderCell: (cell) => (
          <div style={{ padding: '20px 0px' }}>
            {currencyMask(data?.payment_value || '0,00')}
          </div>
        ),
      },
      {
        field: 'situation',
        headerName: 'Status',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<FinancialMovement>) =>
          RESERVATION_SITUATION_PAYMENT_TYPES[
            params.row.situation as ReservationPaymentSituation
          ],
      },
      {
        field: 'settlement_date',
        headerName: 'Liquidação',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<FinancialMovement>) =>
          params.row.settlement_date
            ? moment(params.row.settlement_date, INPUT_MASK.DATE_LOCALE).format(
                INPUT_MASK.DATE_LOCALE,
              )
            : '',
      },
      {
        field: 'pix_text',
        headerName: 'Texto Pix',
        width: 500,
        renderCell: (cell) => {
          return (
            <Typography
              style={{ wordBreak: 'break-word', padding: '20px 0px' }}
            >
              {cell.row?.pix_qr_text}
            </Typography>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Ações',
        flex: 1,
        renderCell: (cell) => {
          return (
            <>
              {cell.row.payment_method === 'P' &&
                cell.row?.pix_qr_text?.includes('pagseguro') && (
                  <a
                    target="_blank"
                    href={cell.row?.pix_qr_img}
                    rel="noreferrer"
                  >
                    <QrCode titleAccess="Baixar qr_code" />
                  </a>
                )}
              {cell.row.payment_method === 'P' &&
                cell.row?.pix_qr_text?.includes('bancointer') && (
                  <button
                    style={{ all: 'unset', cursor: 'pointer' }}
                    onClick={() => {
                      const svg = document.getElementById('qr_inter')
                      if (!svg) {
                        return
                      }
                      const canvas = document.createElement('canvas')
                      canvas.width = 500
                      canvas.height = 500
                      const ctx = canvas.getContext('2d')
                      if (ctx) {
                        ctx.fillStyle = 'white'
                        ctx.fillRect(0, 0, canvas.width, canvas.height)
                      }
                      const stringData = new XMLSerializer().serializeToString(
                        svg,
                      )
                      const DOMURL = window.URL || window.webkitURL || window

                      const img = new Image()
                      const svgBlob = new Blob([stringData], {
                        type: 'image/svg+xml;charset=utf-8',
                      })
                      const url = DOMURL.createObjectURL(svgBlob)
                      img.src = url

                      img.onload = function () {
                        ctx?.drawImage(img, 50, 50)
                        DOMURL.revokeObjectURL(url)

                        const imgURI = canvas
                          .toDataURL('image/png')
                          .replace('image/png', 'image/octet-stream')

                        const a = document.createElement('a')
                        a.setAttribute('download', `QR_${data?.code}.png`)
                        a.setAttribute('href', imgURI)
                        a.setAttribute('target', '_blank')

                        a.click()
                      }
                    }}
                  >
                    <QrCode titleAccess="Baixar qr_code" />
                    <QRCode
                      id="qr_inter"
                      size={400}
                      style={{ display: 'none' }}
                      value={cell.row?.pix_qr_text}
                    />
                  </button>
                )}
            </>
          )
        },
      },
    ],
    [data?.code, data?.payment_value],
  )

  const handleCloseDownloadManualPayment = useCallback(() => {
    if (changeSituationToPaymentConfirmed) return
    setDownloadPaymentManualModal(false)
    setChangeSituationToPaymentConfirmed(false)
  }, [changeSituationToPaymentConfirmed, setChangeSituationToPaymentConfirmed])

  const onConfirmDownloadPaymentManualModal = useCallback(
    async (sendEmailConfirmationPayment?: boolean) => {
      const data = reactHookFormDownloadPaymentManual.getValues()

      if (!id) return
      setIsCanceling(true)
      try {
        await ApiService.Reservations.downloadPaymentManual({
          ...data,
          id,
          sendEmailConfirmationPayment: !!sendEmailConfirmationPayment,
        })
        refetch()
        setSendEmailConfirmationPaymentModal(false)
        setChangeSituationToPaymentConfirmed(false)
        setDownloadPaymentManualModal(false)
        setIsCanceling(false)
        reactHookFormDownloadPaymentManual.reset()
      } catch (error) {
        openErrorToast({ message: 'Erro ao dar baixa de pagamento reserva.' })
        setIsCanceling(false)
      }
    },
    [
      id,
      openErrorToast,
      reactHookFormDownloadPaymentManual,
      refetch,
      setChangeSituationToPaymentConfirmed,
    ],
  )

  if (isLoading || isCanceling) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />

                <Typography sx={{ m: 1 }} variant="h4">
                  Reserva Nº {data?.code}
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                {data?.situation &&
                  data.situation === 'finished' &&
                  data.activity.type === 'faculty' && (
                    <Box sx={{ m: 1 }}>
                      <Button
                        color="success"
                        variant="contained"
                        onClick={async () => {
                          const a = document.createElement('a')

                          if (id) {
                            ApiService.Reservations.getCertPdf(id).then(
                              (pdf) => {
                                const blob = new Blob([pdf.data], {
                                  type: 'application/pdf',
                                })
                                const url = window.URL.createObjectURL(blob)
                                a.href = url
                                a.download = `Certificado.pdf`
                                a.click()
                              },
                            )
                          }
                        }}
                      >
                        Baixar Certificado
                      </Button>
                    </Box>
                  )}

                {data?.situation !== 'payment_confirmed' && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => setEditModal(true)}
                    >
                      Editar
                    </Button>
                  </Box>
                )}
                {data?.situation &&
                  RESERVATION_SITUATION_ALLOWED_DOWNLOAD_PAYMENT_MANUAL.includes(
                    data.situation,
                  ) && (
                    <Box sx={{ m: 1 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setDownloadPaymentManualModal(true)}
                      >
                        Baixa Manual de Pagamento
                      </Button>
                    </Box>
                  )}
                {data?.situation &&
                  data.situation !== 'payment_confirmed' &&
                  data.situation !== 'payment_reversal' && (
                    <Box sx={{ m: 1 }}>
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setDeleteConfirmation(data as Reservation)
                        }}
                      >
                        Remover
                      </Button>
                    </Box>
                  )}
                {data?.situation &&
                  data.situation !== 'cancelled' &&
                  data.situation !== 'payment_reversal' && (
                    <Box sx={{ m: 1 }}>
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setCancelReason('')
                          setIsReversePayment(true)
                          setCancelModal(true)
                        }}
                      >
                        Cancelar Reserva
                      </Button>
                    </Box>
                  )}
                <Box sx={{ m: 1 }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setObsModal(true)}
                  >
                    Adicionar Observação
                  </Button>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.RESERVATIONS.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextWithLabel
                label="Unidade Comercial"
                value={data?.commercialUnit?.fantasy_name}
              />

              <TextWithLabel
                label="Instituição"
                value={data?.institution?.fantasy_name}
              />

              <TextWithLabel label="Atividade" value={data?.activity?.name} />

              <TextWithLabel
                label="Cliente"
                value={data?.parentClient?.fantasy_name}
              />

              <Box
                sx={{
                  height: 'auto',
                  flexGrow: 1,
                  display: 'flex',
                  flexFlow: 'column',
                }}
              >
                <Typography variant="body2" component="label">
                  Celular
                </Typography>
                <a
                  href={`https://api.whatsapp.com/send?phone=${data?.parentClient?.cell_phone?.replace(
                    /\D/g,
                    '',
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    display: 'flex',
                  }}
                >
                  <WhatsApp fontSize="small" style={{ marginRight: '5px' }} />
                  {data?.parentClient?.cell_phone}
                </a>
              </Box>

              <TextWithLabel label="Email" value={data?.parentClient?.email} />

              {data?.activity?.type !== 'faculty' && (
                <TextWithLabel
                  label="Aluno"
                  value={data?.client?.fantasy_name}
                />
              )}

              <TextWithLabel
                label="Valor Total"
                value={currencyMask(data?.payment_value || '0,00')}
              />

              <TextWithLabel
                label="Situação reserva"
                value={
                  RESERVATION_SITUATION_TYPES[
                    data?.situation as ReservationSituation
                  ]
                }
              />

              <TextWithLabel
                label="Situação pagamento"
                value={
                  RESERVATION_SITUATION_PAYMENT_TYPES[
                    (data?.financialMovements?.[0]?.situation ||
                      '') as ReservationPaymentSituation
                  ]
                }
              />

              {data?.situation === 'cancelled' && (
                <TextWithLabel
                  label="Data de cancelamento"
                  value={data?.cancellation_date?.substring(0, 10)}
                />
              )}

              <TextWithLabel
                label="Data de criação"
                value={data?.created_at?.substring(0, 10)}
              />
              {data?.situation === 'cancelled' && (
                <div style={{ gridColumn: '1/-1' }}>
                  <TextWithLabel
                    label="Motivo do cancelamento"
                    value={data?.cancellation_description}
                  />
                </div>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <>
                <Tabs
                  value={currentTab}
                  onChange={(_event, value) => {
                    setCurrentTab(value as TABS)
                  }}
                >
                  <Tab label="Movimentações Financeiras" />
                  <Tab
                    label="Observações"
                    style={{
                      color: data?.reservationsObservations.length
                        ? 'red'
                        : 'inherit',
                    }}
                  />
                </Tabs>

                <TabPanel value={TABS.FINANCIAL_MOVEMENTS} index={currentTab}>
                  <Table
                    rows={payments}
                    columns={financialMovementsColumns}
                    getRowHeight={() => 'auto'}
                  />
                </TabPanel>
                <TabPanel value={TABS.OBSERVATIONS} index={currentTab}>
                  <Table
                    rows={data?.reservationsObservations}
                    columns={observatinsColumns}
                    getRowHeight={() => 'auto'}
                  />
                </TabPanel>
              </>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ConfirmationModal
        title="Cancelar reserva"
        opened={cancelModal}
        onClose={() => setCancelModal(false)}
        onConfim={onConfirmCancel}
        onCancel={() => setCancelModal(false)}
      >
        <label htmlFor="cancel-reason"></label>
        <textarea
          name="cancel-reason"
          style={{ width: '100%' }}
          rows={5}
          onChange={(e) => setCancelReason(e.target.value)}
        ></textarea>
        <input
          type="checkbox"
          name="is-reverse-payment"
          style={{ width: '15px', height: '15px', marginTop: '20px' }}
          checked={isReversePayment}
          onChange={() => setIsReversePayment((prev) => !prev)}
        ></input>
        <label htmlFor="is-reverse-payment">Devolver pagamento</label>
      </ConfirmationModal>
      <ConfirmationModal
        title={obsId ? 'Editar Observação' : 'Adicionar Observação'}
        opened={obsModal}
        onClose={() => {
          setObsModal(false)
          setObsId('')
          setObsReservation('')
        }}
        onConfim={onAddObservation}
        onCancel={() => {
          setObsModal(false)
          setObsId('')
          setObsReservation('')
        }}
      >
        <label htmlFor="obs"></label>
        <textarea
          name="obs"
          style={{ width: '100%' }}
          rows={5}
          value={obsReservation}
          onChange={(e) => setObsReservation(e.target.value)}
        ></textarea>
      </ConfirmationModal>
      <ConfirmationModal
        title="Deseja enviar o email de confirmação de pagamento?"
        opened={sendEmailConfirmationPaymentModal}
        onConfim={async () => {
          await onConfirmDownloadPaymentManualModal(true)
        }}
        onCancel={async () => {
          await onConfirmDownloadPaymentManualModal()
        }}
        text={{
          cancel: 'Não',
          confirm: 'Sim',
        }}
      />
      <ConfirmationModal
        title={'Baixa manual de pagamento'}
        opened={downloadPaymentManualModal || changeSituationToPaymentConfirmed}
        onClose={handleCloseDownloadManualPayment}
        onConfim={async () => {
          const isValid = await reactHookFormDownloadPaymentManual.trigger([
            'payment_method',
            'settlement_date',
          ])
          if (!isValid) return
          setSendEmailConfirmationPaymentModal(true)
        }}
        onCancel={handleCloseDownloadManualPayment}
        text={{
          confirm: changeSituationToPaymentConfirmed ? 'Sim' : 'Confirmar',
        }}
        onlyConfirmed
      >
        <Grid container xs={12} spacing={0.3} style={{ width: '500px' }}>
          <Grid item xs={6}>
            <Controller
              name="payment_method"
              control={reactHookFormDownloadPaymentManual.control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Forma de pagamento"
                    id="payment_method"
                    onChange={onChange}
                    value={value}
                    options={FINANCIAL_MOVEMENT_PAYMENT_METHODS_OPTIONS}
                    error={
                      !!reactHookFormDownloadPaymentManual.formState.errors
                        .payment_method?.message
                    }
                    helperText={
                      reactHookFormDownloadPaymentManual.formState.errors
                        .payment_method?.message
                    }
                  />
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', flexGrow: 1, margin: '16px 0 8px' }}
          >
            <Controller
              name="settlement_date"
              control={reactHookFormDownloadPaymentManual.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    error={
                      !!reactHookFormDownloadPaymentManual.formState?.errors
                        ?.settlement_date?.message
                    }
                    helperText={
                      reactHookFormDownloadPaymentManual.formState?.errors
                        ?.settlement_date?.message
                    }
                    label="Data de liquidação"
                    id="settlement_date"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </ConfirmationModal>
      <ConfirmationModal
        title="Editar"
        opened={editModal}
        onClose={() => setEditModal(false)}
        onConfim={onConfirmEditReservation}
        onCancel={() => setEditModal(false)}
      >
        <Grid container spacing={3} xs={12} style={{ width: '500px' }}>
          <Grid item xs={6}>
            <Controller
              name="situation"
              control={reactHookFormReservation.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    label="Situação"
                    id="situation"
                    onChange={onChange}
                    value={value}
                    options={FINANCIAL_SITUATION_OPTIONS}
                    error={
                      !!reactHookFormReservation.formState.errors.situation
                        ?.message
                    }
                    helperText={
                      reactHookFormReservation.formState.errors.situation
                        ?.message
                    }
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="value"
              control={reactHookFormReservation.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputCurrency
                    error={
                      !!reactHookFormReservation.formState?.errors.value
                        ?.message
                    }
                    helperText={
                      reactHookFormReservation.formState?.errors.value?.message
                    }
                    fullWidth
                    label="Preço"
                    id="price"
                    onChange={onChange}
                    value={value}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </ConfirmationModal>

      <DeleteModal
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        reservation={deleteConfirmation as Reservation}
      />
    </>
  )
}
